import React from "react";
import { useSelector } from "react-redux";
import "./styles.css";

const FloatingMessageBar = ({ type, phone }) => {
  const { insuranceDetail } = useSelector((state) => state.leads);
  const [position, setPosition] = React.useState({ x: 0, y: 0 });
  const handleMouseDown = (event) => {
    const startX = event.pageX - position.x;
    const startY = event.pageY - position.y;
    const handleMouseMove = (event) => {
      const newPosX = event.pageX - startX;
      const newPosY = event.pageY - startY;
      setPosition({ x: newPosX, y: newPosY });
    };
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", () => {
      document.removeEventListener("mousemove", handleMouseMove);
    });
  };

  const handleTouchStart = (event) => {
    const startX = event.touches[0].pageX - position.x;
    const startY = event.touches[0].pageY - position.y;
    const handleTouchMove = (event) => {
      const newPosX = event.touches[0].pageX - startX;
      const newPosY = event.touches[0].pageY - startY;
      setPosition({ x: newPosX, y: newPosY });
    };
    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("touchend", () => {
      document.removeEventListener("touchmove", handleTouchMove);
    });
  };
  return (
    <div
      className={`floating-message-bar ${type}`}
      style={{ transform: `translate(${position.x}px, ${position.y}px)` }}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
    >
      <p>
        Call from{" "}
        <span className="font-semibold">{insuranceDetail?.insurance_comp}</span>
        , <span className="font-semibold">INSURANCE #: </span>
        {phone.replaceAll("+1", "")},{" "}
        <span className="font-semibold">DID CALLED: </span>{" "}
        {insuranceDetail?.vendor_phone},{" "}
        <span className="font-semibold">VENDOR NAME: </span>{" "}
        {insuranceDetail?.vendor_name}
      </p>
    </div>
  );
};

export default FloatingMessageBar;
