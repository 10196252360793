import React, { useState } from "react";
import { Input, Button, Loader } from "../../components";
import { verified_crm_logo, Verified_login_bg } from "../../images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faShieldHalved } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { login, reset } from "../../features/authSlice";
import { browserName, osName } from "react-device-detect";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { FaEye } from "react-icons/fa";

const VerifiedLogin = () => {
  const dispatch = useDispatch();
  const { isLoading, isError, message, errors } = useSelector(
    (state) => state.auth
  );

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const submitHandler = async (event) => {
    event.preventDefault();
    const userData = {
      username,
      password,
    };
    const header = {
      browser: browserName,
      os: osName,
      mac_address: "mac Address",
    };
    dispatch(login({ userData, header }));
  };
  const showPas = () => {
    setShowPassword(!showPassword);
  };
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [showPass, setShowPass] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(null);

  function isJSON(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }
  function ErrorModal() {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 top-0 left-0 right-0 bottom-0 bg-black/50 px-4">
        <div className="bg-white p-8 rounded-lg shadow-lg ">
          <h1 className="text-center border-b pb-1">
            Please share this error in a group
          </h1>
          <div className="max-h-[70vh] overflow-y-auto">
            {errorMsg?.includes("\n")
              ? errorMsg?.split("\n")?.map((txt) => {
                  return <div className="text-lg font-medium">{txt}</div>;
                })
              : ""}
          </div>

          <div className="mt-2 flex justify-end items-center">
            <Button
              text={"Close"}
              variant={"btn_cancel"}
              onClick={() => setErrorMsg(null)}
            />
          </div>
        </div>
      </div>
    );
  }
  const restartServer = async () => {
    try {
      setIsLoader(true);
      const res = await axios.post(
        `https://pm2.ecommcube.com/run_sudo_command`,
        {
          host: "51.81.232.138",
          command_type: "restart",
          pm2_process_name: "crm_backend",
          api_call: {
            url: "https://api.logicalcrm.com/",
            config: {
              method: "GET",
            },
          },
          send_response_to: [
            "pratham@interbitsolutions.com",
            "vishnu@interbitsolutions.com",
            "sameer@interbitsolutions.com",
            "faaizseemab@gmail.com",
          ],
        }
      );

      if (res.status === 200) {
        setErrorMsg(res?.data?.data);
        toast.success("Server Restart successfully");
      }
      setIsLoader(false);
    } catch (error) {
      setErrorMsg(isJSON(error) ? JSON.stringify(error) : error);
      setIsLoader(false);
      console.error("🚀 ~ file: index.jsx:65 ~ restartServer ~ error:", error);
    }
  };
  const [pass, setPass] = useState("");
  function CustomModal({ isOpen, onClose }) {
    const handleConfirm = () => {
      // Perform the action here with the selectedOption
      if (!pass) {
        return toast.error("Please enter a password");
      } else if (pass === "crm-restart-5781") {
        restartServer();
      } else {
        return toast.error("Wrong password");
      }

      // Close the modal
      onClose();
    };
    return (
      <>
        <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg relative">
            <h2 className="text-lg font-semibold mb-4">Confirmation</h2>
            <p className="mb-4">
              Are you sure you want to restart the application?
            </p>
            <label className="block">Enter a password:</label>
            <div className="relative flex flex-row">
              <input
                className="bg-white border border-gray-300 text-sm rounded w-[85%] p-2.5 "
                onChange={(e) => setPass(e.target.value)}
                value={pass}
                name="pass"
                placeholder={"Enter a password"}
                type={showPass ? "text" : "password"}
                autoFocus={true}
              />
              <div
                className="border rounded ml-1 h-[42px] w-[42px] flex justify-center items-center cursor-pointer"
                onClick={() => setShowPass(!showPass)}
              >
                <FaEye className="absolute right " />
              </div>
            </div>

            <div className="flex justify-end !mt-4">
              <button
                className="px-4 py-2 mr-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                onClick={handleConfirm}
              >
                OK
              </button>
              <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="lg:h-screen w-full font-mont flex flex-col lg:flex-row justify-center items-center">
        {isModalOpen ? (
          <CustomModal
            onClose={() => {
              setPass("");
              setIsModalOpen(false);
            }}
          />
        ) : null}
        {errorMsg ? <ErrorModal /> : null}
        <div className="w-full lg:h-screen bg-[#FCC81C] pb-4">
          {/* FCC81C    fdbd00 */}
          {/* <img src={new_login_bg} className="inline-block" alt="login_bg" /> */}
          {/* <div className="w-full px-10 py-5 flex justify-between items-center mb-12">
            <div className="bg-white rounded p-3">
              <img
                src={verified_crm_logo}
                alt="login logo"
                style={{ width: "180px" }}
              />
            </div>
            <div className="text-2xl">
              <Link
                to="tel:8002324"
                className="hover:text-black flex items-center gap-2"
              >
                <span className="bg-white rounded-full w-12 h-12 flex justify-center items-center">
                  <FaPhoneAlt />
                </span>{" "}
                Call Us - <strong>8002324</strong>
              </Link>
            </div>
          </div> */}
          <div
            className={`w-full px-10 pt-8 position-relative flex justify-center items-center h-screen`}
          >
            <div className="hidden lg:block absolut right-2 bottom-6 2xl:right-80 2xl:bottom-2 z-1">
              <img
                src={Verified_login_bg}
                alt=""
                className="w-[400p] 2xl:w-full"
              />
            </div>
            {/* <div className="mb-6 position-relative z-10">
              <h1 className="font-extrabold text-5xl">
                A Trusted Verified CRM
              </h1>
              <h2 className="font-normal text-5xl">& Gold Company</h2>
            </div> */}
            {/* <div className="w-full flex flex-col md:flex-row gap-4 position-relative z-10">
              <div>
                <div className="mb-3">
                  <p className="text-2xl">
                    Premiere Editorial <strong>Verified</strong> That Make The
                    Difference
                  </p>
                </div>
                <div className="mb-3">
                  <ul className="list-disc text-2xl pl-5">
                    <li>Establish Online Authority</li>
                    <li>Improve Digital Presence</li>
                    <li>Expert Editorial Team</li>
                    <li>1000s of High Ranking Domains</li>
                    <li>Placements Gauranteed or Don't Pay</li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-4 items-end">
                <img src={gold} alt="Gold" className="w-[200px]" />
                <img src={silver} alt="Silver" className="w-[200px]" />
              </div>
            </div> 

            {/* <div className="mt-6 mb-6">
              <h3 className="text-white [text-shadow:1px_1px_2px_var(--tw-shadow-color)] shadow-neutral-500 text-3xl font-extrabold">
                Get Started Today!
              </h3>
            </div>
            <div className="flex gap-3">
              <button className="bg-black text-white py-3 px-6 rounded text-xl font-semibold">
                Get Free Access
              </button>
              <button className="border-2 border-black text-black py-3 px-6 rounded text-xl font-semibold">
                Contact Sales
              </button>
            </div> */}
          </div>
        </div>{" "}
        <div className="w-full md:w-[600px] max-w-[100%] bg-white flex flex-col   p-4  text-black h-screen">
          <div className="flex w-full !mb-2 justify-center">
            <img src={verified_crm_logo} alt="login logo" />
          </div>
          <div className="my-16 text-black text-center">
            <h1 className="text-3xl font-bold">Hi, Welcome Back!</h1>
            <h5 className="text-sm text-[#aaa]">
              Customer Relation Management Solution
            </h5>
          </div>
          {/* <div className="mb-3">
            <FormRadioGroup
              name="loginType"
              isOnChange={(e) => setLoginType(e)}
              labelProp="label"
              valueProp="value"
              options={[
                { label: "User Login", value: "user" },
                { label: "Vendor Login", value: "vendor" },
              ]}
              isCheck={loginType}
              isHorizontal
            />
          </div> */}
          <form onSubmit={submitHandler} className="mb-10">
            <div className="relative">
              <label className="mb-1">Username</label>
              <Input
                id="email"
                placeholder="Username"
                className="bg-white text-[#38015c] border border-[#d7d7d7] w-[98%] text-left py-3 px-2.5 h-[60px]"
                value={username}
                onChange={({ target: { value } }) => setUsername(value)}
                name="username"
                errors={errors}
              />
              <div className="absolute top-11 right-3.7 text-lg text-[#7c7f82]">
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
            </div>
            <div className="relative mt-2">
              <label className="mb-1">Password</label>
              <Input
                placeholder="Password"
                id="password"
                type={showPassword ? "text" : "password"}
                className="bg-white text-[#38015c] border border-[#d7d7d7] w-[98%] text-left py-3 px-2.5 h-[60px]"
                value={password}
                onChange={({ target: { value } }) => setPassword(value)}
                name="password"
                errors={errors}
              />
              <div className="absolute top-11 right-3.7 text-lg text-[#7c7f82]">
                <FontAwesomeIcon icon={faShieldHalved} />
              </div>
            </div>
            {isError && !errors && (
              <div className="text-[#f00] text-sm mb-3.7 mt-1.5">{message}</div>
            )}

            <div className="text-black mb-6 flex justify-between mt-1.5 px-2.5">
              <div className="cursor-pointer">
                <input
                  type="checkbox"
                  id="showpass"
                  name="showpass"
                  value={showPassword}
                  onChange={showPas}
                  className="cursor-pointer"
                />
                <label htmlFor="showpass" className="ml-2 cursor-pointer">
                  Show Password
                </label>
              </div>

              <NavLink
                to={`/forgot_password`}
                className="text-primary-100 text-base underline"
                onClick={() => dispatch(reset())}
              >
                Forgot Password?
              </NavLink>
            </div>
            <div className="">
              {!isLoading && (
                <Button
                  text="Login"
                  type="submit"
                  className="!w-full !rounded-lg !bg-[#FCC81C] text-white cursor-pointer !p-4 text-xl font-normal uppercase w-[98%]"
                />
              )}
              {isLoading && <Loader />}
            </div>
          </form>
          {/* <div className="flex items-end justify-end">
            {!isLoader && (
              <Button
                text="Restart Application"
                type="button"
                className="!w-1/3 !rounded-lg bg-red-600 text-white cursor-pointer !p-1 text-normal font-normal uppercase"
                onClick={() => setIsModalOpen(true)}
              />
            )}
            {isLoader && <Loader />}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default VerifiedLogin;
