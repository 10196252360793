import React, { useState } from "react";
import api from "../../../../services/api";
import PageHeader from "../../../../components/molecules/PageHeader";
import { DotsLoader, Loader } from "../../../../components";
import MUIDataTable from "../../../../components/molecules/DataTable/muigrid";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaPlay, FaTrash } from "react-icons/fa";
import dayjs from "dayjs";
import { HiOutlineRefresh } from "react-icons/hi";
import { TbReload } from "react-icons/tb";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button } from "@mui/material";
import { MdRefresh } from "react-icons/md";
function KeywordsBatches() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [record, setRecord] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const navigate = useNavigate();

  const getSERPReport = async () => {
    setIsLoading(true);
    try {
      const res = await api.get("/api/domains/list_batches");
      if (res.status === 200) {
        setRecord(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("🚀 ~ getSERPReport ~ err:", err);
    }
  };
  React.useEffect(() => {
    getSERPReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleStart = async (batch_id) => {
    const c = window.confirm("Are you sure want to start the batch?");
    if (!c) return;
    setIsLoading(true);
    try {
      const res = await api.post(`/api/domains/start_batch/${batch_id}`);
      if (res.status === 200) {
        toast.success("Batch started successfully");
      } else {
        toast.error(res?.data?.message || "Batch couldn't be started");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(err?.response?.data?.message || "Batch couldn't be started");
      console.log("🚀 ~ handleStart ~ err:", err);
    }
  };
  const handleDelete = async (batch_id) => {
    const c = window.confirm("Are you sure want to delete the batch?");
    if (!c) return;
    setIsLoading(true);
    try {
      const res = await api.delete(`/api/domains/delete_batch/${batch_id}`);
      if (res.status === 200) {
        getSERPReport();
        toast.success("Batch deleted successfully");
      } else {
        toast.error("Batch couldn't be deleted");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error("Batch couldn't be deleted");
      console.log("🚀 ~ handleStart ~ err:", err);
    }
  };
  const handleRegenerate = async (batch_id) => {
    const c = window.confirm("Are you sure want to regenerate the batch?");
    if (!c) return;
    setIsLoading(true);
    try {
      const res = await api.get(`/api/domains/regenerate_batch/${batch_id}`);
      if (res.status === 200) {
        getSERPReport();
        toast.success("Batch regenerate successfully");
      } else {
        toast.error("Batch couldn't be regenerate");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error("Batch couldn't be regenerate");
      console.log("🚀 ~ handleStart ~ err:", err);
    }
  };
  const onSingleSelect = ({ checked, data }) => {
    try {
      if (checked) {
        setSelectedData((prevSelectedData) => {
          const updatedSelectedData = [...prevSelectedData, data];
          return updatedSelectedData;
        });
      } else {
        setSelectedData((prevSelectedRecord) => {
          const updatedSelectedRecord = prevSelectedRecord?.filter(
            (domain) => domain?.id !== data?.id
          );
          return updatedSelectedRecord;
        });
      }
    } catch (err) {
      console.log("🚀 ~ file: index.jsx:116 ~ onSingleSelect ~ err:", err);
    }
  };
  const onSelectAll = (checked) => {
    if (checked) {
      const updatedArray = batchList?.map((item) => item);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };
  const isSelected = (domain) => {
    if (selectedData?.length > 0) {
      if (selectedData?.filter(({ _id }) => _id === domain?._id).length > 0) {
        return true;
      }
    }
    return false;
  };
  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          checked={
            batchList?.length && selectedData?.length === batchList?.length
          }
          disabled={!batchList?.length}
        />
      ),
      filterable: false,
      sortable: false,
      width: 60,
      renderCell: (params) =>
        isSelected(params.row.records) && isLoader ? (
          <Loader width={30} height={30} />
        ) : (
          <input
            type="checkbox"
            checked={isSelected(params.row.records)}
            onChange={(e) =>
              onSingleSelect({
                checked: e.target.checked,
                data: params.row.records,
              })
            }
            className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          />
        ),
      disableColumnMenu: true,
    },
    { headerName: "#", field: "counter", width: 60, filterable: false },
    {
      headerName: "Batch ID",
      field: "valueserp_id",
      flex: 1,
      renderCell: (params) => (
        <span
          onClick={() =>
            navigate(
              `/domain_rank/searches/${params?.row?.records?.valueserp_id}`
            )
          }
          className="text-blue-600 hover:underline cursor-pointer"
        >
          {params?.row?.valueserp_id}
        </span>
      ),
    },
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      renderCell: (params) => (
        <span
          onClick={() =>
            navigate(
              `/domain_rank/searches/${params?.row?.records?.valueserp_id}`
            )
          }
          className="text-blue-600 hover:underline cursor-pointer"
        >
          {params?.row?.name}
        </span>
      ),
    },
    { headerName: "Short Note", field: "short_note", flex: 1 },
    {
      headerName: "Schedule Type",
      field: "schedule_type",
      flex: 1,
    },
    {
      headerName: "Last Run",
      field: "last_run",
      flex: 1,
    },
    {
      headerName: "Status",
      field: "status",
      flex: 1,
    },
    {
      headerName: "Searches Total Count",
      field: "searches_total_count",
      flex: 1,
    },
    {
      headerName: "Notification Email",
      field: "notification_email",
      flex: 1,
    },
    { headerName: "Enabled", field: "enabled", align: "center", width: 80 },
    {
      headerName: "Batch Run Count",
      field: "results_count",
      flex: 1,
      align: "center",
    },
    {
      headerName: "Batch Delete Status",
      field: "delete_status",
      flex: 1,
      align: "center",
    },
    {
      headerName: "Batch Result Status",
      field: "result_set_status",
      flex: 1,
      align: "center",
    },
    {
      headerName: "Action",
      field: "action",
      flex: 1,
      renderCell: (params) => (
        <div>
          <button
            onClick={() => handleStart(params.row?.records?.valueserp_id)}
            title={"Start the position checking process"}
          >
            <FaPlay />
          </button>
          <button
            className="ml-2"
            onClick={() => handleDelete(params.row?.records?.valueserp_id)}
            title={"Delete batch"}
          >
            <FaTrash />
          </button>
          {params.row?.delete_status === "Deleted" ? (
            <button
              className="ml-2"
              onClick={() =>
                handleRegenerate(params.row?.records?.valueserp_id)
              }
              title={"Regenerate batch"}
            >
              <TbReload />
            </button>
          ) : null}
        </div>
      ),
    },
  ];
  const batchList = record?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const RefreshButton = () => {
    return (
      <button
        className="bg-primary-100 text-white ml-2 h-[35px] w-[35px] flex justify-center items-center rounded-sm"
        onClick={getSERPReport}
      >
        <HiOutlineRefresh />
      </button>
    );
  };
  async function handleBulkDelete() {
    if (isLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    if (selectedData?.length === 0) {
      return toast.error("Please select the batch");
    }
    const c = window.confirm(
      "are you sure want to delete the selected batches?"
    );
    if (!c) return;
    setIsLoader(true);
    const selectedDataCopy = [...selectedData];
    for (const item of selectedDataCopy) {
      try {
        // onClick={() => handleDelete(params.row?.records?.valueserp_id)}
        const response = await api.delete(
          `/api/domains/delete_batch/${item?.valueserp_id}`
        );

        if (response.status === 200) {
          toast.success(`Batch No. ${item?.valueserp_id} deleted successfully`);
          setSelectedData((prevState) =>
            prevState.filter(
              ({ valueserp_id }) => valueserp_id !== item?.valueserp_id
            )
          );
        } else {
          toast.error(`Batch No. ${item?.valueserp_id} couldn't be deleted`);
        }
      } catch (error) {
        console.log("🚀 ~ file: index.jsx:252 ~ sendRequests ~ error:", error);
        toast.error(`Batch No. ${item?.valueserp_id} couldn't be deleted`);
      }
    }

    setIsLoader(false);
    getSERPReport();
    // Clear the selectedData after processing all requests
    setSelectedData([]);
  }
  async function handleBulkStart() {
    if (isLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    if (selectedData?.length === 0) {
      return toast.error("Please select the batch");
    }
    const c = window.confirm(
      "are you sure want to start the selected batches?"
    );
    if (!c) return;
    setIsLoader(true);
    const selectedDataCopy = [...selectedData];
    for (const item of selectedDataCopy) {
      try {
        // onClick={() => handleDelete(params.row?.records?.valueserp_id)}
        const response = await api.post(
          `/api/domains/start_batch/${item?.valueserp_id}`
        );
        if (response.status === 200) {
          toast.success(`Batch No. ${item?.valueserp_id} started successfully`);
          setSelectedData((prevState) =>
            prevState.filter(
              ({ valueserp_id }) => valueserp_id !== item?.valueserp_id
            )
          );
        } else {
          toast.error(
            `Batch No. ${item?.valueserp_id} ${response?.data?.message}` ||
              `Batch No. ${item?.valueserp_id} couldn't be started`
          );
        }
      } catch (error) {
        console.log("🚀 ~ file: index.jsx:252 ~ sendRequests ~ error:", error);
        toast.error(
          `Batch No. ${item?.valueserp_id} ${error?.response?.data?.message}` ||
            `Batch No. ${item?.valueserp_id} couldn't be started`
        );
        // toast.error(`Batch No. ${item?.valueserp_id} couldn't be started`);
      }
    }

    setIsLoader(false);

    // Clear the selectedData after processing all requests
    setSelectedData([]);
  }
  async function handleBulkRegenerate() {
    if (isLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    if (selectedData?.length === 0) {
      return toast.error("Please select the deleted batch");
    }
    const c = window.confirm(
      "are you sure want to regenerate the selected batches?"
    );
    if (!c) return;
    setIsLoader(true);
    const deletedBatched = selectedData?.filter(
      ({ delete_status }) => delete_status === "Deleted"
    );
    const selectedDataCopy = [...deletedBatched];
    for (const item of selectedDataCopy) {
      try {
        const response = await api.post(
          `/api/domains/regenerate_batch/${item?.valueserp_id}`
        );
        if (response.status === 200) {
          toast.success(`Batch No. ${item?.valueserp_id} started successfully`);
          setSelectedData((prevState) =>
            prevState.filter(
              ({ valueserp_id }) => valueserp_id !== item?.valueserp_id
            )
          );
        } else {
          toast.error(
            `Batch No. ${item?.valueserp_id} ${response?.data?.message}` ||
              `Batch No. ${item?.valueserp_id} couldn't be started`
          );
        }
      } catch (error) {
        console.log("🚀 ~ file: index.jsx:252 ~ sendRequests ~ error:", error);
        toast.error(
          `Batch No. ${item?.valueserp_id} ${error?.response?.data?.message}` ||
            `Batch No. ${item?.valueserp_id} couldn't be started`
        );
      }
    }
    setIsLoader(false);
    setSelectedData([]);
  }
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <Button
          variant="text"
          onClick={handleBulkDelete}
          startIcon={<FaTrash size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Delete Batches
        </Button>
        <Button
          variant="text"
          onClick={handleBulkStart}
          startIcon={<FaPlay size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Start Batches
        </Button>
        <Button
          variant="text"
          onClick={handleBulkRegenerate}
          startIcon={<MdRefresh size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Regenerate Batches
        </Button>
      </GridToolbarContainer>
    );
  }
  return (
    <>
      <PageHeader route="Setting > Industries" heading="Batch List" />
      {isLoading ? <DotsLoader /> : null}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={batchList?.map((record, index) => {
            const counter = index + 1;
            const {
              name,
              enabled,
              schedule_type,
              last_run,
              searches_total_count,
              notification_email,
              short_note,
              valueserp_id,
              status,
              results_count,
              delete_status,
              result_set_status,
            } = record;
            return {
              records: { ...record },
              counter,
              name,
              schedule_type,
              last_run: last_run
                ? dayjs(last_run).format("ddd, MMM D, YYYY h:mm A")
                : "",
              searches_total_count,
              notification_email,
              short_note,
              enabled: enabled ? "Yes" : "No",
              valueserp_id,
              status,
              results_count,
              delete_status,
              result_set_status,
            };
          })}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          AfterSearch={RefreshButton}
          pagination="No"
          CustomToolbar={CustomToolbar}
        />
      </div>
    </>
  );
}

export default KeywordsBatches;
