import React, { useEffect, useState } from "react";
import AddUpdateForm from "./AddUpdateForm";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PageHeader from "../../components/molecules/PageHeader";
import errorMessage from "../../util/errorMessage";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { Button } from "../../components";
import {
  delete_lead_status,
  get_lead_status,
} from "../../features/leadStatusSlice";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate, useLocation } from "react-router-dom";

const LeadStatus = () => {
  const location = useLocation();
  const { isLoading, record } = useSelector((state) => state.leadStatus);
  const dispatch = useDispatch();
  const optionsRef = React.useRef();
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showMenu, setShowMenu] = useState(null);
  const navigate = useNavigate();
  const handleHideDropdown = (event) => {
    if (showMenu && event.key === "Escape") {
      setShowMenu(null);
    }
  };
  const handleClickOutside = (event) => {
    if (
      showMenu &&
      optionsRef.current &&
      !optionsRef.current.contains(event.target)
    ) {
      setShowMenu(null);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  const deleteRecordHandler = async (record_id) => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_lead_status(record_id));
        if (res?.payload?.status === 200) {
          toast.success("Lead Status deleted");
          dispatch(get_lead_status());
        } else {
          errorMessage({
            payload: res.payload,
            action: "Lead Status",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({ payload: error, action: "Lead Status", msg: "deleted" });
      }
    }
  };

  useEffect(() => {
    dispatch(get_lead_status());
    // eslint-disable-next-line
  }, []);
  const status = record?.filter((record) =>
    record?.title?.includes(searchText)
  );
  const openActionAssignForm = (record) => {
    navigate("/settings/administration/lead_status/assign_lead_actions", {
      state: record,
    });
  };
  const openActionEmailForm = (record) => {
    navigate("/settings/administration/lead_status/assign_lead_email", {
      state: record,
    });
  };
  const testMenu = [
    {
      label: "Actions",
      action: (e) => {
        openActionAssignForm(e);
      },
    },
    {
      label: "Assign Email",
      action: (e) => {
        openActionEmailForm(e);
      },
    },
    // {
    //   label: "Edit",
    //   action: (e) => {
    //     openFormHandler(e);
    //     setShowMenu(null);
    //   },
    // },
    {
      label: "Delete",
      action: (e) => {
        deleteRecordHandler(e._id);
        setShowMenu(null);
      },
    },
  ];
  const getLuminance = (color) => {
    const rgb = color.match(/\w\w/g).map((c) => parseInt(c, 16) / 255);
    const [r, g, b] = rgb.map((c) => {
      if (c <= 0.03928) {
        return c / 12.92;
      }
      return Math.pow((c + 0.055) / 1.055, 2.4);
    });
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luminance;
  };

  const isDarkColor = (color) => {
    const luminance = getLuminance(color);
    return luminance < 0.5; // Adjust the threshold as needed
  };
  const columnDefs = [
    { headerName: "#", field: "counter", width: 60 },
    {
      headerName: "Title",
      field: "title",
      // flex: 1,
      minWidth: 300,
      // renderCell: (params) => {
      //   const backgroundColor = params?.row?.color || "#000000";
      //   const textColor = isDarkColor(backgroundColor) ? "#000000" : "#000000";
      //   return (
      //     <div
      //       style={{
      //         // backgroundColor,
      //         color: backgroundColor,
      //         flex: 1,
      //         height: 40,
      //         alignContent: "center",
      //         justifyContent: "center",
      //       }}
      //       className={`py-0.5 px-0.5 bg-[${params?.row?.color} rounded-[20px] min-w-[72px] text-center flex items-center`}
      //     >
      //       {params?.row?.title}
      //     </div>
      //   );
      // },
    },
    // { headerName: "Parent Status", field: "parent_id", flex: 1, minWidth: 200 },
    { headerName: "Milestone", field: "milestone", flex: 1, minWidth: 120 },
    { headerName: "Lead State", field: "lead_state", flex: 1, minWidth: 100 },
    {
      headerName: "Action",
      field: "actions",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <div>
          <BsThreeDotsVertical
            size={18}
            className="cursor-pointer !relative hover:text-primary-100"
            onClick={() => setShowMenu(params.row?.records?._id)}
          />
          {showMenu === params.row?.records?._id ? (
            <div
              ref={optionsRef}
              className="absolute w-auto right-12 min-w-[100px] rounded shadow !mt-1 bg-white z-20 border-x border-y border-[#ddd] -ml-px overflow-hidden transition ease-in-out delay-150"
            >
              <ul className="!pl-0 !mb-0">
                {testMenu?.map((option, index) => {
                  // if (option.label === "Actions" && !params.row.parent_id) {
                  //   return null; // Skip rendering this option if there is no parent_id
                  // }
                  return (
                    <li
                      key={index}
                      className="cursor-pointer px-3 !py-1.25 border- border-[#ddd] hover:bg-[#e1e1e1]"
                      onClick={() => option.action(params.row?.records)}
                    >
                      {option.label}
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}
        </div>
      ),

      width: 100,
    },
    // {
    //   field: "actions",
    //   renderCell: (params) => (
    //     <div>
    //       <FaEdit
    //         onClick={openFormHandler(params.row.records)}
    //         className="my_navIcon"
    //         title="Update Lead Status"
    //       />
    //       <FaTrashAlt
    //         onClick={deleteRecordHandler(params.row.records._id)}
    //         className="my_navIcon"
    //         title="Delete Lead Status"
    //       />
    //     </div>
    //   ),
    // },
  ];
  const CustomButtons = () => {
    return (
      <div className="flex gap-x-2">
        {/* <Button
          text="Add New"
          variant="btn_submit"
          onClick={() => openFormHandler(0)}
        /> */}
        <Button
          text="Manage Lead Actions"
          variant="btn_submit"
          onClick={() =>
            navigate(
              `${location?.pathname?.replace("lead_status", "lead_actions")}`
            )
          }
        />
      </div>
    );
  };
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add Lead Status"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        route="Setting > Lead Status"
        heading=""
        CustomButtons={CustomButtons}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={status?.map((item, index) => {
            let counter = index + 1;
            const { title, color, milestone, parent_id, lead_state } = item;
            return {
              counter,
              title,
              parent_id: record?.find(({ _id }) => _id === parent_id)?.title,
              color,
              milestone,
              records: item,
              lead_state,
            };
          })}
          pagination={"No"}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          searchable="No"
          showCount="No"
          density="Standard"
          height="76vh"
        />
      </div>
    </>
  );
};

export default LeadStatus;
