import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Input, InputLabel, Button as MUIButton } from "@mui/material";
import { FaInfoCircle, FaMinus, FaSearch, FaSync } from "react-icons/fa";
import PageHeader from "../../../components/molecules/PageHeader";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import {
  get_lead_job_count,
  get_namesilo_domains_report,
} from "../../../features/domainCenterSlice";
import {
  Button,
  DotsLoader,
  FormSelectInput,
  Loader,
} from "../../../components";
import { toast } from "react-toastify";
import AutoRenewModal from "./AutoRenewModal";
import api from "../../../services/api";
import { Tooltip } from "primereact/tooltip";
import * as XLSX from "xlsx";
import VendorsListModal from "./VendorsList";
import { MdOutlineGetApp, MdOutlineQueuePlayNext } from "react-icons/md";
import { get_industry_drd } from "../../../features/IndustrySlice";
import SelectedDataModal from "./SelectedDomains";
import LeadJobListModal from "./LeadJobList";
import { get_job_status_with_job_count } from "../../../features/jobStatusSlice";
const NamesiloReport = () => {
  const dispatch = useDispatch();
  const [vendors, setVendors] = useState(null);
  const [countModal, setCountModal] = useState(null);
  const { isLoading, namesiloDomains } = useSelector(
    (state) => state.domainCenter
  );
  const { industryDrd } = useSelector((state) => state.industry);
  const { jobStatuses } = useSelector((state) => state.jobStatus);
  const [searchText, setSearchText] = useState("");
  const [customFilter, setCustomFilter] = useState(null);
  const [renewModal, setRenewModal] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showSelectedData, setShowSelectedData] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "AND",
    rules: [],
  });
  const [renewStatus, setRenewStatus] = useState(1);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    setSelectedData([]);
    let queryRules = { ...queryOptions };
    const hasDljcJobStatusRule = queryRules?.rules?.some(
      ({ field }) => field === "dljc.job_status"
    );
    const hasMpdJobCountRule = queryRules?.rules?.find(
      ({ field }) => field === "mpd.job_count"
    );
    if (hasDljcJobStatusRule && hasMpdJobCountRule) {
      queryRules = {
        ...queryRules,
        rules: [
          ...queryRules.rules.filter(
            ({ field }) =>
              field !== "dljc.job_count" && field !== "mpd.job_count"
          ),
          {
            field: "dljc.job_count",
            op: "gte",
            data: hasMpdJobCountRule?.data || 0,
          },
        ],
      };
    }
    dispatch(
      get_namesilo_domains_report({
        filters: queryRules,
        page: 1,
        custom_filters: customFilter,
        perPage: paginationModel.pageSize,
      })
    );
  };
  function splitAndFlattenArray(inputArray) {
    const result = inputArray?.reduce((acc, currentValue) => {
      return [...acc, ...currentValue.split(",")];
    }, []);
    return result;
  }
  const processData = (rule) => {
    let processedValue;

    if (Array.isArray(rule)) {
      processedValue = splitAndFlattenArray(rule);
    } else if (rule && typeof rule === "string") {
      processedValue = rule;
    } else if (typeof rule === "number") {
      processedValue = rule;
    } else if (rule === 0) {
      processedValue = 0;
    } else {
      processedValue = null;
    }
    return processedValue;
  };

  const onFilterChange = React.useCallback((filterModel) => {
    if (filterModel?.items?.length === 0) {
      dispatch(
        get_namesilo_domains_report({
          filters: {
            groupOp: "",
            rules: [],
          },
          custom_filters: null,
          page: 1,
          size: paginationModel.pageSize,
        })
      );
      setSelectedData([]);
    }
    let ruless = [];
    let custom_filter = { ...customFilter };
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      if (
        rule?.field !== "expiring_in_days" &&
        rule?.field !== "system_domains"
      ) {
        ruless = [
          ...ruless,
          {
            field:
              rule.field === "tlds"
                ? "nd.domain"
                : rule.field === "vendors_in_net"
                ? "vendor_type"
                : rule.field === "vendors_out_net"
                ? "vendor_type"
                : `${rule.field}`,
            op:
              rule.field === "tlds"
                ? "cn"
                : rule.operator === "contains"
                ? "cn"
                : rule.operator === "equals"
                ? "eq"
                : rule.operator === "is"
                ? "eq"
                : rule.operator === "="
                ? "eq"
                : rule.operator === "!="
                ? "not"
                : rule.operator === ">"
                ? "gt"
                : rule.operator === ">="
                ? "gte"
                : rule.operator === "<"
                ? "lt"
                : rule.operator === "<="
                ? "lte"
                : rule.operator === "onOrBefore"
                ? "lte"
                : rule.operator === "before"
                ? "lt"
                : rule.operator === "onOrAfter"
                ? "gte"
                : rule.operator === "after"
                ? "gt"
                : rule.operator,
            data: processData(rule.value),
          },
        ];
      } else {
        custom_filter = {
          ...custom_filter,
          [`${rule?.field}`]:
            rule?.op === "isEmpty"
              ? ""
              : rule.value || rule.value === 0
              ? rule.value
              : null,
        };
      }
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    setCustomFilter(custom_filter);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    let queryRules = { ...queryOptions };
    const hasDljcJobStatusRule = queryRules?.rules?.some(
      ({ field }) => field === "dljc.job_status"
    );
    const hasMpdJobStatusRule = queryRules?.rules?.find(
      ({ field }) => field === "mpd.job_count"
    );
    if (hasDljcJobStatusRule) {
      queryRules = {
        ...queryRules,
        rules: [
          ...queryRules.rules.filter(
            ({ field }) =>
              field !== "dljc.job_count" && field !== "mpd.job_count"
          ),

          {
            field: "dljc.job_count",
            op: "gte",
            data: hasMpdJobStatusRule?.data || 0,
          },
        ],
      };
    }
    dispatch(
      get_namesilo_domains_report({
        filters: queryRules,
        page: paginationModel?.page,
        perPage: paginationModel.pageSize,
        custom_filters: customFilter,
      })
    );
    dispatch(get_job_status_with_job_count());
    dispatch(get_industry_drd({type:"all"}));
    // eslint-disable-next-line
  }, []);
  const domainList = namesiloDomains?.domainList?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const onSelectAll = (checked) => {
    if (isLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    if (checked) {
      const updatedArray = domainList?.filter(
        (item) =>
          (item?.renew_status === "Completed" ||
            item?.renew_status === "NotPending") &&
          (item?.auto_renew_status === "Completed" ||
            item?.auto_renew_status === "NotPending")
      );
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };
  const isSelected = (item) => {
    if (selectedData?.length > 0) {
      if (
        selectedData?.filter(({ domain }) => domain === item.domain).length > 0
      ) {
        return true;
      }
    }
    return false;
  };
  const onSingleSelect = ({ checked, data }) => {
    if (isLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    try {
      if (checked) {
        setSelectedData((prevSelectedData) => {
          const updatedSelectedData = [...prevSelectedData, data];
          return updatedSelectedData;
        });
      } else {
        setSelectedData((prevSelectedRecord) => {
          const updatedSelectedRecord = prevSelectedRecord?.filter(
            ({ domain }) => domain !== data?.domain
          );
          return updatedSelectedRecord;
        });
      }
    } catch (err) {
      console.log("🚀 ~ file: index.jsx:116 ~ onSingleSelect ~ err:", err);
    }
  };

  const CustomSelectInput = ({ item, applyValue }) => {
    const [value, setValue] = useState(item.value);
    return (
      <div fullWidth>
        <InputLabel>Value</InputLabel>
        <Input
          value={value || ""}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          onBlur={() => applyValue({ ...item, value: value })}
          fullWidth
        />
      </div>
    );
  };
  const sortedIndustries = [...industryDrd].sort((a, b) =>
    a?.name?.localeCompare(b?.name)
  );
  const deleteStatusOptions = [
    { value: "ActiveDomain", label: "Active Domain" },
    { value: "PendingToImgDelete", label: "Pending To Img Delete" },
    { value: "PendingToDataDelete", label: "Pending To Data Delete" },
    { value: "Deleted", label: "Deleted from namesilo" },
  ];
  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          checked={selectedData?.length === domainList?.length}
        />
      ),
      filterable: false,
      sortable: false,
      width: 60,
      renderCell: (params) => (
        <div className="flex justify-center w-full items-center">
          {isSelected(params.row.records) && isLoader ? (
            <Loader />
          ) : (params?.row?.records?.renew_status === "Completed" ||
              params?.row?.records?.renew_status === "NotPending") &&
            (params?.row?.records?.auto_renew_status === "Completed" ||
              params?.row?.records?.auto_renew_status === "NotPending") ? (
            <input
              type="checkbox"
              checked={isSelected(params.row.records)}
              onChange={(e) =>
                onSingleSelect({
                  checked: e.target.checked,
                  data: params.row.records,
                })
              }
              className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
            />
          ) : null}
        </div>
      ),
      disableColumnMenu: true,
    },
    {
      headerName: "#",
      field: "counter",
      width: 60,
      filterable: false,
      renderCell: (params) => (
        <div>
          <span>{params?.row?.counter}</span>
          {(params?.row?.records?.renew_status !== "Completed" &&
            params?.row?.records?.renew_status !== "NotPending") ||
          (params?.row?.records?.auto_renew_status !== "Completed" &&
            params?.row?.records?.auto_renew_status !== "NotPending") ? (
            <span className="font-medium">
              <Tooltip
                target=".start-header"
                className="max-w-[450px] font-bold text-xs text-left bg-whit !shadow-none"
              />
              <span
                className="start-header ml-1 cursor-pointer"
                data-pr-tooltip={`${
                  params?.row?.records?.renew_status &&
                  params?.row?.records?.renew_status !== "NotPending" &&
                  params?.row?.records?.renew_status !== "Completed"
                    ? `Renew Status: ${params?.row?.records?.renew_status}\n\n`
                    : ""
                }${
                  params?.row?.records?.last_renew_status_log
                    ? `\n\nRenew Status Log:  ${params?.row?.records?.last_renew_status_log}`
                    : ""
                }${
                  params?.row?.records?.auto_renew_status &&
                  params?.row?.records?.auto_renew_status !== "NotPending" &&
                  params?.row?.records?.auto_renew_status !== "Completed"
                    ? `Auto Renew Status: ${params?.row?.records?.auto_renew_status}`
                    : ""
                }${
                  params?.row?.records?.last_auto_renew_status_log
                    ? `\n\nAuto Renew Status Log: ${params?.row?.records?.last_auto_renew_status_log}`
                    : ""
                }`}
                data-pr-position="top"
                data-pr-at="center top-10"
              >
                <FaInfoCircle className="text-primary-100" />
              </span>
            </span>
          ) : null}
        </div>
      ),
    },
    {
      headerName: "Domain",
      field: "nd.domain",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <div className="flex flex-col">
          <a
            className="items-center text-black cursor-pointer hover:underline no-underline"
            target="_blank"
            rel="noreferrer"
            href={`https://${params.row["nd.domain"]}`}
          >
            {params.row["nd.domain"]}
          </a>
          {params.row.delete_status ? (
            <small
              className={`${
                params.row.delete_status === "Deleted"
                  ? "text-red-600 font-medium"
                  : ""
              }`}
            >
              {params.row.delete_status === "Deleted" && params.row.is_deleted
                ? "Deleted from namesilo"
                : params.row.delete_status === "Deleted" &&
                  !params.row.is_deleted
                ? "Deleted from runners"
                : params.row.delete_status}{" "}
              | {params.row.delete_status}
            </small>
          ) : null}
          {params.row["nd.is_detached"] ? (
            <small className={`text-red-600 font-medium`}>
              Detached
            </small>
          ) : null}
        </div>
      ),
    },
    {
      field: "delete_status",
      headerName: "Delete Status",
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value.label,
      valueOptions: deleteStatusOptions,
      renderCell: (params) => params.value.delete_status,
      valueGetter: (params) => params.row.delete_status,
      valueFormatter: (params) => params.value.delete_status,
    },
    {
      headerName: "Vendor Type",
      field: "vendor_type",
      flex: 1,
      type: "singleSelect",
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        vendor_type: value?.value,
      }),
      valueOptions: [
        { value: "InNet", label: "InNet" },
        { value: "OutNet", label: "OutNet" },
      ],
    },
    {
      headerName: "Vendors",
      field: "vendors",
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      sortable: true,
      filterable: false,
      valueGetter: (params) =>
        params?.row?.vendors ? params?.row?.vendors?.length : 0,
      sortComparator: (v1, v2, row1, row2) => v1 - v2,
      renderCell: (params) =>
        params?.row?.vendors?.length ? (
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            onClick={() =>
              setVendors({
                vendors: params?.row?.vendors,
                domain: params?.row["nd.domain"],
              })
            }
          >
            {params?.row?.vendors?.length ? params?.row?.vendors?.length : 0}
          </span>
        ) : (
          <span>0</span>
        ),
    },
    {
      headerName: "Vendors InNet",
      field: "vendors_in_net",
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      sortable: true,
      filterable: false,
      valueGetter: (params) =>
        params?.row?.vendors_in_net ? params?.row?.vendors_in_net?.length : 0,
      sortComparator: (v1, v2, row1, row2) => v1 - v2,
      renderCell: (params) =>
        params?.row?.vendors_in_net?.length ? (
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            onClick={() =>
              setVendors({
                vendors: params?.row?.vendors_in_net,
                domain: params?.row["nd.domain"],
              })
            }
          >
            {params?.row?.vendors_in_net?.length
              ? params?.row?.vendors_in_net?.length
              : 0}
          </span>
        ) : (
          <span>0</span>
        ),
    },
    {
      headerName: "Vendors OutNet",
      field: "vendors_out_net",
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      sortable: true,
      filterable: false,
      valueGetter: (params) =>
        params?.row?.vendors_out_net ? params?.row?.vendors_out_net?.length : 0,
      sortComparator: (v1, v2, row1, row2) => v1 - v2,
      renderCell: (params) =>
        params?.row?.vendors_out_net?.length ? (
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            onClick={() =>
              setVendors({
                vendors: params?.row?.vendors_out_net,
                domain: params?.row["nd.domain"],
              })
            }
          >
            {params?.row?.vendors_out_net?.length
              ? params?.row?.vendors_out_net?.length
              : 0}
          </span>
        ) : (
          <span>0</span>
        ),
    },
    {
      field: "industry_name",
      headerName: "Industry",
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      getOptionValue: (value) => value?.name,
      getOptionLabel: (value) => value.name,
      valueOptions: sortedIndustries,
      renderCell: (params) => params.value?.industry_name,
      valueGetter: (params) => params.row?.industry_name,
      valueFormatter: (params) => params.value?.industry_name,
    },
    {
      headerName: "Generate By",
      field: "generate_domain_by",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "State",
      field: "state",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "City",
      field: "city",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Location",
      field: "location",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Population",
      field: "population",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Phone",
      field: "mpd.phone",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Lead Count",
      field: "mpd.lead_count",
      flex: 1,
      type: "number",
      sortable: true,
      valueGetter: (params) =>
        params?.row["mpd.lead_count"] ? params?.row["mpd.lead_count"] : 0,
      sortComparator: (v1, v2, row1, row2) => v1 - v2,
      renderCell: (params) =>
        params?.row["mpd.lead_count"] ? (
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            onClick={() =>
              setCountModal({
                phone: params?.row["mpd.phone"],
                type: "Leads",
                domain: params?.row["nd.domain"],
              })
            }
          >
            {params?.row["mpd.lead_count"] ? params?.row["mpd.lead_count"] : 0}
          </span>
        ) : (
          <span>0</span>
        ),
      minWidth: 100,
    },
    {
      headerName: "Job Count",
      field: "mpd.job_count",
      flex: 1,
      type: "number",
      sortable: true,
      valueGetter: (params) =>
        params?.row["mpd.job_count"] ? params?.row["mpd.job_count"] : 0,
      sortComparator: (v1, v2, row1, row2) => v1 - v2,
      renderCell: (params) =>
        params?.row["mpd.job_count"] ? (
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            onClick={() =>
              setCountModal({
                phone: params?.row["mpd.phone"],
                type: "Jobs",
                domain: params?.row["nd.domain"],
              })
            }
          >
            {params?.row["mpd.job_count"] ? params?.row["mpd.job_count"] : 0}
          </span>
        ) : (
          <span>0</span>
        ),
      minWidth: 100,
    },
    {
      field: "dljc.job_status",
      headerName: "Job Status",
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      getOptionValue: (value) => value?.name,
      getOptionLabel: (value) => value.name,
      valueOptions: jobStatuses,
      renderCell: (params) => params.row["dljc.job_status"],
    },
    {
      headerName: "Created",
      field: "created",
      flex: 1,
      type: "date",
      minWidth: 120,
      valueGetter: (params) => new Date(params.row.created),
      renderCell: (params) => {
        const rawDate = params.row.created;
        if (rawDate) {
          return rawDate;
        }
        return "";
      },
    },
    {
      headerName: "Expires",
      field: "expires",
      flex: 1,
      type: "date",
      minWidth: 120,
      valueGetter: (params) => new Date(params.row.expires),
      renderCell: (params) => {
        const rawDate = params.row.expires;

        if (rawDate) {
          return rawDate;
        }

        return "";
      },
    },
    {
      headerName: "In/Out Area",
      field: "in_area",
      flex: 1,
      type: "singleSelect",
      minWidth: 100,
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        in_area: value?.value,
      }),
      valueOptions: [
        { value: 1, label: "In Area" },
        { value: 0, label: "Out Area" },
      ],
    },

    {
      headerName: "Detached",
      field: "nd.is_detached",
      flex: 1,
      type: "singleSelect",
      minWidth: 100,
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        "nd.is_detached": value?.value,
      }),
      valueOptions: [
        { value: 1, label: "Yes" },
        { value: 0, label: "No" },
      ],
    },
    {
      headerName: "System Domain",
      field: "system_domains",
      flex: 1,
      type: "singleSelect",
      minWidth: 130,
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        system_domains: value?.value,
      }),
      valueOptions: [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
      ],
    },
    {
      headerName: "Auto Renew",
      field: "nd.auto_renew",
      flex: 1,
      type: "singleSelect",
      minWidth: 100,
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        "nd.auto_renew": value?.value,
      }),
      valueOptions: [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
      ],
    },
    {
      headerName: "Expiring in Days",
      field: "expiring_in_days",
      flex: 1,
      type: "number",
      minWidth: 140,
      align: "left",
      filterOperators: [
        {
          label: "is in or before",
          value: "is",
          InputComponent: CustomSelectInput,
          getApplyFilterFn: (filterItem) => {
            return (params) => {
              return params.value === filterItem.value;
            };
          },
        },
      ],
      renderCell: (params) =>
        params?.row.expiring_in_days <= 0 ? (
          <span>{`${Math.abs(params?.row?.expiring_in_days)} days ago`}</span>
        ) : (
          <span>{params?.row?.expiring_in_days}</span>
        ),
    },
    {
      headerName: "Status",
      field: "status",
      flex: 1,
      type: "singleSelect",
      minWidth: 100,
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        status: value?.value,
      }),
      valueOptions: [{ value: "Active", label: "Active" }],
      renderCell: (params) => <span>{params?.row?.status}</span>,
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });

    let queryRules = { ...queryOptions };
    const hasDljcJobStatusRule = queryRules?.rules?.some(
      ({ field }) => field === "dljc.job_status"
    );
    const hasMpdJobStatusRule = queryRules?.rules?.find(
      ({ field }) => field === "mpd.job_count"
    );
    if (hasDljcJobStatusRule) {
      queryRules = {
        ...queryRules,
        rules: [
          ...queryRules.rules.filter(
            ({ field }) =>
              field !== "dljc.job_count" && field !== "mpd.job_count"
          ),
          {
            field: "dljc.job_count",
            op: "gte",
            data: hasMpdJobStatusRule?.data || 0,
          },
        ],
      };
    }
    dispatch(
      get_namesilo_domains_report({
        filters: queryRules,
        page: +params.page + 1,
        perPage: params.pageSize,
        custom_filters: customFilter,
      })
    );
  };
  const sendRequests = async () => {
    if (!selectedData || selectedData.length === 0) {
      toast.error("Please select the domain");
      return;
    }
    const isExpiredDomain = selectedData?.filter(
      ({ is_expired }) => is_expired === 0
    );
    if (isExpiredDomain?.length > 0)
      return toast.error("Please select the expired domains only");
    const c = window.confirm(
      `Are you sure want to remove ${selectedData?.length} domains?`
    );
    if (!c) return;
    const payload = selectedData?.map(({ domain }) => domain);
    setIsLoader(true);
    try {
      const response = await api.post(
        "/api/expire_domains/remove_domains",
        payload
      );
      if (response.status === 200) {
        toast.success(`Domains removed successfully`);
        setSelectedData([]);
        dispatch(
          get_namesilo_domains_report({
            filters: queryOptions,
            page: paginationModel.page,
            perPage: paginationModel.pageSize,
          })
        );
      } else {
        toast.error(`Domains couldn't be removed`);
      }
    } catch (error) {
      console.error("Error in sendRequests:", error);
      toast.error(`Domains couldn't be removed`);
    }
    setIsLoader(false);
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="!text-[#042a42]">
        {selectedData?.length ? (
          <span className="text-sm font-semibold bg-[#fcebc5] py-1 px-1.5">
            {`${selectedData?.length} items are selected `}
            <span
              className="text-blue-600 hover:underline cursor-pointer"
              onClick={() => setShowSelectedData(true)}
            >
              Show
            </span>
            <span
              className="text-blue-600 hover:underline cursor-pointer ml-2"
              onClick={() => setSelectedData([])}
            >
              Clear
            </span>
          </span>
        ) : null}
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className="!text-[#042a42]"
        >
          Apply filter
        </MUIButton>
        <MUIButton
          variant="text"
          onClick={sendRenewRequests}
          startIcon={<FaSync size={14} />}
          sx={{ fontSize: "0.8125rem" }}
          className="!text-[#042a42]"
        >
          Renew Domain
        </MUIButton>
        <MUIButton
          variant="text"
          onClick={() => {
            if (!selectedData?.length)
              return toast.error("Please select the domains");
            setRenewModal(true);
          }}
          startIcon={<FaSync size={14} />}
          sx={{ fontSize: "0.8125rem" }}
          className="!text-[#042a42]"
        >
          Set auto renew
        </MUIButton>
        <MUIButton
          variant="text"
          onClick={() => updateDomainRenewalDetails("Renew")}
          startIcon={<MdOutlineQueuePlayNext size={18} />}
          sx={{ fontSize: "0.8125rem" }}
          className="!text-[#042a42]"
        >
          Add in Renew Queue
        </MUIButton>
        <MUIButton
          variant="text"
          onClick={() => updateDomainRenewalDetails("AutoRenew")}
          startIcon={<MdOutlineQueuePlayNext size={18} />}
          sx={{ fontSize: "0.8125rem" }}
          className="!text-[#042a42]"
        >
          Add in set auto renew Queue
        </MUIButton>
        <MUIButton
          variant="text"
          onClick={getLeadJobCount}
          startIcon={<MdOutlineGetApp size={18} />}
          sx={{ fontSize: "0.8125rem" }}
          className="!text-[#042a42]"
        >
          Get Lead Job Count
        </MUIButton>
        <MUIButton
          variant="text"
          onClick={sendRequests}
          startIcon={<FaMinus size={14} />}
          sx={{ fontSize: "0.8125rem" }}
          className="!text-[#042a42]"
        >
          Remove Domains
        </MUIButton>
      </GridToolbarContainer>
    );
  }
  const handleTLDs = ({ data, field, op }) => {
    setQueryOptions((prevOptions) => {
      const existingRuleIndex = prevOptions.rules.findIndex(
        (rule) => rule.field === field
      );

      if (existingRuleIndex !== -1) {
        if (data !== "") {
          const updatedRules = [...prevOptions.rules];
          updatedRules[existingRuleIndex] = {
            ...updatedRules[existingRuleIndex],
            data: data,
          };

          return {
            ...prevOptions,
            rules: updatedRules,
          };
        } else {
          const updatedRules = prevOptions?.rules.filter(
            (item) => item?.field !== field
          );

          return {
            ...prevOptions,
            rules: updatedRules,
          };
        }
      } else {
        return {
          ...prevOptions,
          rules: [
            ...prevOptions.rules,
            {
              field: field,
              op: op,
              data: data,
            },
          ],
        };
      }
    });
  };
  async function sendRenewRequests() {
    if (isLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    if (selectedData?.length === 0) {
      return toast.error("Please select the domain");
    }
    const c = window.confirm(
      `Are you sure want to renew the ${selectedData?.length} domains?`
    );
    if (!c) return;
    setIsLoader(true);

    const selectedDataCopy = [...selectedData];
    let breakForLoop = false;

    for (const item of selectedDataCopy) {
      let retryCount = 0;

      while (retryCount < 3) {
        try {
          const response = await api.post(
            "api/vendors/domain_prime_time/renew_domain",
            { domain: `${item?.domain}` }
          );

          if (response.status === 200) {
            toast.success(`${response?.data}`);
            setSelectedData((prevState) =>
              prevState.filter(({ domain }) => domain !== item?.domain)
            );
            break;
          } else if (response.status === 503) {
            toast.info(
              `Received an error to renew "${item?.domain}" domain. Retrying with 3 sec delay...`
            );
            console.log(
              `503 status received, attempt no. ${retryCount + 1} to retry`
            );
            await new Promise((resolve) => setTimeout(resolve, 3000));
          } else {
            toast.error(`"${item?.domain}" ${response.data}`);
            breakForLoop = true;
            break;
          }
        } catch (error) {
          console.log(
            "🚀 ~ file: index.jsx:252 ~ sendRequests ~ error:",
            error
          );
          toast.error(
            `"${item?.domain}" ${error?.response?.data || "Getting an error"}`
          );
          breakForLoop = true;
          break;
        }

        retryCount++;
      }
      if (breakForLoop) {
        break;
      }
    }
    setIsLoader(false);
    handleNewFilter();
  }

  async function getLeadJobCount() {
    try {
      const c = window.confirm(
        `Are you sure want to get the count of lead and job?`
      );
      if (!c) return;
      const dids = namesiloDomains?.domainList
        ?.filter(({ phone }) => phone)
        ?.map(({ phone }) => `1${phone}`);
      dispatch(get_lead_job_count({ dids }));
    } catch (err) {
      console.log("🚀 ~ sendAutoRenewRequests ~ err:", err);
    }
  }
  async function updateDomainRenewalDetails(type) {
    try {
      if (selectedData?.length === 0) {
        return toast.error("Please select the domain");
      }
      const c = window.confirm(
        `Are you sure want to add in queue to ${
          type === "AutoRenew" ? "set auto renew for" : "renew"
        } the selected domains?`
      );
      if (!c) return;
      setLoader(true);
      const domain_ids = selectedData?.map(({ id }) => id);
      const res = await api.post(
        "api/vendors/domain_prime_time/update_domain_renewal_details",
        { domain_ids, type }
      );
      if (res.status === 200) {
        setLoader(false);
        toast.success(
          `Domains added in queue to ${
            type === "AutoRenew" ? "set auto renew" : "renew"
          } successfully`
        );
        setSelectedData([]);
      } else {
        toast.error(
          `Domains couldn't be added in queue to ${
            type === "AutoRenew" ? "set auto renew" : "renew"
          }`
        );
      }
    } catch (err) {
      toast.error(
        `Domains couldn't be added in queue to ${
          type === "AutoRenew" ? "set auto renew" : "renew"
        }`
      );
      setLoader(false);
      console.log("🚀 ~ sendAutoRenewRequests ~ err:", err);
    }
  }

  async function sendAutoRenewRequests() {
    if (isLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    if (selectedData?.length === 0) {
      return toast.error("Please select the domain");
    }
    setIsLoader(true);
    setRenewModal(false);
    const selectedDataCopy = [...selectedData];
    for (const item of selectedDataCopy) {
      try {
        const response = await api.post(
          "api/vendors/domain_prime_time/set_auto_renew_domain",
          { domain: `${item?.domain}`, set_auto_renew: renewStatus }
        );
        if (response.status === 200) {
          toast.success(`${response?.data}`);
          setSelectedData((prevState) =>
            prevState.filter(({ domain }) => domain !== item?.domain)
          );
        } else {
          toast.error(`"${item?.domain}" ${response.data}`);
          break;
        }
      } catch (error) {
        console.log("🚀 ~ file: index.jsx:252 ~ sendRequests ~ error:", error);
        toast.error(
          `"${item?.domain}" ${error?.response?.data || "Getting an error"}`
        );
        break;
      }
    }
    setIsLoader(false);
  }
  const handleReset = () => {
    dispatch(
      get_namesilo_domains_report({
        filters: {
          groupOp: "",
          rules: [],
        },
        custom_filters: null,
        page: 1,
        size: paginationModel.pageSize,
      })
    );
    setSelectedData([]);
    setCustomFilter(null);
    setQueryOptions({
      groupOp: "",
      rules: [],
    });
  };
  function exportToExcel(type) {
    const data = namesiloDomains?.domainList;
    const rows = [
      [
        "#",
        "Domain",
        "Industry",
        "State",
        "City",
        "Location",
        "Population",
        "Created",
        "Expires",
        "In/Out Area",
        "Lead Count",
        "Job Count",
        "Detached",
        "System Domain",
        "Auto Renew",
        "Expiring in Days",
        "Status",
      ],
    ];
    data.forEach((item, index) => {
      const {
        domain,
        industry_name,
        state,
        city,
        location,
        population,
        created,
        expires,
        in_area,
        lead_count,
        job_count,
        is_detached,
        system_domain_id,
        auto_renew,
        expiring_in_days,
        status,
      } = item;
      const system_domains = system_domain_id ? "Yes" : "No";
      const detached = is_detached ? "Yes" : "No";
      const area = in_area === null ? "" : in_area ? "In Area" : "Out Area";
      const counter = index + 1;
      const expiry =
        expiring_in_days < 0
          ? `${Math.abs(expiring_in_days)} days ago`
          : expiring_in_days;
      rows.push([
        counter,
        domain,
        industry_name,
        state,
        city,
        location,
        population,
        created,
        expires,
        area,
        lead_count,
        job_count,
        detached,
        system_domains,
        auto_renew,
        expiry,
        status,
      ]);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(rows);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const fileName = "Namesilo Report.xlsx";

    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    if (typeof window.navigator.msSaveBlob !== "undefined") {
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100);
    }
  }

  const LineOne = () => {
    return (
      <div className="text-base font-medium ml-3">
        <span className="font-semibold text-lg font_pop text-red-600">
          Note:{" "}
        </span>{" "}
        Please select only domains that will expire in 30 days.
      </div>
    );
  };
  return (
    <>
      <PageHeader
        route="Setting > Industries"
        heading="Namesilo Domains Report"
      />
      {vendors?.vendors?.length ? (
        <VendorsListModal
          onCancelForm={() => setVendors(null)}
          data={vendors}
        />
      ) : null}
      {showSelectedData ? (
        <SelectedDataModal
          onCancelForm={() => setShowSelectedData(false)}
          data={selectedData}
        />
      ) : null}
      {countModal?.phone ? (
        <LeadJobListModal
          onCancelForm={() => setCountModal(null)}
          data={countModal}
        />
      ) : null}

      {renewModal ? (
        <AutoRenewModal
          onClose={() => {
            setRenewStatus(null);
            setRenewModal(false);
          }}
          setRenewStatus={setRenewStatus}
          renewStatus={renewStatus}
          handleConfirm={sendAutoRenewRequests}
        />
      ) : null}
      {loader ? <DotsLoader /> : null}
      <div className="grid grid-cols-4 gap-x-3 !px-5 !pt-5 bg-white rounded transition duration-[2s] ease-out">
        <FormSelectInput
          name="active"
          label="TLDs"
          options={[
            { value: "", label: "All" },
            { value: ".us", label: ".us" },
            { value: ".net", label: ".net" },
            { value: ".top", label: ".top" },
            { value: ".info", label: ".info" },
            { value: ".online", label: ".online" },
            { value: ".site", label: ".site" },
            { value: ".business", label: ".business" },
            { value: ".click", label: ".click" },
            { value: ".company", label: ".company" },
            { value: ".link", label: ".link" },
            { value: ".buzz", label: ".buzz" },
          ]}
          valueProp="value"
          labelProp="label"
          onChange={(value) =>
            handleTLDs({ data: value, field: "nd.domain", op: "cn" })
          }
          value={
            `${
              queryOptions.rules.find((rule) => rule.field === "nd.domain")
                ?.data
            }` || ""
          }
          nullOption="No"
        />
        <div className="space-x-2 flex items-center">
          <Button
            text="Search"
            variant="btn_submit"
            onClick={handleNewFilter}
          />
          <Button text="Reset" variant="btn_cancel" onClick={handleReset} />
        </div>
      </div>
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={domainList?.map((record, index) => {
            const counter = index + 1;
            const {
              id,
              auto_renew,
              created,
              domain,
              industry_name,
              state,
              city,
              location,
              population,
              expires,
              expiring_in_days,
              in_area,
              status,
              system_domain_id,
              is_detached,
              renew_status,
              vendors,
              counts,
              generate_domain_by,
              job_count,
              lead_count,
              job_counts,
              vendors_out_net,
              vendors_in_net,
              delete_status,
              is_deleted,
            } = record;
            let phone = record["mpd.phone"];
            return {
              records: { ...record, _id: id },
              id,
              counter,
              "nd.auto_renew": auto_renew,
              created,
              "nd.domain": domain,
              industry_name,
              state,
              city,
              location,
              population,
              expires,
              expiring_in_days,
              in_area,
              status,
              system_domains: system_domain_id ? "Yes" : "No",
              "nd.is_detached": is_detached,
              renew_status,
              "mpd.job_count": job_count,
              "mpd.lead_count": lead_count,
              vendors: vendors?.split(","),
              vendors_in_net: vendors_in_net?.split(","),
              vendors_out_net: vendors_out_net?.split(","),
              counts,
              "mpd.phone": phone,
              generate_domain_by,
              "dljc.job_status": job_counts[0]["dljc.job_status"],
              delete_status,
              is_deleted,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={namesiloDomains?.totalCount}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          onFilterModelChange={onFilterChange}
          CustomToolbar={CustomToolbar}
          exportButton={exportToExcel}
          LineOne2={LineOne}
          density="standard"
          filterMode="server"
          columnVisibilityModel={{
            has_monthly_searched: false,
            vendor_type: false,
            delete_status: false,
            "nd.is_detached":false
          }}
        />
      </div>
    </>
  );
};

export default NamesiloReport;
