import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/molecules/PageHeader";
import { DotsLoader } from "../../../components";
import api from "../../../services/api";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { FaSearch } from "react-icons/fa";
import userPermission from "../../../util/userPermission";

function DocusignTemplates() {
  const [isLoader, setIsLoader] = useState(false);
  const [docusignTemplates, setDocusignTemplates] = React.useState([]);
  const [docusignAllTemplates, setDocusignAllTemplates] = React.useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [isDocusignModal, setIsDocusignModal] = React.useState(false);
  const [selectedDocusignTemplates, setSelectedDocusignTemplates] =
    React.useState([]);
  const get_docusign_template_list = async () => {
    setIsLoader(true);
    try {
      const res = await api.post("/api/docusign/get_all_added_template_list");
      if (res.status === 200) {
        setDocusignTemplates(res.data.records);
      }
      setIsLoader(false);
    } catch (err) {
      console.log("👊 ~ get_docusign_template_list ~ err:", err);
      setIsLoader(false);
    }
  };
  useEffect(() => {
    get_docusign_template_list();
  }, []);
  const get_all_docusign_template_list = async () => {
    setIsLoader(true);
    try {
      const res = await api.get("/api/docusign");
      if (res.status === 200) {
        setDocusignAllTemplates(res.data.data);
      }
      setIsLoader(false);
    } catch (err) {
      console.log("👊 ~ get_docusign_template_list ~ err:", err);
      setIsLoader(false);
    }
  };
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "Template Name", field: "template_name", flex: 1 },
  ];
  const handleSaveDocusignTemaplates = async () => {
    setIsLoader(true);
    try {
      const res = await api.post("/api/docusign/add_and_update_template", {
        template_data: selectedDocusignTemplates,
      });
      if (res.status === 200) {
        toast.success(res.data.message || "Template added successfully");
        setDocusignTemplates(res.data?.data);
        setIsDocusignModal(false);
      } else {
        toast.error(
          res.data.error || res.data.message || "Template couldn't be added"
        );
      }
    } catch (error) {
      toast.error(
        error?.response.data.error ||
          error?.response.data.message ||
          "Template couldn't be added"
      );
      console.log("👊 ~ handleSaveDocusignTemaplates ~ error:", error);
    }
    setIsLoader(false);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "850px",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 0,
  };
  const handleCheckboxChange = ({ event, data }) => {
    const { checked } = event.target;
    setSelectedDocusignTemplates((prevSelected) => {
      if (checked) {
        return [...prevSelected, data];
      } else {
        return prevSelected.filter(
          ({ template_id }) => template_id !== data?.template_id
        );
      }
    });
  };
  const filteredTemplates = docusignAllTemplates?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
  return (
    <div>
      <PageHeader
        isAllowed={false}
        onClick={() => {
          const existingTemplates = docusignTemplates?.map(
            ({ template_id, template_name }) => ({
              template_id,
              template_name,
            })
          );
          get_all_docusign_template_list();
          setSelectedDocusignTemplates([...existingTemplates]);
          setIsDocusignModal(true);
        }}
        topBtn={userPermission("Write Docusign")}
        // breadcrumb={`/${lead_type}`}
      />
      {isLoader ? <DotsLoader /> : null}
      <MUIDataTable
        columnDefs={columnDefs}
        items={docusignTemplates?.map((item, index) => {
          let counter = index + 1;
          const { template_name } = item;
          return {
            counter,
            records: { ...item },
            template_name,
          };
        })}
        searchable="No"
        pagination="No"
        showCount="No"
        toolbar="No"
        height={docusignTemplates?.length ? "80vh" : 100}
        hideFooter={true}
      />
      <Modal
        open={isDocusignModal}
        onClose={() => setIsDocusignModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="p-2 bg-primary-100 text-white mb-3 text-lg font-semibold text-center relative">
            Add Docusign Templates
            <div className="absolute top-0 right-0 p-2 z-20 -mt-6 -mr-6">
              <button
                onClick={() => setIsDocusignModal(false)}
                className="bg-white rounded-full text-gray-700 hover:text-red-500 p-2 w-11 h-11"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="flex items-center ml-2 !w-1/2 appearance-none px-2 !mb-3 flex- border border-gray-300 dark:border-white/25 rounded-md bg-white dark:bg-blue">
            <FaSearch className="w-5" />
            <input
              placeholder={"Search..."}
              className="bg-transparent flex-1 w-full outline-none !ml-3 py-1 appearance-none"
              onChange={(e) => setSearchInput(e.target.value)}
              value={searchInput}
            />
          </div>
          <div className="historyTabs px-2 max-h-[calc(100vh-400px)] overflow-y-auto">
            {filteredTemplates?.map(({ name, template_id }) => {
              const isChecked = selectedDocusignTemplates.some(
                (template) => template.template_id === template_id
              );
              return name ? (
                <div className={`flex items-center !py-1.5`}>
                  <label className={`text-base text-[#495057] cursor-pointer`}>
                    <input
                      type="checkbox"
                      className={`form-checkbox h-5 w-5 text-primary-100 !rounded-sm focus:ring-0 cursor-pointer mr-2`}
                      checked={isChecked}
                      onChange={(e) =>
                        handleCheckboxChange({
                          event: e,
                          data: {
                            template_id,
                            template_name: name,
                          },
                        })
                      }
                    />

                    {name}
                  </label>
                </div>
              ) : null;
            })}
          </div>
          <div className="text-center mt-3 mb-3">
            <button
              onClick={handleSaveDocusignTemaplates}
              className=" py-1.5 !px-3 align-middle !bg-primary-100 border-primary-100 !text-white font-semibold rounded-none"
            >
              Save
            </button>
          </div>
          {/* <div className="text-center mt-3 mb-3">
                        <button className=" py-1.5 !px-3 align-middle !bg-primary-100 border-primary-100 !text-white font-semibold rounded-none">
                          Save
                        </button>
                      </div> */}
        </Box>
      </Modal>
    </div>
  );
}

export default DocusignTemplates;
