import React, { useEffect, useState } from "react";
import AddUpdateForm from "./AddUpdateForm";
import { useSelector, useDispatch } from "react-redux";
import {
  delete_user_group,
  get_user_groups,
} from "../../features/userGroupSlice";
import { toast } from "react-toastify";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import errorMessage from "../../util/errorMessage";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { useLocation, useNavigate } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Button } from "../../components";
import { Box } from "@mui/material";
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from "react-icons/md";
import {
  gridFilteredDescendantCountLookupSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";

const UserGroups = () => {
  const optionsRef = React.useRef();
  const location = useLocation();
  const { isLoading, record } = useSelector((state) => state.userGroups);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [expendedRows, setExpendedRow] = useState([]);

  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [showMenu, setShowMenu] = useState(null);
  const dispatch = useDispatch();
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  // function addPathToGroups(groups, parentPath = []) {
  //   return groups.map((group) => {
  //     const path = [...parentPath, group.title];
  //     const children = group.children || [];
  //     const updatedGroup = { ...group, path };

  //     if (children.length > 0) {
  //       updatedGroup.children = addPathToGroups(children, path);
  //     }

  //     return updatedGroup;
  //   });
  // }

  // const updatedGroups = addPathToGroups(record);
  // console.log("updatedGroups", updatedGroups);
  function flattenGroups(groups, parentPath = []) {
    let flattenedGroups = [];
    groups.forEach((group) => {
      const path = [...parentPath, group.title];
      flattenedGroups.push({ ...group, path });
      if (group.children && group.children.length > 0) {
        flattenedGroups = flattenedGroups.concat(
          flattenGroups(group.children, path)
        );
      }
    });
    return flattenedGroups;
  }
  const updatedFlattenedGroups = flattenGroups(record);
  // console.log("updatedFlattenedGroups", updatedFlattenedGroups);
  const handleHideDropdown = (event) => {
    if (showMenu && event.key === "Escape") {
      setShowMenu(null);
    }
  };
  const handleClickOutside = (event) => {
    if (
      showMenu &&
      optionsRef.current &&
      !optionsRef.current.contains(event.target)
    ) {
      setShowMenu(null);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  const deleteRecordHandler = async (record_id) => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_user_group(record_id));
        if (res?.payload?.status === 200) {
          toast.success("User group deleted successfully");
          dispatch(get_user_groups());
        } else {
          errorMessage({
            payload: res.payload,
            action: "User group",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({ payload: error, action: "User group", msg: "deleted" });
      }
    }
  };

  const openFormHandler = (record) => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  const openUserAssignForm = (record) => {
    navigate(
      `${location?.pathname?.replace(
        "user_groups",
        "user_groups/assign_user_groups"
      )}`,
      { state: record }
    );
    // navigate("/settings/user_groups/assign_user_groups", );
  };
  useEffect(() => {
    dispatch(get_user_groups());
    // eslint-disable-next-line
  }, []);
  const testMenu = [
    {
      label: "Settings",
      action: (e) => {
        toast.info("Coming soon");
        setShowMenu(null);
      },
    },
    {
      label: "Users",
      action: (e) => {
        openUserAssignForm(e);
      },
    },
    {
      label: "Edit group",
      action: (e) => {
        openFormHandler(e);
        setShowMenu(null);
      },
    },
    {
      label: "Delete Group",
      action: (e) => {
        deleteRecordHandler(e._id);
        setShowMenu(null);
      },
    },
  ];

  const columnDefs = [
    { headerName: "Active", field: "active", flex: 1, minWidth: 100 },
    { headerName: "Manager", field: "manager", flex: 1 },
    { headerName: "Users", field: "user_count", minWidth: 100, flex: 1 },
    {
      headerName: "Action",
      field: "actions",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <div>
          <BsThreeDotsVertical
            size={18}
            className="cursor-pointer !relative hover:text-primary-100"
            onClick={() => setShowMenu(params.row?.records?._id)}
          />
          {showMenu === params.row?.records?._id ? (
            <div
              ref={optionsRef}
              className="absolute w-auto right-12 min-w-[100px] rounded shadow !mt-1 bg-white z-20 border-x border-y border-[#ddd] -ml-px overflow-hidden transition ease-in-out delay-150"
            >
              <ul className="!pl-0 !mb-0">
                {testMenu?.map((option, index) => {
                  return (
                    <li
                      key={index}
                      className="cursor-pointer px-3 !py-1.25 border- border-[#ddd] hover:bg-[#e1e1e1]"
                      onClick={() => option.action(params.row?.records)}
                    >
                      {option.label}
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}
        </div>
      ),

      width: 100,
    },
  ];
  const CustomButtons = () => {
    return (
      <div className="flex gap-x-2">
        {/* {userPermission("Add User") ? (
          <Button
            text="Add New Group"
            variant="btn_submit"
            onClick={() => openFormHandler(0)}
          />
        ) : null} */}
        <Button
          text="Manage Users"
          variant="btn_submit"
          onClick={() =>
            navigate(`${location?.pathname?.replace("user_groups", "users")}`)
          }
        />
        <Button
          text="Roles & Permissions"
          variant="btn_submit"
          onClick={() =>
            navigate(
              `${location?.pathname?.replace(
                "user_groups",
                "user_permissions"
              )}`
            )
          }
        />
      </div>
    );
  };
  const getTreeDataPath = (row) => row.path;
  const groupingColDef = {
    headerName: "Title",
    flex: 1,
    minWidth: 150,
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
  };

  function CustomGridTreeDataGroupingCell(props) {
    const { id, field, rowNode } = props;
    const apiRef = useGridApiContext();

    const filteredDescendantCountLookup = useGridSelector(
      apiRef,
      gridFilteredDescendantCountLookupSelector
    );
    const filteredDescendantCount =
      filteredDescendantCountLookup[rowNode.id] ?? 0;

    const handleClick = (event) => {
      if (rowNode.type !== "group") {
        return;
      }
      if (expendedRows?.includes(rowNode?.groupingKey)) {
        setExpendedRow(
          expendedRows.filter((key) => key !== rowNode?.groupingKey)
        );
      } else {
        setExpendedRow([...expendedRows, rowNode?.groupingKey]);
      }

      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
      apiRef.current.setCellFocus(id, field);
      event.stopPropagation();
    };

    return (
      <Box sx={{ ml: rowNode.depth * 4 }}>
        <div>
          {filteredDescendantCount > 0 ? (
            <div className="flex items-center">
              <button onClick={handleClick} tabIndex={-1} size="small" className="flex items-center">
                {rowNode.childrenExpanded ? (
                  <MdKeyboardArrowDown size={20} />
                ) : (
                  <MdKeyboardArrowRight size={20} />
                )}
                <span className="ml-2" style={{ fontWeight: "normal" }}>
                  {`${props.value} (${filteredDescendantCount})`}
                </span>
              </button>
            </div>
          ) : (
            <span className="flex items-center gap-x-2">
              <span className=""></span>
              {props.value}
            </span>
          )}
        </div>
      </Box>
    );
  }
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle={editingRecord ? "Update Group" : "Add Group"}
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        route="Setting > Users"
        heading="User Groups Listing"
        CustomButtons={CustomButtons}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          // items={updatedFlattenedGroups}
          items={updatedFlattenedGroups?.map((record, index) => {
            const { active, title, path, users } = record;
            const counter = index + 1;
            return {
              counter,
              records: record,
              active: active ? "Yes" : "No",
              title,
              path,
              // manager: users?.filter?.(({ user_type }) => user_type !== "User")
              //   ?.length,
              user_count: users?.length,
            };
          })}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="No"
          isLoading={isLoading}
          treeData={true}
          getTreeDataPath={getTreeDataPath}
          groupingColDef={groupingColDef}
          defaultGroupingExpansionDepth={
            updatedFlattenedGroups?.length ? -1 : 0
          }
          expendedRows={expendedRows}
          // exportButton={exportToCsv}
        />
      </div>
    </>
  );
};

export default UserGroups;
