import api from "./api";

export const getPurchaseRegistrarAccounts = async () => {
  return await api.get("/api/purchase_registrar/account");
};
export const createPurchaseRegistrarAccount = async (data) => {
  return await api.post("/api/purchase_registrar/account", data);
};

export const updatePurchaseRegistrarAccount = async (data) => {
  return await api.put(`/api/purchase_registrar/account/${data?.id}`, data);
};

export const deletePurchaseRegistrarAccount = async (id) => {
  return await api.delete(`/api/purchase_registrar/account/${id}`);
};

export const getRegistrarAccounts = async () => {
  return await api.get("/api/registrar_account");
};
export const createRegistrarAccount = async (data) => {
  return await api.post("/api/registrar_account", data);
};

export const updateRegistrarAccount = async (data) => {
  return await api.put(`/api/registrar_account/${data?.id}`, data);
};
