import React from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../../components";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  create_call_centers,
  update_call_centers,
} from "../../../features/callCentersSlice";
function AddUpdateForm({
  editingRecord,
  onCancelForm,
  modalTitle,
  onSave,
  newRecord,
}) {
  const { isLoading } = useSelector((state) => state.callCenters);
  const [errors, setErrors] = React.useState([]);

  const dispatch = useDispatch();
  const initialValues = {
    url: "",
    db_name: "",
    db_password: "",
    port: "",
    username: "",
    password: "",
    default_dialer_phone: "",
    active: true,
    ...(editingRecord && {
      _id: editingRecord._id,
      url: editingRecord.url,
      db_name: editingRecord.db_name,
      active: editingRecord.active,
      db_password: editingRecord.db_password,
      port: editingRecord.port,
      username: editingRecord.username,
      password: editingRecord.password,
      default_dialer_phone: editingRecord?.default_dialer_phone,
    }),
  };
  const handleSubmit = async (values) => {
    const action = editingRecord ? update_call_centers : create_call_centers;
    const message = editingRecord ? "updated" : "created";
    try {
      const res = await dispatch(action(values));
      if (res.payload?.status === 200) {
        toast.success(`Call Center successfully  ${message}`);
        onSave(!newRecord);
        onCancelForm();
      } else {
        if (Array.isArray(res.payload) || typeof res.payload === "object") {
          setErrors(res.payload);
        } else {
          toast.error(
            res.payload ? res.payload : `Call Center couldn't be ${message}`
          );
        }
      }
    } catch (error) {
      if (Array.isArray(error.payload)) {
        setErrors(error.payload);
      } else {
        toast.error(
          error.payload ? error.payload : `Call Center couldn't be ${message}`
        );
      }
    }
  };
  const formik = useFormik({
    initialValues,

    validateOnBlur: true,
    // validationSchema: CallCenters,
    onSubmit: handleSubmit,
  });
  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form className="grid md:grid-cols-4 gap-x-5 mt-5 mx-2.5">
          <div className="mb-3">
            <FormInput errors={errors} name="url" label="URL" formik={formik} />
          </div>
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="db_name"
              label="Database Name"
              formik={formik}
            />
          </div>
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="db_password"
              label="Database Password"
              formik={formik}
            />
          </div>
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="username"
              label="User Name"
              formik={formik}
            />
          </div>
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="password"
              label="Password"
              formik={formik}
            />
          </div>
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="port"
              label="Port"
              formik={formik}
            />
          </div>
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="active"
              label="Active"
              formik={formik}
              options={[
                { value: "true", label: "Yes" },
                { value: "false", label: "No" },
              ]}
              convertor={(value) => value === "true"}
              valueProp="value"
              labelProp="label"
            />
          </div>
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="default_dialer_phone"
              label="Default Dialer Phone"
              formik={formik}
            />
          </div>
        </form>
      </Modal>
    </>
  );
}

export default AddUpdateForm;
