import dayjs from "dayjs";

export const getFullName = (record) => {
  const fullName = `${record?.first_name || ""} ${record?.last_name || ""}`;
  if (fullName && fullName !== " ") {
    return fullName;
  } else {
    return "";
  }
};

export const getFormattedDate = (date, format) => {
  return dayjs(date).format(format ? format : "ddd, MMM D, YYYY h:mm:ss A");
};

export const getFormattedAddress = (shipping_address) => {
  const { address, city, state, zip } = shipping_address || {};
  const addressComponents = [address, city, state, zip].filter(Boolean);
  return addressComponents.join(", ");
};