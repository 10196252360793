import React, { useEffect, useState } from "react";
import AddUpdateForm from "./AddUpdateForm";
import { FaEdit } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../../components/molecules/PageHeader";
import {
  get_call_centers,
  callCenterReset,
} from "../../../features/callCentersSlice";
import userPermission from "../../../util/userPermission";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
const CallCenters = () => {
  const dispatch = useDispatch();
  const { isLoading, record } = useSelector((state) => state.callCenters);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newRecord, setNewRecord] = useState(false);
  const [searchText, setSearchText] = useState("");

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  useEffect(() => {
    dispatch(get_call_centers());
    return () => {
      dispatch(callCenterReset());
    };
    // eslint-disable-next-line
  }, []);

  const callCenter = record?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const columnDefs = [
    { headerName: "#", field: "counter" },
    { headerName: "DataBase Name", field: "db_name", flex: 1 },
    { headerName: "URL", field: "url", flex: 1 },
    { headerName: "Port", field: "port", flex: 1 },
    { headerName: "Default Phone", field: "default_dialer_phone", flex: 1 },
    { headerName: "Status", field: "status", flex: 1 },
    {
      field: "actions",
      renderCell: (params) => (
        <div>
          {userPermission("Update Call Center") && (
            <FaEdit
              onClick={openFormHandler(params.row.records)}
              className="my_navIcon"
              title="Update Call Center"
            />
          )}
        </div>
      ),
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  };
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add Call Center"
          onCancelForm={cancelFormHandler}
          onSave={setNewRecord}
          newRecord={newRecord}
        />
      )}
      <PageHeader
        route="Call Center > Call Centers"
        heading="Call Centers Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add Call Center")}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={callCenter?.map((record, index) => {
            let counter = index + 1;
            let db_name = record.db_name;
            let port = record.port;
            let url = record.url;
            let status = record.active ? "Active" : "InActive";
            let default_dialer_phone = record.default_dialer_phone;
            return {
              counter,
              records: record,
              db_name,
              port,
              url,
              status,
              default_dialer_phone,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={record?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          paginationMode="client"
        />
      </div>
    </>
  );
};

export default CallCenters;
