import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { createDomainEmailPrefix, createforwardEmail, deleteDomainEmailPrefix, deleteforwardEmail, getCloudflareDomainList, getDomainEmailPrefix, getforwardEmail, updateDomainEmailPrefix, updateforwardEmail } from "../services/cloudflareService";

const initialState = {
  isError: false,
  isLoading: false,
  cloudflareDomains: [],
  forwardEmail:[],
  emailPrefix:[],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error || 
    (error?.response?.data?.debugInfo&&[error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Card Type
export const get_cloudflare_domains = createAsyncThunk(
  "get_cloudflare_domains",
  async (data, thunkAPI) => {
    try {
      return await getCloudflareDomainList(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Forward Email
export const get_forward_email = createAsyncThunk(
  "get_forward_email",
  async (thunkAPI) => {
    try {
      return await getforwardEmail();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Create Forward Email
export const create_forward_email = createAsyncThunk(
  "create_forward_email",
  async (data, thunkAPI) => {
    try {
      return await createforwardEmail(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Forward Email
export const update_forward_email = createAsyncThunk(
  "update_forward_email",
  async (data, thunkAPI) => {
    try {
      return await updateforwardEmail(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const delete_forward_email = createAsyncThunk(
  "delete_forward_email",
  async (data, thunkAPI) => {
    try {
      return await deleteforwardEmail(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Forward Email
export const get_email_prefix = createAsyncThunk(
    "get_email_prefix",
    async (thunkAPI) => {
      try {
        return await getDomainEmailPrefix();
      } catch (error) {
        const message = getErrorMessage(error);
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  // Create Forward Email
  export const create_email_prefix = createAsyncThunk(
    "create_email_prefix",
    async (data, thunkAPI) => {
      try {
        return await createDomainEmailPrefix(data);
      } catch (error) {
        const message = getErrorMessage(error);
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  export const delete_email_prefix = createAsyncThunk(
    "delete_email_prefix",
    async (data, thunkAPI) => {
      try {
        return await deleteDomainEmailPrefix(data);
      } catch (error) {
        const message = getErrorMessage(error);
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  
  // Update Forward Email
  export const update_email_prefix = createAsyncThunk(
    "update_email_prefix",
    async (data, thunkAPI) => {
      try {
        return await updateDomainEmailPrefix(data);
      } catch (error) {
        const message = getErrorMessage(error);
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
export const cloudflareSlice = createSlice({
  name: "cloudflare",
  initialState,
  reducers: {
    cloudflareDomainsReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.cloudflareDomains = [];
      state.forwardEmail = [];
      state.emailPrefix = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_cloudflare_domains.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_cloudflare_domains.fulfilled, (state, action) => {
        state.isLoading = false;
        state.cloudflareDomains = action.payload?.data
      })
      .addCase(get_cloudflare_domains.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.cloudflareDomains = [];
      })
      .addCase(create_forward_email.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_forward_email.fulfilled, (state, action) => {
        state.isLoading = false;
        state.forwardEmail.push(action.payload.data);
      })
      .addCase(create_forward_email.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_forward_email.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_forward_email.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.forwardEmail.findIndex(
          ({ id }) => id === action?.payload?.data?.id
        );
        state.forwardEmail[result] = {
          ...state.forwardEmail[result],
          ...action.payload.data,
        };  
      })
      .addCase(update_forward_email.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_forward_email.pending, (state) => {
        state.isLoading = true;
        state.forwardEmail = [];
      })
      .addCase(get_forward_email.fulfilled, (state, action) => {
        state.isLoading = false;
        state.forwardEmail = action.payload?.data
      })
      .addCase(get_forward_email.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload?action.payload:"Unknown Error")
      })
      .addCase(create_email_prefix.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_email_prefix.fulfilled, (state, action) => {
        state.isLoading = false;
        state.emailPrefix.push(action.payload.data);
      })
      .addCase(create_email_prefix.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_email_prefix.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_email_prefix.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.emailPrefix.findIndex(
          ({ id }) => id === action?.payload?.data?.id
        );
        state.emailPrefix[result] = {
          ...state.emailPrefix[result],
          ...action.payload.data,
        };  
      })
      .addCase(update_email_prefix.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_email_prefix.pending, (state) => {
        state.isLoading = true;
        state.emailPrefix = [];
      })
      .addCase(get_email_prefix.fulfilled, (state, action) => {
        state.isLoading = false;
        state.emailPrefix = action.payload?.data
      })
      .addCase(get_email_prefix.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload?action.payload:"Unknown Error")
      })
      .addCase(delete_email_prefix.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_email_prefix.fulfilled, (state, action) => {
        state.isLoading = false;
        state.emailPrefix = state.emailPrefix.filter(
          ({ id }) => id !== +action?.payload?.data
        );
      })
      .addCase(delete_email_prefix.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload?action.payload:"Unknown Error")
      })
      .addCase(delete_forward_email.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_forward_email.fulfilled, (state, action) => {
        state.isLoading = false;
        state.forwardEmail = state.forwardEmail.filter(
          ({ id }) => id !== +action?.payload?.data
        );
      })
      .addCase(delete_forward_email.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload?action.payload:"Unknown Error")
      });
  },
});

export const { cloudflareDomainsReset } = cloudflareSlice.actions;
export default cloudflareSlice.reducer;
