import React, { useState } from "react";
import api from "../../services/api";
import PageHeader from "../../components/molecules/PageHeader";
import { DotsLoader } from "../../components";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";

function DIDsInventory() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [record, setRecord] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const getSERPReport = async (filter) => {
    setIsLoading(true);
    try {
      const res = await api.post("/api/connectcx/getCxDids", filter);
      if (res.status === 200) {
        setRecord(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("🚀 ~ getSERPReport ~ err:", err);
    }
  };
  React.useEffect(() => {
    getSERPReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnDefs = [
    { headerName: "#", field: "counter", width: 100, filterable: false },

    { headerName: "DID", field: "did", flex: 1 },
    {
      headerName: "Customer",
      field: "customer",
      flex: 1,
    },
    {
      headerName: "Destination Server IP",
      field: "destination_server_ip",
      flex: 1,
    },
    {
      headerName: "Destination",
      field: "destination",
      flex: 1,
    },
  ];
  const batchList = record?.data?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ page: 1, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    getSERPReport({
      page: +params.page + 1,
      size: params.pageSize,
    });
  };
  return (
    <>
      <PageHeader route="Setting > Industries" heading="DIDs Inventory List" />
      {isLoading ? <DotsLoader /> : null}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={batchList?.map((record, index) => {
            const counter = index + 1;
            const { did, customer, destination, destination_server_ip } =
              record;
            return {
              records: { ...record },
              counter,
              did,
              customer,
              destination,
              destination_server_ip,
            };
          })}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          paginationModel={paginationModel}
          totalItems={record?.totalItems}
        />
      </div>
    </>
  );
}

export default DIDsInventory;
