import React, { useEffect, useState } from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import AddUpdateForm from "./AddUpdateForm";
import { toast } from "react-toastify";
import errorMessage from "../../../util/errorMessage";
import userPermission from "../../../util/userPermission";
import PageHeader from "../../../components/molecules/PageHeader";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import { delete_email_prefix, get_email_prefix } from "../../../features/cloudflareSlice";

const EmailPrefix = () => {
  const dispatch = useDispatch();
  const { isLoading, emailPrefix } = useSelector((state) => state.cloudflare);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState("");
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_email_prefix(record_id));
        if (res?.payload?.status === 200) {
          toast.success("Email Prefix deleted");
          cancelFormHandler();
        } else {
          errorMessage({
            payload: res.payload,
            action: "Email Prefix",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({ payload: error, action: "Email Prefix", msg: "deleted" });
      }
    }
  };

  useEffect(() => {
    dispatch(get_email_prefix());
    // eslint-disable-next-line
  }, []);
  const jobStatus = emailPrefix?.filter((status) =>
    status?.name?.toLowerCase().includes(searchText.toLowerCase())
  );
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "Name", field: "name", flex: 1 },
    {
      field: "actions",
      renderCell: (params) => (
          <div>
              <FaEdit
                onClick={openFormHandler(params.row.records)}
                className="my_navIcon"
                title="Update Email Prefix"
              />
              <FaTrashAlt
                onClick={deleteRecordHandler(params.row.records.id)}
                className="my_navIcon"
                title="Delete Email Prefix"
              />
          </div>
        ),
    },
  ];
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add Email Prefix"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        route="Setting > Email Prefix"
        heading="Email Prefix Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add Job Status")}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={jobStatus?.map((record, index) => {
            let counter = index + 1;
            let name = record.name;
            return {
              records: record,
              counter,
              name,
            };
          })}
          pagination="No"
          totalItems={emailPrefix?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default EmailPrefix;
