import React from "react";
import SelectInput from "../../atoms/SelectInput";
import { DataGridPro } from "@mui/x-data-grid-pro";
import MUIToolbar from "./MUIToolbar";
const MUIDataTable = ({
  searchText,
  setSearchText,
  totalItems,
  isLoading,
  onPageSizeChange,
  onPaginationModelChange,
  paginationModel,
  items,
  columnDefs,
  getRowId,
  paginationMode,
  gridOptions,
  autoHeight,
  checkboxSelection,
  onRowSelectionModelChange,
  ref,
  additionalMsg,
  customSize,
  additionalStatus,
  additionalStatusValue,
  pagination,
  searchable,
  height,
  exportButton,
  handleExport,
  searchCounter,
  onFilterModelChange,
  CustomToolbar,
  processRowUpdate,
  onCellEditStart,
  onProcessRowUpdateError,
  onCellEditStop,
  cellModesModel,
  displayCount,
  rowModesModel,
  onRowModesModelChange,
  onRowEditStart,
  onRowEditStop,
  onRowEditCommit,
  pinnedColumns,
  CustomComponent,
  showCount,
  LineOne,
  rowReordering,
  handleRowOrderChange,
  getRowClass,
  columnGroupingModel,
  columnGrouping,
  filterModel,
  columnVisibilityModel,
  editMode,
  toolbarProps,
  density,
  rowHeight,
  toolbar,
  hideFooter,
  LineOne2,
  filterMode,
  CustomComponent2,
  hidePageSelector,
  hideTopPageSelector,
  onSortModelChange,
  sortingMode,
  disableColumnMenu,
  AfterSearch,
  treeData,
  getTreeDataPath,
  groupingColDef,
  defaultGroupingExpansionDepth,
  initialState,
  columnHeaderHeight,
  expendedRows,
  hideHeader,
  disableRowSelectionOnClick,
  mainClass,
  CustomFooter,
  foterProps,
  displayClass,
  exportOption,
  minHeight,
  maxHeight,
  style
}) => {
  const options = [
    {
      value: 25,
      label: "25 Records",
    },
    {
      value: 50,
      label: "50 Records",
    },
    {
      value: 75,
      label: "75 Records",
    },
    {
      value: 100,
      label: "100 Records",
    },
    {
      value: 250,
      label: "250 Records",
    },
    {
      value: 500,
      label: "500 Records",
    },
    {
      value: 1000,
      label: "1000 Records",
    },
    {
      value: 1500,
      label: "1500 Records",
    },
    {
      value: customSize ? customSize : totalItems,
      label: customSize ? `${customSize} Records` : "All Records",
    },
  ];
  const [showExportOptions, setShowExportOptions] = React.useState(false);
  const optionsRef = React.useRef();
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setShowExportOptions(false);
    }
  };
  const handleClickOutside = (event) => {
    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      setShowExportOptions(false);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  return (
    <div className={`bg-white p-2 ${mainClass}`}>
      <div className="ml-auto mr-auto" style={{ overflowX: "hidden" }}>
        {displayCount !== "No" ? (
          <>
            <div className="grid grid-cols-12">
              <div className={["col-span-12"].join(" ")}>
                {showCount !== "No" ? (
                  <div
                    className={`w-full py-[5px] text-base font-normal font-mont text-heading my-2 flex flex-wrap justify-between ${displayClass}`}
                  >
                    <div className="flex items-center space-x-2">
                      {items?.length > 0
                        ? searchCounter === "Yes"
                          ? `Displaying 1-${items?.length} of ${
                              totalItems ? totalItems : items?.length
                            } result ${additionalMsg ? additionalMsg : ""}`
                          : pagination === "No"
                          ? `Displaying 1-${
                              totalItems ? totalItems : items?.length
                            } of ${
                              totalItems ? totalItems : items?.length
                            } result ${additionalMsg ? additionalMsg : ""}`
                          : `Displaying ${
                              (paginationModel?.page - 1) *
                                paginationModel?.pageSize +
                              1
                            }-${Math.min(
                              paginationModel?.page * paginationModel?.pageSize,
                              totalItems ? totalItems : items?.length
                            )} of ${
                              totalItems ? totalItems : items?.length
                            } result ${additionalMsg ? additionalMsg : ""}`
                        : `Displaying 0-0 of ${
                            totalItems
                              ? totalItems
                              : items?.length
                              ? items?.length
                              : 0
                          } result ${additionalMsg ? additionalMsg : ""}`}
                      {LineOne2 ? <LineOne2 /> : null}
                    </div>
                    {searchable === "No" ? (
                      exportOption?.length > 0 ? (
                        <div className="relative">
                          <button
                            onClick={() =>
                              setShowExportOptions(!showExportOptions)
                            }
                            text="Export"
                            className="relative float-right border ml-1 min-w-[119px] w-full h-[31px] text-sm"
                          >
                            Export
                            {showExportOptions && (
                              <div className="absolute w-[101.5%] !mt-1.25 bg-white z-10 border-x border-b border-[#ddd] -ml-px overflow-hidden transition ease-in-out delay-150">
                                <ul ref={optionsRef} className="!pl-0 !mb-0">
                                  {exportOption?.map((option, index) => {
                                    return (
                                      <li
                                        key={index}
                                        className="cursor-pointer px-2.5 !py-1.25 border-t border-[#ddd] hover:bg-[#e1e1e1]"
                                        onClick={
                                          option?.action
                                            ? option?.action
                                            : () => handleExport(option.value)
                                        }
                                      >
                                        {option.label}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            )}
                          </button>
                        </div>
                      ) : exportButton ? (
                        <div>
                          <button
                            onClick={exportButton}
                            text="Export"
                            className="relative float-right border ml-1 w-[119px] h-[31px] text-sm"
                          >
                            Export
                          </button>
                        </div>
                      ) : null
                    ) : null}
                    {LineOne ? <LineOne /> : null}
                  </div>
                ) : null}
              </div>
            </div>
          </>
        ) : null}
        {searchable !== "No" ? (
          <div className="flex flex-wrap w-full justify-between mb-3 mt-2 mt-md-0">
            <div className="relative w-full col-span-6 md:col-span-6 !w-1/2 flex items-center">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                className="h-[31px] bg-white border border-gray-300 text-gray-900 text-sm rounded-[0.2rem] w-full pl-12 p-2.5 "
                placeholder="Search"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
              {AfterSearch ? <AfterSearch /> : null}
            </div>
            {CustomComponent2 ? <CustomComponent2 /> : null}
            {additionalStatus && (
              <div className="ml-3 flex justify-between w-[25%]">
                {additionalStatusValue?.inqueue > 0 && (
                  <span className="font-semibold font_pop">
                    In Queue:{additionalStatusValue?.inqueue}{" "}
                  </span>
                )}

                {additionalStatusValue?.purchased > 0 && (
                  <span className="font-semibold font_pop">
                    Purchased:{additionalStatusValue?.purchased}{" "}
                  </span>
                )}

                {additionalStatusValue?.error > 0 && (
                  <span className="font-semibold font_pop">
                    Error:{additionalStatusValue?.error}{" "}
                  </span>
                )}

                {additionalStatusValue?.selectedRecords > 0 && (
                  <span className="font-semibold font_pop">
                    Selected Records:{additionalStatusValue?.selectedRecords}{" "}
                  </span>
                )}
              </div>
            )}

            {exportButton ||
            pagination !== "No" ||
            exportOption?.length > 0 ? (
              <div className="w-[20%] justify-center md:justify-end gap-2 flex md:!mt-0 md:col-span-2 pr-1">
                {exportButton && (
                  <div>
                    <button
                      onClick={exportButton}
                      text="Export"
                      className="relative float-right border ml-1 w-[119px] h-[31px] text-sm"
                    >
                      Export
                    </button>
                  </div>
                )}
                {exportOption?.length > 0 ? (
                  <div className="relative">
                    <button
                      onClick={() => setShowExportOptions(!showExportOptions)}
                      text="Export"
                      className="relative float-right border ml-1 min-w-[119px] w-full h-[31px] text-sm"
                    >
                      Export
                      {showExportOptions && (
                        <div className="absolute w-[101.5%] !mt-1.25 bg-white z-10 border-x border-b border-[#ddd] -ml-px overflow-hidden transition ease-in-out delay-150">
                          <ul ref={optionsRef} className="!pl-0 !mb-0">
                            {exportOption?.map((option, index) => {
                              return (
                                <li
                                  key={index}
                                  className="cursor-pointer px-2.5 !py-1.25 border-t border-[#ddd] hover:bg-[#e1e1e1]"
                                  onClick={
                                    option?.action
                                      ? option?.action
                                      : () => handleExport(option.value)
                                  }
                                >
                                  {option.label}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                    </button>
                  </div>
                ) : null}
                {pagination !== "No" &&
                hidePageSelector !== "Yes" &&
                hideTopPageSelector !== "Yes" ? (
                  <div className="justify-center md:justify-end gap-2 flex md:!mt-0 md:col-span-2 pr-1">
                    <SelectInput
                      className={
                        "h-[31px] bg-white border border-gray-300 float-right rounded-[0.2rem]"
                      }
                      onChange={(e) =>
                        onPaginationModelChange({
                          pageSize: e.target.value,
                          page: 0,
                        })
                      }
                      options={options}
                      value={paginationModel.pageSize}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            {CustomComponent ? <CustomComponent /> : null}
          </div>
        ) : null}
        {CustomComponent && searchable === "No" ? <CustomComponent /> : null}
        <div style={{ height: height ? height : "62vh", width: "100%",...style }}>
          {/* <div className={`w-full h-[50vh] md:h-[${height ? height : "62vh"}]`}> */}
          <DataGridPro
            ref={ref}
            density={density ? density : "compact"}
            rows={isLoading ? [] : items ? items : []}
            columns={columnDefs}
            pagination={pagination === "No" ? false : true}
            paginationModel={
              pagination === "No"
                ? { page: 1, pageSize: items?.length }
                : {
                    page: +paginationModel?.page - 1,
                    pageSize: +paginationModel?.pageSize,
                  }
            }
            page={pagination === "No" ? 1 : paginationModel?.page}
            pageSize={
              pagination === "No" ? items?.length : paginationModel?.pageSize
            }
            rowCount={totalItems ? totalItems : 0}
            onPaginationModelChange={onPaginationModelChange}
            onPageSizeChange={onPageSizeChange}
            paginationMode={paginationMode ? paginationMode : "server"}
            components={{
              Toolbar:
                toolbar === "No"
                  ? null
                  : CustomToolbar
                  ? CustomToolbar
                  : MUIToolbar,
              Footer: CustomFooter,
            }}
            loading={isLoading}
            componentsProps={{
              toolbar: {
                ...(toolbarProps || {}),
                className: "bg-gray-500 text-white",
              },
              style: {
                backgroundColor: "gray",
                color: "#000",
              },
              footer: { ...foterProps },
            }}
            filterModel={filterModel}
            filterMode={
              filterMode
                ? filterMode
                : onFilterModelChange
                ? "server"
                : "client"
            }
            checkboxSelection={checkboxSelection}
            getRowId={(row) =>
              getRowId
                ? getRowId
                : row?.counter
                ? row?.counter
                : row?.records?._id
                ? row?.records?._id
                : row?.id
                ? row?.id
                : row?._id
                ? row?._id
                : row?.records?.id
                ? row?.records?.id
                : row?.status
            }
            headerHeight={40}
            columnHeaderHeight={columnHeaderHeight ? columnHeaderHeight : 40}
            classes={{
              // ...classes,
              columnHeader: "bg-[#f8f8f8] shadow-sm",
              columnHeaderTitle: "tableHead font-bold text-md capitalize px-0",
              row: "text-md",
            }}
            onFilterModelChange={onFilterModelChange}
            pageSizeOptions={
              hidePageSelector !== "Yes"
                ? [
                    25,
                    50,
                    75,
                    100,
                    250,
                    500,
                    1000,
                    1500,
                    customSize ? customSize : totalItems ? totalItems : 0,
                  ]
                : []
            }
            autoHeight={autoHeight}
            getRowHeight={gridOptions}
            onRowSelectionModelChange={onRowSelectionModelChange}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={onProcessRowUpdateError}
            onCellEditStart={onCellEditStart}
            onCellEdit
            onCellEditStop={onCellEditStop}
            cellModesModel={cellModesModel}
            editMode={editMode ? editMode : "row"}
            rowModesModel={rowModesModel}
            onRowModesModelChange={onRowModesModelChange}
            onRowEditStart={onRowEditStart}
            onRowEditStop={onRowEditStop}
            onRowEditCommit={onRowEditCommit}
            pinnedColumns={pinnedColumns}
            rowReordering={rowReordering}
            onRowOrderChange={handleRowOrderChange}
            getRowClassName={getRowClass}
            columnGroupingModel={columnGroupingModel}
            experimentalFeatures={{ columnGrouping: columnGrouping }}
            columnVisibilityModel={columnVisibilityModel}
            rowHeight={rowHeight ? rowHeight : null}
            hideFooter={hideFooter}
            onSortModelChange={onSortModelChange}
            sortingMode={
              sortingMode
                ? sortingMode
                : onSortModelChange
                ? "server"
                : "client"
            }
            disableColumnMenu={disableColumnMenu === "No" ? false : true}
            // treeData={treeData}
            // getTreeDataPath={getTreeDataPath}
            // groupingColDef={groupingColDef}
            defaultGroupingExpansionDepth={defaultGroupingExpansionDepth}
            treeData={treeData}
            getTreeDataPath={getTreeDataPath}
            groupingColDef={groupingColDef}
            initialState={initialState}
            isGroupExpandedByDefault={(node) => {
              return expendedRows?.includes(node.groupingKey);
            }}
            sx={{
              ...(hideHeader && {
                "& .MuiDataGrid-columnHeaders": {
                  display: "none",
                },
              }),
              ...(disableRowSelectionOnClick && {
                "& .MuiDataGrid-row:hover": {
                  // cursor: "pointer",
                  backgroundColor: "inherit",
                },
                "& .MuiDataGrid-row.Mui-selected": {
                  backgroundColor: "inherit",
                },
                "& .MuiDataGrid-cell:focus": {
                  outline: "none",
                },
              }),
            }}
            disableRowSelectionOnClick={disableRowSelectionOnClick}
          />
        </div>
      </div>
    </div>
  );
};

export default MUIDataTable;
