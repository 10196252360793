import React, { useEffect, useState } from "react";
import PurchaseRegistrarModal from "./AddUpdateForm";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import errorMessage from "../../../util/errorMessage";
import userPermission from "../../../util/userPermission";
import PageHeader from "../../../components/molecules/PageHeader";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import dayjs from "dayjs";
import {
  delete_purcahse_registrar_account,
  get_purcahse_registrar_account,
  registrarResetAccount,
} from "../../../features/registrarAccountsSlice";
import { get_purcahse_registrar } from "../../../features/purchaseRegistrarSlice";

const PurchaseRegistrarAccounts = () => {
  const dispatch = useDispatch();
  const { isLoading, record } = useSelector(
    (state) => state?.registrarAccounts
  );
  const [isEditing, setIsEditing] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [searchText, setSearchText] = useState("");
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(
          delete_purcahse_registrar_account(record_id)
        );
        if (res?.payload?.status === 200) {
          toast.success("Purchase registrar account deleted");
          dispatch(get_purcahse_registrar_account());
          cancelFormHandler();
        } else {
          errorMessage({
            payload: res.payload,
            action: "Purchase registrar account",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({
          payload: error,
          action: "Purchase registrar account",
          msg: "deleted",
        });
      }
    }
  };
  useEffect(() => {
    dispatch(get_purcahse_registrar_account());
    dispatch(get_purcahse_registrar());
    return () => {
      dispatch(registrarResetAccount());
    };
    // eslint-disable-next-line
  }, []);
  const filterIP = record?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const getRowHeight = (params) => {
    const rowHeight = 40; // minimum height of the row
    const cellContentHeight = 0; // you can set the height of the cell content if you want
    const data = params?.model;
    if (data?.username && data.username?.length > 0) {
      const tagHeight = 20; // height of each tag
      const numTags = data?.username.length;
      return rowHeight + tagHeight * numTags + cellContentHeight;
    }
  };
  const columnDefs = [
    { headerName: "#", field: "counter", filterable: false },
    {
      headerName: "Name",
      field: "name",
      flex: 1,
    },
    {
      headerName: "Created At",
      field: "created_at",
      flex: 1,
    },
    {
      field: "actions",
      renderCell: (params) => (
        <div className="flex flex-row">
          <FaEdit
            onClick={openFormHandler(params.row.records)}
            className="my_navIcon"
            title="Update Purchase Registrar Account"
          />
          <FaTrashAlt
            onClick={deleteRecordHandler(params.row.record_id)}
            className="my_navIcon"
            title="Delete Purchase Registrar Account"
          />
        </div>
      ),
      width: 100,
      filterable: false,
    },
  ];
  return (
    <>
      {isEditing && (
        <PurchaseRegistrarModal
          editingRecord={editingRecord}
          modalTitle="Add Purchase registrar"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        route="Setting > Purchase registrar"
        heading="Purchase Registrar Accounts Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add Allowed IP")}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={filterIP?.map((record, index) => {
            let counter = index + 1;
            let records = { ...record, id: record?._id };
            let record_id = record._id;
            let name = record?.name;
            let status = record?.status ? "Active" : "InActive";
            let created_at = dayjs(record.createdAt).format(
              "ddd, MMM D, YYYY h:mm A"
            );
            return {
              counter,
              records,
              record_id,
              name,
              status,
              created_at,
            };
          })}
          totalItems={record?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="No"
          isLoading={isLoading}
          gridOptions={getRowHeight}
        />
      </div>
    </>
  );
};

export default PurchaseRegistrarAccounts;
