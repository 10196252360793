import React, { useState } from "react";
import api from "../../../../services/api";
import PageHeader from "../../../../components/molecules/PageHeader";
import { Button, DotsLoader } from "../../../../components";
import MUIDataTable from "../../../../components/molecules/DataTable/muigrid";
import { useDispatch, useSelector } from "react-redux";
import {
  get_industry,
  industryReset,
} from "../../../../features/IndustrySlice";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { FaDownload, FaPlus, FaSearch } from "react-icons/fa";
import {
  InputLabel,
  Button as MUIButton,
  MenuItem,
  Select,
  TablePagination,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import LeadJobListModal from "./LeadJobList";
import VendorsListModal from "./VendorsList";
import relativeTime from "dayjs/plugin/relativeTime"; // Import the plugin
import utc from "dayjs/plugin/utc";
import NewCreateBatchModal from "./NewCreateBatchModal";
import { Dropdown } from "react-bootstrap";
// import ReactDOMServer from "react-dom/server";
import HrefDetailModal from "./HrefDetailModal";
function Domains() {
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [record, setRecord] = useState([]);
  const dispatch = useDispatch();
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const [isBatch, setIsBatch] = React.useState(false);
  const [vendors, setVendors] = useState(null);
  const [detailType, setDetailType] = useState(null);
  const [countModal, setCountModal] = useState(null);
  const industry = useSelector((state) => state.industry);
  const [selectedData, setSelectedData] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [sortingModel, setSortingModel] = React.useState({
    sort_field: null,
    sort_by: null,
  });
  dayjs.extend(relativeTime);
  dayjs.extend(utc);
  const getSERPDomainReport = async (filter) => {
    setIsLoading(true);
    if (!filter?.filters?.rules?.length) {
      delete filter.filters;
    }
    delete filter?.pageSize;
    try {
      const res = await api.post("/api/domains/domain_data", filter); //vishnu
      if (res.status === 200) {
        setRecord(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("🚀 ~ getSERPReport ~ err:", err);
    }
  };
  React.useEffect(() => {
    getSERPDomainReport({
      ...paginationModel,
      ...sortingModel,
      size: paginationModel.pageSize,
      filters: queryOptions,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const sortedIndustries = [...(industry?.record?.records || [])].sort((a, b) =>
    a?.name?.localeCompare(b?.name)
  );
  const CustomSelectInput = ({ item, applyValue }) => {
    const [value, setValue] = useState(item.value);
    return (
      <div fullWidth>
        <InputLabel>Value</InputLabel>
        <Select
          value={value || ""}
          onChange={(event) => {
            setValue(event.target.value);
            applyValue({ ...item, value: event.target.value });
          }}
          fullWidth
        >
          {sortedIndustries?.map((option) => (
            <MenuItem key={option._id} value={option._id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  };
  const getLatestIndex = async (domain) => {
    const c = window.confirm("Are you sure want to perform this action?");
    if (!c) return;
    setLoading(true);
    try {
      const res = await api.post(`/api/domains/check_domain_indexing`, {
        domain,
      });
      if (res.status === 200) {
        const data = res.data;
        const updatedItems = record?.data?.map((item) =>
          item._id === data._id ? data : item
        );
        toast.success("Latest indexed updated");
        setRecord({ ...record, data: updatedItems });
      } else {
        toast.error("Couldn't update the latest indexed");
      }
      setLoading(false);
    } catch (err) {
      toast.error("Couldn't update the latest indexed");
      console.log("🚀 ~ getLatestPosition ~ err:", err);
      setLoading(false);
    }
  };
  const onSingleSelect = ({ checked, data }) => {
    try {
      if (checked) {
        setSelectedData((prevSelectedData) => {
          const updatedSelectedData = [...prevSelectedData, data];
          return updatedSelectedData;
        });
      } else {
        setSelectedData((prevSelectedRecord) => {
          const updatedSelectedRecord = prevSelectedRecord?.filter(
            (domain) => domain?.id !== data?.id
          );
          return updatedSelectedRecord;
        });
      }
    } catch (err) {
      console.log("🚀 ~ file: index.jsx:116 ~ onSingleSelect ~ err:", err);
    }
  };
  const onSelectAll = (checked) => {
    if (checked) {
      const updatedArray = batchList?.map((item) => item);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };
  const isSelected = (data) => {
    if (selectedData?.length > 0) {
      if (
        selectedData?.filter(({ domain }) => domain === data?.domain).length > 0
      ) {
        return true;
      }
    }
    return false;
  };
  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          checked={
            batchList?.length && selectedData?.length === batchList?.length
          }
          disabled={!batchList?.length}
        />
      ),
      filterable: false,
      sortable: false,
      width: 60,
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={isSelected(params.row.records)}
          onChange={(e) =>
            onSingleSelect({
              checked: e.target.checked,
              data: params.row.records,
            })
          }
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
        />
      ),
      disableColumnMenu: true,
    },
    { headerName: "#", field: "counter", width: 60, filterable: false },
    {
      field: "domain",
      headerName: "Domain",
      renderCell: (params) => {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${params?.row["domain"]}`}
            className="text-[#000] hover:text-blue-600 hover:underline no-underline"
          >
            {params?.row["domain"]}
          </a>
        );
      },
      flex: 1,
      minWidth: 200,
      disableColumnMenu: true,
    },
    {
      headerName: "DR",
      field: "latest_ahref_domain_DR",
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      type: "number",
      disableColumnMenu: true,
      valueGetter: (params) => params?.row?.ahref_domain_DR?.length, // Provide a function to get the sorting value
      sortComparator: (v1, v2, row1, row2) => v1 - v2, // Provide a sorting function
      renderCell: (params) =>
        params?.row?.ahref_domain_DR?.length > 0 ? (
          <div>
            <div className="flex justify-between items-center w-full">
              <span>
                {params?.row?.latest_ahref_domain_DR &&
                params?.row?.latest_ahref_domain_DR !== -1
                  ? params?.row?.latest_ahref_domain_DR
                  : "N/A"}
              </span>
              <Tooltip
                title={
                  <div className="flex flex-col text-sm w-[full]">
                    {params?.row?.ahref_domain_DR?.map((item, index) => (
                      <span key={index}>
                        {`${index + 1}. Date: ${dayjs(item?.log_date).format(
                          "ddd, MMM D, YYYY h:mm:ss A"
                        )}, DR: ${item["DR"] || "N/A"}`}
                      </span>
                    ))}
                  </div>
                }
              >
                <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
                  {params?.row?.ahref_domain_DR?.length}
                </span>
              </Tooltip>
            </div>
            {params.row.ahref_domain_DR_status !== "Complete" ? (
              <span>{params.row.ahref_domain_DR_status}</span>
            ) : null}
          </div>
        ) : null,
    },
    {
      headerName: "Domain Backlinks",
      field: "latest_ahref_domain_Backlink",
      flex: 1,
      align: "center",
      type: "number",
      headerAlign: "center",
      minWidth: 150,
      disableColumnMenu: true,
      valueGetter: (params) => params?.row?.ahref_domain_Backlink?.length, // Provide a function to get the sorting value
      sortComparator: (v1, v2, row1, row2) => v1 - v2, // Provide a sorting function
      renderCell: (params) =>
        params?.row?.ahref_domain_Backlink?.length > 0 ? (
          <div className="w-auto min-w-[100px]">
            <div className="flex justify-between items-center w-full">
              <span>
                {params?.row?.latest_ahref_domain_Backlink &&
                params?.row?.latest_ahref_domain_Backlink !== -1
                  ? params?.row?.latest_ahref_domain_Backlink
                  : "N/A"}
              </span>
              <Tooltip
                title={
                  <div className="flex flex-col text-sm w-[full]">
                    {params?.row?.ahref_domain_Backlink?.map((item, index) => (
                      <span key={index}>
                        {`${index + 1}. Date: ${dayjs(item?.log_date).format(
                          "ddd, MMM D, YYYY h:mm:ss A"
                        )}, Backlinks: ${item["Backlinks"] || "N/A"}`}
                      </span>
                    ))}
                  </div>
                }
              >
                <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
                  {params?.row?.ahref_domain_Backlink?.length}
                </span>
              </Tooltip>
            </div>
            {params.row.ahref_domain_Backlink_status !== "Complete" ? (
              <span>{params.row.ahref_domain_Backlink_status}</span>
            ) : null}
          </div>
        ) : null,
    },
    {
      headerName: "PK Difficulty",
      field: "latest_PK_ahref_keyword_difficulty",
      flex: 1,
      align: "center",
      type: "number",
      headerAlign: "center",
      minWidth: 100,
      disableColumnMenu: true,
      valueGetter: (params) => params?.row?.ahref_PK_keyword_difficulty?.length, // Provide a function to get the sorting value
      sortComparator: (v1, v2, row1, row2) => v1 - v2, // Provide a sorting function
      renderCell: (params) =>
        params?.row?.ahref_PK_keyword_difficulty?.length > 0 ? (
          <div className="flex justify-between items-center w-full">
            <span>
              {params?.row?.latest_PK_ahref_keyword_difficulty &&
              params?.row?.latest_PK_ahref_keyword_difficulty !== -1
                ? params?.row?.latest_PK_ahref_keyword_difficulty
                : "N/A"}
            </span>
            <Tooltip
              title={
                <div className="flex flex-col text-sm w-[full]">
                  {params?.row?.ahref_PK_keyword_difficulty?.map(
                    (item, index) => (
                      <span key={index}>
                        {`${index + 1}. Date: ${dayjs(item?.log_date).format(
                          "ddd, MMM D, YYYY h:mm:ss A"
                        )}, Difficulty: ${item["difficulty"] || "N/A"}`}
                      </span>
                    )
                  )}
                </div>
              }
            >
              <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
                {params?.row?.ahref_PK_keyword_difficulty?.length}
              </span>
            </Tooltip>
          </div>
        ) : null,
    },
    {
      headerName: "PK Volume",
      field: "latest_PK_ahref_keyword_volume",
      flex: 1,
      align: "center",
      type: "number",
      headerAlign: "center",
      minWidth: 100,
      disableColumnMenu: true,
      valueGetter: (params) => params?.row?.ahref_PK_keyword_volume?.length, // Provide a function to get the sorting value
      sortComparator: (v1, v2, row1, row2) => v1 - v2, // Provide a sorting function
      renderCell: (params) =>
        params?.row?.ahref_PK_keyword_volume?.length > 0 ? (
          <div className="flex justify-between items-center w-full">
            <span>
              {params?.row?.latest_PK_ahref_keyword_volume &&
              params?.row?.latest_PK_ahref_keyword_volume !== -1
                ? params?.row?.latest_PK_ahref_keyword_volume
                : "N/A"}
            </span>
            <Tooltip
              title={
                <div className="flex flex-col text-sm w-[full]">
                  {params?.row?.ahref_PK_keyword_volume?.map((item, index) => (
                    <span key={index}>
                      {`${index + 1}. Date: ${dayjs(item?.log_date).format(
                        "ddd, MMM D, YYYY h:mm:ss A"
                      )}, Volume: ${item["volume"] || "N/A"}`}
                    </span>
                  ))}
                </div>
              }
            >
              <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
                {params?.row?.ahref_PK_keyword_volume?.length}
              </span>
            </Tooltip>
          </div>
        ) : null,
    },
    {
      headerName: "Primary Keyword",
      field: "primary_keyword",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 130,
    },
    {
      headerName: "Generated By",
      field: "generate_domain_by",
      flex: 1,
      minWidth: 110,
      type: "singleSelect",
      align: "center",
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        generate_domain_by: value?.value,
      }),
      valueOptions: [
        { value: "city", label: "City" },
        { value: "city_alias", label: "City Alias" },
      ],
    },
    {
      headerName: "In/Out Area",
      field: "in_area",
      flex: 1,
      minWidth: 100,
      type: "singleSelect",
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        in_area: value?.value,
      }),
      valueOptions: [
        { value: true, label: "In Area" },
        { value: false, label: "Out Area" },
      ],
    },
    {
      headerName: "Population",
      field: "population",
      flex: 1,
      disableColumnMenu: true,
      type: "number",
      minWidth: 100,
    },
    {
      headerName: "Indexed",
      field: "is_indexed",
      flex: 1,
      minWidth: 100,
      type: "singleSelect",
      disableColumnMenu: true,
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        is_indexed: value?.value,
      }),
      valueOptions: [
        { value: "Pending", label: "Pending" },
        { value: "Indexed", label: "Indexed" },
        { value: "NotChecked", label: "Not Checked" },
        { value: "NotIndexed", label: "Not Indexed" },
        { value: "NotApplicable", label: "Not Applicable" },
      ],
      renderCell: (params) =>
        params.row.is_indexed === "NotIndexed" ||
        params.row.is_indexed === "NotChecked" ? (
          <span
            className="text-blue-600 hover:underline cursor-pointer"
            onClick={() => getLatestIndex(params.row.domain)}
          >
            {params.row.is_indexed}
          </span>
        ) : (
          <span>{params.row.is_indexed}</span>
        ),
    },
    {
      headerName: "State",
      field: "state",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 100,
    },
    {
      headerName: "City",
      field: "city",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 100,
    },
    {
      headerName: "Domain Location",
      field: "location",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      headerName: "Industry",
      field: "industry_id",
      flex: 1,
      type: "singleSelect",
      minWidth: 150,
      valueOptions: sortedIndustries,
      disableColumnMenu: true,
      renderCell: (params) => params.row?.industry_id,
      filterOperators: [
        {
          label: "is",
          value: "is",
          InputComponent: CustomSelectInput,
          InputComponentProps: { type: "singleSelect" },
          getApplyFilterFn: (filterItem) => {
            return (params) => {
              return params.value === filterItem.value;
            };
          },
        },
      ],
    },
    {
      headerName: "Latest Position",
      field: "latest_position",
      flex: 1,
      disableColumnMenu: true,
      type: "number",
      align: "center",
      headerAlign: "left",
      minWidth: 120,
    },
    {
      headerName: "Vendors",
      field: "vendors",
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      sortable: true,
      filterable: false,
      valueGetter: (params) =>
        params?.row?.vendors ? params?.row?.vendors?.length : 0,
      sortComparator: (v1, v2, row1, row2) => v1 - v2,
      renderCell: (params) =>
        params?.row?.vendors?.length ? (
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            onClick={() =>
              setVendors({
                vendors: params?.row?.vendors,
                domain: params?.row["domain"],
              })
            }
          >
            {params?.row?.vendors?.length ? params?.row?.vendors?.length : 0}
          </span>
        ) : (
          <span>0</span>
        ),
    },
    {
      headerName: "Vendor Type",
      field: "vendors.vendor_type",
      flex: 1,
      minWidth: 120,
      type: "singleSelect",
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        in_area: value?.value,
      }),
      valueOptions: [
        { value: "InNet", label: "In Net" },
        { value: "OutNet", label: "Out Net" },
      ],
    },
    {
      headerName: "InNet",
      field: "vendors.vendor_type_in",
      flex: 1,
      minWidth: 100,
      filterable: false,
      renderCell: (params) => (
        <span>
          {params?.row?.vendors?.length
            ? params?.row?.vendors?.filter(
                ({ vendor_type }) => vendor_type === "InNet"
              )?.length
            : 0}
        </span>
      ),
      sortable: true,
      valueGetter: (params) => {
        const value = params?.row?.vendors?.length
          ? params?.row?.vendors?.filter(
              ({ vendor_type }) => vendor_type === "InNet"
            )?.length
          : 0;
        return value;
      },
      sortComparator: (v1, v2, row1, row2) => {
        return v1 - v2;
      },
    },
    {
      headerName: "OutNet",
      field: "vendors.vendor_type_Out",
      flex: 1,
      minWidth: 100,
      filterable: false,
      renderCell: (params) => (
        <span>
          {params?.row?.vendors?.length
            ? params?.row?.vendors?.filter(
                ({ vendor_type }) => vendor_type === "OutNet"
              )?.length
            : 0}
        </span>
      ),
      sortable: true,
      valueGetter: (params) => {
        const value = params?.row?.vendors?.length
          ? params?.row?.vendors?.filter(
              ({ vendor_type }) => vendor_type === "OutNet"
            )?.length
          : 0;
        return value;
      },
      sortComparator: (v1, v2, row1, row2) => {
        return v1 - v2;
      },
    },
    {
      headerName: "Total Lead",
      field: "lead_count",
      flex: 1,
      type: "number",
      sortable: true,
      valueGetter: (params) =>
        params?.row["lead_count"] ? params?.row["lead_count"] : 0,
      sortComparator: (v1, v2, row1, row2) => v1 - v2,
      renderCell: (params) =>
        params?.row["lead_count"] ? (
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            onClick={() =>
              setCountModal({
                phone: params?.row["phone"],
                type: "Leads",
                domain: params?.row["domain"],
              })
            }
          >
            {params?.row["lead_count"] ? params?.row["lead_count"] : 0}
          </span>
        ) : (
          <span>0</span>
        ),
      minWidth: 100,
    },
    {
      headerName: "Total Job",
      field: "job_count",
      flex: 1,
      type: "number",
      sortable: true,
      valueGetter: (params) =>
        params?.row["job_count"] ? params?.row["job_count"] : 0,
      sortComparator: (v1, v2, row1, row2) => v1 - v2,
      renderCell: (params) =>
        params?.row["job_count"] ? (
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            onClick={() =>
              setCountModal({
                phone: params?.row["phone"],
                type: "Jobs",
                domain: params?.row["domain"],
              })
            }
          >
            {params?.row["job_count"] ? params?.row["job_count"] : 0}
          </span>
        ) : (
          <span>0</span>
        ),
      minWidth: 100,
    },
  ];
  const batchList = record?.data?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  React.useEffect(() => {
    dispatch(get_industry({ data: { page: 1, size: 1000 } }));
    return () => {
      dispatch(industryReset());
    };
    // eslint-disable-next-line
  }, []);
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    getSERPDomainReport({
      filters: queryOptions,
      ...sortingModel,
      page: +params.page + 1,
      size: params.pageSize,
    });
  };
  const handleTopPageChange = (page) => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: +page + 1 });
    getSERPDomainReport({
      ...sortingModel,
      filters: queryOptions,
      page: +page + 1,
      size: paginationModel.pageSize,
    });
  };
  const handleTopPageSizeChange = (newPageSize) => {
    setPaginationModel({ page: 1, pageSize: newPageSize });
    getSERPDomainReport({
      ...sortingModel,
      filters: queryOptions,
      page: 1,
      size: newPageSize,
    });
  };
  const LineOne = () => {
    return (
      <TablePagination
        color="primary"
        shape="rounded"
        size="medium"
        showFirstButton
        showLastButton
        count={
          record?.totalItems ? record?.totalItems : record?.data?.length || 0
        }
        page={paginationModel.page - 1 || 0}
        onPageChange={(e, page) => handleTopPageChange(page)}
        rowsPerPage={paginationModel.pageSize || 0}
        onRowsPerPageChange={(e) => handleTopPageSizeChange(e.target.value)}
        component="div"
        rowsPerPageOptions={[
          25,
          50,
          75,
          100,
          250,
          500,
          1000,
          1500,
          2000,
          record?.totalItems ? record?.totalItems : record?.data?.length || 0,
        ]}
      />
    );
  };
  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      getSERPDomainReport({
        ...sortingModel,
        page: 1,
        size: paginationModel.pageSize,
      });
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : // : rule.operator === "isEmpty"
                // ? "eq"
                rule.operator,
          data:
            rule.field === "latest_position"
              ? +rule.value
              : rule.value
              ? rule.value
              : rule.value === 0
              ? rule.value
              : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    getSERPDomainReport({
      ...sortingModel,
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
    });
  };

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Apply filter
        </MUIButton>
        {queryOptions?.rules?.length ? (
          <MUIButton
            variant="text"
            onClick={async () => {
              setLoading(true);
              const newRules = [
                ...queryOptions.rules,
                // { field: "valueserp_BatchID", op: "eq", data: null },
              ];
              await getSERPDomainReport({
                filters: { ...queryOptions, rules: newRules },
                page: 1,
                size: paginationModel.pageSize,
              });
              setLoading(false);
              setIsBatch(true);
            }}
            startIcon={<FaPlus size={16} />}
            sx={{ fontSize: "0.8125rem" }}
            className={"!text-[#042a42]"}
          >
            Create Batch - Filtered Records
          </MUIButton>
        ) : null}

        <Dropdown>
          <Dropdown.Toggle
            id="dropdown-basic"
            className="rounded-0 w-full md:w-auto m] py-1. px- align-middle border-0 bg-transparent text-black focus:!shadow-none text-[13px] font-medium"
          >
            ACTIONS
          </Dropdown.Toggle>
          <Dropdown.Menu className="w-auto min-w-[360px] flex flex-row px-1.5">
            <button
              className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-white "
              onClick={() => setDetailType("by_filter")}
            >
              <div className="flex flex-row items-center pl-1">
                <FaDownload size={14} />
                <span className="ml-2">Fetch Ahref Details - By Filters</span>
              </div>
            </button>
            <button
              className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-white "
              onClick={() => setDetailType("by_selected")}
            >
              <div className="flex flex-row items-center pl-1">
                <FaDownload size={14} />
                <span className="ml-2">
                  Fetch Ahref Detail - Selected Domains
                </span>
              </div>
            </button>
          </Dropdown.Menu>
        </Dropdown>
      </GridToolbarContainer>
    );
  }
  const handleSortModelChange = (params) => {
    setSortingModel({
      sort_field: params[0]?.field?.includes("_in")
        ? params[0]?.field.replaceAll("_in", "")
        : params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
    getSERPDomainReport({
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
      sort_field: params[0]?.field?.includes("_in")
        ? params[0]?.field.replaceAll("_in", "")
        : params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
  };
  const syncData = async () => {
    const c = window.confirm("Are you sure want to start the process?");
    if (!c) return;
    setLoading(true);
    try {
      const res = await api.post(
        "https://api.logicalcrm.com/api/dataSync/start",
        {
          sync_type: "update_mongo_domains",
        }
      );
      if (res.status === 200) {
        toast.success(res.data.message || "Process Start successfully");
      } else if (res.status === 201) {
        toast.info(res.data.message || "Process already running");
      } else {
        toast.error(res.data.message || "Process couldn't be Started");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err.response.data.message || "Process couldn't be Started");
      console.log("🚀 ~ syncData ~ err:", err);
    }
  };
  const CustomButtons = () => {
    const displayTimeDifference = (endTime) => {
      const now = dayjs();
      const end = dayjs.utc(endTime); // Make sure to use utc() if your time is in UTC

      const diffHours = now.diff(end, "hour");
      const diffMinutes = now.diff(end, "minute");

      if (diffHours < 24) {
        // If the difference is less than 24 hours, but more than an hour, show "X hours ago"
        if (diffHours >= 1) {
          return diffHours === 1 ? "1 hour ago" : `${diffHours} hours ago`;
        } else {
          // If the difference is less than an hour, show "X minutes ago"
          return diffMinutes === 1
            ? "1 minute ago"
            : `${diffMinutes} minutes ago`;
        }
      } else {
        // Otherwise, format the date
        return end.format("ddd, MMM D, YYYY h:mm A");
      }
    };

    return (
      <div className="flex flex-col">
        <Button text="Sync" variant="btn_submit" onClick={syncData} />
        <span className="text-xs">
          {displayTimeDifference(record?.syncStatus?.end_time)}
        </span>
      </div>
    );
  };
  // const Testing = () => {
  //   return (
  //     <div>
  //       {batchList?.map((item, index) => {
  //         return <span key={index}>{item?.domain}</span>;
  //       })}
  //     </div>
  //   );
  // };
  // const convertToHtml = () => {
  //   // Render React component to HTML string
  //   const htmlString = ReactDOMServer.renderToString(<Testing />);
  //   // Create a blob with the HTML content
  //   const blob = new Blob(
  //     [
  //       `<!DOCTYPE html>\n<html>\n<head>\n<title>React Component to HTML</title>\n</head>\n<body>\n${htmlString}\n</body>\n</html>`,
  //     ],
  //     { type: "text/html" }
  //   );

  //   // Create a download link and trigger the download
  //   const downloadLink = document.createElement("a");
  //   downloadLink.href = URL.createObjectURL(blob);
  //   downloadLink.download = "react_component.html";
  //   downloadLink.click();
  // };
  return (
    <>
      <PageHeader
        route="Setting > Industries"
        heading="Domains"
        CustomButtons={CustomButtons}
      />
      {/* <Button
      onClick={convertToHtml}
      text="Convert"
      variant="btn_submit"
      /> */}
      {isBatch ? (
        <NewCreateBatchModal
          onClose={() => setIsBatch(false)}
          filters={queryOptions}
          totalItems={record?.totalItems}
        />
      ) : null}
      {isLoading || loading ? <DotsLoader /> : null}
      {countModal?.phone ? (
        <LeadJobListModal
          onCancelForm={() => setCountModal(null)}
          data={countModal}
        />
      ) : null}
      {vendors?.vendors?.length ? (
        <VendorsListModal
          onCancelForm={() => setVendors(null)}
          data={vendors}
        />
      ) : null}
      {detailType ? (
        <HrefDetailModal
          setDetailType={setDetailType}
          detailType={detailType}
          getSERPDomainReport={getSERPDomainReport}
          sortingModel={sortingModel}
          queryOptions={queryOptions}
          setSelectedData={setSelectedData}
          selectedData={selectedData}
          paginationModel={paginationModel}
          onClose={() => setDetailType(null)}
        />
      ) : null}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={batchList?.map((record, index) => {
            const counter = index + 1;
            const {
              domain,
              industry_id,
              vendors,
              lead_count,
              job_count,
              address,
              generate_domain_by,
              in_area,
              positions,
              population,
              state,
              city,
              location,
              is_indexed,
              phone,
              latest_position,
              ahref_domain_DR,
              latest_ahref_domain_DR,
              ahref_domain_DR_status,
              latest_ahref_domain_Backlink,
              ahref_domain_Backlink_status,
              ahref_domain_Backlink,
              ahref_PK_keyword_difficulty,
              latest_PK_ahref_keyword_difficulty,
              latest_PK_ahref_keyword_volume,
              ahref_PK_keyword_volume,
              primary_keyword,
            } = record;
            const cleanedPositions =
              positions?.filter((pos) => pos !== null) || [];
            const sortedPositions =
              cleanedPositions.length > 0
                ? cleanedPositions.sort(
                    (a, b) => new Date(b.on_date) - new Date(a.on_date)
                  )
                : [];
            const cleanedDifficulty =
              ahref_domain_DR?.filter((pos) => pos !== null) || [];
            const sortedDifficulty =
              cleanedDifficulty.length > 0
                ? cleanedDifficulty.sort(
                    (a, b) => new Date(b.log_date) - new Date(a.log_date)
                  )
                : [];

            const cleanedBacklink =
              ahref_domain_Backlink?.filter((pos) => pos !== null) || [];
            const sortedBacklink =
              cleanedBacklink.length > 0
                ? cleanedBacklink.sort(
                    (a, b) => new Date(b.log_date) - new Date(a.log_date)
                  )
                : [];

            const cleanedPKDifficulty =
              ahref_PK_keyword_difficulty?.filter((pos) => pos !== null) || [];
            const sortedPKDifficulty =
              cleanedPKDifficulty.length > 0
                ? cleanedPKDifficulty.sort(
                    (a, b) => new Date(b.log_date) - new Date(a.log_date)
                  )
                : [];

            const cleanedPKVolume =
              ahref_PK_keyword_volume?.filter((pos) => pos !== null) || [];
            const sortedPKVolume =
              cleanedPKVolume.length > 0
                ? cleanedPKVolume.sort(
                    (a, b) => new Date(b.log_date) - new Date(a.log_date)
                  )
                : [];
            return {
              records: { ...record },
              counter,
              domain,
              phone,
              is_indexed,
              population: (+population || 0)?.toLocaleString(),
              state,
              city,
              location,
              address,
              generate_domain_by,
              in_area,
              industry_id: sortedIndustries?.find(
                ({ _id }) => _id === industry_id
              )?.name,
              vendors: vendors,
              lead_count: (+lead_count || 0)?.toLocaleString(),
              job_count: (+job_count || 0)?.toLocaleString(),
              positions: sortedPositions,
              latest_position,
              latest_ahref_domain_DR,
              ahref_domain_DR: sortedDifficulty,
              ahref_domain_DR_status,
              ahref_domain_Backlink: sortedBacklink,
              latest_ahref_domain_Backlink,
              ahref_domain_Backlink_status,

              ahref_PK_keyword_difficulty: sortedPKDifficulty,
              latest_PK_ahref_keyword_difficulty,

              ahref_PK_keyword_volume: sortedPKVolume,
              latest_PK_ahref_keyword_volume,

              primary_keyword,
            };
          })}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          LineOne={LineOne}
          paginationModel={paginationModel}
          totalItems={record?.totalItems}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onFilterModelChange={onFilterChange}
          CustomToolbar={CustomToolbar}
          topPagination
          density="standard"
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          columnVisibilityModel={{ "vendors.vendor_type": false }}
        />
      </div>
    </>
  );
}

export default Domains;
