import React, { useEffect } from "react";
import { FaDownload, FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../components";
import Filter from "../../components/molecules/Filter";
import PageHeader from "../../components/molecules/PageHeader";
import { getLeadsData, setRecording } from "../../features/leadsSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { get_users_drd } from "../../features/usersSlice";
import userPermission from "../../util/userPermission";
import dayjs from "dayjs";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { get_recordings } from "../../features/dialerSlice";
const Leads = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, record } = useSelector((state) => state.leads);
  const { usersDrd } = useSelector((state) => state.users);

  const [searchText, setSearchText] = React.useState("");
  const [loading, setLoading] = React.useState("");
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  useEffect(() => {
    dispatch(getLeadsData({ page: 1, size: paginationModel.pageSize }));
    dispatch(get_users_drd());
    // eslint-disable-next-line
  }, []);
  const leads = record?.records?.filter(
    (lead) =>
      lead?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
      lead?.email?.toLowerCase().includes(searchText.toLowerCase()) ||
      lead?.phone?.toLowerCase().includes(searchText.toLowerCase()) ||
      lead?.alternate_phone?.toLowerCase().includes(searchText.toLowerCase()) ||
      lead?.payment_type?.toLowerCase().includes(searchText.toLowerCase()) ||
      lead?.distance?.toLowerCase().includes(searchText.toLowerCase())
  );

  let initialValues = {
    searchBy: "",
    created_by: "",
    lead_num: "",
    phone_num: "",
    call_type: "",
    to_date: "",
    from_date: "",
  };

  const onReset = () => {
    formik.resetForm();
    setPaginationModel({ ...paginationModel, page: 1 });
    dispatch(getLeadsData({ page: 1, size: paginationModel.pageSize }));
  };
  const formik = useFormik({
    initialValues,

    validateOnBlur: true,

    validationSchema: Yup.object().shape({
      searchBy: Yup.string(),
      created_by: Yup.string(),
      lead_num: Yup.string(),
      phone_num: Yup.string(),
    }),

    onSubmit: async (values) => {
      setPaginationModel({ ...paginationModel, page: 1 });
      await dispatch(
        getLeadsData({ ...values, page: 1, size: paginationModel.pageSize })
      );
    },
  });
  const filterForm = [
    {
      name: "searchBy",
      placeholder: "Select Duration",
      isRange: true,
      onchange: (data) => {
        formik.setFieldValue("to_date", data.endDate);
        formik.setFieldValue("from_date", data.startDate);
      },
      isVisible: true,
    },
    {
      name: "from_date",
      placeholder: "From Date",
      formik: formik,
      isInput: true,
      isVisible: formik.values.searchBy === "custom" ? true : false,
      type: "date",
    },
    {
      name: "to_date",
      placeholder: "To Date",
      formik: formik,
      isInput: true,
      isVisible: formik.values.searchBy === "custom" ? true : false,
      type: "date",
    },
    {
      name: "call_type",
      placeholder: "Select Call type",
      formik: formik,
      isSelect: true,
      isVisible: true,
    },
    {
      name: "created_by",
      placeholder: "Select Created By",
      formik: formik,
      isSelect: true,
      options: usersDrd,
      onchange: (e) => formik.setFieldValue("created_by", e._id),
      getOptionLabel: (options) => options?.first_name,
      getOptionValue: (options) => options?._id,
      isVisible: true,
      value: formik.values.created_by
        ? {
            first_name: usersDrd?.find(
              ({ _id }) => _id === formik.values?.created_by
            )?.first_name,
            _id: formik.values.created_by,
          }
        : null,
    },
    {
      name: "lead_num",
      placeholder: "Lead Number",
      formik: formik,
      isInput: true,
      isVisible: true,
    },
    {
      name: "phone_num",
      placeholder: "Phone Number",
      formik: formik,
      isInput: true,
      isVisible: true,
    },
  ];
  const [currentlyPlayingAudio, setCurrentlyPlayingAudio] =
    React.useState(null);
  const [selectedLead, setSelectedLead] = React.useState(null);
  const handlePlay = async (lead_num) => {
    setLoading(true);
    setSelectedLead(lead_num);
    try {
      const res = await dispatch(
        get_recordings({ lead_id: lead_num, type: "Lead" })
      );
      if (res?.payload?.status === 200 && res?.payload?.data?.length > 0) {
        await dispatch(
          setRecording({
            vicidial_id: res?.payload?.data[0]?.vicidial_id,
            url: res?.payload?.data[0]?.location,
          })
        );
        const audioElement = document.getElementById(
          res?.payload?.data[0]?.vicidial_id
        );
        if (audioElement) {
          if (currentlyPlayingAudio && currentlyPlayingAudio !== audioElement) {
            currentlyPlayingAudio.pause();
          }
          audioElement.play();
          setCurrentlyPlayingAudio(audioElement);
          setLoading(false);
        }
      } else {
        setLoading(false);
        toast.error("No Record found");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching audio URL:", error);
    }
  };
  const columnDefs = [
    { headerName: "Lead #", field: "lead_num" },
    { headerName: "Created By", field: "createdBy", flex: 1 },
    { headerName: "Name", field: "name", flex: 1 },
    { headerName: "Phone", field: "phone", flex: 1 },
    { headerName: "Created", field: "createdAt", flex: 1 },
    {
      headerName: "Status",
      field: "status",
      flex: 1,
      renderCell: (params) => (
        <div>
          {`${params.row.status}${params.row.job_number ? " | " : ""}`}
          {params.row.job_number ? (
            <a
              href={`/jobs/update/preview/${params.row._id}`}
              target="_blank"
              rel="noreferrer"
              className="text-blue-600 hover:underline cursor-pointer"
            >
              {`${params.row.job_number}`}
            </a>
          ) : null}
        </div>
      ),
    },
    {
      headerName: "Recording",
      field: "recording",
      flex: 1,
      minWidth: 300,
      renderCell: (params) =>
        params.row.records.call_type !== "Manual" &&
        params.row.records.vicidial_id ? (
          <div className="relative flex items-center">
            <audio
              id={params.row.records?.vicidial_id}
              controls
              className="h-10 w-[250px] relative"
            >
              {params.row.records.recording && (
                <source
                  src={params.row.records.recording}
                  type="audio/mpeg"
                  onClick={() => handlePlay(params.row.records?.lead_num)}
                />
              )}
            </audio>
            {!params.row.records.recording ? (
              <button
                onClick={() => handlePlay(params.row.records?.lead_num)}
                className="h-[30px] w-[30px] z-[100] bg-primary-100 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center rounded-full text-white"
              >
                {loading && selectedLead === params.row.records?.lead_num ? (
                  <Loader />
                ) : null}
                <FaDownload />
              </button>
            ) : null}
          </div>
        ) : params.row.records.call_type === "Manual" ? (
          <span>Manual Lead</span>
        ) : null,
    },
    {
      field: "actions",
      renderCell: (params) =>
        !params.row.records.is_declined && !params.row.records.job_created ? (
          <div>
            {userPermission("Update Lead") && (
              <FaEdit
                onClick={() => {
                  navigate(`/leads/update/${params.row._id}`);
                }}
                className="my_navIcon"
                title="Update Lead"
              />
            )}
          </div>
        ) : (
          <>
            {!params.row.records.is_declined &&
              params.row.records.job_created && (
                <div>
                  {userPermission("Update Lead") && (
                    <FaEdit
                      onClick={() => {
                        navigate(`/jobs/update/${params.row._id}`);
                      }}
                      className="my_navIcon"
                      title="Update Job"
                    />
                  )}
                </div>
              )}
          </>
        ),
    },
  ];
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      getLeadsData({
        ...formik.values,
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };
  return (
    <>
      <PageHeader
        route="Leads"
        heading="Lead Listing"
        onClick={() => navigate("/leads/add")}
        isAllowed={userPermission("Add Lead")}
      />
      <Filter
        filterForm={filterForm}
        onReset={onReset}
        formik={formik}
        className="mt-2"
        showFilter={true}
      />

      <div className="bg-white my-3 border rounded overflow-y-auto">
        <MUIDataTable
          columnDefs={columnDefs}
          items={leads?.map((record, index) => {
            const {
              _id,
              lead_num,
              job_number,
              createdBy,
              name,
              phone,
              is_declined,
              createdAt,
              job_created,
              recording,
            } = record;
            return {
              _id,
              counter: index + 1,
              records: record,
              lead_num,
              createdBy: createdBy?.username,
              name,
              phone,
              is_declined,
              createdAt: dayjs(createdAt).format("ddd, MMM D, YYYY h:mm A"),
              recording,
              status: `${
                is_declined ? "Declined" : job_created ? "Job Created" : "Open"
              }`,
              job_number,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={record?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          density="standard"
        />
      </div>
    </>
  );
};

export default Leads;
