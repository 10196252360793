import React, { useState, useRef, useEffect } from "react";
import styles from "./style.module.css";
import { RiSendPlaneFill } from "react-icons/ri";
import { FaUserCircle, FaBars } from "react-icons/fa";
import { get_roles } from "../../../features/rolesSlice";
import { useDispatch, useSelector } from "react-redux";
import { BsArrowLeftShort } from "react-icons/bs";
import {
  get_client_list,
  get_conversation,
  get_group_recent_messages,
  get_msg_user_list,
  get_recent_message_list,
  markAsReadMsg,
  mark_as_group_read,
  mark_as_read,
  resetConversation,
  send_group_message,
  send_message,
  updateConversation,
} from "../../../features/messageCenterSlice";
import DotsLoader from "../../atoms/DotsLoader";
import { BiMessageRoundedAdd } from "react-icons/bi";
import dayjs from "dayjs";
import ChatHeads from "./Chats";
import MsgContacts from "./Users";
import "intersection-observer";
import CustomMsgModal from "./CustomMsgModal";
import { get_vendors_drd } from "../../../features/GeoVendorsSlice.js";
import GroupChatHeads from "./GroupChatHeads";
import api from "../../../services/api.js";
import Loader from "../../atoms/Loader/index.jsx";
function SMS(props) {
  const [userId, setUserId] = useState(null);
  const [msg, setMsg] = useState();
  const bottomRef = useRef();
  const chatWindowRef = useRef(null);
  const messageRef = useRef(null);
  const { isLoading, record } = useSelector((state) => state.roles);

  const [isLoader, setIsLoader] = useState(false);
  const [isLoadChat, setIsLoadChat] = useState(false);
  console.log("👊 ~ SMS ~ isLoadChat:", isLoadChat);
  const [isEnd, setIsEnd] = useState(false);
  const msgCenter = useSelector((state) => state.msgCenter);
  console.log("👊 ~ SMS ~ msgCenter:", msgCenter?.conversation);
  const lastUnreadMessageRef = useRef(null);
  const [isCustom, setIsCustom] = useState(false);
  const [newMsg, setNewMsg] = useState(false);
  const [msgType, setMsgType] = useState("Chats");
  const [userType, setUserType] = useState("Agent");
  const [chatFilter, setChatFilter] = useState("all");
  const [hideContacts, setHideContacts] = useState(true);
  const dispatch = useDispatch();
  const { vendorDrd } = useSelector((state) => state.geoVendors);
  const showContacts = () => {
    setHideContacts(!hideContacts);
  };
  const getData = async () => {
    try {
      const res = await dispatch(get_roles());
      if (res.payload?.status === 200) {
        dispatch(
          get_msg_user_list(
            res.payload.data?.find(({ name }) => name === "Agent")?._id
          )
        );
      }
    } catch (err) {
      console.error("🚀 ~ file: index.jsx:31 ~ getData ~ err:", err);
    }
  };
  React.useEffect(() => {
    getData();
    if (msgType === "Chats") {
      let filter = {};
      if (chatFilter === "all") {
        filter = { user_type: "" };
      } else if (chatFilter === "unread") {
        filter = { unread: true };
      } else if (chatFilter === "vendor") {
        filter = { user_type: "Vendor" };
      } else if (chatFilter === "client") {
        filter = { user_type: "Client" };
      }
      dispatch(get_recent_message_list({ filter }));
    } else if (msgType === "Groups") {
      dispatch(get_group_recent_messages());
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const chatWindow = chatWindowRef.current;
    const isAtBottom =
      chatWindow.scrollTop ===
      chatWindow.scrollHeight - chatWindow.clientHeight;
    if (!isLoadChat && isAtBottom) {
      setTimeout(() => {
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 0);
    } else {
      console.warn("not at bottom of the window");
    }
  }, [msgCenter?.conversation?.length]);
  React.useEffect(() => {
    chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
  }, []);
  useEffect(() => {
    if (!isLoadChat) {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [msgCenter?.conversation?.length]);
  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Call your API to mark the message as read using the messageId
          }
        });
      },
      { threshold: 0.5 }
    );

    const messageNodes =
      chatWindowRef.current.querySelectorAll("[data-message-id]");
    messageNodes.forEach((node) => observer.observe(node));

    const handleScroll = () => {
      observer.disconnect();
      messageNodes.forEach((node) => observer.observe(node));
    };

    const currentChatWindowRef = chatWindowRef.current;
    currentChatWindowRef.addEventListener("scroll", handleScroll);

    return () => {
      observer.disconnect();
      currentChatWindowRef.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const unreadRef = useRef();
  const unreadMsg = msgCenter?.conversation?.filter(
    (msg) => msg?.is_read === false && msg?.message_type === "Received"
  );
  const [lastMsgId, setLastMsgId] = useState(null);
  console.log("👊 ~ SMS ~ lastMsgId:", lastMsgId);
  const findMessageIndex = (conversation) => {
    if (!lastMsgId || !conversation) {
      return -1;
    }
    return conversation.findIndex((msg) => msg.createdAt === lastMsgId);
  };
  useEffect(() => {
    const chatWindow = chatWindowRef.current;
    const conversation = msgCenter?.conversation || [];
    const index = findMessageIndex(conversation);
    if (index !== -1) {
      // Calculate the position of the message in the chat window
      const messageElement = chatWindow.children[index];
      const messagePosition = messageElement.offsetTop;
      // Scroll to the position of the message
      chatWindow.scrollTop = messagePosition;
    }
  }, [msgCenter.conversation]);
  const handleLoadMore = async () => {
    const lastMsg =
      msgCenter?.conversation[msgCenter?.conversation?.length - 1]?.createdAt;
    setIsLoader(true);
    setIsLoadChat(true);
    setLastMsgId(lastMsg);
    try {
      const res = await api.post(
        "api/message_center/get_conversation",
        userId.user?._id
          ? { user_id: userId.user?._id, user_type: "User", createdAt: lastMsg }
          : userId.client?._id
          ? {
              user_id: userId.client?._id,
              user_type: "Client",
              createdAt: lastMsg,
            }
          : userId.vendor?._id
          ? {
              user_id: userId.vendor?._id,
              user_type: "Vendor",
              createdAt: lastMsg,
            }
          : {
              custom_phone: userId.user_phone,
              user_type: "Custom",
              createdAt: lastMsg,
            }
      );
      if (res.status === 200) {
        if (res?.data?.length) {
          dispatch(updateConversation(res.data));
        } else {
          setIsEnd(true);
        }
      }
      setIsLoadChat(false);
    } catch (err) {
      console.log("🚀 ~ handleLoadMore ~ err:", err);
    }
    setIsLoader(false);
    // setCurrentPage((prevPage) => prevPage + 1);
  };
  useEffect(() => {
    const container = chatWindowRef.current;
    const handleScroll = () => {
      if (container.scrollTop <= 0 && !isEnd && !isLoader) {
        handleLoadMore();
      }
    };
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [isEnd, isLoader, userId]);
  useEffect(() => {
    async function readMsg() {
      if (unreadMsg?.length > 0) {
        unreadRef.current?.scrollIntoView({ behavior: "smooth" });
        let unReadMsgIds = [];
        unreadMsg?.map((msg) => (unReadMsgIds = [...unReadMsgIds, msg?._id]));
        const action = msgType === "Chats" ? mark_as_read : mark_as_group_read;
        try {
          await dispatch(action({ message_ids: unReadMsgIds }));
        } catch (err) {
          console.error("🚀 ~ file: index.jsx:169 ~ useEffect ~ err:", err);
        }
      }
    }
    readMsg();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadMsg?.length]);
  const handleMessage = async (event) => {
    event.preventDefault();
    if (userId?.user_type !== "Group") {
      try {
        const res = await dispatch(
          send_message({
            message: msg,
            user_id:
              userId?.user_type === "User"
                ? userId?.user?._id
                : userId?.user_type === "Client"
                ? userId?.client?._id
                : userId?.user_type === "Vendor"
                ? userId?.vendor?._id
                : "",
            custom_phone:
              userId?.user_type === "Custom" ? userId?.user_phone : "",
            user_type: userId?.user_type,
          })
        );
        if (res.payload?.status === 201) {
          setMsg("");
          if (newMsg) {
            dispatch(
              get_conversation(
                userId.user?._id
                  ? { user_id: userId.user?._id, user_type: "User" }
                  : userId.client?._id
                  ? { user_id: userId.client?._id, user_type: "Client" }
                  : userId.vendor?._id
                  ? { user_id: userId.vendor?._id, user_type: "Vendor" }
                  : { custom_phone: userId.user_phone, user_type: "Custom" }
              )
            );
            setNewMsg(false);
            setUserType("");
          }
          dispatch(markAsReadMsg());
        }
      } catch (err) {}
    } else {
      try {
        const res = await dispatch(
          send_group_message({
            message: msg,
            vendor_conversation_group_id: userId?.vendor_conversation_group_id,
          })
        );
        if (res.payload?.status === 201) {
          setMsg("");
          if (newMsg) {
            dispatch(
              get_conversation(
                userId.user?._id
                  ? { user_id: userId.user?._id, user_type: "User" }
                  : userId.client?._id
                  ? { user_id: userId.client?._id, user_type: "Client" }
                  : userId.vendor?._id
                  ? { user_id: userId.vendor?._id, user_type: "Vendor" }
                  : { custom_phone: userId.user_phone, user_type: "Custom" }
              )
            );
            setNewMsg(false);
            setUserType("");
          }
          dispatch(markAsReadMsg());
        }
      } catch (err) {}
    }
  };
  return (
    <>
      <div className="fixed w-[90%] md:w-[1024px] z-[101] left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] max-h-[600px]">
        <div
          className="absolute -right-3.7 -top-3.7 w-[34px] h-[34px] bg-white rounded-full flex justify-center items-center cursor-pointer drop-shadow"
          onClick={() => {
            dispatch(resetConversation());
            props.setIsSMS(false);
          }}
        >
          X
        </div>
        <div className="max-w-[1024px] h-[600px] bg-[#E6EAEA] rounded">
          {isCustom && (
            <CustomMsgModal
              onClosemodal={() => setIsCustom(false)}
              newMsg={newMsg}
              setNewMsg={setNewMsg}
              setUserType={setUserType}
              setUserId={setUserId}
            />
          )}

          {isLoading || msgCenter.isLoading ? <DotsLoader /> : null}
          <div
            className={`float-left ${
              hideContacts ? "hidden" : ""
            }  md:block min-w-[280px] max-w-[350px] w-[45%] h-full !bg-white text-[#333] overflow-hidden relative border-r border-[#ccc]`}
          >
            <div className="flex flex-row items-center w-full border-y border-1 bg-[#fff] p-[6px] pl-3.7 !pr-4">
              <ul className="flex flex-row items-center w-full justify-between !mb-0 !pl-0">
                {!newMsg ? (
                  <>
                    <li
                      className="text-[#2d4665] py-[6px] px-[6px] text-xs cursor-pointer flex items-center hover:bg-[#2d4665] hover:text-white text-lg font-medium"
                      onClick={() => setNewMsg(true)}
                    >
                      <BiMessageRoundedAdd
                        size={24}
                        className="cursor-pointer mr-1"
                        onClick={() => setNewMsg(false)}
                      />
                      New Message
                    </li>
                    <div className="flex flex-row">
                      <li
                        className={`text-[#2d4665] py-[6px] px-[6px] text-xs cursor-pointer ${
                          msgType === "Chats"
                            ? "border-b-2 border-primary-100 text-primary-100"
                            : ""
                        }`}
                        onClick={() => {
                          let filter = {};
                          if (chatFilter === "all") {
                            filter = { user_type: "" };
                          } else if (chatFilter === "unread") {
                            filter = { unread: true };
                          } else if (chatFilter === "vendor") {
                            filter = { user_type: "Vendor" };
                          } else if (chatFilter === "client") {
                            filter = { user_type: "Client" };
                          }
                          dispatch(get_recent_message_list({ filter }));
                          setMsgType("Chats");
                        }}
                      >
                        {"Chats"}
                      </li>
                      <li
                        className={`text-[#2d4665] py-[6px] px-[6px] text-xs cursor-pointer ${
                          msgType === "Groups"
                            ? "border-b-2 border-primary-100 text-primary-100"
                            : ""
                        }`}
                        onClick={() => {
                          dispatch(get_group_recent_messages());
                          setMsgType("Groups");
                        }}
                      >
                        {"Groups"}
                      </li>
                    </div>
                  </>
                ) : (
                  <div className="flex flex-row items-center">
                    <BsArrowLeftShort
                      size={24}
                      color="#2d4665"
                      className="cursor-pointer"
                      onClick={() => setNewMsg(false)}
                    />
                    <span className="font-semibold">New Chat</span>
                  </div>
                )}
                <div className="flex flex-row">
                  {newMsg && (
                    <>
                      {record?.map((role, index) => {
                        return (
                          <>
                            {role.name === "Agent" ? (
                              <li
                                className={`text-[#2d4665] py-[6px] px-[6px] text-xs cursor-pointer ${
                                  userType === role?.name
                                    ? "border-b-2 border-primary-100 text-primary-100"
                                    : ""
                                }`}
                                onClick={() => {
                                  dispatch(get_msg_user_list(role?._id));
                                  setUserType(role?.name);
                                }}
                              >
                                {role?.name}
                              </li>
                            ) : null}
                          </>
                        );
                      })}
                      <li
                        className={`text-[#2d4665] py-[6px] px-[6px] text-xs cursor-pointer ${
                          userType === "Vendor"
                            ? "border-b-2 border-primary-100 text-primary-100"
                            : ""
                        }`}
                        onClick={() => {
                          dispatch(get_vendors_drd());
                          setUserType("Vendor");
                        }}
                      >
                        {"Vendor"}
                      </li>
                      <li
                        className={`text-[#2d4665] py-[6px] px-[6px] text-xs cursor-pointer ${
                          userType === "Client"
                            ? "border-b-2 border-primary-100 text-primary-100"
                            : ""
                        }`}
                        onClick={() => {
                          dispatch(get_client_list());
                          setUserType("Client");
                        }}
                      >
                        {"Client"}
                      </li>
                      <li
                        className={`text-[#2d4665] py-[6px] px-[6px] text-xs cursor-pointer ${
                          userType === "Client"
                            ? "border-b-2 border-primary-100 text-primary-100"
                            : ""
                        }`}
                        onClick={() => {
                          setIsCustom(true);
                        }}
                      >
                        {"Custom"}
                      </li>
                    </>
                  )}
                </div>
              </ul>
            </div>
            {!newMsg ? (
              <>
                {msgType === "Chats" ? (
                  <>
                    <div className="flex flex-row items-center w-full border-y border-1 bg-[#f7f8f8] p-[6px] pl-3.7 !pr-4">
                      <ul className="flex flex-row items-center w-full !mb-0 !pl-0">
                        <li
                          className={`text-[#2d4665] py-[6px] px-[6px] text-xs cursor-pointer ${
                            chatFilter === "all"
                              ? "border-b-2 border-primary-100 text-primary-100"
                              : ""
                          }`}
                          onClick={() => {
                            dispatch(
                              get_recent_message_list({
                                filter: { user_type: "" },
                              })
                            );
                            setChatFilter("all");
                          }}
                        >
                          All
                        </li>
                        <li
                          className={`text-[#2d4665] py-[6px] px-[6px] text-xs cursor-pointer ${
                            chatFilter === "unread"
                              ? "border-b-2 border-primary-100 text-primary-100"
                              : ""
                          }`}
                          onClick={() => {
                            dispatch(
                              get_recent_message_list({
                                filter: { unread: true },
                              })
                            );
                            setChatFilter("unread");
                          }}
                        >
                          Unread
                        </li>
                        <li
                          className={`text-[#2d4665] py-[6px] px-[6px] text-xs cursor-pointer ${
                            chatFilter === "vendor"
                              ? "border-b-2 border-primary-100 text-primary-100"
                              : ""
                          }`}
                          onClick={() => {
                            dispatch(
                              get_recent_message_list({
                                filter: { user_type: "Vendor" },
                              })
                            );
                            setChatFilter("vendor");
                          }}
                        >
                          Vendor
                        </li>
                        <li
                          className={`text-[#2d4665] py-[6px] px-[6px] text-xs cursor-pointer ${
                            chatFilter === "client"
                              ? "border-b-2 border-primary-100 text-primary-100"
                              : ""
                          }`}
                          onClick={() => {
                            dispatch(
                              get_recent_message_list({
                                filter: { user_type: "Client" },
                              })
                            );
                            setChatFilter("client");
                          }}
                        >
                          Client
                        </li>
                      </ul>
                    </div>

                    <ChatHeads
                      userId={userId}
                      setUserId={setUserId}
                      userType={userType}
                      chatFilter={chatFilter}
                      onClick={showContacts}
                      setIsLoadChat={setIsLoadChat}
                    />
                  </>
                ) : msgType === "Groups" ? (
                  <GroupChatHeads
                    userId={userId}
                    setUserId={setUserId}
                    setIsLoadChat={setIsLoadChat}
                  />
                ) : null}
              </>
            ) : (
              <MsgContacts
                data={
                  userType === "Client"
                    ? msgCenter?.clientList
                    : userType === "Agent"
                    ? msgCenter?.userList
                    : userType === "Vendor"
                    ? vendorDrd
                    : []
                }
                userId={userId}
                setUserId={setUserId}
                type={userType}
              />
            )}
          </div>

          <div className="z-[100] min-w-[calc(100%-300px)] w-[100%]">
            <div className="flex flex-row items-center bg-white p-[6px] h-[42px] border-b border-[#ccc]">
              <FaBars
                className="block md:hidden cursor-pointer w-[24px]"
                onClick={showContacts}
              />
              <span
                className={`${
                  hideContacts ? "block" : "hidden"
                } md:block px-[6px] border-r-2 border-black`}
              >
                {userId?.user_type === "User"
                  ? userId?.user?.first_name
                  : userId?.user_type === "Vendor"
                  ? userId?.vendor?.first_name
                  : userId?.user_type === "Group"
                  ? userId?.vendor?.first_name
                  : userId?.client?.name}{" "}
                {userId?.user_type === "User"
                  ? userId?.user?.last_name
                    ? userId?.user?.last_name
                    : ""
                  : userId?.client?.last_name
                  ? userId?.client?.last_name
                  : userId?.user_type === "Vendor"
                  ? userId?.vendor?.last_name
                    ? userId?.vendor?.last_name
                    : ""
                  : userId?.client?.last_name
                  ? userId?.client?.last_name
                  : userId?.user_type === "Group"
                  ? userId?.vendor?.last_name
                    ? userId?.vendor?.last_name
                    : ""
                  : userId?.client?.last_name
                  ? userId?.client?.last_name
                  : ""}
                {userId?.user_type === "Custom" ? userId?.user_phone : ""}{" "}
              </span>
              <span
                className={`${
                  hideContacts ? "block" : "hidden"
                } md:block text-[#ccc] ml-1`}
              >
                {userId
                  ? userId?.user_type === "User"
                    ? userId?.role?.name
                    : userId?.user_type === "Client"
                    ? "Client"
                    : userId?.user_type === "Vendor"
                    ? "Vendor"
                    : userId?.user_type === "Group"
                    ? "Group"
                    : "Custom"
                  : ""}
              </span>
              {userId && msgCenter?.userList?.length > 0 && (
                <FaUserCircle
                  size={20}
                  className={` ${
                    hideContacts ? "block" : "hidden"
                  } md:block ml-1 cursor-pointer`}
                  color="#999"
                />
              )}
            </div>
            <div
              className={`${
                hideContacts ? "block" : "hidden"
              } md:block !p-4 h-[488px] overflow-y-auto`}
              ref={chatWindowRef}
            >
              {isLoader ? <Loader /> : null}
              {msgCenter.conversation
                ?.slice()
                ?.reverse()
                ?.map((msg, index) => {
                  return (
                    <div key={index}>
                      {msg?.message_type === "Received" && msg?.is_read ? (
                        <>
                          {msg.is_read === true && (
                            <div
                              className="inline-block w-[calc(100%-25px)] !m-1"
                              ref={messageRef}
                              data-message-id={msg._id}
                            >
                              <p className="bg-menu text-white float-left max-w-[80%] w-[60%] rounded-t-2xl rounded-r-2xl !py-2 px-3.7">
                                <span className="flex justify-between !mb-2 font-bold text-base">
                                  {msg?.user_type === "User"
                                    ? userId?.user?.first_name
                                    : msg?.user_type === "Client"
                                    ? userId?.client?.first_name
                                    : msg?.user_type === "Vendor"
                                    ? userId?.vendor?.first_name
                                    : ""}
                                </span>
                                <span className="text-sm !mb-2">
                                  {msg.message}
                                </span>

                                <div className="text-xs text-end">
                                  {dayjs(msg.createdAt).format(
                                    "ddd, MMM D, YYYY h:mm A"
                                  )}
                                </div>
                              </p>
                            </div>
                          )}
                        </>
                      ) : (
                        msg?.message_type === "Sent" && (
                          <div className="inline-block w-[calc(100%-25px)] !m-1">
                            <p className="bg-client-100 text-white float-right max-w-[80%] w-[60%] rounded-t-2xl rounded-l-2xl !py-2 px-3.7">
                              <span className="flex justify-between !mb-2 font-bold text-base">
                                You
                              </span>
                              <span className="text-sm">{msg.message}</span>
                              <div className=" text-xs text-end">
                                {dayjs(msg.createdAt).format(
                                  "ddd, MMM D, YYYY h:mm A"
                                )}
                              </div>
                            </p>
                          </div>
                        )
                      )}

                      <div ref={bottomRef} />
                    </div>
                  );
                })}
              {unreadMsg?.length > 0 && (
                <div id="chat-container">
                  <div className="text-center mb-2">
                    {" "}
                    <span className="bg-white rounded-full p-2" ref={unreadRef}>
                      {unreadMsg?.length} Unread Messages
                    </span>
                  </div>
                  {unreadMsg?.map((msg, index) => {
                    return (
                      <div
                        className="inline-block w-[calc(100%-25px)] !m-1"
                        data-message-id={msg._id}
                        key={msg.id}
                        ref={
                          index === unreadMsg.length - 1 && !msg.is_read
                            ? lastUnreadMessageRef
                            : null
                        }
                      >
                        <p className="bg-menu text-white float-left max-w-[80%] w-[60%] rounded-t-2xl rounded-r-2xl !py-2 px-3.7">
                          <span className="flex justify-between !mb-2 font-bold text-base">
                            {msg?.user_type === "User"
                              ? userId?.user?.first_name
                              : msg?.user_type === "Client"
                              ? userId?.client?.first_name
                              : msg?.user_type === "Vendor"
                              ? userId?.vendor?.first_name
                              : ""}
                          </span>
                          <span className="text-sm !mb-2">{msg.message}</span>

                          <div className="text-xs text-end">
                            {dayjs(msg.createdAt).format(
                              "ddd, MMM D, YYYY h:mm A"
                            )}
                          </div>
                        </p>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <form
              onSubmit={handleMessage}
              className="flex flex-row justify-between w-full md:w-[calc(100%-350px)] bottom-0 bg-[#f8f8f8] border-t border-[#ccc] p-3.7 float-right "
            >
              <input
                className="border w-11/12 py-1.5 !px-3 rounded !focus:outline-none"
                onChange={(e) => setMsg(e.target.value)}
                value={msg}
              />
              <button
                className="float-right bg-[#32465a] w-[40px] !py-1"
                type="submit"
              >
                <RiSendPlaneFill
                  color={"white"}
                  size={20}
                  style={{ color: "#fff" }}
                />
              </button>
            </form>
          </div>
        </div>
      </div>
      <div
        className={[styles.backdrop, props.open ? "open" : ""].join(" ")}
        onClick={() => {
          dispatch(resetConversation());
          props.setIsSMS(false);
        }}
      ></div>
    </>
  );
}

export default SMS;
