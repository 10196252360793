import React from "react";
import Button from "../../atoms/Button";
import PageHeader from "../PageHeader";
import { useNavigate } from "react-router-dom";

function AddUpdatePageContainer({
  children,
  modalBodyClass,
  breadcrumbs,
  childrenClass,
  hideButtons,
  onSubmit,
  isLoading,
  CloseOnly,
  modalClass,
  path,
  onCancelModal,
  CustomButtons,
  title,
}) {
  const navigate = useNavigate();
  const cancelFormHandler = () => {
    if (!path) return;
    navigate(path);
  };
  return (
    <div>
      <PageHeader
        heading={title}
        breadcrumb={breadcrumbs}
        CustomButtons={CustomButtons}
      />
      <hr className="mt-2" />
      <div className="flex items-center justify-center bg-white my-3 rounded">
        <div
          className={`bg-white my- rounded z-10 w-aut w-full md:max-w-[100%] md:min-w-[730px] overflow-y-auto  p-3.5 ${modalClass}`}
        >
          <div className={`w-full pt-0 h-full px-2 m-aut${modalBodyClass}`}>
            <div className={`md:p-4 bg-neutral-100 w-full ${childrenClass}`}>
              {children}
            </div>
            {!hideButtons && (
              <div className="!p-4 text-right">
                {onCancelModal ? (
                  <Button
                    text="Cancel"
                    className="mx-2"
                    onClick={onCancelModal}
                    variant="btn_cancel"
                  />
                ) : null}

                <Button
                  text={"Submit"}
                  className=""
                  onClick={onSubmit}
                  isLoading={isLoading}
                  variant="btn_submit"
                />
              </div>
            )}
            {CloseOnly && onCancelModal ? (
              <div className="!p-4 text-right">
                <Button
                  text="Cancel"
                  className="mx-2"
                  onClick={onCancelModal}
                  variant="btn_cancel"
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddUpdatePageContainer;
