import React, { useEffect, useState } from "react";
import AddUpdateForm from "./AddUpdateForm";
import { FaEdit, FaPlus, FaTrashAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { delete_merchant, get_merchants } from "../../features/merchantSlice";
import { get_timezone } from "../../features/timezoneSlice";
import { get_countries, get_states } from "../../features/countriesSlice";
import Filter from "../../components/molecules/Filter";
import { get_industry_drd } from "../../features/IndustrySlice";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import userPermission from "../../util/userPermission";
import errorMessage from "../../util/errorMessage";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import api from "../../services/api";
import { get_cc_cards, get_cc_providers } from "../../features/didSlice";

const Merchants = () => {
  const { isLoading, record } = useSelector((state) => state.merchant);
  const { timezones } = useSelector((state) => state.timezone);
  const { countries, states } = useSelector((state) => state.countries);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [connectcx, setConnectcx] = React.useState([]);
  const dispatch = useDispatch();
  let initialValues = {
    name: "",
    email: "",
    state_id: "",
    address: "",
    zip: "",
    company_name: "",
  };
  const onReset = () => {
    formik.resetForm();
    setPaginationModel({ ...paginationModel, page: 1 });
    dispatch(
      get_merchants({
        page: 1,
        size: paginationModel.pageSize,
      })
    );
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: async (values) => {
      try {
        const res = await dispatch(
          get_merchants({
            ...values,
            page: 1,
            size: paginationModel.pageSize,
          })
        );
        if (res?.payload?.status === 200) {
        }
      } catch (error) {
        console.error("🚀 ~ file: index.jsx:61 ~ onSubmit: ~ error:", error);
      }
    },
  });
  const getCX = async () => {
    try {
      const res = await api.get("/api/connectcx/getAllCustomers");
      if (res.status === 200) {
        setConnectcx(res.data);
      }
    } catch (err) {
      console.log("🚀 ~ getCX ~ err:", err);
    }
  };
  const filterForm = [
    {
      name: "name",
      placeholder: "Full Name",
      formik: formik,
      isInput: true,
      isVisible: true,
    },
    {
      name: "company_name",
      placeholder: "Company Name",
      formik: formik,
      isInput: true,
      isVisible: true,
    },
    {
      name: "address",
      placeholder: "Address",
      formik: formik,
      isInput: true,
      isVisible: true,
    },
    {
      name: "state_id",
      placeholder: "Select State",
      formik: formik,
      isSelect: true,
      options: states,
      onchange: (e) => formik.setFieldValue("state_id", e._id),
      getOptionLabel: (options) => options.name,
      getOptionValue: (options) => options._id,
      isVisible: true,
      value: formik?.values.state_id
        ? {
            _id: formik?.values.state_id,
            name: states?.find(({ _id }) => _id === formik?.values.state_id)
              ?.name,
          }
        : null,
    },
    {
      name: "zip",
      placeholder: "Zip",
      formik: formik,
      isInput: true,
      isVisible: true,
    },
    {
      name: "email",
      placeholder: "Email address",
      formik: formik,
      isInput: true,
      isVisible: true,
    },
  ];

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_merchant(record_id));
        if (res?.payload?.status === 200) {
          toast.success("Merchant deleted");
          cancelFormHandler();
        } else {
          errorMessage({
            payload: res.payload,
            action: "Merchant",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({ payload: error, action: "Merchant", msg: "deleted" });
      }
    }
  };
  const get_timezones = async () => {
    if (timezones?.length === 0) {
      try {
        const res = await dispatch(get_timezone());
        if (res?.payload?.status === 200) {
          if (countries?.length === 0) {
            dispatch(get_countries());
          }
        }
      } catch (error) {
        console.error("Timezones couldn't be loaded", error);
      }
    }
  };

  useEffect(() => {
    dispatch(
      get_merchants({
        page: 1,
        size: paginationModel.pageSize,
      })
    );
    get_timezones();
    dispatch(get_industry_drd({ type: "all" }));
    dispatch(get_states());
    getCX();
    dispatch(get_cc_cards());
    dispatch(get_cc_providers());
    // eslint-disable-next-line
  }, []);
  const merchant = record?.records?.filter(
    (record) =>
      record?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
      record?.address?.toLowerCase().includes(searchText.toLowerCase()) ||
      record?.email?.toLowerCase().includes(searchText.toLowerCase()) ||
      record?.industry_id?.name
        ?.toLowerCase()
        .includes(searchText.toLowerCase()) ||
      record?.job_category_id?.name
        ?.toLowerCase()
        .includes(searchText.toLowerCase()) ||
      record?.street?.toLowerCase().includes(searchText.toLowerCase())
  );
  const columnDefs = [
    { headerName: "#", field: "counter" },
    { headerName: "Name", field: "name", flex: 1 },
    { headerName: "Dialer User", field: "dialer_user", flex: 1 },
    { headerName: "Dialer Pass", field: "dialer_pass", flex: 1 },
    { headerName: "Dialer Phone", field: "dialer_phone", flex: 1 },
    { headerName: "Address", field: "address", flex: 1 },
    { headerName: "Street", field: "street", flex: 1 },
    { headerName: "Zip", field: "zip", flex: 1 },
    { headerName: "Email", field: "email", flex: 1 },
    { headerName: "Industry", field: "industry", flex: 1 },
    { headerName: "Job Categories", field: "job_category", flex: 1 },
    { headerName: "Status", field: "status", flex: 1 },
    {
      field: "actions",
      renderCell: (params) =>
        params.row.records.active &&
        params.row.records.name !== "superadmin" ? (
          <div>
            {userPermission("Update Merchant") && (
              <FaEdit
                onClick={openFormHandler(params.row.records)}
                className="my_navIcon"
                title="Update Merchant"
              />
            )}
            {userPermission("Delete Merchant") && (
              <FaTrashAlt
                onClick={deleteRecordHandler(params.row.record_id)}
                className="my_navIcon"
                title="Delete Merchant"
              />
            )}
          </div>
        ) : null,
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_merchants({
        ...formik.values,
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };
  return (
    <div className="container-fluid">
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add Merchant"
          onCancelForm={cancelFormHandler}
          connectcx={connectcx}
        />
      )}
      <div className="mb-3 mt-5 mt-md-3">
        <div
          style={{
            fontSize: 16,
            fontWeight: 500,
            color: "#343434",
            fontFamily: "Montserrat",
          }}
        >{`Settings > Merchant`}</div>
      </div>
      <Filter
        filterForm={filterForm}
        onReset={onReset}
        formik={formik}
        showFilter={true}
      />
      <div className="mt-5 mt-md-3">
        <div className="flex flex-row justify-between px-1.7">
          <div className="">
            <div
              style={{
                fontSize: 20,
                fontWeight: 500,
                color: "#343434",
                fontFamily: "Montserrat",
              }}
            >{`Merchant Listing`}</div>
          </div>
          <div className="">
            {userPermission("Add Merchant") && (
              <button
                type="button"
                className="py-1.5 px-3 align-middle bg-primary-100 border-primary-100 text-white float-right flex items-center rounded-0"
                onClick={openFormHandler(0)}
              >
                <FaPlus className="mr-2" />
                Add New
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={merchant?.map((record, index) => {
            let counter = index + 1;
            let record_id = record._id;
            let name = record.name;
            let dialer_pass = record.dialer_pass;
            let dialer_phone = record.dialer_phone;
            let dialer_user = record.dialer_user;
            let address = record.address;
            let zip = record.zip;
            let email = record.email;
            let industry = record.industry_id?.name;
            let job_category = record?.job_category_id?.map((val, ind) => {
              return `${val?.name}`;
            });
            let street = record.street;
            let status = record.active ? "Active" : "InActive";
            return {
              records: record,
              record_id,
              counter,
              name,
              dialer_user,
              dialer_pass,
              dialer_phone,
              address,
              street,
              zip,
              email,
              industry,
              job_category,
              status,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={record?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default Merchants;
