import React from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  FormTextArea,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  create_lead_source,
  update_lead_source,
} from "../../features/leadSourceSlice";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import AddUpdatePageContainer from "../../components/molecules/AddUpdatePage";
import { FaPlus } from "react-icons/fa";
import AddUpdateForm from "../LeadSourceGroup/AddUpdateForm";
import { get_lead_source_group } from "../../features/leadSourceGroupSlice";
import { filterPayload } from "../../util/filterPayload";
const AddUpdateLeadSouce = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cancelFormHandler = () => {
    navigate("/settings/lead_source");
  };
  const cancelGroupFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  const { isLoading } = useSelector((state) => state.leadSource);
  const [errors, setErrors] = React.useState([]);
  const { record } = useSelector((state) => state?.leadSourceGroup);
  const location = useLocation();
  const [isEditing, setIsEditing] = React.useState(false);
  const [editRecord, setEditingRecord] = React.useState(null);
  const editingRecord = location.state;
  const initialValues = {
    title: "",
    alternate_title: "",
    type: "",
    cost_per_lead: "",
    email: "",
    note: "",
    active: true,
    lead_source_group_id: "",
    provider: "",
    inbound_phone_number_ad_source: "",
    ad_source_cost: "",
    ad_source_cost_basis: "",
    ...(editingRecord && {
      ...editingRecord,
      lead_source_group_id: editingRecord?.lead_source_group_id?._id,
    }),
  };
  const activeOptions = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
  ];
  const providers = [
    { label: "Adchemy - RateMarketPlace - Mortgage (Preferred)", value: "9" },
    { label: "Leads123-Wisdom Companies - Mortgage (Preferred)", value: "14" },
    { label: "Bills.com  - Mortgage (Preferred)", value: "15" },
    { label: "DoublePositve - Mortgage (Preferred)", value: "19" },
    { label: "LeadPoint - Mortgage (Preferred)", value: "2" },
    { label: "LoanBright - Mortgage (Preferred)", value: "39" },
    { label: "LowerMyBills - Mortgage (Preferred)", value: "1" },
    { label: "QuinStreet  - Mortgage (Preferred)", value: "56" },
    { label: "Accelerize - Debt (Preferred)", value: "72" },
    { label: "Find Your Customers  - Debt (Preferred)", value: "22" },
    { label: "LowerMyBills - Debt (Preferred)", value: "8" },
    { label: "Really Great Rate - Debt (Preferred)", value: "59" },
    { label: "Affnet - Debt", value: "84" },
    { label: "NationalLeadService - Debt", value: "83" },
    { label: "TriAd Media Solutions - Debt", value: "77" },
    { label: "220 Marketing", value: "332" },
    { label: "411Web", value: "76" },
    { label: "Affiliated Media Networks", value: "71" },
    { label: "Americas Lead Source (XML)", value: "11" },
    { label: "AtLeads", value: "12" },
    { label: "Best Mortgage Rate", value: "13" },
    { label: "ChoicePoint", value: "16" },
    { label: "Client Shop", value: "17" },
    { label: "Cole X-Dates", value: "270" },
    { label: "Consumer Track", value: "18" },
    { label: "Encompass Consumer Connect", value: "337" },
    { label: "Endeavor Ads", value: "218" },
    { label: "EZ Rate Quotes", value: "20" },
    { label: "Fast Find", value: "21" },
    { label: "Financial Firebird", value: "75" },
    { label: "Go Apply eLeadz", value: "220" },
    { label: "GREEN TREE MEDIA GROUP", value: "81" },
    { label: "Home AccountLF", value: "221" },
    { label: "Impact Leads", value: "24" },
    { label: "Informed Sources", value: "25" },
    { label: "Insure Me", value: "26" },
    { label: "IPS", value: "27" },
    { label: "Killer Lead", value: "29" },
    { label: "Lead Capture Marketing", value: "30" },
    { label: "Lead Stream", value: "31" },
    { label: "Lead2Net", value: "32" },
    { label: "LeadGuardian", value: "33" },
    { label: "LeadPoint (Student Loans)", value: "34" },
    { label: "LeadQual", value: "219" },
    { label: "Leads 2 Loans", value: "35" },
    { label: "Leads123-Wisdom Companies - Debt (Preferrred)", value: "128" },
    { label: "Lending Tree Short Form", value: "4" },
    { label: "Life Health", value: "36" },
    { label: "Live Lead Network", value: "37" },
    { label: "Loan Page", value: "38" },
    { label: "Low Lender", value: "40" },
    { label: "Low.com", value: "3" },
    { label: "Lower My Payment", value: "41" },
    { label: "LowerMyBills - Loan Mod (Preferred)", value: "130" },
    { label: "Military.com", value: "82" },
    { label: "mLeads", value: "42" },
    { label: "Money Nest", value: "43" },
    { label: "Mortgage 101 (Lion Inc.)", value: "44" },
    { label: "Mortgage Expo", value: "45" },
    { label: "mRevolution (Insurance)", value: "46" },
    { label: "mRevolution (Mortgage)", value: "47" },
    { label: "NeighborhoodLoans", value: "48" },
    { label: "NetBlue", value: "49" },
    { label: "NexTag (Preferred)", value: "5" },
    { label: "Nolan Micro", value: "50" },
    { label: "NR Leads", value: "51" },
    { label: "Potreo", value: "52" },
    { label: "ProspectZone", value: "53" },
    { label: "PX", value: "339" },
    { label: "Qualify4", value: "73" },
    { label: "Quality Loan Network", value: "54" },
    { label: "QuinStreet (Education)", value: "55" },
    { label: "Rate Advisor", value: "58" },
    { label: "Root Marketing", value: "60" },
    { label: "SharedPath", value: "61" },
    { label: "SMARTQuote - Mortgage (Preferred)", value: "7" },
    { label: "Spectrum Direct", value: "62" },
    { label: "Spend On Life", value: "63" },
    { label: "Superior Internet Solutions", value: "64" },
    { label: "USHUD", value: "79" },
    { label: "Vimo", value: "74" },
    { label: "WhosCalling", value: "65" },
    { label: "Zillow", value: "290" },
    { label: "Zoom Credit", value: "78" },
  ];
  const typeOptions = [
    { label: "Direct Mail", value: "1" },
    { label: "Internet (Bought Lead)", value: "2" },
    { label: "Television", value: "4" },
    { label: "Dialer", value: "8" },
    { label: "Retail", value: "16" },
    { label: "Radio", value: "32" },
    { label: "Inbound Call", value: "33" },
    { label: "Webform", value: "34" },
    { label: "Import", value: "35" },
    { label: "Other", value: "64" },
  ];
  const basisOption = [
    { label: "Lead Source", value: "1" },
    { label: "Monthly", value: "2" },
    { label: "Weekly", value: "4" },
    { label: "Daily", value: "8" },
    { label: "Per Call", value: "16" },
  ];
  React.useEffect(() => {
    dispatch(get_lead_source_group());
    // eslint-disable-next-line
  }, []);
  const handleSubmit = async (values) => {
    const action = editingRecord ? update_lead_source : create_lead_source;
    const message = editingRecord ? "updated" : "created";
    const filteredValues = filterPayload(values);
    try {
      const res = await dispatch(action(filteredValues));
      if (res.payload?.status === 200) {
        toast.success(`Lead Source ${message}`);
        cancelFormHandler();
      } else {
        if (Array.isArray(res.payload)) {
          setErrors(res.payload);
        } else {
          toast.error(
            res.payload ? res.payload : `Lead Source couldn't be ${message}`
          );
        }
      }
    } catch (error) {
      console.log("👊 ~ handleSubmit ~ error:", error);
      if (Array.isArray(error.payload)) {
        setErrors(error.payload);
      } else {
        toast.error(
          error.payload ? error.payload : `Lead Source couldn't be ${message}`
        );
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  return (
    <div>
      {isLoading && <DotsLoader />}
      <AddUpdatePageContainer
        breadcrumbs={`/lead_source/${
          editingRecord ? "Edit" : "Add"
        }_lead_source`}
        title=""
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onCancelModal={cancelFormHandler}
        path={"/lead_source"}
      >
        {isEditing && (
          <AddUpdateForm
            editingRecord={editRecord}
            modalTitle="Add Lead Source Group"
            onCancelForm={cancelGroupFormHandler}
          />
        )}
        <form className="mt-4 md:grid p-2 md:p-0 md:grid-cols-2 gap-x-2">
          <div className="!mb-3 w-full">
            <FormInput
              errors={errors}
              name="title"
              label="Title"
              formik={formik}
            />
          </div>
          <div className="!mb-3 w-full">
            <FormInput
              errors={errors}
              name="alternate_title"
              label="Alternate Title"
              formik={formik}
            />
          </div>
          <div className="mb-3 w-full">
            <FormSelectInput
              errors={errors}
              name="type"
              label="Type"
              formik={formik}
              options={typeOptions}
              valueProp="label"
              labelProp="label"
            />
          </div>
          <div className="!mb-3 w-full">
            <FormInput
              errors={errors}
              name="cost_per_lead"
              label="Cost Per Lead"
              formik={formik}
            />
          </div>
          <div className="!mb-3 w-full">
            <FormInput
              errors={errors}
              name="email"
              label="Email"
              formik={formik}
            />
          </div>
          <div className="mb-3 w-full">
            <FormSelectInput
              errors={errors}
              name="active"
              label="Active"
              formik={formik}
              options={activeOptions}
              convertor={(value) => value === "true"}
              valueProp="value"
              labelProp="label"
            />
          </div>
          <div className="!mb-3 col-span-2 w-full">
            <FormTextArea
              errors={errors}
              name="note"
              label="Note"
              formik={formik}
              rows={4}
            />
          </div>
          <div className="mb-3 flex flex-row w-full">
            <FormSelectInput
              errors={errors}
              name="lead_source_group_id"
              label="Lead Source Group"
              formik={formik}
              options={record?.records}
              valueProp="_id"
              labelProp="name"
            />
            <button
              className="ml-2 bg-primary-100 w-11 h-10 px-1 flex justify-center items-center"
              onClick={openFormHandler(0)}
              type="button"
            >
              <FaPlus className="text-white" />
            </button>
          </div>
          <div className="mb-3 w-full">
            <FormSelectInput
              errors={errors}
              name="provider"
              label="Provider"
              formik={formik}
              options={providers}
              valueProp="label"
              labelProp="label"
            />
          </div>

          <div className="!mb-3 w-full">
            <FormInput
              errors={errors}
              name="inbound_phone_number_ad_source"
              label="Inbound Phone Number Ad Source"
              formik={formik}
            />
          </div>

          <div className="!mb-3 w-full">
            <FormInput
              errors={errors}
              name="ad_source_cost"
              label="Ad Source Cost"
              formik={formik}
            />
          </div>

          <div className="mb-3 w-full">
            <FormSelectInput
              errors={errors}
              name="ad_source_cost_basis"
              label="Ad Source Cost Basis"
              formik={formik}
              options={basisOption}
              valueProp="label"
              labelProp="label"
            />
          </div>
        </form>
      </AddUpdatePageContainer>
    </div>
  );
};

export default AddUpdateLeadSouce;
