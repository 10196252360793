import React, { useEffect, useState } from "react";
import AddUpdateForm from "./AddUpdateForm";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { delete_user, get_users } from "../../features/usersSlice";
import { get_countries, get_states } from "../../features/countriesSlice";
import { get_timezone } from "../../features/timezoneSlice";
import { get_roles } from "../../features/rolesSlice";
import { toast } from "react-toastify";
import PageHeader from "../../components/molecules/PageHeader";
import Filter from "../../components/molecules/Filter";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import userPermission from "../../util/userPermission";
import SelectTaskForm from "./SelectTaskForm";
import errorMessage from "../../util/errorMessage";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { get_merchants } from "../../features/merchantSlice";
import { get_inbound_group_drd } from "../../features/inboundGroupSlice";
import AssignIndustries from "./AssignIndustries";
import { get_industry_drd } from "../../features/IndustrySlice";
import { useLocation, useNavigate } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Button, DotsLoader } from "../../components";
import api from "../../services/api";
const User = () => {
  const location = useLocation();
  const filterData = location.state;
  const optionsRef = React.useRef();
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const { isLoading, users } = useSelector((state) => state.users);
  const { record } = useSelector((state) => state.roles);
  const { timezones } = useSelector((state) => state.timezone);
  const { countries } = useSelector((state) => state.countries);
  const navigate = useNavigate();
  const [editingRecord, setEditingRecord] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isIndustries, setIsIndustries] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showPassword, setShowPassword] = useState("");
  const [showMenu, setShowMenu] = useState(null);

  const [roleManager, setRoleManager] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 1,
  });
  const dispatch = useDispatch();

  let initialValues = {
    user_name: "",
    first_name: "",
    last_name: "",
    email: "",
    role_id: "",
    active: "",
  };

  const onReset = () => {
    formik.resetForm();
    setPaginationModel({ ...paginationModel, page: 1 });
    dispatch(
      get_users({
        page: 1,
        size: paginationModel.pageSize,
      })
    );
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    validationSchema: Yup.object().shape({
      user_name: Yup.string(),
      first_name: Yup.string(),
      last_name: Yup.string(),
      email: Yup.string().email("Please enter a valid email"),
      role_id: Yup.string(),
      active: Yup.boolean(),
    }),

    onSubmit: async (values) => {
      setPaginationModel({ ...paginationModel, page: 1 });
      try {
        const res = await dispatch(
          get_users({
            ...values,
            page: 1,
            size: paginationModel.pageSize,
          })
        );
        if (res?.payload?.status === 200) {
        }
      } catch (error) {
        console.error("🚀 ~ file: index.jsx:68 ~ onSubmit: ~ error:", error);
      }
    },
  });
  const activeOptions = [
    { label: "Active", value: 1 },
    { label: "Inactive", value: 0 },
  ];
  const filterForm = [
    {
      name: "user_name",
      placeholder: "Username",
      formik: formik,
      isInput: true,
      isVisible: true,
    },
    {
      name: "first_name",
      placeholder: "First Name",
      formik: formik,
      isInput: true,
      isVisible: true,
    },
    {
      name: "last_name",
      placeholder: "Last Name",
      formik: formik,
      isInput: true,
      isVisible: true,
    },
    {
      name: "email",
      placeholder: "Email address",
      formik: formik,
      isInput: true,
      isVisible: true,
    },
    {
      name: "role_id",
      placeholder: "Select Role",
      formik: formik,
      isSelect: true,
      options: record,
      onchange: (e) => formik.setFieldValue("role_id", e._id),
      getOptionLabel: (options) => options.name,
      getOptionValue: (options) => options._id,
      isVisible: true,
      value: (() => {
        const roleId = filterData?.role_id || formik?.values.role_id;
        const roleName = record?.find(({ _id }) => _id === roleId)?.name;

        return roleId ? { _id: roleId, name: roleName } : null;
      })(),
    },
    {
      name: "active",
      placeholder: "Select Status",
      formik: formik,
      isSelect: true,
      options: activeOptions,
      onchange: (e) => formik.setFieldValue("active", e.value),
      getOptionLabel: (options) => options.label,
      getOptionValue: (options) => options.value,
      isVisible: true,
      value: (() => {
        const activeValue = filterData?.active || formik?.values.active;
        const activeLabel = activeValue ? "Active" : "Inactive";

        return activeValue ? { value: activeValue, label: activeLabel } : null;
      })(),
    },
  ];

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
    setRoleManager(false);
    setIsIndustries(false);
  };
  const handleHideDropdown = (event) => {
    if (showMenu && event.key === "Escape") {
      setShowMenu(null);
    }
  };
  const handleClickOutside = (event) => {
    if (
      showMenu &&
      optionsRef.current &&
      !optionsRef.current.contains(event.target)
    ) {
      setShowMenu(null);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  const deleteRecordHandler = async (record_id) => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_user(record_id));
        if (res?.payload?.status === 200) {
          toast.success("User deleted");
          cancelFormHandler();
        } else {
          errorMessage({
            payload: res.payload,
            action: "User",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({ payload: error, action: "User", msg: "deleted" });
      }
    }
  };
  const handleReceivingLeads = async (record_id) => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      setIsLoader(true);
      try {
        const res = await api.put(
          `api/users/${record_id}/receiving_lead_call_status`
        );
        if (res?.status === 200) {
          toast.success("Action performed successfully");
        } else {
          errorMessage({
            payload: res.data?.message,
            action: "Action",
            msg: "performed",
          });
        }
      } catch (error) {
        console.log("👊 ~ handleReceivingLeads ~ error:", error);
        errorMessage({
          payload: error?.response?.data?.message,
          action: "Action",
          msg: "performed",
        });
      }
      setIsLoader(false);
    }
  };
  const get_timezones = async () => {
    if (timezones?.length === 0) {
      try {
        const res = await dispatch(get_timezone());
        if (res?.payload?.status === 200) {
          if (countries?.length === 0) {
            dispatch(get_countries());
            dispatch(get_states());
          }
        }
      } catch (error) {
        console.error("Timezones couldn't be loaded");
      }
    }
  };
  const openFormHandler = (record) => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  const openIndustriesHandler = (record) => {
    setEditingRecord(record);
    setIsIndustries(true);
  };

  useEffect(() => {
    dispatch(
      get_users({
        ...filterData,
        page: 1,
        size: paginationModel.pageSize,
      })
    );
    dispatch(get_roles());
    dispatch(get_merchants());
    get_timezones();
    // dispatch(get_industry_drd());
    // dispatch(get_inbound_group_drd());
    // eslint-disable-next-line
  }, []);
  const sendVerificationEmail = async (mail) => {
    const c = window.confirm(
      "Are you sure want to send the verification email?"
    );
    if (!c) return;
    setIsLoader(true);
    try {
      const res = await api.post("/api/users/send-verification-email", {
        email: mail,
      });
      if (res.status === 200) {
        toast.success(
          res.data?.message || "Verification email send successfully"
        );
      } else {
        toast.error(res.data?.message || "Verification email couldn't be send");
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      toast.error(
        err?.response?.data?.message || "Verification email couldn't be send"
      );
    }
  };
  const filterUsers = users?.users?.filter((user) => {
    const searchTextLower = searchText.toLowerCase().trim();
    const userFirstNameLower = user?.first_name?.toLowerCase() || "";
    const userLastNameLower = user?.last_name?.toLowerCase() || "";
    const userEmailLower = user?.email?.toLowerCase() || "";
    const userNameLower = user?.username?.toLowerCase() || "";
    const userRoleNameLower = user?.role_id?.name?.toLowerCase() || "";
    const userPrimaryMerchantNameLower =
      user?.primary_merchant_id?.name?.toLowerCase() || "";
    const userFullNameLower = `${userFirstNameLower} ${userLastNameLower}`;
    const lastLoginIp = user?.last_login_ip?.toLowerCase() || "";
    return (
      userFullNameLower.includes(searchTextLower) ||
      userEmailLower.includes(searchTextLower) ||
      userNameLower.includes(searchTextLower) ||
      userRoleNameLower.includes(searchTextLower) ||
      lastLoginIp.includes(searchTextLower) ||
      userPrimaryMerchantNameLower.includes(searchTextLower)
    );
  });
  const openDistributionSettingForm = (record) => {
    navigate("/users/lead_distribution", { state: record });
  };
  const testMenu = [
    { label: "Edit user", action: (e) => openFormHandler(e) },
    {
      label: "Assign Industries",
      action: (e) => openIndustriesHandler(e),
      isHide: user?.company_name === "Verified CRM",
    },
    {
      label: "Delete User",
      action: (e) => deleteRecordHandler(e._id),
      isHide: user?.company_name === "Verified CRM",
    },
    {
      label: "Lead distribution",
      action: (e) => openDistributionSettingForm(e._id),
      isHide: user?.company_name === "Verified CRM",
    },
    {
      label: "Roles",
      action: () => toast.info("Coming soon"),
      isHide: user?.company_name === "Verified CRM",
    },
    {
      label: "Setting",
      action: () => toast.info("Coming soon"),
      isHide: user?.company_name === "Verified CRM",
    },
  ];
  const columnDefs = [
    { headerName: "#", field: "counter", width: 80 },
    { headerName: "Name", field: "name" },
    {
      headerName: "Email",
      field: "email",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <div className="flex flex-col">
          <span>{params.row.email}</span>
          {params.row.email_verified ? (
            <small className="text-green-600">Verified</small>
          ) : (
            <small
              className="text-red-600 hover:underline cursor-pointer ml3"
              onClick={() => sendVerificationEmail(params.row.email)}
            >
              Not verified
            </small>
          )}
        </div>
      ),
    },
    { headerName: "User Name", field: "username" },
    {
      field: "pswrd",
      headerName: "Password",
      width: 130,
      renderCell: (params) => {
        return (
          <div className="">
            <span className="!mr-2">
              {showPassword === params.row.records?._id
                ? params.row.records.pswrd
                : "*******"}
            </span>
            {showPassword === params.row.records._id ? (
              <FaEye
                className="my_navIcon cursor-pointer"
                onClick={() => setShowPassword("")}
              />
            ) : (
              <FaEyeSlash
                className="my_navIcon cursor-pointer"
                onClick={() => setShowPassword(params.row.records._id)}
              />
            )}
          </div>
        );
      },
    },
    { headerName: "Role", field: "role", width: 100 },
    { headerName: "Merchant", field: "primaryMerchant", width: 100 },
    {
      headerName: "Created At",
      field: "createdAt",
      width: 120,
      sortable: true,
      valueGetter: (params) => params.row.createdAt, // Provide a function to get the sorting value
      sortComparator: (v1, v2, row1, row2) => {
        // Convert the date strings to Date objects for comparison
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        // Compare the date objects for sorting
        return date1 - date2;
      },
    },
    user?.company_name !== "Verified CRM" && {
      headerName: "Last Login IP",
      field: "lastLoginIp",
      width: 120,
    },
    // {
    //   headerName: "Receiving leads",
    //   field: "receiving_leads",
    //   width: 120,
    //   align: "center",
    //   renderCell: (params) => (
    //     <button
    //       className={`${
    //         params.row.receiving_leads ? "text-green-600" : "text-red-500"
    //       }`}
    //       onClick={() => handleReceivingLeads(params.row.records._id)}
    //     >
    //       {params.row.receiving_leads ? "On" : "Off"}
    //     </button>
    //   ),
    // },
    { headerName: "Status", field: "status", width: 80 },
    {
      headerName: "Action",
      field: "actions",
      align: "center",
      renderCell: (params) => (
        <div>
          {/* {userPermission("Update User") && (
            <FaEdit
              onClick={openFormHandler(params.row.records)}
              className="my_navIcon"
              title="Update User"
            />
          )}
          {userPermission("Delete User") && (
            <FaTrashAlt
              onClick={deleteRecordHandler(params.row.record_id)}
              className="my_navIcon"
              title="Delete User"
            />
          )}
          {params.row.status ? (
            <MdAssignmentAdd
              onClick={openIndustriesHandler(params.row.records)}
              className="my_navIcon"
              title="Assign Industries to this user"
              size={18}
            />
          ) : null} */}
          <BsThreeDotsVertical
            size={18}
            className="cursor-pointer !relative hover:text-primary-100"
            onClick={() => setShowMenu(params.row.records._id)}
          />
          {showMenu === params.row.record_id ? (
            <div
              ref={optionsRef}
              className="absolute w-auto right-12 min-w-[100px] rounded shadow !mt-1 bg-white z-20 border-x border-y border-[#ddd] -ml-px overflow-hidden transition ease-in-out delay-150"
            >
              <ul className="!pl-0 !mb-0">
                {testMenu?.map((option, index) => {
                  return !option.isHide ? (
                    <li
                      key={index}
                      className="cursor-pointer px-3 !py-1.25 border- border-[#ddd] hover:bg-[#e1e1e1]"
                      onClick={() => option.action(params.row.records)}
                    >
                      {option.label}
                    </li>
                  ) : null;
                })}
              </ul>
            </div>
          ) : null}
        </div>
      ),

      width: 100,
    },
  ].filter(Boolean);

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_users({
        ...formik.values,
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };
  // const exportToCsv = (fileName = 'dialer_users.csv') => {
  //   // Check if data is not empty
  //   if (!users?.users || !users?.users?.length) {
  //     return;
  //   }

  //   // Map data to extract the specific fields
  //   const mappedData = users?.users?.filter(({dialer_user})=>!!dialer_user)?.map(item => ({
  //     crm_username: item?.username,
  //     crm_password: item?.pswrd,
  //     dialer_user: item?.dialer_user?.dialer_user,
  //     dialer_phone: item?.dialer_user?.dialer_phone,
  //     dialer_pass: item?.dialer_user?.dialer_pass,
  //   }));

  //   // Extract column headers
  //   const headers = Object.keys(mappedData[0]);

  //   // Build CSV string
  //   const csvRows = [
  //     headers.join(','), // Add column headers
  //     ...mappedData.map(row =>
  //       headers.map(fieldName => JSON.stringify(row[fieldName], (key, value) =>
  //         !value||value === null ? '' : value // Convert null to empty string
  //       )).join(',')
  //     )
  //   ];

  //   // Create CSV string
  //   const csvString = csvRows.join('\n');

  //   // Create a Blob with the CSV string
  //   const blob = new Blob([csvString], { type: 'text/csv' });

  //   // Create a link and set the URL as the Blob object
  //   const link = document.createElement('a');
  //   link.href = URL.createObjectURL(blob);
  //   link.download = fileName; // Set the file name

  //   // Append the link to the DOM, click it, and then remove it
  //   document.body.appendChild(link); // Append to the body
  //   link.click(); // Trigger download
  //   document.body.removeChild(link); // Clean up
  // };
  const CustomButtons = () => {
    return (
      <div className="flex gap-x-2">
        {userPermission("Add User") && user?.company_name !== "Verified CRM" ? (
          <Button
            text="Add New User"
            variant="btn_submit"
            onClick={() => openFormHandler(0)}
          />
        ) : null}
        <Button
          text="Manage Groups"
          variant="btn_submit"
          onClick={() =>
            navigate(`${location?.pathname?.replace("users", "user_groups")}`)
          }
        />
        <Button
          text="Roles & Permissions"
          variant="btn_submit"
          onClick={() =>
            navigate(
              `${location?.pathname?.replace("users", "user_permissions")}`
            )
          }
        />
      </div>
    );
  };
  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle={editingRecord ? "Update User" : "Add User"}
          onCancelForm={cancelFormHandler}
        />
      )}
      {isLoader ? <DotsLoader /> : null}
      {isIndustries ? (
        <AssignIndustries
          userDetail={editingRecord}
          onClose={cancelFormHandler}
        />
      ) : null}
      <PageHeader
        route="Setting > Users"
        heading=""
        CustomButtons={CustomButtons}
      />
      {roleManager && (
        <SelectTaskForm
          editingRecord={editingRecord}
          modalTitle="Manage Role Permissions"
          onCancelForm={cancelFormHandler}
          users={users?.users}
        />
      )}
      <Filter
        filterForm={filterForm}
        onReset={onReset}
        formik={formik}
        className="mt-2"
        showFilter={false}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={filterUsers?.map((record, index) => {
            const { email_verified, receiving_leads } = record;
            let records = record;
            let counter = offset + index + 1;
            let record_id = record._id;
            let name =
              record?.first_name +
              " " +
              (record?.last_name ? record?.last_name : "");
            let email = record.email;
            let username = record?.username;
            let role = record?.role_id?.name;
            let role_level = record?.role_id?.level;
            let primaryMerchant = record?.primary_merchant_id?.name;
            let createdAt = record.createdAt
              ? dayjs(record?.createdAt).format("ddd, MMM D, YYYY h:mm A")
              : null;
            let lastLoginIp = record.last_login_ip;
            let lastLoginDate = record.last_login_date
              ? dayjs(record.last_login_date).format("ddd, MMM D, YYYY h:mm A")
              : "";
            let ipFiltering = record.ip_filtering ? "Yes" : "No";
            let status = record.active ? "Active" : "InActive";
            let pswrd = record.pswrd;
            return {
              counter,
              name,
              email,
              username,
              role,
              primaryMerchant,
              createdAt,
              lastLoginIp,
              lastLoginDate,
              ipFiltering,
              status,
              records,
              record_id,
              pswrd,
              role_level,
              email_verified,
              receiving_leads,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={users.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          density="standard"
          // searchable="No"
          // pagination="No"
          showCount="No"
          height="65vh"
          // exportButton={exportToCsv}
        />
      </div>
    </>
  );
};

export default User;
