import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  addNationSiteZips,
  createNationSite,
  getNationSiteList,
  getNationSiteZips,
  getNationVendorList,
  updateNationSite,
} from "../services/nationSiteService";

const initialState = {
  isError: false,
  isLoading: false,
  nationSites: [],
  nationVendors: [],
  nationZips:[],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Nation Sites Data
export const get_nation_site = createAsyncThunk(
  "get_nation_site",
  async (thunkAPI) => {
    try {
      return await getNationSiteList();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Nation Sites
export const create_nation_site = createAsyncThunk(
  "create_nation_site",
  async (data, thunkAPI) => {
    try {
      return await createNationSite(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);


// Update Nation Sites
export const update_nation_site = createAsyncThunk(
  "update_nation_site",
  async (data, thunkAPI) => {
    try {
      return await updateNationSite(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const get_nation_site_zips = createAsyncThunk(
  "get_nation_site_zips",
  async (id, thunkAPI) => {
    try {
      return await getNationSiteZips(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const add_nation_site_zips = createAsyncThunk(
  "add_nation_site_zips",
  async (data, thunkAPI) => {
    try {
      return await addNationSiteZips(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const nationSitesSlice = createSlice({
  name: "nationSites",
  initialState,
  reducers: {
    nationSiteReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.nationSites = [];
      state.message = "";
    },
    nationSiteZipsReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.nationZips = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_nation_site.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_nation_site.fulfilled, (state, action) => {
        state.isLoading = false;
        state.nationSites = action.payload?.data?.map((item) => ({
          ...item,
          dids: [],
          tollfree: [],
        }));
      })
      .addCase(get_nation_site.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.nationSites = [];
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(create_nation_site.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_nation_site.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(create_nation_site.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_nation_site.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_nation_site.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_nation_site.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_nation_site_zips.pending, (state) => {
        state.isLoading = true;
        state.isLoader = true;
        state.nationZips = [];
      })
      .addCase(get_nation_site_zips.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.nationZips = action.payload?.data;
      })
      .addCase(get_nation_site_zips.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.isError = true;
        state.message = action.payload;
        state.nationZips = [];
      })
      .addCase(add_nation_site_zips.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(add_nation_site_zips.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(action?.payload?.data?.message)
      })
      .addCase(add_nation_site_zips.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const { nationSiteReset, nationSiteZipsReset } = nationSitesSlice.actions;
export default nationSitesSlice.reducer;
