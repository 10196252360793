import React from "react";
import { Dropdown } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
function VerificationHistory() {
  return (
    <div className="relative w-full h-full bg-white py-2.5 px-3 rounded-lg mt-2.5 border">
      <h5 className="flex flex-row">
        Verification History
        <Dropdown className="ml-2">
          <Dropdown.Toggle
            id="dropdown-basic"
            className="rounded-0 w-auto  py-1.5 px-3 align-middle !border-primary-100 !bg-primary-100 !mb-2 focus:!shadow-none"
          >
            Action
          </Dropdown.Toggle>

          <Dropdown.Menu className="w-auto min-w-[185px] flex flex-row ">
            <Dropdown.ItemText className="cursor-pointer mt-1 mx-1.5 flex flex-col hover:bg-[#182237] hover:text-white ">
              <div className="flex flex-row items-center pl-1">
                <FaDownload />
                <span className="ml-2">Download invoice</span>
              </div>
            </Dropdown.ItemText>
          </Dropdown.Menu>
        </Dropdown>
      </h5>
    </div>
  );
}

export default VerificationHistory;
