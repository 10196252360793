import { MultiSelect } from 'primereact/multiselect';
import React from 'react'

function ExportExcelModal({ industryDrd, onClose, setIndustryToExport, industryToExport, handleConfirm }) {

    
    return (
      <>
        <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-50 bg-black/60">
          <div className="bg-white p-8 rounded-lg shadow-lg relative w-[400px]">
            <h2 className="text-lg font-semibold mb-4">
              Export Excel by Industry
            </h2>
            <label className="block">Please select an industry:</label>
            <MultiSelect
              placeholder="Select an industry"
              options={industryDrd}
              optionLabel="name"
              optionValue="cms_id"
              filter
              maxSelectedLabels={3}
              value={industryToExport}
              onChange={(e) => setIndustryToExport(e.value)}
              className="w-full"
            />

            <div className="flex justify-end !mt-4">
              <button
                className="px-4 py-2 mr-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                onClick={handleConfirm}
              >
                OK
              </button>
              <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
  export default ExportExcelModal