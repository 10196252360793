import React, { useState } from "react";
import { Button } from "../..";
import { setGlobalBaseURL } from "../../../features/authSlice";
import { useDispatch } from "react-redux";
import api from "../../../services/api";

const BackendURl = () => {
  const [baseURL, setBaseURL] = useState("https://api.verifiedcrm.com");
  const dispatch = useDispatch();
  const handleConfirm = async () => {
    // localStorage.setItem("baseURL", baseURL);
    api.updateBackendUrl(baseURL);
    dispatch(setGlobalBaseURL(baseURL));
  };
  return (
    <>
      <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-40">
        <div className="bg-white p-8 rounded-lg shadow-lg relative w-[400px]">
          <h2 className="text-lg font-semibold mb-4">Enter the backend path</h2>
          <input
            placeholder="Base URL"
            onChange={(e) => setBaseURL(e.target.value)}
            className="placeholder:text-[#6c757d] placeholder:font-medium bg-white w-full !border border-[#ced4da] rounded-md py-2 px-2 shadow-none sm:text-sm"
            value={baseURL}
          />
          <div className="flex justify-end !mt-4">
            <Button
              text="Continue"
              onClick={handleConfirm}
              variant="btn_submit"
              className="mr-2"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BackendURl;
