import api from "./api";

export const getCalendarEvents = async () => {
  return await api.get(`/api/calendarEvent`);
};
export const createCalendarEvents = async (data) => {
  return await api.post(`/api/calendarEvent`, data);
};

export const updateCalendarEvents = async (data) => {
  return await api.put(`/api/calendarEvent/${data?.id}`, data);
};
export const deleteCalendarEvents = async (id) => {
  return await api.delete(`/api/calendarEvent/${id}`);
};
