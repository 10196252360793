import api from "./api";

export const agentlessGetPhone = async (token) => {
  return await api.get(`/api/agentless?token=${token}`);
};
export const agentlessGetCategory = async (token) => {
  return await api.get(`/api/agentless/job_categories?token=${token}`);
};
export const agentlessCreateLead = async (data) => {
  return await api.post(`/api/agentless/get_quote`, data);
};
export const agentCreateLead = async (data) => {
  return await api.post(`/api/sms/get_quote`, data);
};
export const agentlessCreateJob = async (data) => {
  return await api.post(`/api/agentless/create_job`, data);
};
export const agentCreateJob = async (data) => {
  return await api.post(`/api/leads/create_job`, data);
};
export const agentCreateJobTowing = async (data) => {
  return await api.post(`/api/leads/create_job`, data);
};