import React, { useState, useEffect } from "react";
import { Button, DotsLoader, Modal } from "../../components";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import * as XLSX from "xlsx";
import api from "../../services/api";
const ZipCodeListingModal = ({ onCancelForm, modalTitle, payload }) => {
  const [searchText, setSearchText] = useState("");
  const [zipCodes, setZipCodes] = useState([]);
  const columnDefs = [
    { headerName: "#", field: "counter", filterable: false },
    {
      headerName: "Zip Code",
      field: "zip",
      flex: 1,
    },
  ];
  const [isLoading, setisLoading] = useState(false);
  const get_zip_codes = async () => {
    setisLoading(true);
    try {
      if (payload?.zip_type === "out_area_zips") {
        const zips = await api.post("/api/nation/zips", {
          ...payload,
          zip_type: "domain_zips",
        });
        const inAreaZips = await api.post("/api/nation/zips", {
          ...payload,
          zip_type: "in_area_zips",
        });
        if (zips?.status === 200 && inAreaZips?.status === 200) {
          const resultArray = zips?.data.filter(
            (item) => !inAreaZips?.data.includes(item)
          );
          setZipCodes(resultArray);
        }
      } else {
        const res = await api.post("/api/nation/zips", payload);
        if (res.status === 200) {
          setZipCodes(res.data);
        }
      }
      setisLoading(false);
    } catch (err) {
      setisLoading(false);
      console.log("🚀 ~ err:", err);
    }
  };
  useEffect(() => {
    get_zip_codes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchedDids = zipCodes?.filter((item) => item?.includes(searchText));
  const exportData = () => {
    const rows = [
      ["#", "ZipCodes"], // Add headings
    ];

    zipCodes?.forEach((item, index) => {
      rows.push([index + 1, item]); // Add subsequent rows with empty state and city
    });
    const worksheet = XLSX.utils.aoa_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const fileName = "national_site_zip_codes.xlsx";
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    if (typeof window.navigator.msSaveBlob !== "undefined") {
      // For IE browser
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      // For other browsers
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100);
    }
  };
  const ImportExportButtons = () => {
    return (
      <div className="flex space-x-2">
        <Button text="Export" variant="btn_submit" onClick={exportData} />
      </div>
    );
  };
  return (
    <Modal
      title={`${modalTitle} for ${payload?.domain}`}
      onCancelModal={onCancelForm}
      onClick={onCancelForm}
      modalClass={"!min-w-[60vw]"}
      hideButtons
    >
      {isLoading ? <DotsLoader /> : null}
      <div className="bg-white mt-3 border rounded">
        <MUIDataTable
          height="51vh"
          columnDefs={columnDefs}
          items={searchedDids?.map((record, index) => {
            let counter = index + 1;
            return {
              counter,
              zip: record,
              id: counter,
            };
          })}
          totalItems={zipCodes?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="No"
          toolbar="No"
          hideFooter
          LineOne={ImportExportButtons}
        />
      </div>
    </Modal>
  );
};
export default ZipCodeListingModal;
