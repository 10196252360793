import React, { useState, useEffect } from "react";
import { DotsLoader, Modal } from "../../components";
import { toast } from "react-toastify";
import { get_nation_site } from "../../features/nationSitesSlice";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import {
  GridActionsCellItem,
  GridRowModes,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { FaEdit, FaPlus, FaSave, FaTrash } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import api from "../../services/api";
import { Button as MUIButton } from "@material-ui/core";
import userPermission from "../../util/userPermission";
import { useDispatch } from "react-redux";
const VendorsModal = ({ editingRecord, onCancelForm, modalTitle }) => {
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);
  const [vendors, setVendors] = React.useState([]);
  const [searchText, setSearchText] = useState("");
  const [rowModesModel, setRowModesModel] = React.useState({});
  const get_vendors = async () => {
    setisLoading(true);
    try {
      const res = await api.get(`api/nation/vendor/${editingRecord?.id}`);
      if (res.status === 200) {
        setVendors(res.data);
      }
      setisLoading(false);
    } catch (err) {
      setisLoading(false);
      console.log("🚀 ~ VendorsModal ~ err:", err);
    }
  };
  useEffect(() => {
    get_vendors();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewRecord = () => {
    // Check if there are any unsaved rows before adding a new row
    const unsavedRows = Object.values(rowModesModel).filter(
      (modeModel) => modeModel.mode === GridRowModes.Edit
    );
    if (unsavedRows.length > 0) {
      toast.error("Please save all rows before adding a new row.");
      return;
    }
    // Add a new row
    const id = new Date().getTime() % 100000000;
    setVendors((oldRows) => [{ id: id, isNew: true }, ...oldRows]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: "edit", fieldToFocus: "name" },
    }));
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        {userPermission("Add National Site Vendor") ? (
          <MUIButton
            variant="text"
            startIcon={<FaPlus size={18} />}
            sx={{ fontSize: "0.8125rem", background: "" }}
            onClick={handleNewRecord}
            className="text-black btnSecondary text-sm "
          >
            Add Vendor
          </MUIButton>
        ) : null}

        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
      </GridToolbarContainer>
    );
  }
  const handleEdit = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: "edit" },
    });
  };
  const handleSaveClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: "view" },
    });
  };
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: "view", ignoreModifications: true },
    });
    const editedRow = vendors.find((row) => row.id === id);
    if (editedRow?.isNew) {
      setVendors(vendors.filter((row) => row.id !== id));
    }
  };

  const columnDefs = [
    {
      field: "actions",
      type: "actions",
      cellClassName: "actions",
      headerName: "Action",
      disableColumnMenu: false,
      width: 100,
      filterable: false,
      getActions: (params) => {
        const isInEditMode = rowModesModel[params.id]?.mode === "edit";
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<FaSave size={16} />}
              label="Save"
              onClick={handleSaveClick(params.id)}
              className="focus:outline-none"
            />,
            <GridActionsCellItem
              icon={<MdOutlineCancel size={16} />}
              label="Cancel"
              className="textPrimary focus:outline-none"
              onClick={handleCancelClick(params.id)}
              color="inherit"
            />,
          ];
        } else {
          return userPermission("Update National Site Vendor")
            ? [
                <GridActionsCellItem
                  icon={<FaEdit size={16} />}
                  label="Edit"
                  className="textPrimary focus:outline-none"
                  onClick={handleEdit(params.id)}
                  color="inherit"
                />,
                <GridActionsCellItem
                  icon={<FaTrash size={16} />}
                  label="Delete"
                  className="textPrimary focus:outline-none"
                  onClick={() => handleDeleteVendor(params.id)}
                  color="inherit"
                />,
              ]
            : [];
        }
      },
    },
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      editable: true,
    },
  ];
  const handleRowEditStart = (_params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (_params, event) => {
    event.defaultMuiPrevented = true;
  };
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleSaveUpdate = async (newRow) => {
    const updatedRow = newRow;
    delete newRow.counter;
    setisLoading(true);
    if (updatedRow.isNew) {
      try {
        api
          .post(`/api/nation/vendor`, {
            names: [newRow?.name],
            national_site_id: editingRecord?.id,
          })
          .then(() => {
            toast.success("Vendor added successfully");
            get_vendors();
            dispatch(get_nation_site());
          })
          .catch((error) => {
            toast.error("Vendor couldn't be added");
          })
          .finally(() => {
            setisLoading(false);
          });
      } catch (error) {
        toast.error("Vendor couldn't be added");
      }
    } else {
      try {
        api
          .put(`/api/nation/vendor`, {
            id: newRow?.id,
            name: newRow?.name,
            national_site_id: editingRecord?.id,
          })
          .then(() => {
            toast.success("Vendor update successfully");
            get_vendors();
            dispatch(get_nation_site());
          })
          .catch((error) => {
            toast.error("Vendor couldn't be updated");
          })
          .finally(() => {
            setisLoading(false);
          });
      } catch (error) {
        toast.error("Vendor couldn't be updated");
      }
    }
    return updatedRow;
  };
  const handleDeleteVendor = async (ids) => {
    const c = window.confirm("Are you sure want to delete Vendor?");
    if (!c) return;
    setisLoading(true);
    try {
      const res = await api.post(`/api/nation/delete_vendor`, {
        ids: [ids],
        national_site_id: editingRecord?.id,
      });
      if (res.status === 200 || res.status === 201) {
        toast.success(res.data.message || "DIDs deleted successfully");
        get_vendors();
        dispatch(get_nation_site());
      } else {
        toast.error(res.data.message || "DIDs couldn't be deleted");
      }
      setisLoading(false);
    } catch (err) {
      setisLoading(false);
      console.log("🚀 ~ handleDeleteDids ~ err:", err);
      toast.error(err?.response?.data.message || "DIDs couldn't be deleted");
    }
  };
  return (
    <Modal
      isUpdate={!!editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      isLoading={isLoading}
      onClick={onCancelForm}
      modalClass={"!min-w-[60vw]"}
      CloseOnly
      hideButtons
    >
      {isLoading && <DotsLoader />}
      <div className="bg-white mt-3 border rounded">
        <MUIDataTable
          height="46vh"
          columnDefs={columnDefs}
          items={vendors}
          totalItems={vendors?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="No"
          searchable="No"
          CustomToolbar={CustomToolbar}
          rowModesModel={rowModesModel}
          editMode="cell"
          processRowUpdate={handleSaveUpdate}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          onProcessRowUpdateError={console.error}
        />
      </div>
    </Modal>
  );
};

export default VendorsModal;
