import React from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import errorMessage from "../../../util/errorMessage";
import { DotsLoader, FormInput, Modal } from "../../../components";
import api from "../../../services/api";

const AddUpdateForm = ({ editingRecord, onCancelForm, modalTitle, get_domain_email_list, paginationModel }) => {
  const [errors, setErrors] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  let initialValues = {
    email: "",
    domain_email_auth_key:'',
    account_id:''
  };

  if (editingRecord) {
    const { email } = editingRecord;
    initialValues = {
      email,
      domain_email_auth_key:'',
      account_id:''
    };
  }
  function validateForm(data) {
    const errors = [];
    if (!data.email) {
      errors.push({ email: "Phone is not allowed to be empty" });
    }
    if (!data.domain_email_auth_key) {
      errors.push({ domain_email_auth_key: '"domain_email_auth_key" is not allowed to be empty' });
    }
    if (!data.account_id) {
      errors.push({ account_id: '"account_id" required' });
    }
    return errors;
  }
  const formik = useFormik({
    initialValues,

    validateOnBlur: true,

    // validationSchema: JobStatusSchema,

    onSubmit: async (values) => {
      const errors = validateForm(formik.values);
      if(errors?.length){
        errorMessage({
          payload: errors,
          setErrors: setErrors,
          action: "Account",
          msg: "Added",
        });
        return
      }
      if (!isLoading) {
        setIsLoading(true)
        try {
          const res = await api.post('/api/cloudflare/create_account_for_domain_email', values);
          if (res?.status === 200) {
            toast.success("Account added succesfully");
            get_domain_email_list({
              page: paginationModel?.page,
              size: paginationModel?.pageSize,
            });
            onCancelForm();
          } else {
            errorMessage({payload:res?.data?.message, setErrors:setErrors, action:"Account", msg:'added'})
          }
          setIsLoading(false)
        } catch (error) {
          setIsLoading(false)
          console.log("🚀 ~ onSubmit: ~ error:", error)
          errorMessage({payload:error?.response?.data?.message, setErrors:setErrors, action:"Account", msg:'added'})
        }
      } else {
        toast.error("Please wait until previous action be completed");
      }
    },
  });

  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form className="mt-4">
          <FormInput errors={errors} name="email" label="Email" formik={formik} readOnly/>
          <div  className={'my-3'}>
          <FormInput errors={errors} name="domain_email_auth_key" label="Domain Email Auth Key" formik={formik}/>
          </div>
          <FormInput errors={errors} name="account_id" label="Account ID" formik={formik} />
        </form>
      </Modal>
    </>
  );
};

export default AddUpdateForm;
