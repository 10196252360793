import React, { useState } from "react";
import api from "../../../services/api";
import { DotsLoader } from "../../../components";
import PageHeader from "../../../components/molecules/PageHeader";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import { FaCopy, FaEye, FaEyeSlash } from "react-icons/fa";
import AddUpdateForm from "./AddUpdateForm";
import { toast } from "react-toastify";
const DomainEmailList = () => {
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [record, setRecord] = useState([]);
  const [showPassword, setShowPassword] = useState("");
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  const domainList = record?.records?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.info("Password copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "Email", field: "email", flex: 1 },
    { headerName: "Password", field: "password", flex: 1,
    renderCell: (params) => {
      return (
        <div className="">
          <span className="!mr-2">
            {showPassword === params.row.records?.id
              ? params.row.records.password
              : "*******"}
          </span>
          {showPassword === params.row.records.id ? (
            <FaEyeSlash
              className="my_navIcon cursor-pointer"
              onClick={() => setShowPassword("")}
            />
          ) : (
            <FaEye
              className="my_navIcon cursor-pointer"
              onClick={() => setShowPassword(params.row.records.id)}
            />
          )}
          <span className="ml-3 cursor-pointer" onClick={()=>handleCopy(`${params.row.records.password}`)}>
            <FaCopy size={16}/>
          </span>
        </div>
      );
    }, },
    { headerName: "Forward Email", field: "forward_email", flex: 1 },
    // {
    //   headerName: "Account Status",
    //   field: "cloudflare_account_status",
    //   flex: 1,
    // },
    {
      headerName: "Account Status",
      field: "id",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => params?.row?.cloudflare_account_status==="Pending"?(
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            onClick={openFormHandler({email:params.row.email})}
          >
            Add Account
          </span>
        ):(<div>
          {params?.row?.cloudflare_account_status}
        </div>)
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const get_domain_email_list = async (filter) => {
    setRecord([]);
    setIsLoading(true);
    try {
      const res = await api.get("/api/cloudflare/domain_email_list", {
        params: filter,
      });
      if (res.status === 200) {
        setRecord(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("🚀 ~ file: index.jsx:35 ~ DomainEmailList ~ err:", err);
    }
  };
  React.useEffect(() => {
    get_domain_email_list({
      page: paginationModel?.page,
      size: paginationModel?.pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    get_domain_email_list({
      page: +params.page + 1,
      size: params.pageSize,
    });
  };
  return (
    <>
    {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add Account"
          onCancelForm={cancelFormHandler}
          get_domain_email_list={get_domain_email_list}
          paginationModel={paginationModel}
        />
      )}
      <PageHeader route="Setting > Industries" heading="Domain Email Listing" />
      {isLoading && <DotsLoader />}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={domainList?.map((record, index) => {
            const counter = index + 1;
            const { email, cloudflare_account_status, password, forward_email } = record;
            return {
              records: { ...record },
              counter,
              email,
              cloudflare_account_status,
              password,
              forward_email
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={record?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default DomainEmailList;
