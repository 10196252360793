import React from "react";
import { NavLink } from "react-router-dom";
import reports from "./reports.json";
import {
  HiArrowCircleLeft,
  HiOutlineCalculator,
  HiLogin,
  HiOutlineLogin,
  HiOutlineStatusOnline,
  HiChartBar,
  HiOutlineScale,
} from "react-icons/hi";
import { CgCloseR } from "react-icons/cg";
import { TbUserOff } from "react-icons/tb";
import { GrMoney, GrTransaction } from "react-icons/gr";
import userPermission from "../util/userPermission";
function ReportsDashboard() {
  const getIcon = (service) => {
    let Icon = "";
    switch (service) {
      case "Agentless":
        Icon = TbUserOff;
        break;
      case "Commissions":
        Icon = HiOutlineCalculator;
        break;
      case "Closing":
        Icon = CgCloseR;
        break;
      case "Last Touch":
        Icon = HiArrowCircleLeft;
        break;
      case "Job Statistics":
        Icon = HiChartBar;
        break;
      case "Balances":
        Icon = HiOutlineScale;
        break;
      case "Login IP":
        Icon = HiLogin;
        break;
      case "Multiple Login":
        Icon = HiOutlineLogin;
        break;
      case "Sales":
        Icon = GrMoney;
        break;
      case "Transaction":
        Icon = GrTransaction;
        break;
      case "Online User":
        Icon = HiOutlineStatusOnline;
        break;
      case "Agent Performance":
        Icon = CgCloseR;
        break;
      case "Call Summary":
        Icon = CgCloseR;
        break;
      case "Call Logs":
        Icon = CgCloseR;
        break;
      case "Company Summary":
        Icon = CgCloseR;
        break;
      case "Extension Summary":
        Icon = CgCloseR;
        break;
      case "Call Detail Records":
        Icon = CgCloseR;
        break;
      case "Calls by DID":
        Icon = CgCloseR;
        break;
      case "Vendor Location Report":
        Icon = CgCloseR;
        break;
      case "Domain Data Report":
        Icon = CgCloseR;
        break;
      case "OOA Status Report":
        Icon = CgCloseR;
        break;
        case "Major Cities":
        Icon = CgCloseR;
        break;
      default:
        Icon = CgCloseR;
        break;
    }
    return <Icon size={30} />;
  };

  return (
    <>
      {/* CRM Reports */}
      <div className="mt-2">
        <div className="border-b w-full">
          <span className="!ml-3 text-xl font-semibold">Reports</span>
        </div>
        <div className="flex flex-col lg:flex-wrap lg:flex-row justify-start mx-[1%] mt-4 md:mt-3 gap-x-6">
          {reports.data.map((report, index) => {
            const { path, title } = report;
            return (
              <NavLink
                key={index}
                to={path}
                className="flex justify-between items-center text-ml-3 !mb-2 !p-2 sm:w-[45%] md:w-[32%] hover:no-underline no-underline text-black shadow-report rounded-[1px] bg-white border-l-[3px] border-primary-100 hover:shadow-hover transition-all"
              >
                {title}
                {getIcon(title)}
              </NavLink>
            );
          })}
        </div>
      </div>

      {/* Call Center Reports */}
      <div className="mt-2 md:mt-3">
        <div className="border-b w-full">
          <span className="!ml-3 text-xl font-semibold">Call Center</span>
        </div>
        <div className="flex flex-col lg:flex-wrap lg:flex-row justify-start mx-[1%] mt-4 md:mt-3 gap-x-6">
          {reports.repots.map((report, index) => {
            const { path, title, task } = report;
            return (
              <>
                {userPermission(task) && (
                  <NavLink
                    key={index}
                    to={path}
                    className="flex justify-between items-center text-ml-3 !mb-5 !p-2 w-full lg:w-[32%] hover:no-underline no-underline text-black shadow-report rounded-[1px] bg-white border-l-[3px] border-primary-100 hover:shadow-hover transition-all"
                  >
                    {title}
                    {getIcon(title)}
                  </NavLink>
                )}
              </>
            );
          })}
        </div>
      </div>

      {/* Domains Reports */}
      <div className="mt-2 md:mt-3">
        <div className="border-b w-full">
          <span className="!ml-3 text-xl font-semibold">Domains</span>
        </div>
        <div className="flex flex-col lg:flex-wrap lg:flex-row justify-start mx-[1%] mt-4 md:mt-3 gap-x-6">
          {reports.domains.map((report, index) => {
            const { path, title } = report;
            return (
              <NavLink
                key={index}
                to={path}
                className="flex justify-between items-center text-ml-3 !mb-5 !p-2 w-full lg:w-[32%] hover:no-underline no-underline text-black shadow-report rounded-[1px] bg-white border-l-[3px] border-primary-100 hover:shadow-hover transition-all"
              >
                {title}
                {getIcon(title)}
              </NavLink>
            );
          })}
        </div>
      </div>
      {/* Coming Soon */}
      <div className="mt-2 md:mt-3">
        <div className="border-b w-full">
          <span className="!ml-3 text-xl font-semibold">Coming Soon</span>
        </div>
        <div className="flex flex-col lg:flex-wrap lg:flex-row justify-start mx-[1%] mt-4 md:mt-3 gap-x-6">
          {reports.comingsoon.map((report, index) => {
            const { path, title } = report;
            return (
              <>
                <NavLink
                  key={index}
                  to={path}
                  className="flex justify-between items-center text-ml-3 !mb-5 !p-2 w-full lg:w-[32%] hover:no-underline no-underline text-black shadow-report rounded-[1px] bg-white border-l-[3px] border-primary-100 hover:shadow-hover transition-all"
                >
                  {title}
                  {getIcon(title)}
                </NavLink>
              </>
            );
          })}
        </div>
      </div>

      {/* 8x8 */}
      <div className="mt-2 md:mt-3">
        <div className="border-b w-full">
          <span className="!ml-3 text-xl font-semibold">8x8</span>
        </div>
        <div className="flex flex-col lg:flex-wrap lg:flex-row justify-start mx-[1%] mt-4 md:mt-3 gap-x-6">
          {reports?.sso?.map((report, index) => {
            const { path, title } = report;
            return (
              <>
                <NavLink
                  key={index}
                  to={path}
                  className="flex justify-between items-center text-ml-3 !mb-5 !p-2 w-full lg:w-[32%] hover:no-underline no-underline text-black shadow-report rounded-[1px] bg-white border-l-[3px] border-primary-100 hover:shadow-hover transition-all"
                >
                  {title}
                  {getIcon(title)}
                </NavLink>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default ReportsDashboard;
