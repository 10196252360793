import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import api from "../../../services/api";
import { DotsLoader, Loader } from "../../../components";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import PageHeader from "../../../components/molecules/PageHeader";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Input, InputLabel, Button as MUIButton } from "@mui/material";
import { FaSearch } from "react-icons/fa";
import dayjs from "dayjs";
function ExpireDomains() {
  const { isLoading } = useSelector((state) => state.servers);
  const [processStatus, setProcessStatus] = useState(null);

  const [expiredDomains, setExpiredDomains] = useState(null);
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const [selectedData, setSelectedData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [searchInput, setSearchInput] = React.useState("");
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 50,
    page: 1,
  });
  const getExpiredDomainsReport = async (filter) => {
    if (!filter?.filters?.rules?.length) {
      delete filter.filters;
    }
    setIsLoader(true);
    try {
      const res = await api.post(`/api/expire_domains`, filter);
      if (res.status === 200) {
        setExpiredDomains(res.data);
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      console.log("🚀 ~ getSERPReport ~ err:", err);
    }
  };
  React.useEffect(() => {
    getExpiredDomainsReport({
      filters: queryOptions,
      page: paginationModel.page,
      size: paginationModel.pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isSelected = (data) => {
    if (selectedData?.length > 0) {
      if (
        selectedData?.filter(({ domain }) => domain === data?.domain)?.length >
        0
      ) {
        return true;
      }
    }
    return false;
  };
  const onSingleSelect = ({ checked, data }) => {
    if (isLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    if (checked) {
      setSelectedData([...selectedData, data]);
    } else {
      setSelectedData(selectedData?.filter((id) => id !== data));
    }
  };
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    getExpiredDomainsReport({
      filters: queryOptions,
      page: +params.page + 1,
      size: params.pageSize,
    });
  };
  const CustomSelectInput = ({ item, applyValue }) => {
    const [value, setValue] = useState(item.value);
    return (
      <div fullWidth>
        <InputLabel>Value</InputLabel>
        <Input
          value={value || ""}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          onBlur={() => applyValue({ ...item, value: value })}
          fullWidth
        />
      </div>
    );
  };
  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: (params) => {
        return isSelected(params?.row?.records) && isLoader ? (
          <Loader />
        ) : (
          <input
            type="checkbox"
            onChange={(e) => onSelectAll(e.target.checked)}
            className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
            checked={selectedData?.length === 50}
          />
        );
      },
      filterable: false,
      sortable: false,
      width: 30,
      renderCell: (params) =>
        params.row?.status !== "Completed" ? (
          <input
            type="checkbox"
            checked={isSelected(params.row.records)}
            onChange={(e) => {
              if (e.target.checked && selectedData?.length === 50)
                return toast.error("Only 50 domains can be deleted at a time.");
              onSingleSelect({
                checked: e.target.checked,
                data: params.row.records,
              });
            }}
            className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          />
        ) : null,
    },
    { headerName: "#", field: "counter", width: 60, filterable: false },
    {
      field: "domain",
      headerName: "Domain",
      renderCell: (params) => {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${params?.row["domain"]}`}
            className="text-[#000] hover:text-blue-600 hover:underline no-underline"
          >
            {params?.row["domain"]}
          </a>
        );
      },
      flex: 1,
      minWidth: 200,
      disableColumnMenu: true,
      // filterable: false,
    },
    { headerName: "Industry", field: "industry_name", flex: 1 },
    { headerName: "Runner Name", field: "runner_name", flex: 1 },
    { headerName: "Deploy On", field: "deployed_on_host", flex: 1 },
    {
      headerName: "Expired On",
      field: "expires",
      flex: 1,
      type: "date",
      minWidth: 120,
      valueGetter: (params) => new Date(params.row.expires),
      renderCell: (params) => {
        const rawDate = params.row.expires;

        if (rawDate) {
          return rawDate;
        }

        return "";
      },
    },
    {
      headerName: "Expired Status",
      field: "expires_status",
      flex: 1,
    },
    {
      headerName: "Remove Status",
      field: "status",
      flex: 1,
      type: "singleSelect",
      disableColumnMenu: true,
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        status: value?.value,
      }),
      valueOptions: [
        { value: "Pending", label: "Pending" },
        { value: "Completed", label: "Completed" },
      ],
      renderCell: (params) => params.row.status,
    },
  ];
  function groupByRunner(data) {
    const result = [];
    data.forEach((item) => {
      // Find the group for the current runner
      const group = result.find(
        (group) => group.runner_name === item.runner_name
      );
      if (group) {
        // If the group already exists, add the domain to it
        group.domains.push(item.domain);
      } else {
        // Otherwise, create a new group with the current runner and domain
        result.push({
          runner_name: item.runner_name,
          app_url: `https://${item.runner_name}.ecommcube.com/api/setup_front_backend/remove_expire_domain_from_runners`,
          domains: [item.domain],
        });
      }
    });

    return result;
  }
  async function sendRequests() {
    if (!selectedData || selectedData.length === 0) {
      toast.error("Please select the domain");
      return;
    }
    const c = window.confirm(
      `Are you sure want to remove ${selectedData?.length} domains?`
    );
    if (!c) return;
    setIsLoader(true);
    let removeDomains = [];
    setProcessStatus("Removing the domains from runners");
    for (const item of groupByRunner(selectedData)) {
      try {
        const response = await api.post(item.app_url, item.domains);
        if (response.status === 200) {
          //   toast.success(`${response.data.message} for ${item.runner_name}`);
          removeDomains = [...removeDomains, ...response.data.data];
        } else {
          toast.error(`${response.data.message} for ${item.runner_name}`);
        }
      } catch (error) {
        console.error("Error in sendRequests:", error);
        toast.error(`Error for ${item.runner_name}`);
      }
    }
    try {
      const res = await api.post(
        `/api/expire_domains/remove_domains`,
        removeDomains
      );
      if (res.status === 200) {
        setProcessStatus("Removing the images");
        const removeImgPromise = await api.post(
          `/api/expire_domains/remove_domain_images`,
          removeDomains
        );
        setProcessStatus("Sending the report on email");
        const sendEmailPromise = await api.post(
          `/api/expire_domains/sending_expire_domain_report`,
          removeDomains
        );
        await Promise.all([removeImgPromise, sendEmailPromise]);
      }
    } catch (error) {
      console.error("Error in final steps of sendRequests:", error);
      toast.error("An error occurred during the final steps.");
    }
    setIsLoader(false);
    setSelectedData([]);
    getExpiredDomainsReport({
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
    });
  }
  const filteredData = expiredDomains?.records?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      getExpiredDomainsReport({
        page: 1,
        size: paginationModel.pageSize,
      });
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : // : rule.operator === "isEmpty"
                // ? "eq"
                rule.operator,
          data:
            rule.field === "latest_position"
              ? +rule.value
              : rule.value
              ? rule.value
              : rule.value === 0
              ? rule.value
              : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    getExpiredDomainsReport({
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
    });
  };
  const onSelectAll = (checked) => {
    if (isLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }

    if (checked) {
      // IDs or a unique attribute of the currently selected data to help identify them
      const selectedIds = new Set(selectedData.map((item) => item.domain));

      // First, keep the already selected records if they still meet the criteria
      const keepSelected = selectedData.filter(
        ({ status }) => status === "Pending"
      );

      // Calculate how many more records we can select
      const remainingSlots = 50 - keepSelected.length;

      // Select new records, ignoring ones already selected, up to the remainingSlots
      const newSelections = expiredDomains?.records
        ?.filter(
          ({ status, domain }) =>
            status === "Pending" && !selectedIds.has(domain)
        )
        ?.slice(0, remainingSlots);

      // Combine the already selected records with the new selections
      const updatedArray = [...keepSelected, ...newSelections];
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        {selectedData?.length ? (
          <span className="text-sm font-semibold bg-[#fcebc5] py-1 px-1.5">
            {`${selectedData?.length} items are selected `}
            <span
              className="text-blue-600 hover:underline cursor-pointer"
              onClick={() => setSelectedData([])}
            >
              {"Clear Selection?"}
            </span>
          </span>
        ) : null}
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Apply filter
        </MUIButton>
      </GridToolbarContainer>
    );
  }
  return (
    <>
      {isLoading || isLoader ? (
        <DotsLoader text={`${processStatus || "Please Wait"}`} />
      ) : null}
      <PageHeader
        heading="Expired Domains"
        onClick={sendRequests}
        isAllowed={true}
        buttonTitle={"Remove Domains"}
        addIcon="No"
      />

      <div className="bg-white !my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          totalItems={
            expiredDomains?.totalItems ? expiredDomains?.totalItems : 0
          }
          items={filteredData?.map((server, index) => {
            let counter = index + 1;
            const {
              deployed_on_host,
              domain,
              industry_name,
              runner_name,
              expires_status,
              status,
              id,
              expiring_in_days,
              expires,
            } = server;
            return {
              records: server,
              id,
              counter,
              deployed_on_host,
              domain,
              industry_name,
              runner_name,
              expires_status,
              status,
              expiring_in_days,
              expires: dayjs(expires).format("ddd, MMM D, YYYY h:mm A"),
            };
          })}
          isLoading={isLoading}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          onFilterModelChange={onFilterChange}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          paginationModel={paginationModel}
          CustomToolbar={CustomToolbar}
        />
      </div>
    </>
  );
}

export default ExpireDomains;
