import api from "./api";

// Cloudflare Domains
export const getCloudflareDomainList = async (data) => {
  return await api.get("/api/cloudflare/domain_list", {params:data});
};


// Forward Email
export const getforwardEmail = async () => {
  return await api.get("/api/forwardEmail");
};

export const createforwardEmail = async (data) => {
  return await api.post("/api/forwardEmail", data);
};

export const updateforwardEmail = async ({id, email}) => {
  return await api.put(`/api/forwardEmail/${id}`, {email});
};

export const deleteforwardEmail = async (id) => {
  return await api.delete(`/api/forwardEmail/${id}`);
};


// Email Prefix
export const getDomainEmailPrefix = async () => {
  return await api.get("/api/domainEmailPrefix");
};

export const createDomainEmailPrefix = async (data) => {
  return await api.post("/api/domainEmailPrefix", data);
};

export const updateDomainEmailPrefix = async ({id, name}) => {
  return await api.put(`/api/domainEmailPrefix/${id}`, {name});
};

export const deleteDomainEmailPrefix = async (id) => {
  return await api.delete(`/api/domainEmailPrefix/${id}`);
};



export const createCardType = async (data) => {
  return await api.post("/api/card_type", data);
};
export const updateCardType = async (data) => {
  return await api.put("/api/card_type", data);
};
