import { DataGridPro } from "@mui/x-data-grid-pro";
import React from "react";
import { Button } from "../../../components";

const EmailsListModal = ({ onCancelForm, emails }) => {
  const modalRef = React.useRef(null);
  const [searchText, setSearchText] = React.useState("");
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      onCancelForm();
    }
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onCancelForm();
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  const filterStates = emails?.filter((e) => {
    return e?.toLowerCase()?.includes(searchText?.toLowerCase());
  });
  return (
    <>
      <div className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-center items-center bg-black/60">
        <div className="container-fluid mt-2  text-center">
          <div
            className="inline-block bg-white w-3/4 my-0 mx-auto p-4 bg-red-600 rounded  max-h-[87vh]"
            ref={modalRef}
          >
            <header className="flex items-center justify-center shadow-lg bg-primary-100 text-white h-[50px] mb-3 text-xl">
              Email List
            </header>
            <div className="flex flex-row justify-between">
              <input
                placeholder="Search"
                onChange={(e) => setSearchText(e.target.value)}
                className="!w-1/3 mb-2 placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md !py-2 px-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                value={searchText}
              />
              <span className="text-xl">
                {filterStates?.length} records found
              </span>
            </div>

            <div className="h-[50vh] mb-2">
              <DataGridPro
                rows={
                  filterStates?.length
                    ? filterStates?.map((item, index) => {
                        const counter = index + 1;
                        const email = item;
                        return {
                          id: counter,
                          counter,
                          email,
                        };
                      })
                    : []
                }
                columns={[
                  { headerName: "#", field: "counter", width: 100 },
                  { headerName: "Email", field: "email", flex: 1 },
                ]}
              />
            </div>
            <Button
              text="Cancel"
              variant="btn_cancel"
              className="ml-3"
              onClick={onCancelForm}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailsListModal;
