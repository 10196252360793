import { DataGridPro } from "@mui/x-data-grid-pro";
import { Tooltip } from "primereact/tooltip";
import React, { useState } from "react";
import { Button, DotsLoader } from "../../../components";
import api from "../../../services/api";

const KeywordListModal = ({ onCancelForm, data }) => {
  const modalRef = React.useRef(null);
  const [searchText, setSearchText] = React.useState("");
  const [keywordsData, setKeywordsData] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      onCancelForm();
    }
  };
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onCancelForm();
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  const filterStates = keywordsData?.filter((e) =>
    e.keyword?.toLowerCase()?.includes(searchText?.toLowerCase())
  );
  const getDomainKeywords = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `/api/reports/domain_keywords/${data?.domain_id}`
      );
      if (res.status === 200) {
        setKeywordsData(res.data?.keywords);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("🚀 ~ getDomainKeywords ~ err:", err);
    }
  };
  React.useEffect(() => {
    getDomainKeywords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-center items-center bg-black/60">
        {loading ? <DotsLoader /> : null}
        <div className="container-fluid mt-2  text-center">
          <div
            className="inline-block bg-white w-3/4 my-0 mx-auto p-4 bg-red-600 rounded  max-h-[87vh]"
            ref={modalRef}
          >
            <header className="flex items-center justify-center shadow-lg bg-primary-100 text-white h-[50px] mb-3 text-xl">
              <h1 className="text-2xl text-white m-4 font-medium">
                {`(${data?.domain}) `}Secondary Keywords
              </h1>
            </header>
            <div className="flex flex-row justify-between">
              <input
                placeholder="Search"
                onChange={(e) => setSearchText(e.target.value)}
                className="!w-1/3 mb-2 placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md !py-2 px-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                value={searchText}
              />
              <span className="text-xl">
                {filterStates?.length} records found
              </span>
            </div>

            <div className="h-[50vh] mb-2">
              <DataGridPro
                rows={
                  filterStates?.length
                    ? filterStates?.map((item, index) => {
                        const { id, keyword, monthly_searched, difficulty } =
                          item;
                        return {
                          id,
                          counter: index + 1,
                          keyword,
                          monthly_searched:
                            monthly_searched === "-1"
                              ? "Not Available"
                              : monthly_searched,
                          difficulty:
                            difficulty === "-1" ? "Not Available" : difficulty,
                        };
                      })
                    : []
                }
                columns={[
                  {
                    headerName: "#",
                    field: "counter",
                    width: 100,
                    disableColumnMenu: true,
                  },
                  {
                    headerName: "keyword",
                    field: "keyword",
                    flex: 1,
                    minWidth: 300,
                    disableColumnMenu: true,
                  },

                  {
                    headerName: "Start",
                    field: "start",
                    flex: 1,
                    disableColumnMenu: true,
                    sortable:false,
                    renderHeader:()=>(
                      <span className="font-medium">
                        <Tooltip target=".start-header" className="max-w-[450px] font-bold text-xs text-center"/>
                        Start
                        <span className="start-header ml-1 cursor-pointer"
                        data-pr-tooltip="This is the ranking recorded when the keyword was first added in. You can click on the number to edit the value"
                        data-pr-position="top"
                        data-pr-at="center top-10"
                        // data-pr-my="left center-2"
                        >i</span>
                      </span>
                    )
                  },
                  {
                    headerName: "Rank",
                    field: "rank",
                    flex: 1,
                    disableColumnMenu: true,
                    sortable:false,
                    renderHeader:()=>(
                      <span className="font-medium">
                        <Tooltip target=".rank-header" className="max-w-[450px] font-bold text-xs text-center" />
                        Rank
                        <span className="rank-header ml-1 cursor-pointer"
                        data-pr-tooltip="The current rank of Keyword."
                        data-pr-position="top"
                        data-pr-at="center top-10"
                        // data-pr-my="left center-2"
                        >i</span>
                      </span>
                    )
                  },
                  {
                    headerName: "1d",
                    field: "1d",
                    flex: 1,
                    disableColumnMenu: true,
                    sortable:false,
                    renderHeader:()=>(
                      <span className="font-medium">
                        <Tooltip target=".one-d-header" className="max-w-[450px] font-bold text-xs text-center" />
                        1d
                        <span className="one-d-header ml-1 cursor-pointer"
                        data-pr-tooltip="Indicates the rank position change of Keyword in the last 1 day."
                        data-pr-position="top"
                        data-pr-at="center top-10"
                        // data-pr-my="left center-2"
                        >i</span>
                      </span>
                    )
                  },
                  {
                    headerName: "7d",
                    field: "7d",
                    flex: 1,
                    disableColumnMenu: true,
                    sortable:false,
                    renderHeader:()=>(
                      <span className="font-medium">
                        <Tooltip target=".seven-d-header" className="max-w-[450px] font-bold text-xs text-center" />
                        7d
                        <span className="seven-d-header ml-1 cursor-pointer"
                        data-pr-tooltip="Indicates the rank position change of Keyword in the last 7 days."
                        data-pr-position="top"
                        data-pr-at="center top-10"
                        // data-pr-my="left center-2"
                        >i</span>
                      </span>
                    )
                  },
                  {
                    headerName: "30d",
                    field: "30d",
                    flex: 1,
                    disableColumnMenu: true,
                    sortable:false,
                    renderHeader:()=>(
                      <span className="font-medium">
                        <Tooltip target=".thirty-d-header" className="max-w-[450px] font-bold text-xs text-center" />
                        30d
                        <span className="thirty-d-header ml-1 cursor-pointer"
                        data-pr-tooltip="Indicates the rank position change of Keyword in the last 30 days."
                        data-pr-position="top"
                        data-pr-at="center top-10"
                        // data-pr-my="left center-2"
                        >i</span>
                      </span>
                    )
                  },
                  {
                    headerName: "Life",
                    field: "life",
                    flex: 1,
                    disableColumnMenu: true,
                    sortable:false,
                    renderHeader:()=>(
                      <span className="font-medium">
                        <Tooltip target=".life-header" className="max-w-[450px] font-bold text-xs text-center" />
                        life
                        <span className="life-header ml-1 cursor-pointer"
                        data-pr-tooltip="Indicates the rank position change of Keyword from date added."
                        data-pr-position="top"
                        data-pr-at="center top-10"
                        // data-pr-my="left center-2"
                        >i</span>
                      </span>
                    )
                  },
                  {
                    headerName: "Search Volume",
                    field: "monthly_searched",
                    flex: 1,
                    type: "number",
                    align: "center",
                    headerAlign: "center",
                    minWidth: 150,
                    disableColumnMenu: true,
                    sortable:false,
                    renderHeader:()=>(
                      <span className="font-medium">
                        <Tooltip target=".monthly-searched-header" className="max-w-[350px] font-bold text-xs text-center" />
                        Search Volume
                        <span className="monthly-searched-header ml-1 cursor-pointer"
                        data-pr-tooltip="Monthly search volume for Keyword relevant to tracked region."
                        data-pr-position="top"
                        data-pr-at="center top-10"
                        // data-pr-my="left center-2"
                        >i</span>
                      </span>
                    )
                  },
                  {
                    headerName: "Keyword Difficulty",
                    field: "difficulty",
                    flex: 1,
                    type: "number",
                    minWidth: 150,
                    align: "center",
                    headerAlign: "center",
                    disableColumnMenu: true,
                    // sortable:false,
                    // renderHeader:()=>(
                    //   <span className="font-medium">
                    //     <Tooltip target=".difficulty-header" className="max-w-[450px] font-bold text-xs text-center" />
                    //     Keyword Difficulty
                    //     <span className="difficulty-header ml-1 cursor-pointer"
                    //     data-pr-tooltip="Test"
                    //     data-pr-position="top"
                    //     data-pr-at="center top-10"
                    //     // data-pr-my="left center-2"
                    //     >i</span>
                    //   </span>
                    // )
                  },
                ]}
              />
            </div>
            <Button
              text="Cancel"
              variant="btn_cancel"
              className="ml-3"
              onClick={onCancelForm}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default KeywordListModal;
