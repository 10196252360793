import api from "./api";

export const getNationSiteList = async (data) => {
  return await api.get(`/api/nation`,{params:data});
};
export const createNationSite = async (data) => {
  return await api.post("/api/nation", data);
};
export const updateNationSite = async (data) => {
  return await api.put("/api/nation", data);
};

export const getNationVendorList = async (id) => {
  return await api.get(`/api/nation/vendor/${id}`);
};
export const createNationVendor = async (data) => {
  return await api.post("/api/nation/vendor", data);
};
export const updateNationVendor = async (data) => {
  return await api.put("/api/nation/vendor", data);
};

export const getNationSiteZips = async (id) => {
    return await api.get(`api/custom_vendors/nation_site_zips/${id}`);
  };
// export const getNationSiteZips = async (id) => {
//   return await api.get(`api/custom_vendors/nation_site_zips/service_zips_by_industry?industry_id=${id}`);
// };
export const addNationSiteZips = async (values) => {
  return await api.post("api/custom_vendors/nation_site_zips", values);
};
