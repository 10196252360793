import React from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import {
  create_lead_action,
  update_lead_action,
} from "../../features/leadActionSlice";
import { filterPayload } from "../../util/filterPayload";
const AddUpdateForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  const { isLoading } = useSelector((state) => state.leadAction);
  const dispatch = useDispatch();
  const [errors, setErrors] = React.useState([]);

  let initialValues = {
    title: "",
    comment_required: false,
    contact_attempted: false,
    contact: false,
    calendar_event_required: false,
    milestone: "",
    redistribute_lead_using_program: "",
  };

  if (editingRecord) {
    const {
      _id,
      title,
      comment_required,
      contact_attempted,
      contact,
      calendar_event_required,
      milestone,
      redistribute_lead_using_program,
    } = editingRecord;
    initialValues = {
      id: _id,
      title,
      comment_required,
      contact_attempted,
      contact,
      calendar_event_required,
      milestone,
      redistribute_lead_using_program,
    };
  }

  const formik = useFormik({
    initialValues,

    validateOnBlur: true,
    // validationSchema: LeadStatusSchema,
    onSubmit: async (values) => {
      const filteredValues = filterPayload(values);
      if (!editingRecord) {
        try {
          const res = await dispatch(create_lead_action(filteredValues));
          if (res?.payload?.status === 200) {
            toast.success("Lead Action created");
            onCancelForm();
          } else {
            errorMessage({
              payload: res.payload,
              setErrors: setErrors,
              action: "Lead Action",
              msg: "created",
            });
          }
        } catch (error) {
          errorMessage({
            payload: error,
            setErrors: setErrors,
            action: "Lead Action",
            msg: "created",
          });
        }
      } else {
        try {
          const res = await dispatch(update_lead_action(filteredValues));
          if (res?.payload?.status === 200) {
            toast.success("Lead Action updated");
            onCancelForm();
          } else {
            errorMessage({
              payload: res.payload,
              setErrors: setErrors,
              action: "Lead Action",
              msg: "updated",
            });
          }
        } catch (error) {
          errorMessage({
            payload: error,
            setErrors: setErrors,
            action: "Lead Action",
            msg: "updated",
          });
        }
      }
    },
  });
  const basisOption = [
    { label: "None", value: "None" },
    { label: "Contact", value: "Contact" },
    { label: "Qualification", value: "Qualification" },
    { label: "Conversion", value: "Conversion" },
  ];
  return (
    <Modal
      isUpdate={!!editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      formIsValid={formik.isValid}
      onSubmit={formik.handleSubmit}
      isLoading={isLoading}
      onClick={onCancelForm}
    >
      {isLoading && <DotsLoader />}
      <form className="mt-4">
        <FormInput errors={errors} name="title" label="Title" formik={formik} />

        <div className="grid grid-cols-2 mt-3 gap-x-2">
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="milestone"
              label="Milestone"
              formik={formik}
              options={basisOption}
              valueProp="value"
              labelProp="label"
            />
          </div>
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="redistribute_lead_using_program"
              label="Redistribute Lead Using Program"
              formik={formik}
              options={basisOption}
              valueProp="value"
              labelProp="label"
            />
          </div>
          <div className={`flex items-center !py-1.5 !px-2.5 `}>
            <input
              type="checkbox"
              checked={formik.values.comment_required}
              className={`form-checkbox h-5 w-5 text-primary-100 rounded-sm focus:ring-0 cursor-pointer mr-2`}
              onChange={() =>
                formik.setFieldValue(
                  "comment_required",
                  !formik.values.comment_required
                )
              }
            />
            <label className={`text-base text-[#495057] cursor-pointer`}>
              Comment Required
            </label>
          </div>
          <div className={`flex items-center !py-1.5 !px-2.5 `}>
            <input
              type="checkbox"
              checked={formik.values.contact_attempted}
              className={`form-checkbox h-5 w-5 text-primary-100 rounded-sm focus:ring-0 cursor-pointer mr-2`}
              onChange={() =>
                formik.setFieldValue(
                  "contact_attempted",
                  !formik.values.contact_attempted
                )
              }
            />
            <label className={`text-base text-[#495057] cursor-pointer`}>
              Contact Attempt:
            </label>
          </div>

          <div className={`flex items-center !py-1.5 !px-2.5 `}>
            <input
              type="checkbox"
              checked={formik.values.contact}
              className={`form-checkbox h-5 w-5 text-primary-100 rounded-sm focus:ring-0 cursor-pointer mr-2`}
              onChange={() =>
                formik.setFieldValue("contact", !formik.values.contact)
              }
            />
            <label className={`text-base text-[#495057] cursor-pointer`}>
              Contact
            </label>
          </div>

          <div className={`flex items-center !py-1.5 !px-2.5 `}>
            <input
              type="checkbox"
              checked={formik.values.calendar_event_required}
              className={`form-checkbox h-5 w-5 text-primary-100 rounded-sm focus:ring-0 cursor-pointer mr-2`}
              onChange={() =>
                formik.setFieldValue(
                  "calendar_event_required",
                  !formik.values.calendar_event_required
                )
              }
            />
            <label className={`text-base text-[#495057] cursor-pointer`}>
              Calendar Event Required
            </label>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default AddUpdateForm;
