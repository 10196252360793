import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button } from "@mui/material";
import { FaEdit, FaSearch, FaTrashAlt } from "react-icons/fa";
import PageHeader from "../../../components/molecules/PageHeader";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import {
  delete_domain_ranking_keywords,
  get_domain_ranking_report,
} from "../../../features/domainCenterSlice";
import dayjs from "dayjs";
import AddKeywordsModal from "./AddKeywordsModal";
import { get_industry_drd } from "../../../features/IndustrySlice";
import userPermission from "../../../util/userPermission";
import { toast } from "react-toastify";
import errorMessage from "../../../util/errorMessage";
import KeywordListModal from "./KeywordList";
import VendorsListModal from "./VendorsList";
import * as XLSX from "xlsx";
import { DotsLoader } from "../../../components";
import { get_geo_states } from "../../../features/GeoVendorsSlice";
import ZipsListModal from "./ZipCodesList";
import api from "../../../services/api";
import ExportExcelModal from "./ExportExcelModal";
const DomainRanking = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.domainCenter);
  const [searchText, setSearchText] = useState("");
  const [domainID, setDomainID] = useState("");
  const [vendors, setVendors] = useState("");
  const [zip_codes, setZip_codes] = useState(null);
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });

  const { industryDrd } = useSelector((state) => state.industry);
  const [isEditing, setIsEditing] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("total_record");
  const [industryModal, setIndustryModal] = useState(false);
  const [industryToExport, setIndustryToExport] = useState(null);
  const [loading, setLoading] = useState(false);
  const [domainRankingRpt, setDomainRankingRpt] = useState();
  const { states } = useSelector((state) => state.geoVendors);
  const get_domain_ranking_rpt = async ({ filters, page, perPage }) => {
    // let data=[]
    if (!states || !states?.length) {
      await dispatch(get_geo_states());
    }
    try {
      const res = await dispatch(
        get_domain_ranking_report({
          filters,
          page,
          perPage,
        })
      );
      if (res.payload.status === 200) {
        let data = res.payload.data?.domainList?.map((item) => ({
          ...item,
          zipCodes: "Pending",
        }));
        setDomainRankingRpt({ ...res.payload.data, domainList: data });
      }
    } catch (error) {
      console.log("🚀 ~ constget_domain_ranking_rpt=async ~ error:", error);
    }
  };
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    get_domain_ranking_rpt({
      filters: queryOptions,
      page: 1,
      perPage: paginationModel.pageSize,
    });
  };
  const onFilterChange = React.useCallback((filterModel) => {
    if (filterModel?.items?.length === 0) {
      get_domain_ranking_rpt({
        filters: {
          groupOp: "",
          rules: [],
        },
        page: 1,
        size: paginationModel.pageSize,
      });
    }
    let ruless = [];
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              // : rule.operator === "isEmpty" && rule.field !== "vendors"
              // ? "eq"
              : rule.operator,
          data: rule.value || rule.value === 0 ? rule.value : null,
        },
      ];
    });
    // ?.toLowerCase()?.includes(searchInput?.toLowerCase())
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    get_domain_ranking_rpt({
      filters: queryOptions,
      page: paginationModel?.page,
      perPage: paginationModel.pageSize,
    });
    dispatch(get_industry_drd());
    // return () => {
    //   dispatch(linkReportReset());
    // };
    // eslint-disable-next-line
  }, []);
  const domainList = domainRankingRpt?.domainList?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure want to delete this keyword?");
    if (c) {
      try {
        const res = await dispatch(delete_domain_ranking_keywords(record_id));
        if (res?.payload?.status === 200) {
          toast.success("Keyword deleted");
          get_domain_ranking_rpt({
            filters: queryOptions,
            page: paginationModel.page,
            perPage: paginationModel.pageSize,
          });
        } else {
          errorMessage({
            payload: res.payload,
            action: "Keyword",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({ payload: error, action: "Keyword", msg: "deleted" });
      }
    }
  };
  const columnDefs = [
    { headerName: "#", field: "counter", width: 80, filterable: false },
    {
      headerName: "Industry",
      field: "industry_name",
      flex: 1,

      type: "singleSelect",
      getOptionValue: (value) => value?.name,
      getOptionLabel: (value) => value.name,
      valueOptions: industryDrd,
      renderCell: (params) => params.value.industry_name,
      valueGetter: (params) => params.row.industry_name,
      valueFormatter: (params) => params.value.industry_name,
    },
    { headerName: "State", field: "state", flex: 1 },
    { headerName: "City", field: "city", flex: 1 },
    {
      headerName: "Zip Codes",
      field: "zip_counts",
      flex: 1,
      align: "center",
      minWidth: 100,
      sortable: true,
      renderCell: (params) =>
        !params?.row.zip_counts ? (
          <span>-</span>
        ) : (
          <span
            className={`${
              params.row.zip_counts
                ? "text-blue-600 cursor-pointer hover:underline"
                : ""
            }`}
            onClick={() =>
              params.row.zip_counts
                ? setZip_codes({
                    domain: [params?.row["d.domain"]],
                  })
                : {}
            }
          >
            {params.row.zip_counts}
          </span>
        ),
    },
    {
      field: "d.domain",
      headerName: "Domain",
      renderCell: (params) => {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${params?.row["d.domain"]}`}
            className="text-[#000] hover:text-blue-600 hover:underline no-underline"
          >
            {params?.row["d.domain"]}
          </a>
        );
      },
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Vendors",
      field: "vendors",
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      sortable: true,
      valueGetter: (params) => params.row.vendors?.length, // Provide a function to get the sorting value
      sortComparator: (v1, v2, row1, row2) => v1 - v2,
      renderCell: (params) =>
        params?.row?.vendors?.length ? (
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            onClick={() =>
              setVendors({
                vendors: params?.row?.records.vendors,
                domain: params?.row["d.domain"],
              })
            }
          >
            {params?.row?.vendors?.length ? params?.row?.vendors?.length : 0}
          </span>
        ) : (
          <span>-</span>
        ),
    },
    {
      headerName: "Population",
      field: "population",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    { headerName: "Keyword", field: "keyword", flex: 1 },
    {
      headerName: "In/Out Area",
      field: "is_inarea",
      flex: 1,
      type: "singleSelect",
      align: "center",
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        is_inarea: value?.value,
      }),
      valueOptions: [
        { value: 1, label: "In Area" },
        { value: 0, label: "Out Area" },
      ],
    },
    {
      headerName: "Google Position",
      field: "vkd.google_rank",
      flex: 1,
      align: "center",
    },
    {
      headerName: "Search Volume",
      field: "kmd.monthly_searched",
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
    },
    {
      headerName: "Keyword Difficulty",
      field: "kmd.difficulty",
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
    },
    {
      headerName: "KW Total",
      field: "service_count",
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
      minWidth: 100,
      renderCell: (params) =>
        params?.row?.service_count ? (
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            onClick={
              () =>
                setDomainID({
                  domain_id: params?.row?.records.domain_id,
                  domain: params?.row["d.domain"],
                })
              // api.get(`/api/reports/update_ranking_domains`)
            }
          >
            {params?.row?.service_count ? params?.row?.service_count : 0}
          </span>
        ) : (
          <span>-</span>
        ),
    },
    {
      field: "actions",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <div className="flex flex-row">
          {userPermission("Update Allowed IP") && (
            <FaEdit
              onClick={openFormHandler(params.row.records)}
              className="my_navIcon"
              title="Update AllowedIP"
            />
          )}
          {userPermission("Delete Allowed IP") && (
            <FaTrashAlt
              onClick={deleteRecordHandler(params.row.id)}
              className="my_navIcon"
              title="Delete AllowedIP"
            />
          )}
        </div>
      ),
      width: 100,
      filterable: false,
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    get_domain_ranking_rpt({
      filters: queryOptions,
      page: +params.page + 1,
      perPage: params.pageSize,
    });
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="!text-[#042a42]">
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <Button
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className="!text-[#042a42]"
        >
          Apply filter
        </Button>
      </GridToolbarContainer>
    );
  }
  const handleFilterData = (data) => {
    const query = {
      groupOp: "AND",
      rules: [
        {
          field: "is_inarea",
          op: "eq",
          data: data,
        },
      ],
    };
    if (data) {
      setSelectedFilter("in_area");
    } else {
      setSelectedFilter("out_area");
    }
    setQueryOptions(query);
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    get_domain_ranking_rpt({
      filters: query,
      page: 1,
      perPage: paginationModel.pageSize,
    });
  };
  const handleFilterReset = () => {
    const query = {
      groupOp: "",
      rules: [],
    };
    setQueryOptions(query);
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    get_domain_ranking_rpt({
      filters: query,
      page: 1,
      perPage: paginationModel.pageSize,
    });
    setSelectedFilter("total_record");
  };

  function exportToExcel(type) {
    if (type === "by_industry") {
      setIndustryModal(true);
      return;
    }
    const data = domainRankingRpt?.domainList;
    const rows = [
      [
        "#",
        "Industry",
        "State",
        "City",
        "Domain",
        "Vendor Count",
        "Population",
        "Keywords",
        "In/Out Area",
        "Google Position",
        "Search Volume",
        "Keyword Difficulty",
        "KW Total",
      ], // Add headings
    ];
    data.forEach((item, index) => {
      const {
        monthly_searched,
        keyword,
        domain,
        population,
        state,
        city,
        industry_name,
        is_inarea,
        service_count,
        vendors,
        google_rank,
      } = item;
      const monthlySearched =
        monthly_searched === "-1" ? "Not Available" : monthly_searched;
      const difficulty =
        item?.difficulty === "-1" ? "Not Available" : item?.difficulty;
      const counter = index + 1;
      const vendors_count = vendors ? vendors?.length : 0;
      const inArea = is_inarea ? "In Area" : "Out Area";
      rows.push([
        counter,
        industry_name,
        state,
        city,
        domain,
        vendors_count,
        population,
        keyword,
        inArea,
        google_rank,
        monthlySearched,
        difficulty,
        service_count,
      ]); // Add a row without domains
    });

    const worksheet = XLSX.utils.aoa_to_sheet(rows);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const fileName = "Domain Ranking.xlsx";

    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    if (typeof window.navigator.msSaveBlob !== "undefined") {
      // For IE browser
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      // For other browsers
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100);
    }
  }
  const exportOption = [
    {
      label: "Current Page",
      value: "current_record",
    },
    {
      label: "By Industry",
      value: "by_industry",
    },
  ];
  const handleConfirm = async () => {
    // Perform the action here with the selectedOption
    if (!industryToExport) {
      return toast.error("Please select an industry");
    }
    setLoading(true);
    try {
      const res = await api.post("/api/reports/export_domain_ranking_report", {
        industry_id: industryToExport,
      });
      if (res.status === 200) {
        window.open(`${res.data}`, "_blank").focus();
        toast.success("Database export successfully");
        setIndustryToExport(null);
        setIndustryModal(false);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("🚀 ~ handleConfirm ~ err:", err);
    }
    // Close the modal
  };
  return (
    <>
      <PageHeader
        route="Setting > Industries"
        heading="Domain Ranking"
        onClick={() => setIsEditing(true)}
        isAllowed={userPermission("Add Industry")}
        buttonTitle="Add keywords"
      />
      {loading ? <DotsLoader /> : null}
      {industryModal ? (
        <ExportExcelModal
          onClose={() => {
            setIndustryToExport(null);
            setIndustryModal(false);
          }}
          setIndustryToExport={setIndustryToExport}
          industryToExport={industryToExport}
          industryDrd={industryDrd}
          handleConfirm={handleConfirm}
        />
      ) : null}
      {domainID?.domain_id ? (
        <KeywordListModal
          onCancelForm={() => setDomainID(null)}
          data={domainID}
        />
      ) : null}
      {vendors?.vendors?.length ? (
        <VendorsListModal
          onCancelForm={() => setVendors(null)}
          data={vendors}
        />
      ) : null}
      {zip_codes?.domain?.length ? (
        <ZipsListModal
          onCancelForm={() => setZip_codes(null)}
          data={zip_codes}
        />
      ) : null}
      {isEditing ? (
        <AddKeywordsModal
          modalTitle="Add keywords"
          onCancelForm={cancelFormHandler}
          editingRecord={editingRecord}
          getData={() =>
            get_domain_ranking_rpt({
              filters: queryOptions,
              page: paginationModel.page,
              perPage: paginationModel.pageSize,
            })
          }
        />
      ) : null}
      <div className="flex-cols md:flex flex-wrap justify-start space-x-2 mx-0.5">
        <button
          className={`flex mb-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[19%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
        ${
          selectedFilter === "total_record"
            ? "border-l-2 border-primary-100"
            : ""
        }
        `}
          onClick={handleFilterReset}
        >
          <span className="text-sm font-pop">Total Records</span>
          <span className="text-base font-medium font-pop">
            {domainRankingRpt?.summary?.unique_domains
              ? domainRankingRpt?.summary?.unique_domains?.toLocaleString()
              : 0}
          </span>
        </button>
        <button
          className={`flex mb-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[19%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
        ${selectedFilter === "in_area" ? "border-l-2 border-primary-100" : ""}
        `}
          onClick={() => handleFilterData(1)}
        >
          <span className="text-sm font-pop">In Area</span>
          <span className="text-base font-medium font-pop">
            {domainRankingRpt?.summary?.unique_domains_area_status?.find(
              ({ is_inarea }) => is_inarea === 1
            )?.total
              ? domainRankingRpt?.summary?.unique_domains_area_status
                  ?.find(({ is_inarea }) => is_inarea === 1)
                  ?.total?.toLocaleString()
              : 0}
          </span>
        </button>
        <button
          className={`flex mb-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[19%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
        ${selectedFilter === "out_area" ? "border-l-2 border-primary-100" : ""}
        `}
          onClick={() => handleFilterData(0)}
        >
          <span className="text-sm font-pop">Out Area</span>
          <span className="text-base font-medium font-pop">
            {domainRankingRpt?.summary?.unique_domains_area_status?.find(
              ({ is_inarea }) => is_inarea === 0
            )?.total
              ? domainRankingRpt?.summary?.unique_domains_area_status
                  ?.find(({ is_inarea }) => is_inarea === 0)
                  ?.total?.toLocaleString()
              : 0}
          </span>
        </button>
      </div>
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={domainList?.map((record, index) => {
            const counter = index + 1;
            const {
              id,
              keyword_discovery_score,
              keyword,
              created_at,
              population,
              keyword_com_response,
              state,
              city,
              industry_name,
              is_inarea,
              service_count,
              vendors,
              zip_counts,
            } = record;
            const domain = record["d.domain"];
            const google_rank = record["vkd.google_rank"];
            const difficulty = record["kmd.difficulty"];
            const monthly_searched = record["kmd.monthly_searched"];
            const keyword_data = keyword_com_response
              ? JSON.parse(keyword_com_response)?.data[0]?.attributes
              : null;
            return {
              records: { ...record, _id: id },
              id,
              zip_counts,
              counter,
              keyword,
              "d.domain": domain,
              "vkd.google_rank": google_rank,
              population,
              keyword_discovery_score,
              "kmd.monthly_searched":
                monthly_searched === "-1" ? "Not Available" : monthly_searched,
              grank: keyword_data ? keyword_data?.grank : "",
              created_at: dayjs(created_at).format("ddd, MMM D, YYYY h:mm A"),
              state,
              city,
              industry_name,
              is_inarea,
              service_count,
              "kmd.difficulty":
                difficulty === "-1" ? "Not Available" : difficulty,
              vendors,
            };
          })}
          exportOption={exportOption}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={domainRankingRpt?.totalCount}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          onFilterModelChange={onFilterChange}
          CustomToolbar={CustomToolbar}
          columnVisibilityModel={{ has_monthly_searched: false }}
          // exportButton={exportToExcel}
          handleExport={exportToExcel}
        />
      </div>
    </>
  );
};

export default DomainRanking;
