import api from "./api";

export const getGscEmailAccounts = async () => {
  return await api.get(`/api/gsc/email_accounts`);
};
export const createGscEmailAccounts = async (data) => {
  return await api.post("/api/gsc/email_account", data);
};
export const updateGscEmailAccounts = async (data) => {
  return await api.post(`/api/gsc/email_account/add_refresh_token`, data);
};
export const deleteGscEmailAccounts = async (id) => {
  return await api.delete(`/api/gsc/email_account`, {data:{id}});
};
