import React, { useEffect, useState } from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import AddUpdateForm from "./AddUpdateForm";
import { toast } from "react-toastify";
import errorMessage from "../../../util/errorMessage";
import userPermission from "../../../util/userPermission";
import PageHeader from "../../../components/molecules/PageHeader";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import { delete_forward_email, get_forward_email } from "../../../features/cloudflareSlice";

const ForwardEmail = () => {
  const dispatch = useDispatch();
  const { isLoading, forwardEmail } = useSelector((state) => state.cloudflare);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState("");
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_forward_email(record_id));
        if (res?.payload?.status === 200) {
          toast.success("Forward Email deleted");
          cancelFormHandler();
        } else {
          errorMessage({
            payload: res.payload,
            action: "Forward Email",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({ payload: error, action: "Forward Email", msg: "deleted" });
      }
    }
  };

  useEffect(() => {
    dispatch(get_forward_email());
    // eslint-disable-next-line
  }, []);
  const jobStatus = forwardEmail?.filter((status) =>
    status?.email?.toLowerCase().includes(searchText.toLowerCase())
  );
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "Email", field: "email", flex: 1 },
    {
      field: "actions",
      renderCell: (params) => (
          <div>
              <FaEdit
                onClick={openFormHandler(params.row.records)}
                className="my_navIcon"
                title="Update Forward Email"
              />
              <FaTrashAlt
                onClick={deleteRecordHandler(params.row.records.id)}
                className="my_navIcon"
                title="Delete Forward Email"
              />
          </div>
        ),
    },
  ];
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add Forward Email"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        route="Setting > Forward Email"
        heading="Forward Email Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add Job Status")}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={jobStatus?.map((record, index) => {
            let counter = index + 1;
            let email = record.email;
            return {
              records: record,
              counter,
              email,
            };
          })}
          pagination="No"
          totalItems={forwardEmail?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default ForwardEmail;
