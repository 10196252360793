import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/molecules/PageHeader";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import { FaCopy, FaEdit, FaTrashAlt } from "react-icons/fa";
import GoogleConsoleForm from "./GoogleConsoleForm";
import userPermission from "../../util/userPermission";
import {
  delete_gsc_email_accounts,
  get_gsc_email_accounts,
} from "../../features/googleEmailSlice";
import {
  get_available_gsc_server,
  get_gsc_server,
} from "../../features/serverSlice";

const GoogleEmailAccounts = () => {
  const dispatch = useDispatch();
  const { isLoading, emailAccounts } = useSelector(
    (state) => state.googleEmails
  );
  const { servers } = useSelector((state) => state.servers);
  const [searchText, setSearchText] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  useEffect(() => {
    dispatch(get_gsc_server());
    dispatch(get_gsc_email_accounts());
    dispatch(get_available_gsc_server());
    // eslint-disable-next-line
  }, []);
  const googleConsoles = emailAccounts?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_gsc_email_accounts(record_id));
        if (res?.payload?.status === 200) {
          toast.success("Email account deleted successfully");
          dispatch(get_gsc_email_accounts());
        } else {
          errorMessage({
            payload: res.payload,
            action: "Email account",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({
          payload: error,
          action: "Google Search Console Account",
          msg: "deleted",
        });
      }
    }
  };
  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.info("Refresh Token URL copied successfully");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };
  const columnDefs = [
    { headerName: "#", field: "counter", width: 80 },
    { headerName: "Gmail Accounts", field: "email", flex: 1 },
    { headerName: "Server", field: "gsc_server_id", flex: 1 },
    { headerName: "Password", field: "password", flex: 1 },
    { headerName: "Total Domains", field: "domain_count", minWidth:120, align:'center' },
    { headerName: "Client ID", field: "client_id", flex: 1 },
    { headerName: "Client Secret", field: "client_secret", flex: 1 },
    { headerName: "Server IP", field: "server_ip", flex: 1 },
    {
      headerName: "Action",
      field: "client_sec",
      flex: 1,
      renderCell: (params) => (
        <>
          {!params?.row?.records?.deploy_status ? (
            <div>
              <div className="flex flex-row">
                <FaEdit
                  onClick={openFormHandler(params.row.records)}
                  className="my_navIcon"
                  title="Update Email Account"
                />
                <FaTrashAlt
                  onClick={deleteRecordHandler(params.row.records.id)}
                  className="my_navIcon"
                  title="Delete Email Account"
                />
                <FaCopy
                  onClick={()=>handleCopy(params?.row?.refresh_token_url)}
                  className="my_navIcon"
                  title="Copy Refresh Token URL"
                />
              </div>
            </div>
          ) : null}
        </>
      ),
    },
  ];
  return (
    <>
      <PageHeader
        route="Setting > User Roles"
        heading="Email Accounts List"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add Allowed IP")}
      />
      {isEditing && (
        <GoogleConsoleForm
          editingRecord={editingRecord}
          modalTitle="Add Email"
          onCancelForm={cancelFormHandler}
        />
      )}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={googleConsoles?.map((record, index) => {
            let counter = index + 1;
            const {
              gsc_server_id,
              client_secret,
              email,
              password,
              domain_count,
              client_id,
              refresh_token_url,
              server_ip,
            } = record;
            return {
              counter,
              gsc_server_id: servers?.find(({ id }) => id === gsc_server_id)
                ?.server_ip,
              client_secret,
              email,
              password,
              domain_count,
              client_id,
              records: record,
              refresh_token_url,
              server_ip,
            };
          })}
          totalItems={emailAccounts?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="No"
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default GoogleEmailAccounts;
