import React from "react";
import { DotsLoader, Modal } from "../../../components";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button as MUIButton } from "@material-ui/core";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import {
  GridActionsCellItem,
  GridRowModes,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { FaEdit, FaPlus, FaSave, FaTrash } from "react-icons/fa";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import dayjs from "dayjs";
import api from "../../../services/api";
import { MdOutlineCancel } from "react-icons/md";
import { useDispatch } from "react-redux";
import { get_cloudflare_domains } from "../../../features/cloudflareSlice";
function AddUpdateEmailsForm({
  onCancelForm,
  selectedData,
  queryOptions,
  paginationModel,
  setSelectedData
}) {
  const { isLoading } = useSelector((state) => state.cloudflare);
  const dispatch=useDispatch()
  let initialValues = {
    forward_email: "",
    prefix: [],
  };
  const [rowModesModelForward, setRowModesModelForward] = React.useState({});
  const [selectedRecord, setSelectedRecord] = React.useState([...selectedData]);
  const [activeTab, setActiveTab] = React.useState("Add Domain Emails");
  const [rowModesModelPrefix, setRowModesModelPrefix] = React.useState({});
  const [forwardEmail, setForwardEmail] = React.useState([]);
  const [emailPrefix, setEmailPrefix] = React.useState([]);
  const handleForwardEditForward = (id) => () => {
    setRowModesModelForward({
      ...rowModesModelForward,
      [id]: { mode: "edit" },
    });
  };
  const [loading, setLoading] = React.useState(false);
  const isSelected = (domain) => {
    if (selectedRecord?.length > 0) {
      if (selectedRecord?.filter(({ id }) => id === domain.id).length > 0) {
        return true;
      }
    }
    return false;
  };
  const onSingleSelect = ({ checked, data }) => {
    try {
      if (checked) {
        setSelectedRecord((prevSelectedData) => {
          const updatedSelectedData = [...prevSelectedData, data];
          return updatedSelectedData;
        });
      } else {
        setSelectedRecord((prevSelectedRecord) => {
          const updatedSelectedRecord = prevSelectedRecord?.filter(
            (domain) => domain?.id !== data?.id
          );
          return updatedSelectedRecord;
        });
      }
    } catch (err) {
      console.log("🚀 ~ file: index.jsx:116 ~ onSingleSelect ~ err:", err);
    }
  };
  const handleAddForward = () => {
    // Check if there are any unsaved rows before adding a new row
    const unsavedRows = Object.values(rowModesModelForward).filter(
      (modeModel) => modeModel.mode === GridRowModes.Edit
    );
    if (unsavedRows.length > 0) {
      toast.error("Please save all rows before adding a new row.");
      return;
    }
    // Add a new row
    const id = new Date().getTime() % 100000000;
    setForwardEmail((oldRows) => [{ id, isNew: true }, ...oldRows]);
    setRowModesModelForward((oldModel) => ({
      ...oldModel,
      [id]: { mode: "edit", fieldToFocus: "email" },
    }));
  };
  const handleSaveClickForward = (id) => () => {
    setRowModesModelForward({
      ...rowModesModelForward,
      [id]: { mode: "view" },
    });
  };
  const handleCancelClickForward = (id) => () => {
    setRowModesModelForward({
      ...rowModesModelForward,
      [id]: { mode: "view", ignoreModifications: true },
    });
    const editedRow = forwardEmail.find((row) => row.id === id);
    if (editedRow.isNew) {
      setForwardEmail(forwardEmail.filter((row) => row.id !== id));
    }
  };
  const handleRowEditStartForward = (_params, event) => {
    event.defaultMuiPrevented = true;
  };
  const handleRowEditStopForward = (_params, event) => {
    event.defaultMuiPrevented = true;
  };
  const handleRowModesModelChangeForward = (newRowModesModel) => {
    setRowModesModelForward(newRowModesModel);
  };
  const deleteForwardEmail = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      setLoading(true);
      try {
        api
          .delete(`/api/forwardEmail/${record_id}`)
          .then(() => {
            toast.success("Forward email delete successfully");
            getForwardEmails();
          })
          .catch((error) => {
            toast.error("Forward email couldn't be deleted");
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        toast.error("Forward email couldn't be deleted");
      }
    }
  };
  const handleSaveUpdateForward = async (newRow) => {
    const updatedRow = newRow;
    delete newRow.counter;
    setLoading(true);
    if (updatedRow.isNew) {
      try {
        api
          .post(`/api/forwardEmail`, { email: newRow?.email })
          .then(() => {
            toast.success("Forward email added successfully");
            getForwardEmails();
          })
          .catch((error) => {
            toast.error("Forward email couldn't be added");
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        toast.error("Forward email couldn't be added");
      }
    } else {
      try {
        api
          .put(`/api/forwardEmail/${newRow?.id}`, { email: newRow?.email })
          .then(() => {
            toast.success("Forward email update successfully");
            getForwardEmails();
          })
          .catch((error) => {
            toast.error("Forward email couldn't be updated");
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        toast.error("Forward email couldn't be updated");
      }
    }
    return updatedRow;
  };
  const forwardEmailcolumnDefs = [
    {
      field: "actions",
      type: "actions",
      cellClassName: "actions",
      disableColumnMenu: false,
      width: 100,
      filterable: false,
      getActions: (params) => {
        const isInEditMode = rowModesModelForward[params.id]?.mode === "edit";
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<FaSave size={16} />}
              label="Save"
              onClick={handleSaveClickForward(params.id)}
              className="focus:outline-none"
            />,
            <GridActionsCellItem
              icon={<MdOutlineCancel size={16} />}
              label="Cancel"
              className="textPrimary focus:outline-none"
              onClick={handleCancelClickForward(params.id)}
              color="inherit"
            />,
          ];
        } else {
          return [
            <GridActionsCellItem
              icon={<FaEdit size={16} />}
              label="Edit"
              className="textPrimary focus:outline-none"
              onClick={handleForwardEditForward(params.id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<FaTrash size={16} />}
              label="Insert Tag"
              className="textPrimary focus:outline-none"
              onClick={deleteForwardEmail(params.id)}
              color="inherit"
            />,
          ];
        }
      },
    },
    // { headerName: "#", field: "count", flex: 1 },
    {
      headerName: "Email",
      field: "email",
      flex: 1,
      editable: true,
    },
  ];

  const cloudflareColumnDefs = [
    {
      field: "counter",
      headerName: "#",
      filterable: false,
      sortable: false,
      width: 60,
      renderCell: (params) => (
        <>
          <input
            type="checkbox"
            checked={isSelected(params.row.records)}
            onChange={(e) =>
              onSingleSelect({
                checked: e.target.checked,
                data: params.row.records,
              })
            }
            className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          />
          <span>{params.row.counter}</span>
        </>
      ),
      disableColumnMenu: true,
    },
    {
      field: "domain",
      headerName: "Domain",
      renderCell: (params) => {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${params?.row.domain}`}
            className="text-[#000] hover:text-blue-600 hover:underline no-underline"
          >
            {params?.row.domain}
          </a>
        );
      },
      flex: 1,
      minWidth: 200,
    },
    { headerName: "Industry", field: "industry_name", flex: 1 },
    {
      field: "city",
      headerName: "City",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    { headerName: "State", field: "state", flex: 1 },
    {
      headerName: 'Error',
      field: 'message',
      renderCell: (params) => <CustomCell params={params} />,
      flex: 1,
      minWidth: 250,
    }
  ];
  const handleAddPrefix = () => {
    // Check if there are any unsaved rows before adding a new row
    const unsavedRows = Object.values(rowModesModelPrefix).filter(
      (modeModel) => modeModel.mode === GridRowModes.Edit
    );
    if (unsavedRows.length > 0) {
      toast.error("Please save all rows before adding a new row.");
      return;
    }
    // Add a new row
    const id = new Date().getTime() % 100000000;
    setEmailPrefix((oldRows) => [{ id, isNew: true }, ...oldRows]);
    setRowModesModelPrefix((oldModel) => ({
      ...oldModel,
      [id]: { mode: "edit", fieldToFocus: "name" },
    }));
  };

  const handlePrefixEdit = (id) => () => {
    setRowModesModelPrefix({
      ...rowModesModelPrefix,
      [id]: { mode: "edit" },
    });
  };
  const handleSaveClickPrefix = (id) => () => {
    setRowModesModelPrefix({
      ...rowModesModelPrefix,
      [id]: { mode: "view" },
    });
  };
  const handleCancelClickPrefix = (id) => () => {
    setRowModesModelPrefix({
      ...rowModesModelPrefix,
      [id]: { mode: "view", ignoreModifications: true },
    });
    const editedRow = emailPrefix.find((row) => row.id === id);
    if (editedRow.isNew) {
      setEmailPrefix(emailPrefix.filter((row) => row.id !== id));
    }
  };
  const handleRowEditStartPrefix = (_params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStopPrefix = (_params, event) => {
    event.defaultMuiPrevented = true;
  };
  const handleRowModesModelChangePrefix = (newRowModesModel) => {
    setRowModesModelPrefix(newRowModesModel);
  };
  const deletePrefixEmail = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      setLoading(true);
      try {
        api
          .delete(`/api/domainEmailPrefix/${record_id}`)
          .then(() => {
            toast.success("Domain email prefix delete successfully");
            getEmailPrefix();
          })
          .catch((error) => {
            toast.error("Domain email prefix couldn't be deleted");
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        toast.error("Domain email prefix couldn't be deleted");
      }
    }
  };

  const handleSaveUpdatePrefix = async (newRow) => {
    const updatedRow = newRow;
    delete newRow.counter;
    setLoading(true);
    if (updatedRow.isNew) {
      try {
        api
          .post(`/api/domainEmailPrefix`, { name: newRow?.name })
          .then(() => {
            toast.success("Domain email prefix added successfully");
            getEmailPrefix();
          })
          .catch((error) => {
            toast.error("Domain email prefix couldn't be added");
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        toast.error("Domain email prefix couldn't be added");
      }
    } else {
      try {
        api
          .put(`/api/domainEmailPrefix/${newRow?.id}`, { name: newRow?.name })
          .then(() => {
            toast.success("Domain email prefix update successfully");
            getEmailPrefix();
          })
          .catch((error) => {
            toast.error("Domain email prefix couldn't be updated");
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        toast.error("Domain email prefix couldn't be updated");
      }
    }
    return updatedRow;
  };
  const prefixColumns = [
    {
      field: "actions",
      type: "actions",
      cellClassName: "actions",
      disableColumnMenu: false,
      width: 100,
      filterable: false,
      getActions: (params) => {
        const isInEditMode = rowModesModelPrefix[params.id]?.mode === "edit";
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<FaSave size={16} />}
              label="Save"
              onClick={handleSaveClickPrefix(params.id)}
              className="focus:outline-none"
            />,
            <GridActionsCellItem
              icon={<MdOutlineCancel size={16} />}
              label="Cancel"
              className="textPrimary focus:outline-none"
              onClick={handleCancelClickPrefix(params.id)}
              color="inherit"
            />,
          ];
        } else {
          return [
            <GridActionsCellItem
              icon={<FaEdit size={16} />}
              label="Edit"
              className="textPrimary focus:outline-none"
              onClick={handlePrefixEdit(params.id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<FaTrash size={16} />}
              label="Insert Tag"
              className="textPrimary focus:outline-none"
              onClick={deletePrefixEmail(params.id)}
              color="inherit"
            />,
          ];
        }
      },
    },
    { headerName: "Name", field: "name", flex: 1, editable: true },
  ];
  const getForwardEmails = async () => {
    try {
      const res = await api.get("/api/forwardEmail");
      if (res?.status === 200) {
        setForwardEmail(res.data);
      }
    } catch (err) {
      console.log(
        "🚀 ~ file: AddUpdateEmailsForm.jsx:442 ~ getForwardEmails ~ err:",
        err
      );
    }
  };
  const getEmailPrefix = async () => {
    try {
      const res = await api.get("/api/domainEmailPrefix");
      if (res?.status === 200) {
        setEmailPrefix(res.data);
      }
    } catch (err) {
      console.log(
        "🚀 ~ file: AddUpdateEmailsForm.jsx:284 ~ getPauseCodes ~ err:",
        err
      );
    }
  };
  React.useEffect(() => {
    getForwardEmails();
    getEmailPrefix();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const CustomCell = ( {params} ) => {
    const [hovered, setHovered] = React.useState(false);
    return (
      <div
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className="relative cursor-pointer"
      >
        <span className="text-red-600 cursor-pointer">{params.row.message}</span>
        {hovered && (
          <div className="fixed z-10 bg-gray-200 p-2 transform -translate-x-4 rounded-md shadow cursor-pointer">
            {
              params?.row?.records?.error?.emailList?.split(',')?.map((email, index)=>{
                return(
                <p className="text-sm" key={index} >{email}</p>
                )
              })
            }
            
          </div>
        )}
      </div>
    );
  };
  const handleSubmit = async (values) => {
    if(!values?.forward_email || !values?.prefix?.length>0){
      return toast.error("Forward Email and Email Prefix are required")
    }
    setLoading(true)
    const payload = selectedRecord?.map(
      ({
        domain,
      }) => ({
        domain,
        ...values,
      })
    );

    try {
      const res = await api.post(
        "/api/cloudflare/add_cloud_email_for_domain",
        payload
      );
      if (res.payload?.status === 200 || res.status === 200 ) {
        toast.success(`Cloud email added successfully`);
        setSelectedData([]);
        dispatch(
          get_cloudflare_domains({
            filters: queryOptions,
            page: paginationModel?.page,
            size: paginationModel.pageSize,
          })
        );
        onCancelForm();
      } else {
        toast.error(
          res.data?.message
            ? res.data?.message
            : `Cloud email couldn't be added`
        );
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (error?.response?.status===400 && error?.response?.data){
        setSelectedData((prevData) => {
          const updatedData = prevData.map((item) => {
            const foundError = error?.response?.data?.find((newObject) => newObject.domain === item.domain);
            
            if (foundError) {
              // If domain exists in error response data, merge error into the item
              return { ...item, error: foundError };
            } else if (item.error) {
              // If domain doesn't exist but error key exists in item, remove error key
              const { error, ...rest } = item;
              return { ...rest };
            }
        
            return item; // Return the original item if no changes are needed
          });
          return updatedData;
        });
      }
      
      toast.error(
        error.data?.message
          ? error.data?.message
          : `Cloud email couldn't be added`
      );
    }
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    // validationSchema: CampaignSchema,
    onSubmit: handleSubmit,
  });
  const handleNewRecord = () => {
    if (activeTab === "Forward Emails") {
      handleAddForward();
    } else {
      handleAddPrefix();
    }
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        <MUIButton
          variant="text"
          startIcon={<FaPlus size={18} />}
          sx={{ fontSize: "0.8125rem", background: "" }}
          onClick={handleNewRecord}
          className="text-black btnSecondary text-sm "
        >
          Add {activeTab}
        </MUIButton>
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
      </GridToolbarContainer>
    );
  }
  return (
    <Modal
      childrenClass={"pb-0"}
      title={`${activeTab}`}
      onCancelModal={onCancelForm}
      formIsValid={formik.isValid}
      onSubmit={formik.handleSubmit}
      isLoading={isLoading}
      onClick={() => {}}
      hideButtons={activeTab === "Add Domain Emails" ? false : true}
    >
      {isLoading || loading ? <DotsLoader /> : null}
      <Tabs>
        <TabList className="!flex !flex-wrap !pl-0 react-tabs__tab-list !border-b-[#dee2e6]">
          <Tab
            selectedClassName="!bg-primary-100 text-white !border-2 !border-[#5f5f5f] !border-b-0"
            className="!flex !justify-between !rounded-t !mx-2 bg-[#f9f9f9] border border-[#dee2e6] -bottom-px relative py-2 px-3 cursor-pointer flex-[0_0_23%] w-full md:max-w-[23%]"
            onClick={() => {
              setActiveTab("Add Domain Emails");
            }}
          >
            <p className="font mb-0 truncate w-full text-normal font-semibold">
              Add domain emails
            </p>
          </Tab>
          <Tab
            selectedClassName="!bg-primary-100 text-white text-xl !border-2 !border-primary-100 !border-b-0"
            className="!flex !justify-between !rounded-t !mx-2 bg-[#f9f9f9] border !border-primary-100 -bottom-px relative py-2 px-3 cursor-pointer flex-[0_0_23%] w-full md:max-w-[23%]"
            onClick={() => {
              setActiveTab("Forward Emails");
            }}
          >
            <p className="font mb-0 truncate w-full text-normal font-semibold">
              Forward Emails
            </p>
          </Tab>
          <Tab
            selectedClassName="!bg-primary-100 text-white !border-2 !border-[#5f5f5f] !border-b-0"
            className="!flex !justify-between !rounded-t !mx-2 bg-[#f9f9f9] border border-[#dee2e6] -bottom-px relative py-2 px-3 cursor-pointer flex-[0_0_23%] w-full md:max-w-[23%]"
            onClick={() => {
              setActiveTab("Email Prefix");
            }}
          >
            <p className="font mb-0 truncate w-full text-normal font-semibold">
              Email Prefix
            </p>
          </Tab>
        </TabList>
        {/* Campaigns */}
        <TabPanel>
          <div className="bg-white mt-3 border rounded w-full min-w-[900px] min-h-[50vh]">
            <form className="grid md:grid-cols-2 gap-x-5 !mt-3 mx-2.5">
              <div className="mb-3">
                <Dropdown
                  value={formik.values?.forward_email}
                  onChange={(e) =>
                    formik.setFieldValue("forward_email", e.value)
                  }
                  options={forwardEmail}
                  optionLabel="email"
                  optionValue="email"
                  className="w-full focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                  placeholder="Select an Forward Email"
                  filter
                />
              </div>
              <div className="mb-3">
                <MultiSelect
                  placeholder="Select Email Prefix"
                  options={emailPrefix}
                  optionLabel="name"
                  optionValue="name"
                  filter
                  maxSelectedLabels={5}
                  value={formik.values.prefix}
                  onChange={(e) => {
                    formik.setFieldValue("prefix", e.value);
                  }}
                  className="h-[38px w-full !rounded-[4px focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                />
              </div>
            </form>
            <div className="bg-white border rounded w-full min-w-[900px]">
              <MUIDataTable
                items={selectedData?.map((record, index) => {
                  const counter = index + 1;
                  const {
                    id,
                    keyword,
                    created_at,
                    domain,
                    state,
                    industry_name,
                    city,
                    error,
                  } = record;
                  return {
                    records: { ...record, _id: id },
                    id,
                    counter,
                    keyword,
                    domain,
                    created_at: dayjs(created_at).format(
                      "ddd, MMM D, YYYY h:mm A"
                    ),
                    state,
                    industry_name,
                    city,
                    message:error?.message
                  };
                })}
                columnDefs={cloudflareColumnDefs}
                pagination="No"
                height="46vh"
                searchable="No"
                displayCount="No"
                isLoading={isLoading}
              />
            </div>
          </div>
        </TabPanel>
        {/* Dispositions */}
        <TabPanel>
          <div className="bg-white my-3 border rounded w-full min-w-[900px]">
            <MUIDataTable
              items={forwardEmail}
              columnDefs={forwardEmailcolumnDefs}
              pagination="No"
              height="46vh"
              searchable="No"
              displayCount="No"
              isLoading={isLoading || loading}
              CustomToolbar={CustomToolbar}
              processRowUpdate={handleSaveUpdateForward}
              rowModesModel={rowModesModelForward}
              editMode="cell"
              onRowModesModelChange={handleRowModesModelChangeForward}
              onRowEditStart={handleRowEditStartForward}
              onRowEditStop={handleRowEditStopForward}
              onProcessRowUpdateError={console.error}
            />
          </div>
        </TabPanel>
        {/* Pause Codes */}
        <TabPanel>
          <div className="bg-white my-3 border rounded w-full min-w-[900px]">
            <MUIDataTable
              items={emailPrefix}
              columnDefs={prefixColumns}
              pagination="No"
              height="46vh"
              searchable="No"
              displayCount="No"
              isLoading={isLoading || loading}
              CustomToolbar={CustomToolbar}
              processRowUpdate={handleSaveUpdatePrefix}
              rowModesModel={rowModesModelPrefix}
              editMode="cell"
              onRowModesModelChange={handleRowModesModelChangePrefix}
              onRowEditStart={handleRowEditStartPrefix}
              onRowEditStop={handleRowEditStopPrefix}
              onProcessRowUpdateError={console.error}
            />
          </div>
        </TabPanel>
      </Tabs>
    </Modal>
  );
}

export default AddUpdateEmailsForm;
