import React from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { create_forward_email, update_forward_email } from "../../../features/cloudflareSlice";
import errorMessage from "../../../util/errorMessage";
import { DotsLoader, FormInput, Modal } from "../../../components";

const AddUpdateForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.cloudflare);
  const [errors, setErrors] = React.useState([]);

  let initialValues = {
    email: "",
  };

  if (editingRecord) {
    const { id, email } = editingRecord;
    initialValues = {
      id,
      email,
    };
  }

  const formik = useFormik({
    initialValues,

    validateOnBlur: true,

    // validationSchema: JobStatusSchema,

    onSubmit: async (values) => {
      if (!values?.email){
        return toast.error("Email is required")
      }
      if (!isLoading) {
        if (!editingRecord) {
          try {
            const res = await dispatch(create_forward_email(values));
            if (res?.payload?.status === 201) {
              toast.success("Forward Email created");
              onCancelForm();
            } else {
              errorMessage({payload:res.payload, setErrors:setErrors, action:"Forward Email", msg:'created'})
            }
          } catch (error) {
            toast.error("Forward Email couldn't be created");
          }
        } else {
          try {
            const res = await dispatch(update_forward_email(values));
            if (res?.payload?.status === 200) {
              toast.success("Forward Email updated");
              onCancelForm();
            } else {
              errorMessage({payload:res.payload, setErrors:setErrors, action:"Forward Email", msg:'updated'})
            }
          } catch (error) {
            toast.error("Forward Email couldn't be updated");
          }
        }
      } else {
        toast.error("Please wait until previous action be completed");
      }
    },
  });

  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form className="mt-4">
          <FormInput errors={errors} name="email" label="Email" formik={formik} />
        </form>
      </Modal>
    </>
  );
};

export default AddUpdateForm;
