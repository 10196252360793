import { DataGridPro } from "@mui/x-data-grid-pro";
import React from "react";
import { Button } from "../../../components";
import api from "../../../services/api";

const ZipsListModal = ({ onCancelForm, data }) => {
  const modalRef = React.useRef(null);
  const [searchText, setSearchText] = React.useState("");
  const [zipCodes, setZipCodes] = React.useState([]);
  const [loading, setIsLoading] = React.useState(false);
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      onCancelForm();
    }
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onCancelForm();
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  const filteredZips = zipCodes?.filter((e) =>
  e?.toLowerCase()?.includes(searchText?.toLowerCase())
);
const getDomainKeywords = async () => {
  setIsLoading(true);
  try {
    const res = await api.post(
      `api/reports/list_domain_zips`, {domains:data.domain}
    );
    if (res.status === 200) {
      const data=res?.data?.map(({zips})=>zips?.split(','))
      setZipCodes(data[0]);
    }
    setIsLoading(false);
  } catch (err) {
    setIsLoading(false);
    console.log("🚀 ~ getDomainKeywords ~ err:", err);
  }
};
React.useEffect(() => {
  getDomainKeywords();
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);
  return (
    <>
      <div className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-center items-center bg-black/60">
        <div className="container-fluid mt-2  text-center">
          <div
            className="inline-block bg-white w-3/4 my-0 mx-auto p-4 bg-red-600 rounded  max-h-[87vh]"
            ref={modalRef}
          >
            <header className="flex items-center justify-center shadow-lg bg-primary-100 text-white h-[50px] mb-3 text-xl">
              <h1 className="text-2xl text-white m-4 font-medium">
                {`(${data?.domain[0]}) `}Zip Code List
              </h1>
            </header>
            <div className="flex flex-row justify-between">
              <input
                placeholder="Search"
                onChange={(e) => setSearchText(e.target.value)}
                className="!w-1/3 mb-2 placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md !py-2 px-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                value={searchText}
              />
              <span className="text-xl">{filteredZips?.length} records found</span>
            </div>

            <div className="h-[50vh] mb-2">
            <DataGridPro
                rows={
                  filteredZips?.length
                    ? filteredZips?.map((item, index) => {
                      const zip=item
                        return {
                          id:index+1,
                          counter: index + 1,
                          zip
                        };
                      })
                    : []
                }
                columns={[
                  { headerName: "#", field: "counter", width: 100 },
                  { headerName: "Zip", field: "zip", flex: 1 },
                ]}
                loading={loading}
              />
            </div>
            <Button
              text="Cancel"
              variant="btn_cancel"
              className="ml-3"
              onClick={onCancelForm}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ZipsListModal;
