import { DataGridPro } from "@mui/x-data-grid-pro";
import React from "react";
import { useSelector } from "react-redux";
import { Button } from "../../../components";

const SelectedDataModal = ({ onCancelForm, data }) => {
  const { isLoading, record } = useSelector((state) => state.geoVendors);
  const modalRef = React.useRef(null);
  const [searchText, setSearchText] = React.useState("");
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      onCancelForm();
    }
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onCancelForm();
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  const vendors = data?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const columnDefs = [
    {
      headerName: "#",
      field: "counter",
      width: 60,
      filterable: false,
    },
    {
      headerName: "Domain",
      field: "nd.domain",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <a
          className="items-center text-black cursor-pointer hover:underline no-underline"
          target="_blank"
          rel="noreferrer"
          href={`https://${params.row["nd.domain"]}`}
        >
          {params.row["nd.domain"]}
        </a>
      ),
    },
    {
      field: "industry_name",
      headerName: "Industry",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Generate By",
      field: "generate_domain_by",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "State",
      field: "state",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "City",
      field: "city",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Location",
      field: "location",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Population",
      field: "population",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Phone",
      field: "phone",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "In/Out Area",
      field: "in_area",
      flex: 1,
      type: "singleSelect",
      // align: "center",
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        in_area: value?.value,
      }),
      valueOptions: [
        { value: 1, label: "In Area" },
        { value: 0, label: "Out Area" },
      ],
    },
    {
      headerName: "System Domain",
      field: "system_domains",
      flex: 1,
      type: "singleSelect",
      // align: "center",
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        system_domains: value?.value,
      }),
      valueOptions: [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
      ],
    },
    {
      headerName: "Expiring in Days",
      field: "expiring_in_days",
      flex: 1,
      minWidth: 120,
      filterable: false,
      renderCell: (params) =>
        params?.row.expiring_in_days <= 0 ? (
          <span>{`${Math.abs(params?.row?.expiring_in_days)} days ago`}</span>
        ) : (
          <span>{params?.row?.expiring_in_days}</span>
        ),
    },
    {
      headerName: "Status",
      field: "status",
      flex: 1,
      type: "singleSelect",
      // align: "center",
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        status: value?.value,
      }),
      valueOptions: [{ value: "Active", label: "Active" }],
      renderCell: (params) => <span>{params?.row?.status}</span>,
    },
  ];
  return (
    <>
      <div className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-center items-center bg-black/60">
        <div className="container-fluid mt-2  text-center">
          <div
            className="inline-block bg-white w-3/4 my-0 mx-auto p-4 bg-red-600 rounded  max-h-[87vh]"
            ref={modalRef}
          >
            <header className="flex items-center justify-center shadow-lg bg-primary-100 text-white h-[50px] mb-3 text-xl">
              <h1 className="text-2xl text-white m-4 font-medium">
                Selected Domains List
              </h1>
            </header>
            <div className="flex flex-row justify-between">
              <input
                placeholder="Search"
                onChange={(e) => setSearchText(e.target.value)}
                className="!w-1/3 mb-2 placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md !py-2 px-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                value={searchText}
              />
              <span className="text-xl">{vendors?.length} records found</span>
            </div>

            <div className="h-[50vh] mb-2">
              <DataGridPro
                rows={
                  vendors?.length
                    ? vendors?.map((record, index) => {
                      const counter = index + 1;
                      const {
                        id,
                        auto_renew,
                        created,
                        domain,
                        industry_name,
                        state,
                        city,
                        location,
                        population,
                        expires,
                        expiring_in_days,
                        in_area,
                        status,
                        system_domain_id,
                        is_detached,
                        renew_status,
                        vendors,
                        counts,
                        generate_domain_by,
                        phone,
                      } = record;
                      return {
                        records: { ...record, _id: id },
                        id,
                        counter,
                        "nd.auto_renew": auto_renew,
                        created,
                        "nd.domain": domain,
                        industry_name,
                        state,
                        city,
                        location,
                        population,
                        expires,
                        expiring_in_days,
                        in_area,
                        status,
                        system_domains: system_domain_id ? "Yes" : "No",
                        "nd.is_detached": is_detached,
                        renew_status,
                        vendors: vendors?.split(","),
                        counts,
                        phone,
                        generate_domain_by,
                      };
                    }) : []
                }
                columns={columnDefs}
                loading={isLoading}
              />
            </div>
            <Button
              text="Cancel"
              variant="btn_cancel"
              className="ml-3"
              onClick={onCancelForm}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectedDataModal;
