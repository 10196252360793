import React from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import errorMessage from "../../../util/errorMessage";
import { DotsLoader, FormInput, Modal } from "../../../components";
import {
  add_domain_ranking_keywords,
  get_domains_drd,
  update_domain_ranking_keywords,
} from "../../../features/domainCenterSlice";
import { Dropdown } from "primereact/dropdown";
import CustomDropDown from "./Dropdown";
import TagsInput from "../../../components/atoms/CustomTagsInput";
const AddKeywordsModal = ({ editingRecord, onCancelForm, modalTitle, getData, stopApiCalls }) => {
  const dispatch = useDispatch();
  const { isLoading, domainsDrd } = useSelector((state) => state.domainCenter);
  const [errors, setErrors] = React.useState([]);
  const { industryDrd } = useSelector((state) => state.industry);
  let initialValues = {
    id: null,
    keywords: [],
    industry_id: null,
  };
  if (editingRecord) {
    const { keyword, id } = editingRecord;
    initialValues = {
      id,
      keyword,
    };
  }
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: async (values) => {
      // if (!values?.id || values.keywords?.length === 0) {
      //   return toast.error("Domain and keywords are required");
      // }
      const action = editingRecord
        ? update_domain_ranking_keywords
        : add_domain_ranking_keywords;
      const message = editingRecord ? "updated" : "added";
      delete values.industry_id;
      try {
        const res = await dispatch(action(values));
        if (res?.payload?.status === 200) {
          toast.success(`Keywords ${message}`);
          if(stopApiCalls){
            getData()
          }
          onCancelForm();
        } else {
          errorMessage({
            payload: res.payload,
            setErrors: setErrors,
            action: "keywords",
            msg: { message },
          });
        }
      } catch (error) {
        console.error(
          "🚀 ~ file: AddUpdateForm.jsx:48 ~ onSubmit: ~ error:",
          error
        );
        errorMessage({
          payload: error.payload,
          setErrors: setErrors,
          action: "keywords",
          msg: { message },
        });
      }
    },
  });
  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={() => {}}
      >
        {isLoading && <DotsLoader />}
        <div className="mt-4">
          <div className="grid grid-cols-2 gap-2">
            <Dropdown
              value={formik.values.industry_id}
              onChange={(e) => {
                dispatch(get_domains_drd(e.value));
                formik.setFieldValue("industry_id", e.value);
              }}
              options={industryDrd}
              optionLabel="name"
              optionValue="cms_id"
              placeholder="Industry"
              filter
              className="h-[40px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              showClear
              showFilterClear
            />
            <CustomDropDown
              options={
                editingRecord
                  ? [{ id: editingRecord?.id, domain: editingRecord?.domain }]
                  : formik.values.industry_id
                  ? domainsDrd
                  : []
              }
              placeholder="Select Domain"
              filter
              optionLabel="domain"
              optionValue="id"
              value={formik.values.id}
              onChange={(e) => formik.setFieldValue("id", e)}
              disabled={!formik.values.industry_id}
              requiredMsg="Please select the industry"
            />
          </div>
          {editingRecord ? (
            <FormInput
              errors={errors}
              name="keyword"
              label="Keyword"
              formik={formik}
              className="mt-3"
            />
          ) : (
            <TagsInput
              tags={formik.values.keywords}
              setTags={(tag) => {
                formik.setFieldValue("keywords", tag);
              }}
              placeholder="Add new keyword"
              tagBg={"!bg-[#d8d8d8] !text-black"}
              className="mt-2"
            />
          )}
        </div>
      </Modal>
    </>
  );
};

export default AddKeywordsModal;
