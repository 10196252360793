import React, { useEffect, useState } from "react";
import AddUpdateForm from "./AddUpdateForm";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import {
  get_lead_source_group,
  delete_lead_source_group,
  leadSourceGroupReset,
} from "../../features/leadSourceGroupSlice";
import { toast } from "react-toastify";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import errorMessage from "../../util/errorMessage";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { Button } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
const LeadSourceGroup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoading, record } = useSelector((state) => state?.leadSourceGroup);
  const [isEditing, setIsEditing] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [searchText, setSearchText] = useState("");
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_lead_source_group(record_id));
        if (res?.payload?.status === 200) {
          toast.success("Lead Source Group deleted");
          cancelFormHandler();
        } else {
          errorMessage({
            payload: res.payload,
            action: "Lead Source Group",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({
          payload: error,
          action: "Lead Source Group",
          msg: "deleted",
        });
      }
    }
  };
  useEffect(() => {
    dispatch(get_lead_source_group());
    return () => {
      dispatch(leadSourceGroupReset());
    };
    // eslint-disable-next-line
  }, []);
  const filterIP = record?.records?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const columnDefs = [
    { headerName: "#", field: "counter", filterable: false },
    { headerName: "Name", field: "name", flex: 1 },
    {
      field: "actions",
      renderCell: (params) => (
        <div className="flex flex-row">
          {userPermission("Lead Source Group") && (
            <FaEdit
              onClick={openFormHandler(params.row.records)}
              className="my_navIcon"
              title="Update Lead Source Group"
            />
          )}
          {userPermission("Lead Source Group") && (
            <FaTrashAlt
              onClick={deleteRecordHandler(params.row.records._id)}
              className="my_navIcon"
              title="Delete Lead Source Group"
            />
          )}
        </div>
      ),
      width: 100,
      filterable: false,
    },
  ];
  const CustomButtons = () => {
    return (
      <div className="flex gap-x-2">
        <Button
          text="Add New"
          variant="btn_submit"
          onClick={openFormHandler(0)}
        />
        <Button
          text="Lead Source"
          variant="btn_submit"
          onClick={() =>
            navigate(
              `${location?.pathname?.replace(
                "lead_source_group",
                "lead_source"
              )}`
            )
          }
        />
      </div>
    );
  };
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add Lead Source Group"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        heading=""
        CustomButtons={CustomButtons}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          // height="auto"
          items={filterIP?.map((record, index) => {
            let counter = index + 1;
            const { name } = record;
            return {
              counter,
              records: { ...record },
              name,
            };
          })}
          pagination="No"
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default LeadSourceGroup;
