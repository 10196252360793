import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddUpdateRegistrarAccounts from "./AddUpdateRegistrarAccounts";
import { FaEdit } from "react-icons/fa";
import { get_registrar_account } from "../../../features/registrarAccountsSlice";
import { DotsLoader } from "../../../components";
import PageHeader from "../../../components/molecules/PageHeader";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import dayjs from "dayjs";

function RegistrarAccounts() {
  const [editingRecord, setEditingRecord] = useState(null);
  const [searchInput, setSearchInput] = React.useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const dispatch = useDispatch();

  const { isLoading, registrarAccount } = useSelector(
    (state) => state.registrarAccounts
  );
  const togglePasswordVisibility = (id) => {
    if (showPassword === id) {
      setShowPassword(null);
    } else {
      setShowPassword(id);
    }
  };
  useEffect(() => {
    dispatch(get_registrar_account());
  }, []);

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  const columnDefs = [
    { headerName: "#", field: "counter", width: 60 },
    { headerName: "Email", field: "auth_email", flex: 1, minWidth: 200 },
    {
      headerName: "Domain Counter",
      field: "domain_counter",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Password",
      field: "email_password",
      flex: 1,
      minWidth: 150,
      renderCell: (params) =>
        params.row.email_password ? (
          <div className="flex flex-row">
            {showPassword === params.row.id ? (
              <span>{params.value}</span>
            ) : (
              <span>********</span>
            )}
            <button
              className="ml-2 text-blue-500 underline cursor-pointer focus:outline-none"
              onClick={() => togglePasswordVisibility(params.row.id)}
            >
              {showPassword === params.row.id ? "Hide" : "Show"}
            </button>
          </div>
        ) : null,
    },
    { headerName: "Phone", field: "email_phone", flex: 1, minWidth: 150 },
    {
      headerName: "Recovery Email",
      field: "reconvery_email",
      flex: 1,
      minWidth: 150,
    },
    { headerName: "Auth Key", field: "auth_key", flex: 1, minWidth: 150 },
    { headerName: "Available", field: "is_available", flex: 1, minWidth: 100 },
    {
      headerName: "Last Updated",
      field: "last_updated",
      flex: 1,
      minWidth: 200,
    },
    { headerName: "Account Id", field: "account_id", flex: 1, minWidth: 150 },
    { headerName: "Token", field: "token", flex: 1, minWidth: 125 },
    { headerName: "Blocked", field: "is_blocked", flex: 1, minWidth: 100 },
    {
      headerName: "Blocked Reason",
      field: "blocked_reason",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Note",
      field: "note",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "actions",
      renderCell: (params) => (
        <div className="flex items-center w-full">
          <FaEdit
            onClick={openFormHandler(params.row.records)}
            className="my_navIcon cursor-pointer"
            size={20}
          />
        </div>
      ),
      width: 100,
      cellStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: 10,
      },
    },
  ];
  const data = registrarAccount?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
  return (
    <>
      {isEditing && (
        <AddUpdateRegistrarAccounts
          editingRecord={editingRecord}
          modalTitle="Add Cloudflare Account"
          onCancelForm={cancelFormHandler}
        />
      )}
      {isLoading ? <DotsLoader text="Please Wait" /> : null}
      <PageHeader
        heading="Cloudflare Accounts"
        onClick={openFormHandler(0)}
        isAllowed={true}
      />
      {!isLoading && (
        <div className="bg-white mt-3 border rounded">
          <MUIDataTable
            columnDefs={columnDefs}
            items={data?.map((record, index) => {
              let counter = index + 1;
              const {
                domain_counter,
                id,
                auth_key,
                auth_email,
                account_id,
                token,
                is_blocked,
                blocked_reason,
                email_password,
                email_phone,
                reconvery_email,
                note,
                last_updated,
                is_available,
              } = record;
              return {
                counter,
                records: record,
                domain_counter,
                id,
                auth_key,
                auth_email,
                is_available: is_available ? "Yes" : "No",
                last_updated: last_updated
                  ? dayjs(record.last_updated).format("ddd, MMM D, YYYY h:mm A")
                  : null,
                account_id,
                token,
                is_blocked: is_blocked ? "Yes" : "No",
                blocked_reason,
                email_password,
                email_phone,
                reconvery_email,
                note,
              };
            })}
            isLoading={isLoading}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            pagination="No"
          />
        </div>
      )}
    </>
  );
}

export default RegistrarAccounts;
