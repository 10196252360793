import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import api from "../../../services/api";
import { get_alternate_domains_phone } from "../../../features/domainCenterSlice";
import { DotsLoader } from "../../../components";
import PageHeader from "../../../components/molecules/PageHeader";
function AlternateDomainPhone() {
  const { isLoading, alternatePhoneArea } = useSelector(
    (state) => state.domainCenter
  );
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  useEffect(() => {
    dispatch(get_alternate_domains_phone());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    {
      field: "areacode",
      headerName: "Area Codes",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Alternate Code",
      field: "alternate_code",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Active",
      field: "isActive",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "deleted_on",
      headerName: "Delete On",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Created At",
      field: "created_at",
      flex: 1,
      minWidth: 100,
    },
  ];
  const data = alternatePhoneArea?.records?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });

  const handleUpdatePhone = async () => {
    const c = window.confirm(
      `Are you sure want to update alternate phone list?`
    );
    if (!c) {
      return;
    }
    const array = data?.map(({ alternate_code }) => +alternate_code);
    setIsLoader(true);
    try {
      const res = await api.post(
        "api/domains/filter_alternate_area_codes_by_phone",
        { alternate_area_codes: array }
      );
      if (res.status === 200) {
        toast.success("Alternate phone list updated successfully");
        dispatch(get_alternate_domains_phone());
      } else {
        toast.error("Alternate phone list couldn't be updated");
      }
      setIsLoader(false);
    } catch (err) {
      toast.error("Alternate phone list couldn't be updated");
      console.log("🚀 ~ file: Modal.jsx:79 ~ handleSubmit ~ err:", err);
      setIsLoader(false);
    }
  };

  return (
    <div>
      {isLoading || isLoader ? <DotsLoader text="Please Wait" /> : null}

      <PageHeader
        heading="Alternate Domains Phone"
        onClick={handleUpdatePhone}
        isAllowed={true}
        buttonTitle={"Filter alternate Phone"}
        addIcon="No"
      />
      <div className="bg-white !my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={data?.map((item, index) => {
            let counter = index + 1;
            const {
              areacode,
              alternate_code,
              deleted_on,
              created_at,
              isActive,
            } = item;
            return {
              records: { ...item, id: index + 1 },
              counter,
              areacode,
              alternate_code,
              deleted_on: deleted_on
                ? dayjs(deleted_on).format("ddd, MMM D, YYYY h:mm A")
                : null,
              created_at: created_at
                ? dayjs(created_at).format("ddd, MMM D, YYYY h:mm A")
                : null,
              isActive: isActive ? "Yes" : "No",
            };
          })}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          pagination="No"
          totalItems={
            alternatePhoneArea?.totalItems
              ? alternatePhoneArea?.totalItems
              : alternatePhoneArea?.length
          }
        />
      </div>
    </div>
  );
}

export default AlternateDomainPhone;
