import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button } from "@mui/material";
import { FaSearch } from "react-icons/fa";
import PageHeader from "../../../components/molecules/PageHeader";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import dayjs from "dayjs";
import {
  cloudflareDomainsReset,
  get_cloudflare_domains,
} from "../../../features/cloudflareSlice";
import AddUpdateEmailsForm from "./AddUpdateEmailsForm";
import { toast } from "react-toastify";
import userPermission from "../../../util/userPermission";
import EmailsListModal from "./EmailsList";
import { get_industry_drd } from "../../../features/IndustrySlice";
const Cloudflare = () => {
  const dispatch = useDispatch();
  const { isLoading, cloudflareDomains } = useSelector(
    (state) => state.cloudflare
  );
  const [email_list, setEmail_list] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { industryDrd } = useSelector((state) => state.industry);
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const [selectedData, setSelectedData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    dispatch(
      get_cloudflare_domains({
        filters: queryOptions,
        page: 1,
        size: paginationModel.pageSize,
      })
    );
  };
  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      dispatch(
        get_cloudflare_domains({
          filters: {
            groupOp: "",
            rules: [],
          },
          page: 1,
          size: paginationModel.pageSize,
        })
      );
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator,
          data: rule.value ? rule.value : null,
        },
      ];
    });
    // ?.toLowerCase()?.includes(searchInput?.toLowerCase())
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch(
      get_cloudflare_domains({ page: 1, size: paginationModel.pageSize })
    );
    dispatch(get_industry_drd());
    return () => {
      dispatch(cloudflareDomainsReset());
    };
    // eslint-disable-next-line
  }, []);
  const domainList = cloudflareDomains?.records?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const openFormHandler = () => {
    // setEditingRecord(record);
    setIsEditing(true);
  };
  const cancelFormHandler = () => {
    // setEditingRecord(null);
    setIsEditing(false);
  };
  const onSingleSelect = ({ checked, data }) => {
    try {
      if (checked) {
        setSelectedData((prevSelectedData) => {
          const updatedSelectedData = [...prevSelectedData, data];
          return updatedSelectedData;
        });
      } else {
        setSelectedData((prevSelectedRecord) => {
          const updatedSelectedRecord = prevSelectedRecord?.filter(
            (domain) => domain?.id !== data?.id
          );
          return updatedSelectedRecord;
        });
      }
    } catch (err) {
      console.log("🚀 ~ file: index.jsx:116 ~ onSingleSelect ~ err:", err);
    }
  };
  const onSelectAll = (checked) => {
    if (checked) {
      const updatedArray = domainList?.map((item) => item);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };
  const isSelected = (domain) => {
    if (selectedData?.length > 0) {
      if (selectedData?.filter(({ id }) => id === domain.id).length > 0) {
        return true;
      }
    }
    return false;
  };
  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          checked={selectedData?.length === domainList?.length}
        />
      ),
      filterable: false,
      sortable: false,
      width: 60,
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={isSelected(params.row.records)}
          onChange={(e) =>
            onSingleSelect({
              checked: e.target.checked,
              data: params.row.records,
            })
          }
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
        />
      ),
      disableColumnMenu: true,
    },
    { headerName: "#", field: "counter", width: 80, filterable: false },
    {
      field: "domain",
      headerName: "Domain",
      renderCell: (params) => {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${params?.row.domain}`}
            className="text-[#000] hover:text-blue-600 hover:underline no-underline"
          >
            {params?.row.domain}
          </a>
        );
      },
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Industry",
      field: "industry_name",
      flex: 1,
      type: "singleSelect",
      getOptionValue: (value) => value?.name,
      getOptionLabel: (value) => value.name,
      valueOptions: industryDrd,
      renderCell: (params) => params.value.industry_name,
      valueGetter: (params) => params.row.industry_name,
      valueFormatter: (params) => params.value.industry_name,
    },
    {
      field: "city",
      headerName: "City",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    { headerName: "State", field: "state", flex: 1 },
    {
      headerName: "Emails",
      field: "id",
      flex: 1,
      minWidth: 100,
      renderCell: (params) =>
        params?.row?.email_list ? (
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            onClick={() =>
              setEmail_list(params?.row?.records?.email_list?.split(","))
            }
          >
            {params?.row?.email_list ? params?.row?.email_list : 0}
          </span>
        ) : (
          <span>0</span>
        ),
    },
    { headerName: "Created At", field: "created_at", flex: 1 },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_cloudflare_domains({
        filters: queryOptions,
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <Button
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Apply filter
        </Button>
      </GridToolbarContainer>
    );
  }
  return (
    <>
      <PageHeader
        route="Setting > Industries"
        heading="Cloudflare Domains"
        onClick={() => {
          if (selectedData?.length) {
            openFormHandler();
          } else {
            toast.error("Please select the domains");
          }
        }}
        isAllowed={userPermission("Add Industry")}
        buttonTitle="Add cloud email"
      />
      {email_list?.length > 0 ? (
        <EmailsListModal
          onCancelForm={() => setEmail_list(null)}
          emails={email_list}
        />
      ) : null}
      {isEditing ? (
        <AddUpdateEmailsForm
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          onCancelForm={cancelFormHandler}
          isSelected={isSelected}
          onSingleSelect={onSingleSelect}
          paginationModel={paginationModel}
          queryOptions={queryOptions}
        />
      ) : null}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={domainList?.map((record, index) => {
            const counter = index + 1;
            const {
              id,
              keyword,
              created_at,
              domain,
              state,
              industry_name,
              city,
              email_list,
            } = record;
            return {
              records: { ...record, _id: id },
              id,
              counter,
              keyword,
              domain,
              created_at: dayjs(created_at).format("ddd, MMM D, YYYY h:mm A"),
              state,
              industry_name,
              city,
              email_list: email_list?.split(",")?.length,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={cloudflareDomains?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          onFilterModelChange={onFilterChange}
          CustomToolbar={CustomToolbar}
          columnVisibilityModel={{ has_monthly_searched: false }}
        />
      </div>
    </>
  );
};

export default Cloudflare;
