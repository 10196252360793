import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createGscEmailAccounts,
  deleteGscEmailAccounts,
  getGscEmailAccounts,
  updateGscEmailAccounts,
} from "../services/googleEmailService";

const initialState = {
  isError: false,
  isLoading: false,
  emailAccounts: [],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Google Seach Console
export const get_gsc_email_accounts = createAsyncThunk(
  "get_gsc_email_accounts",
  async (thunkAPI) => {
    try {
      return await getGscEmailAccounts();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Google Search Console
export const create_gsc_email_accounts = createAsyncThunk(
  "create_gsc_email_accounts",
  async (data, thunkAPI) => {
    try {
      return await createGscEmailAccounts(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Google Search Console
export const update_gsc_email_accounts = createAsyncThunk(
  "update_gsc_email_accounts",
  async (data, thunkAPI) => {
    try {
      return await updateGscEmailAccounts(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Google Search Console
export const delete_gsc_email_accounts = createAsyncThunk(
  "delete_gsc_email_accounts",
  async (id, thunkAPI) => {
    try {
      return await deleteGscEmailAccounts(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const googleEmailSlice = createSlice({
  name: "googleEmailSlice",
  initialState,
  reducers: {
    googleConsoleReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.emailAccounts = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_gsc_email_accounts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_gsc_email_accounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.emailAccounts = action.payload?.data;
      })
      .addCase(get_gsc_email_accounts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.emailAccounts = [];
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(create_gsc_email_accounts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_gsc_email_accounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.emailAccounts?.push(action.payload.data);
      })
      .addCase(create_gsc_email_accounts.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_gsc_email_accounts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_gsc_email_accounts.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.emailAccounts?.findIndex(
          ({ id }) => id === action?.payload?.data?.id
        );
        state.emailAccounts[result] = {
          ...state.emailAccounts[result],
          ...action.payload.data,
        };
      })
      .addCase(update_gsc_email_accounts.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(delete_gsc_email_accounts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_gsc_email_accounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.emailAccounts = state.emailAccounts?.filter(
          ({ id }) => id !== +action.payload.data
        );
      })
      .addCase(delete_gsc_email_accounts.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { googleConsoleReset } = googleEmailSlice.actions;
export default googleEmailSlice.reducer;
