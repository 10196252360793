import React, { useState, useRef } from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { HexColorPicker } from "react-colorful";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import {
  create_lead_status,
  update_lead_status,
} from "../../features/leadStatusSlice";
import { filterPayload } from "../../util/filterPayload";
function useVisible(initialIsVisible) {
  const [isPalette, setIsPalette] = useState(initialIsVisible);
  const inputRef = useRef(null);
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setIsPalette(false);
    }
  };

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsPalette(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { inputRef, isPalette, setIsPalette };
}

const AddUpdateForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  const { isLoading } = useSelector((state) => state.leadStatus);
  const { inputRef, isPalette, setIsPalette } = useVisible(false);
  const dispatch = useDispatch();
  const [errors, setErrors] = React.useState([]);

  let initialValues = {
    title: "",
    color: "",
    milestone: "",
    // parent_id: null,
    lead_state: "",
    waiting_period: {
      day: null,
      hours: null,
      minutes: null,
    },
    action_period: {
      day: null,
      hours: null,
      minutes: null,
    },
  };

  if (editingRecord) {
    const {
      _id,
      title,
      color,
      milestone,
      // parent_id,
      lead_state,
      waiting_period,
      action_period,
    } = editingRecord;
    initialValues = {
      id: _id,
      title,
      color,
      milestone,
      // parent_id,
      lead_state,
      waiting_period,
      action_period,
    };
  }

  const formik = useFormik({
    initialValues,

    validateOnBlur: true,

    // validationSchema: LeadStatusSchema,

    onSubmit: async (values) => {
      const filteredValues = filterPayload(values);
      if (!editingRecord) {
        try {
          const res = await dispatch(create_lead_status(filteredValues));
          if (res?.payload?.status === 200) {
            toast.success("Lead Status created");
            onCancelForm();
          } else {
            errorMessage({
              payload: res.payload,
              setErrors: setErrors,
              action: "Lead Status",
              msg: "created",
            });
          }
        } catch (error) {
          errorMessage({
            payload: error,
            setErrors: setErrors,
            action: "Lead Status",
            msg: "created",
          });
        }
      } else {
        try {
          const res = await dispatch(update_lead_status(filteredValues));
          if (res?.payload?.status === 200) {
            toast.success("Lead Status updated");
            onCancelForm();
          } else {
            errorMessage({
              payload: res.payload,
              setErrors: setErrors,
              action: "Lead Status",
              msg: "updated",
            });
          }
        } catch (error) {
          errorMessage({
            payload: error,
            setErrors: setErrors,
            action: "Lead Status",
            msg: "updated",
          });
        }
      }
    },
  });
  const basisOption = [
    { label: "None", value: "None" },
    { label: "Contact", value: "Contact" },
    { label: "Qualification", value: "Qualification" },
    { label: "Conversion", value: "Conversion" },
  ];
  const statesOption = [
    { label: "Lead", value: "Lead" },
    { label: "Deal", value: "Deal" },
    { label: "Fulfillment", value: "Fulfillment" },
    { label: "Order", value: "Order" },
  ];
  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form className="mt-4">
          <FormInput
            errors={errors}
            name="title"
            label="Title"
            formik={formik}
          />
          {/* <div className="my-3">
            <FormSelectInput
              errors={errors}
              name="parent_id"
              label="Parent Status"
              formik={formik}
              options={record?.filter(({ parent_id }) => !parent_id)}
              valueProp="_id"
              labelProp="title"
            />
          </div> */}
          <div className="flex flex-row items-center my-3">
            <div className="w-full ">
              <FormInput
                errors={errors}
                name="color"
                label="Color"
                formik={formik}
                onClick={() => {
                  setIsPalette(!isPalette);
                }}
                readOnly={true}
                placeholder="Click here"
                className="!cursor-pointer"
                // onFocus={() => setIsPalette(!isPalette)}
              />
            </div>
            {formik?.values?.color && (
              <div
                className="w-10 h-10 flex border-2 rounded-sm items-center justify-center cursor-pointer -mt-1"
                onClick={() => setIsPalette(!isPalette)}
              >
                <div
                  className="w-9 h-9 rounded"
                  style={{ backgroundColor: formik?.values?.color }}
                />
              </div>
            )}

            <div></div>
          </div>
          {isPalette && (
            <div ref={inputRef} className="w-[200px]">
              <HexColorPicker
                color={formik.values.color}
                onChange={(e) => formik.setFieldValue("color", e)}
              />
            </div>
          )}

          <div className="my-3">
            <FormSelectInput
              errors={errors}
              name="milestone"
              label="Milestone"
              formik={formik}
              options={basisOption}
              valueProp="value"
              labelProp="label"
            />
          </div>
          <div className="my-3">
            <FormSelectInput
              errors={errors}
              name="lead_state"
              label="Lead State"
              formik={formik}
              options={statesOption}
              valueProp="value"
              labelProp="label"
            />
          </div>
          <div className="border !border-[#a9a9a9] rounded md:h- relative">
            <label
              htmlFor={"waiting_period"}
              className={`small !bg-[#f5f5f5] text-[rgba(0,_0,_0,_0.6)] absolute left-[0] top-0 font-normal text-base truncate max-w-[calc(133%-24px)] translate-x- -translate-y-[14px] scale-75 px-1`}
            >
              {"Waiting Period"}
            </label>
            <div className=" grid grid-cols-3 gap-x-2 px-2 py-3">
              <FormInput
                errors={errors}
                name="waiting_period.day"
                label="Day"
                formik={formik}
                value={formik.values?.waiting_period?.day}
              />
              <FormInput
                errors={errors}
                name="waiting_period.hours"
                label="Hours"
                formik={formik}
                value={formik.values?.waiting_period?.hours}
              />
              <FormInput
                errors={errors}
                name="waiting_period.minutes"
                label="Minutes"
                formik={formik}
                value={formik.values?.waiting_period?.minutes}
              />
            </div>
          </div>
          <div className="border !border-[#a9a9a9] rounded md:h- relative mt-3">
            <label
              htmlFor={"action_period"}
              className={`small !bg-[#f5f5f5] text-[rgba(0,_0,_0,_0.6)] absolute left-[0] top-0 font-normal text-base truncate max-w-[calc(133%-24px)] translate-x- -translate-y-[14px] scale-75 px-1`}
            >
              {"Action Period"}
            </label>
            <div className=" grid grid-cols-3 gap-x-2 px-2 py-3">
              <FormInput
                errors={errors}
                name="action_period.day"
                label="Day"
                formik={formik}
                value={formik.values?.action_period?.day}
              />
              <FormInput
                errors={errors}
                name="action_period.hours"
                label="Hours"
                formik={formik}
                value={formik.values?.action_period?.hours}
              />
              <FormInput
                errors={errors}
                name="action_period.minutes"
                label="Minutes"
                formik={formik}
                value={formik.values?.action_period?.minutes}
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddUpdateForm;
