import React, { useEffect, useState } from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import AddUpdateForm from "./AddUpdateForm";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PageHeader from "../../components/molecules/PageHeader";
import errorMessage from "../../util/errorMessage";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { Button } from "../../components";
import { useNavigate, useLocation } from "react-router-dom";
import {
  delete_lead_action,
  get_lead_action,
} from "../../features/leadActionSlice";

const LeadActions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoading, record } = useSelector((state) => state.leadAction);
  const dispatch = useDispatch();

  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState("");

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_lead_action(record_id));
        if (res?.payload?.status === 200) {
          toast.success("Lead Action deleted");
          dispatch(get_lead_action());
        } else {
          errorMessage({
            payload: res.payload,
            action: "Lead Action",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({ payload: error, action: "Lead Action", msg: "deleted" });
      }
    }
  };

  useEffect(() => {
    dispatch(get_lead_action());
    // eslint-disable-next-line
  }, []);
  const status = record?.filter((record) =>
    record?.title?.includes(searchText)
  );
  const columnDefs = [
    { headerName: "#", field: "counter", width: 60 },
    { headerName: "Title", field: "title", flex: 1, minWidth: 300 },
    {
      headerName: "Comment Required",
      field: "comment_required",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Contact Attempt",
      field: "contact_attempted",
      flex: 1,
      minWidth: 150,
    },
    { headerName: "Milestone", field: "milestone", flex: 1, minWidth: 200 },
    {
      headerName: "Redisribute Lead (Program)",
      field: "redistribute_lead_using_program",
      flex: 1,
      minWidth: 200,
    },
    // { headerName: "Milestone", field: "calendar_event_required", flex: 1, minWidth: 200 },
    {
      field: "actions",
      renderCell: (params) => (
        <div>
          <FaEdit
            onClick={openFormHandler(params.row.records)}
            className="my_navIcon"
            title="Update Lead Action"
          />
          <FaTrashAlt
            onClick={deleteRecordHandler(params.row.records._id)}
            className="my_navIcon"
            title="Delete Lead Action"
          />
        </div>
      ),
    },
  ];
  const CustomButtons = () => {
    return (
      <div className="flex gap-x-2">
        <Button
          text="Add New"
          variant="btn_submit"
          onClick={openFormHandler(0)}
        />
        <Button
          text="Manage Lead Status"
          variant="btn_submit"
          onClick={() =>
            navigate(
              `${location?.pathname?.replace("lead_actions", "lead_status")}`
            )
          }
        />
      </div>
    );
  };
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add Lead Action"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        route="Setting > Lead Action"
        heading=""
        CustomButtons={CustomButtons}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={status?.map((record, index) => {
            let counter = index + 1;
            const {
              title,
              comment_required,
              milestone,
              contact_attempted,
              redistribute_lead_using_program,
            } = record;
            return {
              counter,
              title,
              comment_required: comment_required ? "Yes" : "No",
              contact_attempted: contact_attempted ? "Yes" : "No",
              redistribute_lead_using_program,
              milestone,
              records: record,
            };
          })}
          pagination={"No"}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          searchable="No"
          showCount="No"
          height="76vh"
        />
      </div>
    </>
  );
};

export default LeadActions;
