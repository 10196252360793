import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button } from "@mui/material";
import { FaPlus, FaSearch, FaSync, FaMinus } from "react-icons/fa";
import PageHeader from "../../../components/molecules/PageHeader";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import dayjs from "dayjs";
import { get_industry_drd } from "../../../features/IndustrySlice";
import * as XLSX from "xlsx";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { DotsLoader, Loader } from "../../../components";
import AutoRenewModal from "./AutoRenewModal";
import AddGSCEmailsModal from "./AddGSCEmailsModal";
import {
  add_detach_domain,
  set_test_domains,
} from "../../../features/domainCenterSlice";
import { GiBreakingChain } from "react-icons/gi";
const DomainsListing = () => {
  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState("total_domains");
  const [searchText, setSearchText] = useState("");
  const [selectedTagFilter, setSelectedTagFilter] = useState("All");
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const [renewStatus, setRenewStatus] = useState(1);
  const [renewModal, setRenewModal] = useState(false);
  const [gscEmailModal, setGscEmailModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const { industryDrd } = useSelector((state) => state.industry);
  const { isLoading } = useSelector((state) => state.domainCenter);
  const [loading, setLoading] = useState(false);
  const [domains, setDomains] = useState();
  const get_domains = async ({ filters, page, size }) => {
    setLoading(true);
    try {
      const res = await api.get(`/api/vendors/domain_prime_time/domains`, {
        params: {
          filters,
          page,
          size,
        },
      });
      if (res.status === 200) {
        setDomains(res.data);
      }
      setLoading(false);
    } catch (error) {
      console.log("🚀 ~ constget_domains=async ~ error:", error);
      setLoading(false);
    }
  };
  const addToDetachDomain = async () => {
    let domains = [];
    const c = window.confirm(`Are you sure want to Add?`);
    // eslint-disable-next-line
    selectedData.map((val) => {
      domains = [...domains, val.domain];
    });
    if (c) {
      try {
        await dispatch(add_detach_domain(domains));
      } catch (error) {
        console.log("ImagesList.jsx:48 ~ deleteRecordHandler ~ error", error);
        // toast.error("Template Image couldn't be deleted");
      }
    }
  };
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    get_domains({
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
    });
  };
  const onFilterChange = React.useCallback((filterModel) => {
    if (filterModel?.items?.length === 0) {
      get_domains({
        filters: {
          groupOp: "",
          rules: [],
        },
        page: 1,
        size: paginationModel.pageSize,
      });
    }
    let ruless = [];
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator,
          data: rule.value || rule.value === 0 ? rule.value : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    get_domains({
      filters: queryOptions,
      page: paginationModel?.page,
      size: paginationModel.pageSize,
    });
    dispatch(get_industry_drd());
    // eslint-disable-next-line
  }, []);
  const domainList = domains?.records?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const customOperatorsYesorNo = [
    {
      value: "yes",
      label: "Yes",
      getApplyFilterFn: (filterItem, column) => {
        return (params) =>
          String(params.value)
            .toLowerCase()
            .includes(String(filterItem.operator).toLowerCase());
      },
    },
    {
      value: "no",
      label: "No",
      getApplyFilterFn: (filterItem, column) => {
        return (params) =>
          String(params.value)
            .toLowerCase()
            .includes(String(filterItem.operator).toLowerCase());
      },
    },
    // Add more custom operators as needed
  ];
  const statusOption = [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ];
  const deleteStatusOptions = [
    { value: "ActiveDomain", label: "Active Domain" },
    { value: "PendingToImgDelete", label: "Pending To Img Delete" },
    { value: "PendingToDataDelete", label: "Pending To Data Delete" },
    { value: "Deleted", label: "Deleted" },
  ];

  const onSelectAll = (checked) => {
    if (isLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    if (checked) {
      const updatedArray = domainList?.map((item) => item);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };
  const isSelected = (item) => {
    if (selectedData?.length > 0) {
      if (
        selectedData?.filter(({ domain }) => domain === item.domain).length > 0
      ) {
        return true;
      }
    }
    return false;
  };
  const onSingleSelect = ({ checked, data }) => {
    if (isLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    try {
      if (checked) {
        setSelectedData((prevSelectedData) => {
          const updatedSelectedData = [...prevSelectedData, data];
          return updatedSelectedData;
        });
      } else {
        setSelectedData((prevSelectedRecord) => {
          const updatedSelectedRecord = prevSelectedRecord?.filter(
            ({ domain }) => domain !== data?.domain
          );
          return updatedSelectedRecord;
        });
      }
    } catch (err) {
      console.log("🚀 ~ file: index.jsx:116 ~ onSingleSelect ~ err:", err);
    }
  };
  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          checked={selectedData?.length === domainList?.length}
        />
      ),
      filterable: false,
      sortable: false,
      width: 60,
      renderCell: (params) => (
        <div className="flex justify-center w-full items-center">
          {isSelected(params.row.records) && isLoader ? (
            <Loader />
          ) : (
            <input
              type="checkbox"
              checked={isSelected(params.row.records)}
              onChange={(e) =>
                onSingleSelect({
                  checked: e.target.checked,
                  data: params.row.records,
                })
              }
              className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
            />
          )}
        </div>
      ),
      disableColumnMenu: true,
    },
    {
      field: "counter",
      headerName: "#",
      type: "string",
      flex: 1,
      width: 60,
      filterable: false,
    },
    {
      field: "domain",
      headerName: "Domain",
      renderCell: (params) => {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${params?.value}`}
            className="text-black no-underline hover:underline cursor-poi"
          >
            {params?.value}
          </a>
        );
      },
      flex: 1,
      minWidth: 250,
    },
    {
      field: "gsc_email",
      headerName: "GSC Email",
      type: "string",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "gsc_server",
      headerName: "GSC Server",
      type: "string",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "gsc_server_sync_status",
      headerName: "GSC Sync Status",
      type: "string",
      flex: 1,
      minWidth: 150,
    },

    {
      field: "expiration_date",
      headerName: "Expiration",
      flex: 1,
      minWidth: 95,
    },
    {
      field: "is_expired",
      headerName: "Expired",
      flex: 1,
      minWidth: 70,
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option.label,
      valueOptions: statusOption,
      renderCell: (params) => params.row.isExpired || "",
      valueGetter: (params) => params.row.is_expired,
      valueFormatter: (params) => params.value,
      align: "center",
    },
    {
      field: "auto_renew",
      headerName: "Auto Renew",
      flex: 1,
      minWidth: 90,
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option.label,
      valueOptions: statusOption,
      renderCell: (params) => params.row.auto_renew || "",
      valueGetter: (params) => params.row?.records?.auto_renew,
      valueFormatter: (params) => params.value,
      align: "center",
    },
    {
      field: "industry_name",
      headerName: "Industry",
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      getOptionValue: (value) => value?.name,
      getOptionLabel: (value) => value.name,
      valueOptions: industryDrd,
      renderCell: (params) => params.value.industry_name,
      valueGetter: (params) => params.row.industry_name,
      valueFormatter: (params) => params.value.industry_name,
    },
    {
      field: "delete_status",
      headerName: "Delete Status",
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value.label,
      valueOptions: deleteStatusOptions,
      renderCell: (params) => params.value.delete_status,
      valueGetter: (params) => params.row.delete_status,
      valueFormatter: (params) => params.value.delete_status,
    },

    {
      field: "runner_name",
      headerName: "Runner Name",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "phone",
      headerName: "Phone",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "city",
      headerName: "City",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "county",
      headerName: "County",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "state",
      headerName: "State",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "zip_counts",
      headerName: "Zip Count",
      type: "number",
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "is_test_domain",
      headerName: "Test Domain",
      type: "string",
      flex: 1,
      align: "center",
      minWidth: 100,
      filterOperators: customOperatorsYesorNo,
    },
    {
      field: "auth_email",
      headerName: "Auth Email",
      type: "string",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "cloudflare_zone_id",
      headerName: "Cloudflare Zone ID",
      type: "string",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "deployed_on_host",
      headerName: "Deployed on",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "google_search_console_credential",
      headerName: "Google Credential",
      type: "string",
      flex: 1,
      minWidth: 150,
      align: "center",
      filterOperators: customOperatorsYesorNo,
    },
    {
      field: "is_detached",
      headerName: "Detached",
      type: "string",
      flex: 1,
      minWidth: 100,
      align: "center",
      filterOperators: customOperatorsYesorNo,
    },
    {
      field: "purchase_date",
      headerName: "Purchased date",
      // type: "date",
      flex: 1,
      minWidth: 200,
      filterable: false,
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    get_domains({
      filters: queryOptions,
      page: +params.page + 1,
      size: +params.pageSize,
    });
  };
  const sendRequests = async () => {
    if (!selectedData || selectedData.length === 0) {
      toast.error("Please select the domain");
      return;
    }
    const isExpiredDomain = selectedData?.filter(
      ({ is_expired }) => is_expired === 0
    );
    if (isExpiredDomain?.length > 0)
      return toast.error("Please select the expired domains only");
    const c = window.confirm(
      `Are you sure want to remove ${selectedData?.length} domains?`
    );
    if (!c) return;
    const payload = selectedData?.map(({ domain }) => domain);
    console.log("🚀 ~ sendRequests ~ payload:", payload);
    setLoading(true);
    try {
      const response = await api.post(
        "/api/expire_domains/remove_domains",
        payload
      );
      if (response.status === 200) {
        toast.success(`Domains removed successfully`);
        setSelectedData([]);
        get_domains({
          filters: queryOptions,
          page: paginationModel.page,
          size: paginationModel.pageSize,
        });
      } else {
        toast.error(`Domains couldn't be removed`);
      }
    } catch (error) {
      console.error("Error in sendRequests:", error);
      toast.error(`Domains couldn't be removed`);
    }
    setLoading(false);
  };

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="!text-[#042a42]">
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <Button
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className="!text-[#042a42]"
        >
          Apply filter
        </Button>
        <Button
          variant="text"
          onClick={() => {
            const alreadyAdded = selectedData?.filter(
              ({ gsc_email_account_id }) => !!gsc_email_account_id
            );
            if (!selectedData?.length) {
              return toast.error("Please select the domains");
            } else if (alreadyAdded?.length === selectedData?.length) {
              return toast.error(
                "All domains were already added to the email."
              );
            } else if (alreadyAdded?.length) {
              const c = window.confirm(
                `${alreadyAdded?.length} of the selected domains are already attached with email address, do you want to proceed for rest of the domains?`
              );
              if (!c) return;
              setSelectedData((prevState) =>
                prevState.filter(
                  ({ gsc_email_account_id }) => !gsc_email_account_id
                )
              );
              toast.info(
                `${alreadyAdded?.length} domains removed that were already attached with email address.`
              );
            }
            setGscEmailModal(true);
          }}
          startIcon={<FaPlus size={14} />}
          sx={{ fontSize: "0.8125rem" }}
          className="!text-[#042a42]"
        >
          Add GSC Email
        </Button>
        <Button
          variant="text"
          onClick={handleConfirm}
          startIcon={<FaSync size={14} />}
          sx={{ fontSize: "0.8125rem" }}
          className="!text-[#042a42]"
        >
          Set Test Domains
        </Button>
        <Button
          variant="text"
          onClick={addToDetachDomain}
          startIcon={<GiBreakingChain size={14} />}
          sx={{ fontSize: "0.8125rem" }}
          className="!text-[#042a42]"
        >
          Add to detach
        </Button>
        <Button
          variant="text"
          onClick={sendRequests}
          startIcon={<FaMinus size={14} />}
          sx={{ fontSize: "0.8125rem" }}
          className="!text-[#042a42]"
        >
          Remove Domains
        </Button>
      </GridToolbarContainer>
    );
  }
  function exportToExcel() {
    const data = domains?.records;
    const rows = [
      [
        "#",
        "Industry",
        "State",
        "City",
        "Domain",
        "Expiration Date",
        "Expired",
        "Auto Renew",
        "Vendor Count",
        "Population",
        "Keywords",
        "In/Out Area",
        "Google Position",
        "Search Volume",
        "Keyword Difficulty",
        "KW Total",
      ], // Add headings
    ];
    data.forEach((item, index) => {
      const {
        monthly_searched,
        keyword,
        domain,
        expiration_date,
        population,
        state,
        city,
        industry_name,
        is_inarea,
        service_count,
        vendors,
        google_rank,
      } = item;
      const monthlySearched =
        monthly_searched === "-1" ? "Not Available" : monthly_searched;
      const difficulty =
        item?.difficulty === "-1" ? "Not Available" : item?.difficulty;
      const counter = index + 1;
      const vendors_count = vendors ? vendors?.length : 0;
      const inArea = is_inarea ? "In Area" : "Out Area";
      const is_expired = item.is_expired ? "Yes" : "No";
      const auto_renew = item.auto_renew ? "Yes" : "No";
      rows.push([
        counter,
        industry_name,
        state,
        city,
        domain,
        auto_renew,
        expiration_date,
        is_expired,
        vendors_count,
        population,
        keyword,
        inArea,
        google_rank,
        monthlySearched,
        difficulty,
        service_count,
      ]); // Add a row without domains
    });

    const worksheet = XLSX.utils.aoa_to_sheet(rows);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const fileName = "Domain Ranking.xlsx";

    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    if (typeof window.navigator.msSaveBlob !== "undefined") {
      // For IE browser
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      // For other browsers
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100);
    }
  }

  async function sendRenewRequests() {
    if (isLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    if (selectedData?.length === 0) {
      return toast.error("Please select the domain");
    }
    const c = window.confirm(
      `Are you sure want to renew the selected domains?`
    );
    if (!c) return;
    setIsLoader(true);
    const selectedDataCopy = [...selectedData];
    for (const item of selectedDataCopy) {
      try {
        const response = await api.post(
          "api/vendors/domain_prime_time/renew_domain",
          { domain: `${item?.domain}` }
        );
        if (response.status === 200) {
          toast.success(`${response?.data}`);
          setSelectedData((prevState) =>
            prevState.filter(({ domain }) => domain !== item?.domain)
          );
        } else {
          toast.error(`"${item?.domain}" ${response.data}`);
          break;
        }
      } catch (error) {
        console.log("🚀 ~ file: index.jsx:252 ~ sendRequests ~ error:", error);
        toast.error(
          `"${item?.domain}" ${error?.response?.data || "Getting an error"}`
        );
        break;
      }
    }

    setIsLoader(false);
    // Clear the selectedData after processing all requests
  }
  async function sendAutoRenewRequests() {
    if (isLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    if (selectedData?.length === 0) {
      return toast.error("Please select the domain");
    }
    setIsLoader(true);
    setRenewModal(false);
    const selectedDataCopy = [...selectedData];
    for (const item of selectedDataCopy) {
      try {
        const response = await api.post(
          "api/vendors/domain_prime_time/set_auto_renew_domain",
          { domain: `${item?.domain}`, set_auto_renew: renewStatus }
        );
        if (response.status === 200) {
          toast.success(`${response?.data}`);
          setSelectedData((prevState) =>
            prevState.filter(({ domain }) => domain !== item?.domain)
          );
        } else {
          toast.error(`"${item?.domain}" ${response.data}`);
          break;
        }
      } catch (error) {
        console.log("🚀 ~ file: index.jsx:252 ~ sendRequests ~ error:", error);
        toast.error(
          `"${item?.domain}" ${error?.response?.data || "Getting an error"}`
        );
        break;
      }
    }

    setIsLoader(false);
    // Clear the selectedData after processing all requests
    // setSelectedData([]);
  }
  const handleConfirm = async () => {
    if (selectedData?.length > 1) {
      return toast.error(
        "Please select only 1 domain for creating testing domain."
      );
    }
    const c = window.confirm(
      `Are you sure you want to mark these domains as test domains?`
    );
    if (!c) {
      return;
    }
    try {
      const domainValues = selectedData.map((item) => item.domain);
      const res = await dispatch(
        set_test_domains({ domains: domainValues, is_test_domain: 1 })
      );
      if (res.payload.status === 200) {
        setSelectedData([]);
        toast.success("Domains testing status updated");
      } else {
        toast.error(res.payload || "Some error occurred");
      }
    } catch (error) {
      toast.error("Some error occurred");
      console.log("🚀 ~ file: index.jsx:163 ~ setTestDomains ~ error:", error);
    }
  };
  const filter = [
    "All",
    ".us",
    ".top",
    ".com",
    ".net",
    ".info",
    ".online",
    ".site",
    ".business",
    ".click",
    ".company",
    ".link",
    ".buzz",
  ];
  const TagFilter = () => {
    return (
      <ul className="flex flex-wrap items-center w-ful !mb-0 !pl-0 ml-3">
        {filter?.map((val, index) => {
          return (
            <li
              className={`text-[#2d4665] py-[6px] px-[6px]  cursor-pointer ${
                selectedTagFilter === val
                  ? "border-b-2 border-primary-100 text-primary-100"
                  : ""
              }`}
              key={index}
              onClick={() => handleTagFilter(val)}
            >
              {val}
            </li>
          );
        })}
      </ul>
    );
  };
  const handleTagFilter = (val) => {
    const query =
      val === "All"
        ? {
            groupOp: "AND",
            rules: [],
          }
        : {
            groupOp: "AND",
            rules: [
              {
                field: "domain",
                op: "cn",
                data: val,
              },
            ],
          };
    get_domains({
      filters: query,
      page: 1,
      size: paginationModel.pageSize,
    });
    setSelectedTagFilter(val);
  };
  return (
    <>
      <PageHeader
        route="Setting > Industries"
        heading="Purchased Domains"
        CustomComponent={TagFilter}
        className="col-span-12 w-full !justify-start items-center"
      />
      {isLoading ? <DotsLoader /> : null}
      {renewModal ? (
        <AutoRenewModal
          onClose={() => {
            setRenewStatus(null);
            setRenewModal(false);
          }}
          setRenewStatus={setRenewStatus}
          renewStatus={renewStatus}
          handleConfirm={sendAutoRenewRequests}
        />
      ) : null}
      {gscEmailModal ? (
        <AddGSCEmailsModal
          onClose={() => {
            setGscEmailModal(false);
          }}
          selectedData={selectedData}
          get_domain={() => {
            get_domains({
              filters: queryOptions,
              page: paginationModel.page,
              size: paginationModel.pageSize,
            });
            setSelectedData([]);
          }}
        />
      ) : null}
      <div className="flex-cols md:flex flex-wrap justify-start space-x-2 mx-0.5">
        <button
          className={`flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[19%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
        ${
          selectedFilter === "total_domains"
            ? "border-l-2 border-primary-100"
            : ""
        }
        `}
          onClick={() => {
            const filter = {
              groupOp: "",
              rules: [],
            };
            setQueryOptions(filter);
            setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
            get_domains({
              filters: filter,
              page: 1,
              size: paginationModel.pageSize,
            });
            setSelectedFilter("total_domains");
          }}
        >
          <span className="text-sm font-pop">Total Domains</span>
          <span className="text-base font-medium font-pop">
            {domains?.summary?.total_domains
              ? domains?.summary?.total_domains?.toLocaleString()
              : 0}
          </span>
        </button>
        <button
          className={`flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[19%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
        ${
          selectedFilter === "total_expired"
            ? "border-l-2 border-primary-100"
            : ""
        }
        `}
          onClick={() => {
            const filter = {
              groupOp: "AND",
              rules: [
                {
                  field: "is_expired",
                  op: "eq",
                  data: 1,
                },
              ],
            };
            setQueryOptions(filter);
            setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
            get_domains({
              filters: filter,
              page: 1,
              size: paginationModel.pageSize,
            });
            setSelectedFilter("total_expired");
          }}
        >
          <span className="text-sm font-pop">Total Expired</span>
          <span className="text-base font-medium font-pop">
            {domains?.summary?.total_expired
              ? domains?.summary?.total_expired?.toLocaleString()
              : 0}
          </span>
        </button>
      </div>
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={domainList?.map((record, index) => {
            const counter = index + 1;
            const {
              id,
              domain,
              expiration_date,
              industry_name,
              phone,
              city,
              state,
              zip_counts,
              cloudflare_zone_id,
              county,
              deployed_on_host,
              runner_name,
              purchase_date,
              is_expired,
              gsc_email,
              gsc_server,
              gsc_server_sync_status,
              auth_email = "",
              delete_status,
            } = record;
            const is_detached = record.is_detached ? "Yes" : "No";
            const isExpired = record.is_expired ? "Yes" : "No";
            const auto_renew = record.auto_renew ? "Yes" : "No";
            const is_test_domain = record.is_test_domain ? "Yes" : "No";
            const google_search_console_credential =
              record?.google_search_console_credential ? "Yes" : "No";
            return {
              records: { ...record, _id: id },
              counter,
              id,
              domain,
              expiration_date,
              auto_renew,
              is_expired,
              state,
              city,
              zip_counts,
              cloudflare_zone_id,
              phone,
              is_detached,
              industry_name,
              county,
              is_test_domain,
              google_search_console_credential,
              deployed_on_host,
              runner_name,
              auth_email,
              isExpired,
              gsc_email,
              gsc_server,
              gsc_server_sync_status,
              delete_status,
              purchase_date: dayjs(purchase_date).format(
                "ddd, MMM D, YYYY h:mm A"
              ),
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={domains?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={loading}
          onFilterModelChange={onFilterChange}
          CustomToolbar={CustomToolbar}
          columnVisibilityModel={{ has_monthly_searched: false }}
          handleExport={exportToExcel}
        />
      </div>
    </>
  );
};

export default DomainsListing;
