import React, { useEffect, useState } from "react";
import { FaEdit, FaSearch, FaTrashAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import {
  get_lead_source,
  delete_lead_source,
  leadSourceReset,
} from "../../features/leadSourceSlice";
import { toast } from "react-toastify";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import errorMessage from "../../util/errorMessage";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { get_lead_source_group } from "../../features/leadSourceGroupSlice";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button as MUIButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../components";
const LeadSource = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoading, record } = useSelector((state) => state?.leadSource);
  const leadSourceGroup = useSelector(
    (state) => state?.leadSourceGroup?.record?.records
  );
  const [searchText, setSearchText] = useState("");
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const [sortingModel, setSortingModel] = React.useState({
    sort_field: null,
    sort_by: null,
  });
  const cancelFormHandler = () => () => {
    console.log("test");
    navigate("/settings/lead_source");
  };
  const openFormHandler = (record) => () => {
    navigate("/settings/lead_source/leadSource_AddEdit", {
      state: record,
    });
  };
  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_lead_source(record_id));
        if (res?.payload?.status === 200) {
          toast.success("Lead Source deleted");
          cancelFormHandler();
        } else {
          errorMessage({
            payload: res.payload,
            action: "Lead Source",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({ payload: error, action: "Lead Source", msg: "deleted" });
      }
    }
  };
  useEffect(() => {
    dispatch(get_lead_source({ page: 1, size: paginationModel.pageSize }));
    dispatch(get_lead_source_group());
    return () => {
      dispatch(leadSourceReset());
    };
    // eslint-disable-next-line
  }, []);
  const filterIP = record?.records?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const handleSortModelChange = (params) => {
    setSortingModel({
      sort_field: params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
    dispatch(
      get_lead_source({
        filters: queryOptions,
        page: paginationModel.page,
        size: paginationModel.pageSize,
        sort_field: params[0]?.field,
        sort_by: params[0]?.sort || "default",
      })
    );
  };
  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      setPaginationModel({ ...paginationModel, page: 1 });
      dispatch(
        get_lead_source({
          ...sortingModel,
          page: 1,
          size: paginationModel.pageSize,
        })
      );
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator,
          data: rule.value ? rule.value : rule.value === 0 ? rule.value : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    dispatch(
      get_lead_source({
        ...sortingModel,
        filters: queryOptions,
        page: 1,
        size: paginationModel.pageSize,
      })
    );
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="!text-[#042a42]">
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className="!text-[#042a42]"
        >
          Apply filter
        </MUIButton>
      </GridToolbarContainer>
    );
  }
  const columnDefs = [
    { headerName: "#", field: "counter", filterable: false },
    { headerName: "Title", field: "title", flex: 1 },
    {
      headerName: "Group",
      field: "lead_source_group_id",
      flex: 1,
      type: "singleSelect",
      getOptionValue: (value) => value?._id,
      getOptionLabel: (value) => value.name,
      valueOptions: leadSourceGroup,
      renderCell: (params) => params.value.lead_source_group_id,
      valueGetter: (params) => params.row.lead_source_group_id,
      valueFormatter: (params) => params.value.lead_source_group_id,
    },
    // { headerName: "Group", field: "lead_source_group_id", flex: 1 },
    { headerName: "Provider", field: "provider", flex: 1 },
    { headerName: "Cost Per Lead", field: "cost_per_lead", flex: 1 },
    { headerName: "Active", field: "active", flex: 1 },
    {
      field: "actions",
      renderCell: (params) => (
        <div className="flex flex-row">
          {userPermission("Edit Lead Source") && (
            <FaEdit
              onClick={openFormHandler(params.row.records)}
              className="my_navIcon"
              title="Update Lead Source"
            />
          )}
          {userPermission("Delete Lead Source") && (
            <FaTrashAlt
              onClick={deleteRecordHandler(params.row.records._id)}
              className="my_navIcon"
              title="Delete Lead Source"
            />
          )}
        </div>
      ),
      width: 100,
      filterable: false,
    },
  ].filter(Boolean);
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_lead_source({
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };
  const CustomButtons = () => {
    return (
      <div className="flex gap-x-2">
        {userPermission("Write Lead Source") ? (
          <Button
            text="Add New"
            variant="btn_submit"
            onClick={openFormHandler(0)}
          />
        ) : null}

        <Button
          text="Lead Source Group"
          variant="btn_submit"
          onClick={() =>
            navigate(
              `${location?.pathname?.replace(
                "lead_source",
                "lead_source_group"
              )}`
            )
          }
        />
      </div>
    );
  };
  return (
    <>
      <PageHeader heading="" CustomButtons={CustomButtons} />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          // height="auto"
          items={filterIP?.map((record, index) => {
            let counter = index + 1;
            const {
              title,
              cost_per_lead,
              lead_source_group_id,
              provider,
              active,
            } = record;
            return {
              counter,
              records: { ...record },
              title,
              cost_per_lead,
              lead_source_group_id: lead_source_group_id?.name || "",
              provider,
              active: active ? "Active" : "InActive",
            };
          })}
          // onPaginationModelChange={handlePageChange}
          // onPageSizeChange={handlePageSizeChange}
          totalItems={record?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          onSortModelChange={handleSortModelChange}
          onFilterModelChange={onFilterChange}
          CustomToolbar={CustomToolbar}
          // searchable="No"
          pagination="No"
          showCount="No"
          height={"72vh"}
        />
      </div>
    </>
  );
};

export default LeadSource;
