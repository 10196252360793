import dayjs from "dayjs";
import React, { useState } from "react";
import { FaDownload } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Loader } from "../../../components";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import { get_recordings } from "../../../features/dialerSlice";
import api from "../../../services/api";

const LeadJobListModal = ({ onCancelForm, data }) => {
  const dispatch = useDispatch();
  const [record, setRecord] = useState([]);
  const modalRef = React.useRef(null);
  const [searchText, setSearchText] = React.useState("");
  const [isLoading, setIsloading] = React.useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [currentlyPlayingAudio, setCurrentlyPlayingAudio] =
    React.useState(null);

    const { industryDrd } = useSelector((state) => state.industry);
  const [selectedLead, setSelectedLead] = React.useState(null);
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      onCancelForm();
    }
  };
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onCancelForm();
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  const searchedRecord = record?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const sortedData = [...(searchedRecord || [])]?.sort((a, b) => {
    // Parsing dates for comparison
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);

    // Comparing parsed dates
    return dateB - dateA;
  });
  const getJobLead = async () => {
    setIsloading(true);
    setRecord([]);
    try {
      const res = await api.post(`/api/did/domain_leads_jobs`, {
        phone: data?.phone,
        type: data?.type,
      });
      if (res.status === 200) {
        setRecord(res.data);
      }
      setIsloading(false);
    } catch (err) {
      setIsloading(false);
      console.log("🚀 ~ getJobLead ~ err:", err);
    }
  };

  React.useEffect(() => {
    getJobLead();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const openNewTab = ({ id }) => {
    if (isButtonDisabled) {
      toast.info("Please wait for a second to open this job");
      return;
    }
    setIsButtonDisabled(true);
    if (data?.type === "Jobs") {
      window.open(`/jobs/update/preview/${id}`, "_blank");
    } else {
      window.open(`/leads/update/${id}`, "_blank");
    }

    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 1000);
  };
  const handlePlay = async (lead_num) => {
    setLoading(true);
    setSelectedLead(lead_num);
    try {
      const res = await dispatch(
        get_recordings({ lead_id: lead_num, type: "Lead" })
      );
      if (
        res?.payload?.status === 200 &&
        res?.payload?.data?.recordings?.length > 0
      ) {
        const result = record?.findIndex(
          ({ vicidial_id }) =>
            vicidial_id === res?.payload?.data?.recordings[0]?.vicidial_id
        );
        if (result > -1) {
          record[result] = {
            ...record[result],
            recording: res?.payload?.data?.recordings[0]?.location,
          };
        }
        const audioElement = document.getElementById(
          res?.payload?.data?.recordings[0]?.vicidial_id
        );
        if (audioElement) {
          if (currentlyPlayingAudio && currentlyPlayingAudio !== audioElement) {
            currentlyPlayingAudio.pause();
          }
          audioElement.play();
          setCurrentlyPlayingAudio(audioElement);
          setLoading(false);
        }
      } else {
        setLoading(false);
        toast.error("No Record found");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching audio URL:", error);
    }
  };
  const columnDefs = [
    {
      field: data?.type === "Jobs" ? "Job #" : "Lead #",
      sortable: true,
      valueGetter: (params) => params.row.lead_num,
      sortComparator: (v1, v2, row1, row2) => {
        return v1 - v2;
      },
      renderCell: (params) => (
        <span
          onClick={() => openNewTab({ id: params.row._id })}
          className="text-blue-600 cursor-pointer hover:underline"
        >
          {data?.type === "Jobs" ? params.row.job_number : params.row.lead_num}
        </span>
      ),
      width: 60,
    },
    { headerName: "Created By", field: "createdBy", flex: 1 },
    {
      headerName: "Recording",
      field: "recording",
      flex: 1,
      minWidth: 300,
      renderCell: (params) =>
        params.row.records.call_type !== "Manual" &&
        params.row.records.vicidial_id ? (
          <div className="relative flex items-center">
            <audio
              id={params.row.records?.vicidial_id}
              controls
              className="h-10 w-[250px] relative"
            >
              {params.row.records.recording && (
                <source
                  src={params.row.records.recording}
                  type="audio/mpeg"
                  onClick={() => handlePlay(params.row.records?.lead_num)}
                />
              )}
            </audio>
            {!params.row.records.recording ? (
              <button
                onClick={() => handlePlay(params.row.records?.lead_num)}
                className="h-[30px] w-[30px] z-[100] bg-primary-100 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center rounded-full text-white"
              >
                {loading && selectedLead === params.row.records?.lead_num ? (
                  <Loader />
                ) : null}
                <FaDownload />
              </button>
            ) : null}
          </div>
        ) : params.row.records.call_type === "Manual" ? (
          <span>Manual Lead</span>
        ) : null,
    },

    { headerName: "Name", field: "name", flex: 1 },
    { headerName: "Phone", field: "phone", flex: 1 },
    data?.type === "Jobs"
      ? {
          headerName: "Job Status",
          field: "job_status_id",
          flex: 1,
        }
      : null,
      data?.type === "Jobs"
      ? {
          headerName: "Industry",
          field: "industry_id",
          flex: 1,
        }
      : null,
    {
      headerName: "Address",
      field: "complete_pickup_address",
      width: 180,
      wrap: true,
      cellClassName: "multiline-cell",
    },
    {
      headerName: "Created",
      field: "createdAt",
      flex: 1,
      sortable: true,
      valueGetter: (params) => params.row.createdAt,
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
    },
    {
      headerName: "Status",
      field: "status",
      flex: 1,
      renderCell: (params) => (
        <div>
          {`${params.row.status}${params.row.job_number ? " | " : ""}`}
          {params.row.job_number ? (
            <a
              href={`/jobs/update/preview/${params.row._id}`}
              target="_blank"
              rel="noreferrer"
              className="text-blue-600 hover:underline cursor-pointer"
            >
              {`${params.row.job_number}`}
            </a>
          ) : null}
        </div>
      ),
    },
  ].filter(Boolean);
  const getRowHeight = (params) => {
    const rowHeight = 30; // minimum height of the row
    const cellContentHeight = 0; // you can set the height of the cell content if you want
    const data = params?.model;
    const numberOfLines = data?.complete_pickup_address
      ? Math.ceil(data?.complete_pickup_address?.length / 30)
      : 0;
    const addressHeight = rowHeight + numberOfLines * 30 + 20;
    if (data?.tags && data?.tags.length > 0) {
      const tagHeight = 20; // height of each tag
      const numTags = data.tags.length;
      if (addressHeight > rowHeight + tagHeight * numTags + cellContentHeight) {
        return addressHeight;
      } else {
        return rowHeight + tagHeight * numTags + cellContentHeight;
      }
    }
    if (addressHeight > rowHeight + cellContentHeight) {
      return addressHeight;
    } else {
      return rowHeight + cellContentHeight;
    }
  };
  return (
    <>
      <div className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-center items-center bg-black/60">
        <div className="container-fluid mt-2  text-center">
          <div
            className="inline-block bg-white w-3/4 my-0 mx-auto p-4 bg-red-600 rounded  max-h-[87vh]"
            ref={modalRef}
          >
            <header className="flex items-center justify-center shadow-lg bg-primary-100 text-white h-[50px] mb-3 text-xl">
              <h1 className="text-2xl text-white m-4 font-medium">
                {`(${data?.domain}) ${data?.type}`} List
              </h1>
            </header>
            <div className="flex flex-row justify-between">
              <input
                placeholder="Search"
                onChange={(e) => setSearchText(e.target.value)}
                className="!w-1/3 mb-2 placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md !py-2 px-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                value={searchText}
              />
              <span className="text-xl">
                {searchedRecord?.length} records found
              </span>
            </div>

            <div className="h-[50vh] mb-2">
              <MUIDataTable
                items={sortedData?.map((item, index) => {
                  const {
                    _id,
                    lead_num,
                    job_number,
                    createdBy,
                    name,
                    phone,
                    is_declined,
                    createdAt,
                    job_created,
                    recording,
                    complete_pickup_address,
                    job_status_id,
                    industry_id
                  } = item;
                  return {
                    id: _id,
                    _id,
                    counter: index + 1,
                    records: item,
                    lead_num,
                    createdBy: createdBy?.username,
                    name,
                    phone,
                    is_declined,
                    complete_pickup_address,
                    createdAt: dayjs(createdAt).format(
                      "ddd, MMM D, YYYY h:mm A"
                    ),
                    recording,
                    status: `${
                      is_declined
                        ? "Declined"
                        : job_created
                        ? "Job Created"
                        : "Open"
                    }`,
                    job_number,
                    job_status_id: job_status_id?.name,
                    industry_id:industryDrd?.find(({_id})=>industry_id===_id)?.name
                  };
                })}
                columnDefs={columnDefs}
                isLoading={isLoading}
                gridOptions={getRowHeight}
                pagination="No"
                searchable="No"
                showCount="No"
                toolbar="No"
                height="46vh"
              />
            </div>
            <Button
              text="Cancel"
              variant="btn_cancel"
              className="ml-3"
              onClick={onCancelForm}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadJobListModal;
