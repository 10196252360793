import React, { Fragment } from "react";
import FormInput from "../FormInput";
import Button from "../../atoms/Button";
import Select from "react-select";
import DateRangeComp from "../DateRangePicker";
import { FaSearch, FaAngleDown, FaAngleUp } from "react-icons/fa";

function Filter({ filterForm, onReset, formik, className, showFilter }) {
  const selectInputRef = React.useRef();
  const [isFilter, setIsFilter] = React.useState(showFilter);
  const style = {
    container: (provided) => ({
      ...provided,
      width: "100%",
      height: "40px",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 10,
    }),
    control: (provided) => ({
      ...provided,
      height: "40px",
      minHeight: "40px",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "40px",
    }),
  };
  const [dateRange, setDateRange] = React.useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const handleReset = () => {
    // Reset the date range state
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    // Add other reset logic here
    onReset(); // Assuming this resets other form states
  };
  return (
    <div className="mt-12 md:mt-2">
      <form
        className={[
          className,
          `grid grid-cols-1 gap-5 relative  ${
            !isFilter ? "border-t border-[#ccc]" : ""
          }`,
        ].join(" ")}
      >
        <div
          className="rounded-t-full absolute left-[45%] !bg-menu flex justify-center items-center !p-2 -translate-y-[100%] cursor-pointer"
          onClick={() => setIsFilter(!isFilter)}
        >
          {isFilter ? (
            <FaAngleUp size={20} color="white" />
          ) : (
            <FaAngleDown size={20} color="white" />
          )}
        </div>
        {isFilter && (
          <div className="!p-5 bg-white rounded transition duration-[2s] ease-out">
            <div className="grid grid-cols-12 gap-x-2">
              {filterForm?.map((item, index) => {
                const {
                  name,
                  placeholder,
                  isInput,
                  isSelect,
                  getOptionLabel,
                  getOptionValue,
                  options,
                  onchange,
                  isVisible,
                  type,
                  isRange,
                  value,
                } = item;
                return (
                  <Fragment key={index}>
                    {isVisible && (
                      <div className="col-span-12 md:col-span-3  md:mt-md-2">
                        {isInput && (
                          <div className="mb-2">
                            <FormInput
                              key={index}
                              name={name}
                              placeholder={placeholder}
                              formik={formik}
                              type={type}
                            />
                          </div>
                        )}
                        {isSelect && (
                          <div className="mb-2">
                            <Select
                              ref={selectInputRef}
                              key={index}
                              styles={style}
                              onChange={onchange}
                              onBlur={formik.handleBlur}
                              name={name}
                              options={options}
                              getOptionLabel={getOptionLabel}
                              getOptionValue={getOptionValue}
                              placeholder={placeholder}
                              value={value}
                            />
                          </div>
                        )}

                        {isRange && (
                          <div className="col-span-12 md:col-span-3 mb-2">
                            <DateRangeComp
                              dateRangePicker
                              inputClassName="form-control w-full bg-white focus:shadow-none border-[#a9a9a9] focus:border-2 focus:border-[#a9a9a9]"
                              pickerClassName="-translate-x-[30%]"
                              onChange={onchange}
                              value={dateRange}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </Fragment>
                );
              })}
              <div className="col-span-12 md:col-span-3 flex justify-center">
                <div className="float-right">
                  <Button
                    text={"Search"}
                    className="py-1.5 px-3 align-middle mr-2 bg-primary-100 text-white"
                    onClick={formik.handleSubmit}
                  />
                  <Button
                    text="Reset"
                    className="py-1.5 px-3 align-middle bg-menu text-white"
                    onClick={handleReset}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}

export default Filter;
