import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../../components";
import {
  create_purcahse_registrar_account,
  update_purcahse_registrar_account,
} from "../../../features/registrarAccountsSlice";
const PurchaseRegistrarModal = ({
  editingRecord,
  onCancelForm,
  modalTitle,
}) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.registrarAccounts);
  const { record } = useSelector((state) => state?.purchaseRegistrar);
  const [errors, setErrors] = React.useState([]);
  const initialValues = {
    name: "",
    purchase_registrar_id: "",
    credential: {},
    ...(editingRecord && {
      id: editingRecord.id,
      purchase_registrar_id: editingRecord.purchase_registrar_id,
      name: editingRecord.name,
      credential:
        editingRecord.credential && JSON.parse(editingRecord.credential),
    }),
  };
  const validateForm = (values) => {
    const errors = [];
    // Custom validation for the dynamic `credential` object
    if (values.credential) {
      for (const key in values.credential) {
        if (!values.credential[key]) {
          errors.push({
            [`credential.${key}`]: `credential.${key} is required`,
          });
        }
      }
    }
    // Add validation for other fields if needed
    if (!values.name) {
      errors.push({ name: "name is required" });
    }
    if (!values.purchase_registrar_id) {
      errors.push({
        purchase_registrar_id: "purchase_registrar_id is required",
      });
    }
    // Add validation for other fields if needed
    setErrors(errors);
    return errors;
  };
  const handleSubmit = async (values) => {
    const errors = validateForm(formik.values);
    if (errors && errors?.length > 0) {
      return;
    }
    const action = editingRecord
      ? update_purcahse_registrar_account
      : create_purcahse_registrar_account;
    const message = editingRecord ? "updated" : "created";
    try {
      const res = await dispatch(action(values));
      if (res?.payload?.status === 200 || res?.payload?.status === 201) {
        toast.success(`Purchase registrar ${message}`);
        onCancelForm();
      } else {
        if (Array.isArray(res.payload)) {
          setErrors(res.payload);
        } else {
          toast.error(
            res.payload
              ? res.payload
              : `Purchase registrar couldn't be ${message}`
          );
        }
      }
    } catch (error) {
      console.error(
        "🚀 ~ file: AddUpdateForm.jsx:64 ~ handleSubmit ~ error:",
        error
      );
      if (Array.isArray(error.payload)) {
        setErrors(error.payload);
      } else {
        toast.error(
          error.payload
            ? error.payload
            : `Purchase registrar couldn't be ${message}`
        );
      }
    }
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });
  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form className="mt-4">
          <div className="!mb-3">
            <FormInput
              errors={errors}
              name="name"
              label="Name"
              formik={formik}
            />
          </div>
          <div className="!mb-3">
            <FormSelectInput
              errors={errors}
              name="purchase_registrar_id"
              label="Purchase Registrar"
              formik={formik}
              options={record}
              valueProp="id"
              labelProp="name"
              onChange={(e) => {
                formik.setFieldValue("purchase_registrar_id", e);
                formik.setFieldValue(
                  "credential",
                  e &&
                    JSON.parse(
                      record?.find(({ id }) => id === +e)?.credential_key
                    )
                );
              }}
            />
          </div>
          {formik.values.credential &&
            Object.keys(formik.values.credential)?.map((key, index) => {
              return (
                <div className="!mb-3" key={index}>
                  <FormInput
                    errors={errors}
                    name={`credential.${key}`}
                    label={
                      key === "url"
                        ? "URL"
                        : key?.charAt(0)?.toUpperCase() +
                          key.slice(1)?.replaceAll("_", " ")
                    }
                    formik={formik}
                    value={formik.values.credential[key]}
                  />
                </div>
              );
            })}
        </form>
      </Modal>
    </>
  );
};

export default PurchaseRegistrarModal;
