import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import { Loader } from "../../../../components";
import { useSelector } from "react-redux";
import FormRadioGroup from "../../../../components/molecules/FormRadioGroup";
import { MultiSelect } from "primereact/multiselect";
const CreateKeywordsModal = ({ onClose, getSERPReport }) => {
  const [isLoader, setIsLoader] = useState(false);
  const industry = useSelector((state) => state.industry);
  const [payload, setPayload] = React.useState({
    industry_id: "",
    is_primary: 1,
  });
  const generateKeywords = async () => {
    if (!payload.industry_id) return toast.error("Please select the industry");
    const c = window.confirm("Are you aure want to generate the keywords?");
    if (!c) return;
    setIsLoader(true);
    try {
      const res = await api.post(
        "/api/domains/generate_domain_keyword",
        payload
      );
      if (res.status === 200) {
        toast.success(res?.data?.message || "Keyword generated successfuly");
        getSERPReport({ page: 1, pageSize: 100 });
        onClose();
      } else {
        toast.error(res?.data?.message || "Keyword couldn't be generated");
      }
      setIsLoader(false);
    } catch (err) {
      toast.error(
        err?.response?.data?.message || "Keyword couldn't be generated"
      );
      setIsLoader(false);
      console.log("🚀 ~ getSERPReport ~ err:", err);
    }
  };
  const handleBulkKeywords = () => {
    const selectedDataCopy = [...payload.industry_id];
    for (const item of selectedDataCopy) {
      try {
        api.post("/api/domains/generate_domain_keyword", {
          industry_id: item,
          is_primary: payload?.is_primary,
        });
      } catch (error) {
        console.log("🚀 ~ file: index.jsx:252 ~ sendRequests ~ error:", error);
      }
    }
  };
  async function handleBulkRegenerate() {
    if (isLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    if (payload.industry_id?.length === 0) {
      return toast.error("Please select the deleted batch");
    }
    const c = window.confirm(
      "are you sure want to regenerate the selected batches?"
    );
    if (!c) return;
    setIsLoader(true);
    const selectedDataCopy = [...payload.industry_id];
    for (const item of selectedDataCopy) {
      try {
        const response = await api.post(
          "/api/domains/generate_domain_keyword",
          { industry_id: item, is_primary: payload?.is_primary }
        );
        if (response.status === 200) {
          toast.success(`Industry ID. ${item} started successfully`);
          // setPayload((prevState) =>
          //   prevState.filter(
          //     ({ valueserp_id }) => valueserp_id !== item?.valueserp_id
          //   )
          // );
        } else {
          toast.error(
            `Industry ID. ${item} ${response?.data?.message}` ||
              `Industry ID. ${item} couldn't be started`
          );
        }
      } catch (error) {
        console.log("🚀 ~ file: index.jsx:252 ~ sendRequets ~ error:", error);
        toast.error(
          `Batch No. ${item?.valueserp_id} ${error?.response?.data?.message}` ||
            `Batch No. ${item?.valueserp_id} couldn't be started`
        );
      }
    }
    setIsLoader(false);
    getSERPReport({ page: 1, pageSize: 100 });
    onClose();
    // setSelectedData([]);
  }
  const sortedIndustries = [...(industry?.record?.records || [])].sort((a, b) =>
    a?.name?.localeCompare(b?.name)
  );
  return (
    <>
      <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-40 bg-black/60">
        <div className="bg-white p-4 rounded-lg shadow-lg relative w-auto max-w-xl">
          <div className="text-base font-medium !mb-6">
            <span className="font-semibold text-lg font_pop text-red-600">
              Note:{" "}
            </span>{" "}
            We can generate primary keywords for 10,000 domains at a time and
            for services, we can generate keywords for 500 domains at a time.
          </div>
          <div className="flex items-center grid grid-cols-2 gap-x-6">
            <MultiSelect
              // styles={style}
              placeholder="Industry"
              options={sortedIndustries}
              optionLabel="name"
              optionValue="_id"
              filter
              maxSelectedLabels={2}
              value={payload?.industry_id}
              onChange={(e) => {
                setPayload({
                  ...payload,
                  industry_id: e.value,
                });
              }}
              className="h-[38px] w-full !rounded-[4px] border !border-[#e5e7eb] hover:border-[#ced4da]"
            />
            {/* <select
              onChange={(e) => {
                setPayload({
                  ...payload,
                  industry_id: e.target.value,
                });
              }}
              value={payload?.industry_id}
              className="border p-1 rounded h-[35px] w-full"
            >
              <option value="">Select Industry</option>
              {sortedIndustries?.map((option) => (
                <option
                  key={option?._id}
                  value={option?._id}
                  className="flex justify-between"
                >
                  {option?.name}
                </option>
              ))}
            </select> */}
            <div className="px-2 py-0 relative flex items-center w-full">
              <FormRadioGroup
                name="is_primary"
                label="Is Primary"
                labelProp="label"
                valueProp="value"
                options={[
                  { label: "Yes", value: 1 },
                  { label: "No", value: 0 },
                ]}
                isCheck={+payload?.is_primary}
                type="text"
                isHorizontal
                isBorder
                isFloat
                className={"!w-full"}
                customStyle="w-full"
                isOnChange={(value) => {
                  setPayload({ ...payload, is_primary: value });
                }}
              />
            </div>
          </div>
          <div className="flex justify-end !mt-4">
            {isLoader ? (
              <Loader />
            ) : (
              <>
                <button
                  className="px-4 py-2 mr-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  onClick={handleBulkKeywords}
                >
                  OK
                </button>
                <button
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateKeywordsModal;
