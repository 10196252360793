import React, { useEffect, useState } from "react";
import AddUpdateForm from "./AddUpdateForm";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { DotsLoader } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import errorMessage from "../../util/errorMessage";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import dayjs from "dayjs";
import { delete_registrar, get_registrar } from "../../features/registrarSlice";
const Registrar = () => {
  const dispatch = useDispatch();
  const { isLoading, record } = useSelector((state) => state.registrar);

  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState("");

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_registrar(record_id));
        if (res?.payload?.status === 200) {
          toast.success("Registrar deleted");
          cancelFormHandler();
        } else {
          errorMessage({
            payload: res.payload,
            action: "Registrar",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({ payload: error, action: "Registrar", msg: "deleted" });
      }
    }
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  useEffect(() => {
    dispatch(get_registrar());
    // eslint-disable-next-line
  }, []);
  const registrars = record?.filter((registrar) =>
    registrar?.name?.toLowerCase().includes(searchText.toLowerCase())
  );
  const columnDefs = [
    { headerName: "#", field: "counter", width: 60 },
    { headerName: "Created At", field: "created_at", flex: 1 },
    { headerName: "Name", field: "name", flex: 1 },
    { headerName: "Company", field: "company", flex: 1 },
    { headerName: "Available After", field: "availabe_after", minWidth: 200 },
    { headerName: "Status", field: "status", width: 80 },

    { headerName: "Customer ID", field: "customer_id", flex: 1 },
    { headerName: "Auth", field: "auth", flex: 1 },
    { headerName: "Type", field: "type", flex: 1 },
    { headerName: "API URL", field: "api_url", flex: 1 },
    { headerName: "Active", field: "active", flex: 1 },
    // { headerName: "Name", field: "name", flex: 1 },
    // { headerName: "Created At", field: "created_at", flex: 1 },
    {
      field: "actions",
      renderCell: (params) => (
        params.row.records.active ? (
        <div>
          {userPermission("Domain Center") && (
            <FaEdit
              onClick={openFormHandler(params.row.records)}
              className="my_navIcon"
              title="Update Registrar"
              
            />
          )}
          {userPermission("Domain Center") && (
            <FaTrashAlt
              onClick={deleteRecordHandler(params.row.records._id)}
              className="my_navIcon"
              title="Delete Registrar"
            />
          )}
        </div>
        ):null
      ),
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  };
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add Registrar"
          onCancelForm={cancelFormHandler}
        />
      )}
      {isLoading && <DotsLoader />}

      <PageHeader
        route="Setting > Industries"
        heading="Registrar Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Domain Center")}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={registrars?.map((record, index) => {
            let counter = index + 1;
            let name = record?.name;
            let created_at = dayjs(record?.created_at).format(
              "ddd, MMM D, YYYY h:mm A"
            );
            let api_url = record?.api_url;
            let auth = record?.auth;
            let type = record?.type;
            let active = record?.active?"Yes":"No";
            let customer_id = record?.customer_id;
            let company= record?.company;
            let availabe_after= record?.availabe_after?dayjs(record?.availabe_after).format(
              "ddd, MMM D, YYYY h:mm A"):"";
            let status= record?.status;
            return {
              records: { ...record },
              counter,
              name,
              created_at,
              api_url,
              auth,
              type,
              customer_id,
              active,
              status,
              company,
              availabe_after
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={record?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default Registrar;
