import React from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import {
  create_user_group,
  get_user_groups,
  update_user_group,
} from "../../features/userGroupSlice";
const AddUpdateForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  const { isLoading, record } = useSelector((state) => state.userGroups);
  const dispatch = useDispatch();
  const [errors, setErrors] = React.useState([]);
  let initialValues = {
    title: "",
    parent_group_id: "",
    active: true,
  };
  const activeOptions = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
  ];
  if (editingRecord) {
    const { group_id, title, parent_group_id, active } = editingRecord;
    initialValues = { id: group_id, title, parent_group_id, active };
  }

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    // validationSchema: TagSchema,

    onSubmit: async (values) => {
      if (!editingRecord) {
        try {
          const res = await dispatch(create_user_group(values));
          if (res?.payload?.status === 200) {
            toast.success("User Group created successfully");
            dispatch(get_user_groups());
            onCancelForm();
          } else {
            errorMessage({
              payload: res.payload,
              setErrors: setErrors,
              action: "User Group",
              msg: "created",
            });
          }
        } catch (error) {
          errorMessage({
            payload: error,
            setErrors: setErrors,
            action: "User Group",
            msg: "created",
          });
        }
      } else {
        try {
          const res = await dispatch(update_user_group(values));
          if (res?.payload?.status === 200) {
            toast.success("User Group updated successfully");
            dispatch(get_user_groups());
            onCancelForm();
          } else {
            errorMessage({
              payload: res.payload,
              setErrors: setErrors,
              action: "User Group",
              msg: "updated",
            });
          }
        } catch (error) {
          errorMessage({
            payload: error,
            setErrors: setErrors,
            action: "User Group",
            msg: "updated",
          });
        }
      }
    },
  });
  function flattenGroups(groups, parentPath = []) {
    let flattenedGroups = [];
    groups.forEach((group) => {
      const path = [...parentPath, group.title];
      flattenedGroups.push({ ...group, path });
      if (group.children && group.children.length > 0) {
        flattenedGroups = flattenedGroups.concat(
          flattenGroups(group.children, path)
        );
      }
    });
    return flattenedGroups;
  }
  const updatedFlattenedGroups = flattenGroups(record);
  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form className="mt-3">
          <FormInput
            errors={errors}
            name="title"
            label="Title"
            formik={formik}
          />
          <div className="mt-3">
            <FormSelectInput
              errors={errors}
              name="parent_group_id"
              label="Parent Group"
              formik={formik}
              options={updatedFlattenedGroups}
              valueProp="group_id"
              labelProp="title"
            />
          </div>
          <div className="mt-3">
            <FormSelectInput
              errors={errors}
              name="active"
              label="Active"
              formik={formik}
              options={activeOptions}
              convertor={(value) => value === "true"}
              valueProp="value"
              labelProp="label"
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddUpdateForm;
