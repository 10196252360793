import React, { useState, useCallback } from "react";
import { toast } from "react-toastify";
import { Button, DotsLoader, Modal } from "../../components";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import api from "../../services/api";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";
const TollfreeListingModal = ({
  editingRecord,
  onCancelForm,
  modalTitle,
  tollfree,
  updatedNationSites,
  setUpdatedNationSites,
  setIsTollfree,
}) => {
  const [searchText, setSearchText] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [file, setFile] = React.useState(null);
  const [isImport, setIsImport] = React.useState(null);
  const [importRecord, setImportRecord] = React.useState([]);
  const [fileError, setFileError] = useState("");
  const onDrop = useCallback((acceptedFiles) => {
    let files = acceptedFiles[0];
    setFile(files);
    handleFile(files);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
  });
  const onCloseImportForm = () => {
    setFile(null);
    setImportRecord([]);
    setFileError("");
    setIsImport(false);
  };
  const handleFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setImportRecord([]);
      const workbook = XLSX.read(e.target.result, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 }); // Get data as an array of arrays

      // Check if the first row (headers) includes 'AreaCode' and 'Tollfree'
      if (
        data.length > 0 &&
        data[0].includes("AreaCode") &&
        data[0].includes("Tollfree")
      ) {
        // Proceed with transforming data, excluding the header row
        const jsonData = XLSX.utils
          .sheet_to_json(worksheet, { header: 1 })
          .slice(1)
          .map((row) => {
            return {
              AreaCode: row[data[0].indexOf("AreaCode")],
              Tollfree: row[data[0].indexOf("Tollfree")],
            };
          });
        const transformedData = transformData(jsonData);
        setImportRecord(transformedData);
        setFileError("");
      } else {
        // Set error state indicating invalid file
        toast.error(
          "Invalid file. Please ensure the file contains AreaCode and Tollfree headers."
        );
        setFileError(
          "Invalid file. Please ensure the file contains AreaCode and Tollfree headers."
        );
      }
    };
    reader.onerror = (error) => {
      // Handle file reading errors
      setImportRecord([]);
      toast.error("Error reading file: " + error);
    };
    reader.readAsBinaryString(file);
  };

  const transformData = (data) => {
    return data
      .filter((row) => row.AreaCode || row.Tollfree) // Skip records with both columns empty
      .map((row) => ({
        custom_one: row.AreaCode || null, // Convert empty or 'NaN' values to null
        did_pattern: row.Tollfree || null,
      }));
  };
  const columnDefs = [
    { headerName: "#", field: "counter", filterable: false },
    {
      headerName: "Area Code",
      field: "custom_one",
      minWidth: 150,
      editable: true,
    },
    { headerName: "Tollfree", field: "did_pattern", flex: 1 },
  ];
  const searchedtollfree = tollfree?.filter((item) =>
    item?.did_pattern?.toLowerCase().includes(searchText.toLowerCase())
  );
  const handleRowEditStart = (_params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (_params, event) => {
    event.defaultMuiPrevented = true;
  };
  const handleSaveUpdate = async (newRow, oldRow) => {
    if (newRow?.custom_one === oldRow?.custom_one) {
      return newRow;
    }
    setisLoading(true);
    const updatedRow = newRow;
    try {
      api
        .post(
          `https://call225.logicalcrm.com/admin/public/api/dids/tmp_bulk_update`,
          [
            {
              did_pattern: updatedRow?.did_pattern,
              custom_one: updatedRow?.custom_one,
            },
          ]
        )
        .then(() => {
          toast.success("Area code update successfully");
          const updatedData = (updatedNationSites || [])?.map((domain) => {
            if (domain?.domain === updatedRow?.did_description) {
              return {
                ...domain,
                tollfree: (domain?.tollfree || []).map((item) => {
                  if (item?.did_pattern === updatedRow?.did_pattern) {
                    return { ...item, custom_one: updatedRow?.custom_one };
                  } else {
                    return item;
                  }
                }),
              };
            } else {
              return domain;
            }
          });
          const updatedTollfree = (tollfree || [])?.map((tollfree) => {
            if (tollfree?.did_pattern === updatedRow?.did_pattern) {
              return { ...tollfree, custom_one: updatedRow?.custom_one };
            } else {
              return tollfree;
            }
          });
          setUpdatedNationSites(updatedData);
          setIsTollfree(updatedTollfree);
        })
        .catch((error) => {
          console.log("🚀 ~ handleSaveUpdate ~ error:", error);
          toast.error("Area code couldn't be updated");
        })
        .finally(() => {
          setisLoading(false);
        });
    } catch (error) {
      toast.error("Area code couldn't be updated");
    }
    return updatedRow;
  };
  const exportData = () => {
    const rows = [
      ["AreaCode", "Tollfree"], // Add headings
    ];

    tollfree?.forEach((item) => {
      const { did_pattern, custom_one } = item;
      rows.push([custom_one, did_pattern]); // Add subsequent rows with empty state and city
    });
    const worksheet = XLSX.utils.aoa_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const fileName = "tollfree_area_codes.xlsx";
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    if (typeof window.navigator.msSaveBlob !== "undefined") {
      // For IE browser
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      // For other browsers
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100);
    }
  };
  const ImportExportButtons = () => {
    return (
      <div className="flex space-x-2">
        <Button text="Export" variant="btn_submit" onClick={exportData} />
        <Button
          text="Import"
          variant="btn_submit"
          onClick={() => setIsImport(true)}
        />
      </div>
    );
  };
  const handleUpdateBulk = async () => {
    try {
      if (fileError) return toast.error(fileError);
      // const updateRecord = importRecord?.filter(
      //   ({ did_pattern, custom_one }) => !!custom_one && !!did_pattern
      // );
      const updateRecord = [...importRecord];
      setisLoading(true);
      const res = await api.post(
        `https://call225.logicalcrm.com/admin/public/api/dids/tmp_bulk_update`,
        updateRecord
      );
      if (res.status === 200) {
        // Update local state to reflect the changes made by the bulk update
        const updatedData = (updatedNationSites || []).map((domain) => ({
          ...domain,
          tollfree: (domain.tollfree || []).map((item) => {
            const updatedItem = updateRecord.find(
              (ur) => ur.did_pattern === item.did_pattern
            );
            return updatedItem
              ? { ...item, custom_one: updatedItem.custom_one }
              : item;
          }),
        }));

        const updatedTollfree = (tollfree || []).map((item) => {
          const updatedItem = updateRecord.find(
            (ur) => ur.did_pattern === item.did_pattern
          );
          return updatedItem
            ? { ...item, custom_one: updatedItem.custom_one }
            : item;
        });

        setUpdatedNationSites(updatedData);
        setIsTollfree(updatedTollfree);
        onCloseImportForm();
        toast.success("Area codes updated successfully");
      } else {
        toast.error("Area codes couldn't be updated");
      }
      setisLoading(false);
    } catch (err) {
      console.log("🚀 ~ handleUpdateBulk ~ err:", err);
      toast.error("Area codes couldn't be updated");
      setisLoading(false);
    }
  };
  return (
    <Modal
      isUpdate={!!editingRecord}
      title={`${isImport?"Update tollfree area codes":modalTitle} for ${tollfree[0]?.did_description}`}
      onCancelModal={onCancelForm}
      onClick={onCancelForm}
      modalClass={"!min-w-[60vw]"}
      // CloseOnly
      hideButtons
    >
      {isLoading ? <DotsLoader /> : null}
      {isImport ? (
        <div className>
          {file ? (
            <div className="text-base font-medium">
              {`Total Tollfree Numbers: `}
              <span className="font-semibold text-lg font_pop text-menu">
                {importRecord?.length}
              </span>
              {` | With AreaCode: `}

              <span className="font-semibold text-lg font_pop text-menu">
                {importRecord?.filter(({ custom_one }) => !!custom_one)?.length}
              </span>
              {` | Without AreaCode: `}
              <span className="font-semibold text-lg font_pop text-menu">
                {importRecord?.filter(({ custom_one }) => !custom_one)?.length}
              </span>
            </div>
          ) : null}

          <div
            className={`w-full flex justify-center border-2 border-gray-300 border-dashed rounded-md !p-2 hover:border-gray-400 focus:outline-none ${
              isDragActive ? "bg-gray-100" : "bg-white"
            }`}
            {...getRootProps()}
          >
            <label
              className={`flex justify-center w-full ${"h-[80px]"} px-4 transition appearance-none cursor-pointer`}
            >
              {file ? (
                <span className="flex items-center space-x-2">
                  <div className="flex flex-col text-center">
                    <span className="font-medium text-gray-600">
                      File Name: {file?.name}
                    </span>
                    <span className="text-gray-600" onClick={open}>
                      Size:{" "}
                      {file?.size > 1000 && file.size < 1000000
                        ? (file.size / 1000).toFixed(2) + " KB"
                        : (file.size / 1000000).toFixed(2) + " MB"}
                    </span>
                  </div>
                </span>
              ) : (
                <span className="flex items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-gray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                  <span className="font-medium text-gray-600">
                    Drop files to Attach, or{" "}
                    <span className="text-blue-600 underline" onClick={open}>
                      browse
                    </span>
                  </span>
                </span>
              )}
              <input {...getInputProps()} />
            </label>
          </div>
          <span className="text-red-600 font-pop font-medium">{fileError}</span>
          <div className="flex space-x-2 mt-3 justify-end items-center">
            <Button
              text="Cancel"
              variant="btn_cancel"
              onClick={onCloseImportForm}
            />
            <Button
              text="Submit"
              variant="btn_submit"
              onClick={handleUpdateBulk}
            />
          </div>
        </div>
      ) : (
        <div className="bg-white mt-3 border rounded">
          <MUIDataTable
            height="51vh"
            columnDefs={columnDefs}
            items={searchedtollfree?.map((record, index) => {
              let counter = index + 1;
              const { did_pattern, custom_one, did_description } = record;
              return {
                counter,
                did_pattern,
                custom_one,
                id: index + 1,
                did_description,
              };
            })}
            totalItems={tollfree?.length}
            searchText={searchText}
            setSearchText={setSearchText}
            pagination="No"
            toolbar="No"
            hideFooter
            editMode="cell"
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            onProcessRowUpdateError={console.error}
            processRowUpdate={handleSaveUpdate}
            LineOne={ImportExportButtons}
          />
        </div>
      )}
    </Modal>
  );
};

export default TollfreeListingModal;
