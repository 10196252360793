import React, { useEffect, useState } from "react";
import AddUpdateNationSitesModal from "./AddUpdateNationSites";
import { FaEdit, FaEye, FaPlus } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import {
  get_nation_site,
  nationSiteReset,
} from "../../features/nationSitesSlice";
import { get_industry } from "../../features/IndustrySlice";
import VendorsModal from "./VendorsModal";
import api from "../../services/api";
import { DotsLoader } from "../../components";
import DIDsListingModal from "./DIDsListModal";
import TollfreeListingModal from "./tollfreeListModal";
import * as XLSX from "xlsx";
import { BiExport, BiImport } from "react-icons/bi";
import ImportVendors from "./AddTollfreeDidForm";
import ZipCodeListingModal from "./ZipCodeListModal";
const NationSites = () => {
  const dispatch = useDispatch();
  const { isLoading, nationSites } = useSelector((state) => state?.nation);
  const industry = useSelector((state) => state?.industry);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedNationSites, setUpdatedNationSites] = useState([]);
  const [isVendors, setIsVendors] = useState(false);
  const [isDids, setIsDids] = useState(null);
  const [zipCodes, setZipCodes] = useState(null);
  const [isTollfree, setIsTollfree] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [isImportVendors, setIsImportVendors] = useState(false);

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
    setIsVendors(false);
    setIsImportVendors(false);
  };
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  const openVendorFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsVendors(true);
  };
  const openImportVendorFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsImportVendors(true);
  };
  useEffect(() => {
    dispatch(get_nation_site());
    dispatch(get_industry({ data: { page: 1, size: 100 } }));
    return () => {
      dispatch(nationSiteReset());
    };
    // eslint-disable-next-line
  }, []);
  const sites = updatedNationSites?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });

  const get_vendors = async ({ id, domain }) => {
    setIsLoader(true);
    try {
      const res = await api.get(`api/nation/vendor/${id}`);
      if (res.status === 200) {
        exportToExcel({ record: res?.data, domain });
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      console.log("🚀 ~ VendorsModal ~ err:", err);
    }
  };

  const exportToExcel = ({ record, domain }) => {
    const data = [["Name"], ...record?.map(({ name }) => [name])];
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${domain?.replaceAll(".", "-")}-vendors.xlsx`);
  };
  const columnDefs = [
    { headerName: "#", field: "counter", filterable: false },
    { headerName: "Domain", field: "domain", flex: 1 },
    { headerName: "Industry", field: "industry_id", flex: 1 },
    {
      headerName: "Domain Zips Count",
      field: "domain_zips_count",
      flex: 1,
      renderCell: (params) => (
        <div className="flex items-center">
          {params.row.domain_zips_count ? (
            <span
              className="text-blue-600 hover:underline cursor-pointer"
              onClick={() =>
                setZipCodes({
                  industry_id: params.row.industryID,
                  zip_type: "domain_zips",
                  domain: params.row.domain,
                })
              }
            >
              {params.row.domain_zips_count}
            </span>
          ) : (
            <span>0</span>
          )}
        </div>
      ),
    },
    {
      headerName: "InArea Zips Count",
      field: "in_area_zip_count",
      flex: 1,
      renderCell: (params) => (
        <div className="flex items-center">
          {params.row.in_area_zip_count ? (
            <span
              className="text-blue-600 hover:underline cursor-pointer"
              onClick={() =>
                setZipCodes({
                  industry_id: params.row.industryID,
                  zip_type: "in_area_zips",
                  domain: params.row.domain,
                })
              }
            >
              {params.row.in_area_zip_count}
            </span>
          ) : (
            <span>0</span>
          )}
        </div>
      ),
    },
    {
      headerName: "OutArea Zips Count",
      field: "out_area_zip_count",
      flex: 1,
      renderCell: (params) => (
        <div className="flex items-center">
          {params.row.out_area_zip_count ? (
            <span
              className="text-blue-600 hover:underline cursor-pointer"
              onClick={() =>
                setZipCodes({
                  industry_id: params.row.industryID,
                  zip_type: "out_area_zips",
                  domain: params.row.domain,
                })
              }
            >
              {params.row.out_area_zip_count}
            </span>
          ) : (
            <span>0</span>
          )}
        </div>
      ),
    },
    {
      headerName: "Additional Zips Count",
      field: "additional_zips_count",
      flex: 1,
      renderCell: (params) => (
        <div className="flex items-center">
          {params.row.additional_zips_count ? (
            <span
              className="text-blue-600 hover:underline cursor-pointer"
              onClick={() =>
                setZipCodes({
                  industry_id: params.row.industryID,
                  zip_type: "additional_zips",
                  domain: params.row.domain,
                })
              }
            >
              {params.row.additional_zips_count}
            </span>
          ) : (
            <span>0</span>
          )}
        </div>
      ),
    },
    {
      headerName: "Vendors",
      field: "vendor_count",
      flex: 1,
      renderCell: (params) => (
        <div className="flex items-center">
          {params.row.vendor_count ? (
            <div className="flex justify-between items-center w-full">
              <span className="bg-[#93c5fd] py-1 px-2 !rounded">
                {params.row.vendor_count}
              </span>
              {userPermission("List National Site Vendor") ? (
                <button
                  className="text-[#343434] hover:text-primary-100 ml-2"
                  onClick={openVendorFormHandler(params.row.records)}
                  title="Show vendor list"
                >
                  <FaEye size={18} />
                </button>
              ) : null}
              <button
                className="text-[#343434] hover:text-primary-100 ml-2"
                onClick={() =>
                  get_vendors({
                    id: params.row.records?.id,
                    domain: params?.row?.domain,
                  })
                }
                title="Export vendor list"
              >
                <BiExport size={18} />
              </button>
              <button
                className="text-[#343434] hover:text-primary-100 ml-2"
                onClick={openImportVendorFormHandler(params.row.records)}
                title="Import vendors"
              >
                <BiImport size={18} />
              </button>
            </div>
          ) : (
            <>
              <span>Not Added</span>
              {userPermission("Add National Site Vendor") ? (
                <button
                  className="text-[#343434] hover:text-primary-100 ml-2"
                  onClick={openVendorFormHandler(params.row.records)}
                  title="Add new vendor"
                >
                  <FaPlus />
                </button>
              ) : null}
              <button
                className="text-[#343434] hover:text-primary-100 ml-2"
                onClick={openImportVendorFormHandler(params.row.records)}
                title="Import vendors"
              >
                <BiImport size={18} />
              </button>
            </>
          )}
        </div>
      ),
    },
    {
      headerName: "DIDs",
      field: "did_count",
      flex: 1,
      renderCell: (params) => (
        <div className="flex items-center">
          {params.row.did_count ? (
            <span
              className="text-blue-600 hover:underline cursor-pointer"
              onClick={() => setIsDids(params?.row?.records?.dids)}
            >
              {params.row.did_count}
            </span>
          ) : (
            <span>0</span>
          )}
        </div>
      ),
    },
    {
      headerName: "Tollfree",
      field: "tollfree_count",
      flex: 1,
      renderCell: (params) => (
        <div className="flex items-center">
          {params.row.tollfree_count ? (
            <span
              className="text-blue-600 hover:underline cursor-pointer"
              onClick={() => setIsTollfree(params?.row?.records?.tollfree)}
            >
              {params.row.tollfree_count}
            </span>
          ) : (
            <span>0</span>
          )}
        </div>
      ),
    },
    {
      field: "actions",
      renderCell: (params) => (
        <div className="flex flex-row">
          {userPermission("Update National Site") ? (
            <FaEdit
              onClick={openFormHandler(params.row.records)}
              className="my_navIcon"
              title="Update National Sites"
            />
          ) : null}
        </div>
      ),
      width: 100,
      filterable: false,
    },
  ];

  useEffect(() => {
    if (nationSites?.length) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationSites]);

  const makeApiCall = async (query) => {
    const payload = {
      sqlwhere: query,
      page: 1,
      size: 50000,
    };

    const response = await api.post("/api/cc/dids/get_dids", payload);
    return response.data;
  };

  const fetchData = async () => {
    setIsLoader(true);
    try {
      // Prepare an array of promises for parallel API calls
      const apiRequests = nationSites?.map(async (item) => {
        const didQuery = `did_description LIKE '${item.domain}' AND ( did_pattern NOT LIKE '1833%' AND did_pattern NOT LIKE '1844%' AND did_pattern NOT LIKE '1855%' AND did_pattern NOT LIKE '1866%' AND did_pattern NOT LIKE '1877%' AND did_pattern NOT LIKE '1888%' AND did_pattern NOT LIKE '800%')`;
        const tollfreeQuery = `did_description LIKE '${item.domain}' AND ( did_pattern LIKE '1833%' OR did_pattern LIKE '1844%' OR did_pattern LIKE '1855%' OR did_pattern LIKE '1866%' OR did_pattern LIKE '1877%' OR did_pattern LIKE '1888%' OR did_pattern LIKE '1800%' )`;

        // Make API calls for DIDs and tollfree concurrently
        const didsPromise = makeApiCall(didQuery);
        const tollfreePromise = makeApiCall(tollfreeQuery);

        // Return a promise that resolves to an object with the original item and the API responses
        const [didsResponse, tollfreeResponse] = await Promise.all([
          didsPromise,
          tollfreePromise,
        ]);
        return {
          originalItem: item,
          dids: didsResponse?.data,
          tollfree: tollfreeResponse?.data,
        };
      });
      // Wait for all API calls to complete
      const responses = await Promise.all(apiRequests);
      // Update the original array with the new data
      const updatedArray = responses.map((response) => ({
        ...response.originalItem,
        dids: response.dids,
        tollfree: response.tollfree,
      }));
      setUpdatedNationSites(updatedArray);
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      console.error("Error:", error.message);
    }
  };

  function toNumeric(value) {
    return isNaN(value) ? 0 : Number(value);
  }
  return (
    <>
      {isEditing && (
        <AddUpdateNationSitesModal
          editingRecord={editingRecord}
          modalTitle="Add National Site"
          onCancelForm={cancelFormHandler}
        />
      )}
      {isVendors && (
        <VendorsModal
          editingRecord={editingRecord}
          modalTitle="Vendor List"
          onCancelForm={cancelFormHandler}
        />
      )}
      {isLoader || isLoading ? <DotsLoader /> : null}
      <PageHeader
        heading="National Site Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add National Site")}
      />
      {isDids?.length ? (
        <DIDsListingModal
          modalTitle="DIDs List"
          onCancelForm={() => setIsDids(null)}
          dids={isDids}
          updatedNationSites={updatedNationSites}
          setUpdatedNationSites={setUpdatedNationSites}
          setIsDids={setIsDids}
        />
      ) : null}
      {zipCodes?.industry_id ? (
        <ZipCodeListingModal
          modalTitle="Zip Code List"
          onCancelForm={() => setZipCodes(null)}
          payload={zipCodes}
        />
      ) : null}
      {isTollfree?.length ? (
        <TollfreeListingModal
          modalTitle="Tollfree List"
          onCancelForm={() => setIsTollfree(null)}
          tollfree={isTollfree}
          updatedNationSites={updatedNationSites}
          setUpdatedNationSites={setUpdatedNationSites}
          setIsTollfree={setIsTollfree}
        />
      ) : null}
      {isImportVendors && (
        <ImportVendors
          national_site_id={editingRecord}
          modalTitle="Import Vendors"
          onCancelForm={cancelFormHandler}
        />
      )}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={sites?.map((record, index) => {
            let counter = index + 1;
            let records = record;
            const {
              domain,
              industry_id,
              id,
              vendor_count,
              domain_zips_count,
              dids,
              tollfree,
              in_area_zip_count,
              additional_zips_count,
            } = record;

            return {
              counter,
              records,
              domain,
              vendor_count,
              domain_zips_count,
              industry_id: industry?.record?.records?.find(
                ({ cms_id }) => cms_id === industry_id
              )?.name,
              id,
              industryID: industry_id,
              did_count: dids?.length,
              tollfree_count: tollfree?.length,
              in_area_zip_count,
              additional_zips_count,
              out_area_zip_count:
                toNumeric(domain_zips_count) - toNumeric(in_area_zip_count),
            };
          })}
          totalItems={nationSites?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="No"
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default NationSites;
