import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  delete_detach_domain,
  get_detach_domain,
} from "../../../features/domainCenterSlice";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import { DotsLoader } from "../../../components";
import PageHeader from "../../../components/molecules/PageHeader";
const DetachDomain = () => {
  const { isLoading, detachDomain } = useSelector(
    (state) => state.domainCenter
  );

  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = React.useState("");
  const [selectedData, setSelectedData] = React.useState([]);
  useEffect(() => {
    dispatch(get_detach_domain());
    // eslint-disable-next-line
  }, []);
  const onRowSelectionModelChange = (params) => {
    const selectedData = params;
    setSelectedData(selectedData);
  };

  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    {
      headerName: "Industry Name #",
      field: "industry_name",
      flex: 1,
      minWidth: 30,
      width: 30,
    },
    { headerName: "Domain", field: "domain", flex: 1, minWidth: 40, width: 60 },
    {
      headerName: "Created On",
      field: "created_on",
      flex: 1,
      minWidth: 30,
      width: 50,
    },
    { headerName: "Certificate", field: "certificate", flex: 1, minWidth: 30 },
  ];
  const data = detachDomain?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });

  const deleteDomain = async () => {
    const c = window.confirm(`Are you sure want to delete?`);
    if (c) {
      try {
        await dispatch(
          delete_detach_domain({
            ids: selectedData,
          })
        );
      } catch (error) {
        console.log("ImagesList.jsx:48 ~ deleteRecordHandler ~ error", error);
      }
    }
  };
  return (
    <>
      {isLoading && <DotsLoader text="Please Wait" />}
      <PageHeader
        heading="Detach Domains"
        onClick={deleteDomain}
        isAllowed={true}
        buttonTitle="Delete"
        addIcon="No"
      />
      <div className="bg-white !my-3 border rounded">
        <MUIDataTable
          // rowSelection="multiple"
          onRowSelectionModelChange={onRowSelectionModelChange}
          columnDefs={columnDefs}
          items={data?.map((item, index) => {
            let counter = index + 1;
            const { id, industry_id, industry_name, domain } = item;
            let created_on = item.created_on
              ? dayjs(item.created_on).format("ddd, MMM D, YYYY h:mm A")
              : null;
            let certificate = item.certificate;

            return {
              records: item,
              id,
              counter,
              industry_name,
              industry_id,
              domain,
              certificate,
              created_on,
            };
          })}
          checkboxSelection
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          pagination="No"
        />
      </div>
    </>
  );
};

export default DetachDomain;
